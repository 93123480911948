@import "../include/variables";

.faq_wrapper {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   position: relative;
   margin-bottom: 4rem !important;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   .faq_text {
      display: flex;
      flex-direction: column;
      // flex-basis: 40%;
      align-items: flex-start;
      h1 {
         font-style: normal;
         font-weight: 600;
         // font-size: 54px;
         // line-height: 62px;
         max-width: 427px;
         font-weight: 500;
         @include HeaderFontResponsive(54, 62);
      }
      p {
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #596579;
         margin-top: 20px;
         max-width: 359px;
         a {
            text-decoration: none;
         }
      }
   }
   .faq_question {
      flex-basis: 50%;
      .accordion {
         width: 100%;
         @include breakpoint(mobile) {
            width: 100%;
         }
         @include breakpoint(tablet) {
            width: 100%;
         }
         .accordion-item {
            border: none;
            border-bottom: 1px solid #e6eaf0;
            padding-top: 30px;
            padding-bottom: 30px;
            &:nth-last-child(1) {
               border-bottom: none;
            }
            .accordion-button {
               font-weight: 500;
               font-size: 18px;
               color: #374253;
               line-height: 26px;
            }
            .accordion-button:not(.collapsed) {
               background-color: #fff;
               color: #ed1c24;
               box-shadow: none;
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
            }
            .accordion-button::after {
               background-image: url(../../assets/package/expand.png);
            }
            .accordion-button:not(.collapsed)::after {
               background-image: url(../../assets/package/collapse.png);
            }
         }
         .accordion-body {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #374253;
         }
      }
   }
   .showMore_button {
      display: flex;
      position: absolute;
      right: 18px;
      margin-top: 6px;
      margin-bottom: 18px;
      background-color: #ed1c24;
      color: #fff;
      padding: 8px 12px;
      border-radius: 12px;
      &:hover {
         background-color: #e4585d;
      }
   }
}
