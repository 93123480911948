@import "../include/variables";

.channellist_wrapper {
   display: flex;
   flex-direction: column;
   text-align: center;
   .channellist_text {
      p {
         font-weight: 500;
         font-size: 20px;
         line-height: 28px;
         /* identical to box height, or 140% */
         /* Grey/700 */
         color: #374253;
      }
      h1 {
         font-style: normal;
         font-weight: 600;
         // font-size: 54px;
         // line-height: 62px;
         /* identical to box height, or 115% */
         /* Grey/800 */
         color: #192638;
         @include HeaderFontResponsive(54, 62);
         span {
            color: #ed1c24;
         }
      }
   }
   .channellist_category {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 23px;
      border-bottom: 1px solid #e6eaf0;
      @include breakpoint(mobile) {
         justify-content: center;
         gap: 18px;
      }
      @include breakpoint(phablet) {
         justify-content: center;
      }
      @include breakpoint(tablet) {
         justify-content: flex-start;
      }
      @include breakpoint(laptop) {
         justify-content: flex-start;
      }
      @include breakpoint(desktop) {
         justify-content: flex-start;
      }
      @include breakpoint(2k) {
         justify-content: flex-start;
      }
   }
   .channellist_channelcontainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include breakpoint(mobile) {
         justify-content: center;
      }
   }
}
