.map {
   width: 373px;
   height: 96px;
   margin-top: 20px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   background: #f6f6f6;
   border-radius: 8px;
}
#map-title {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 24px;
   color: #374253;
}
#map-address {
   font-style: normal;
   font-weight: 500;
   font-size: 13px;

   display: flex;
   align-items: center;

   /* Grey/600 */

   color: #596579;
}
#direction {
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 13px;
   /* identical to box height, or 100% */

   /* Information/DEF */

   color: #1a63f4;
}
.leaflet-container {
   height: 430px;
   min-width: 400px;
   width: 100%;
}
.map-align {
   display: flex;
   gap: 20px;
}
