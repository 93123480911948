@import "../../include/variables";
.plan-card {
   max-width: 1150px;
   margin: 0 auto;
   padding: 0 10px;
   display: grid;
   grid-gap: 2rem;
   justify-items: center;
   @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 10px;
   }
   @include breakpoint(phablet) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 10px;
   }
   @include breakpoint(tablet) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
   }
   @include breakpoint(laptop) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
   }
   @include breakpoint(desktop) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
   }
   @include breakpoint(2k) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
   }
}

.Dropdown-option:hover {
   background-color: red !important;
   color: #fff !important;
}
.plan-filter-wrapper {
   .Dropdown-control {
      width: 142px;
   }
}
.Dropdown-option.is-selected {
   background-color: none !important;
}
.Dropdown-control {
   width: 120px;
   height: 44px;

   /* Grey/100 */

   background: #f4f7fb;
   border-radius: 8px !important;
}
.inside-plans {
   border-radius: 6px;
   padding-left: 6px;
   padding-right: 6px;
   height: 195px;
   /* Information/DEF */
   background: linear-gradient(103.52deg, #e3193e 1.33%, #c20c5d);
   // background: linear-gradient(103.52deg, #7910ab 1.33%, #541dca 100%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px 12px 0px 0px;
}
.inside-plans:nth-child(2) {
   background-color: red;
}
.inside-plans2 {
   border-radius: 6px;

   height: 195px;
   /* Information/DEF */
   background: linear-gradient(103.52deg, #e3193e 1.33%, #c20c5d);
   // background: red;
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px 12px 0px 0px;
}
#inside-plans-top {
   position: relative;
   top: 25px;
}
.header-li .active {
   color: red;
}
.inside-plans1 {
   border-radius: 6px;

   height: 195px;
   /* Information/DEF */
   background: linear-gradient(103.52deg, #040915 1.33%, #0c0a3d 100%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px 12px 0px 0px;
}
#combo-tag {
   position: absolute;
   width: 82px;
   display: flex;

   height: 32px;
   left: 0;
   background: #ff6f00;
   border-radius: 12px 0px;
}
#combo-tag p {
   text-align: center;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   margin: auto;
   color: #ffffff;
}
#plan-dropdown {
   background-color: white !important;
   border: 1px solid #e6eaf0;
   border-radius: 8px;
   color: #374253;
}
#plan-dropdown::after {
   display: none;
}

//HEADERS

.header-dropdown {
   display: flex;
   justify-content: space-between;
   margin-top: 50px;
   align-items: center;
   gap: 10px;
   @include breakpoint(mobile) {
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      padding: 0;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      padding: 0;
   }
}
.header-li {
   display: flex;
   // margin-left: 40px;
   gap: 15px;
   justify-content: center;
   @include breakpoint(mobile) {
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 8px;
   }
   @include breakpoint(phablet) {
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 8px;
   }
   @include breakpoint(tablet) {
      flex-wrap: wrap;
      margin: 0;
   }
   @include breakpoint(laptop) {
      flex-wrap: nowrap;
      margin: 0;
   }
   @include breakpoint(desktop) {
      flex-wrap: nowrap;
      margin: 0;
   }
   @include breakpoint(2k) {
      margin: 0;
      flex-wrap: nowrap;
   }
}
.header-li a {
   // margin-right: 20px;
   text-decoration: none;
}
.filters {
   border-radius: 8px !important;
   background-color: #ffff;
   color: #374253;
   border: 1px solid #e6eaf0;
}
.existing-user {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   cursor: pointer;
   /* identical to box height, or 150% */

   text-align: center;

   /* Grey/700 */

   color: #374253;
}

.plansbanner {
   width: 100%;
   height: 260px;
   float: left;
   margin-top: 50px;
   background: linear-gradient(90deg, #3d7bf5 48.88%, #3e7cf6 83.05%);
}
.installation {
   font-style: normal;
   font-weight: 500;
   font-size: 32px;
   margin-top: 10px;
   line-height: 52px;
   /* identical to box height, or 162% */

   text-align: center;

   color: #ffffff;
}
.plan-img {
   display: flex;
   justify-content: center;
}
.second-header-wrapper {
   margin-top: 20px;
   display: flex;
   gap: 50px;
   @include breakpoint(mobile) {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
   }
}
.sorting-plans {
   .Dropdown-control {
      width: 255px !important;
   }
}
