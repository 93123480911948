#dashboard-formwidth {
   .error {
      color: #de0303;
      margin-top: 10px;
      &::first-letter {
         text-transform: uppercase;
      }
   }
   #input-dashboard {
      #currentpw {
         padding-right: 25px;
      }
   }
}
