@import "../include/variables";

.share_button_container {
   display: flex;
   margin-left: 8px;
   position: relative;
   .share_icon {
      cursor: pointer;
      display: flex;
      align-items: center;
   }
   .share_popup {
      display: flex;
      position: absolute;
      top: 115%;
      right: -25%;
      background: #fff;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      padding: 7px;
      z-index: 1;
      gap: 6px;
      margin-top: 4px;
      .share_popup_item {
         cursor: pointer;
      }
   }
}

._big_btn_icon_container {
   margin: 0;
   padding: 15px;
   border-radius: 50%;
   background: #fff;
   border: 1px solid #e6eaf0;
   .share_icon {
      display: block;
   }
   .share_popup {
      margin-top: 0;
   }
}
