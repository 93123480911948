.dashboard-profile {
   display: flex;
   gap: 15px;
   align-items: center;
   @media screen and (max-width: 429px) {
      flex-direction: column;
      align-items: baseline;
   }
}
#dashboard-upload {
   background: #f03e45;
   border-radius: 12px;
   border: none;
   padding-left: 12px;
   padding-right: 12px;
   height: 44px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   color: #ffffff;
   cursor: pointer;
   &:hover {
      background: #d31118 !important;
   }
}
#dashboard-remove {
   background: #f4f7fb;
   border-radius: 12px;
   border: none;
   height: 44px;
   width: 105px;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   color: #374253;
   display: flex;
   justify-content: center;
   align-items: center;
}
.dashboard-form {
   margin-top: 30px;
   @media screen and (max-width: 429px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      #input-dashboard {
         width: 320px;
      }
      .input-group {
         width: 320px;
         #input-dashboard {
            width: 200px;
         }
      }
      .input-wrapper-billing,
      #form-account {
         flex-direction: column;
         gap: 12px;
         margin-bottom: 12px;
         div {
            width: 320px;
            #input-flex {
               width: 320px;
            }
         }
         #input-account {
            width: 320px;
         }
      }
   }
}
#form-contact {
   display: flex;
   gap: 125px;
   margin-top: 20px;
}
#form-account {
   display: flex;
   gap: 20px;
   align-items: flex-end;
   margin-top: 20px;
}
#label-distance {
   margin-top: 20px;
}
#dashboardform-btn,
#dashboardform-btn-disable {
   margin-top: 20px;
   background: #ed1c24;
   border-radius: 12px;
   border: none;
   width: 135px;
   height: 45px;
   &:hover {
      background: #d31118 !important;
   }
}
#dashboardform-btn- {
   background: #596579;
   color: #d0d7e2;
}
#dashboard-label {
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/700 */

   color: #374253;
}
#input-dashboard {
   background: #f4f7fb;
   /* Grey/300 */
   width: 130%;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
}
#input-account {
   background: #f4f7fb;
   /* Grey/300 */
   width: 90%;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
}
.disabled {
   cursor: not-allowed;
}
#dashboard-upload-label {
   border-radius: 12px;
   border: 1px solid grey;
   width: 150px;
   height: 44px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   /* color: #ffffff; */
   cursor: pointer;
}
#preview-image {
   border-radius: 50%;
   display: inline-block;
   background-color: #f4f7fb;
   margin: 5px;
   text-align: center;
   padding: 12px;
   vertical-align: middle;
   height: 80px;
   width: 80px;
}
#thumbnail-text {
   background: #fff;
   border: none;
   border-radius: 8px;

   font-size: 20px;
   font-style: normal;
   font-weight: 800;
   height: 50px;
   justify-content: flex-start;
   line-height: 24px;
   padding: 14px 0;
   font-weight: 500;
}
.documment_container {
   display: flex;
   gap: 55px;
   align-items: center;
   margin-top: 20px;
}
.documment_button_container {
   display: flex;
   gap: 10px;
}
#dashboardform-btn-cancel {
   margin-top: 20px;
   background: none;
   border-radius: 12px;
   border: none !important;
   background: #f4f7fb;
   color: #596579;

   width: 135px;
   height: 45px;
   &:hover {
      opacity: 0.7;
   }
   &:active {
      background-color: #f4f7fb !important;
      color: #596579;
      opacity: 0.7;
   }
}

.dashboard-InputGroup {
   max-width: 400px;
}
