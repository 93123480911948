@import "../include/variables";

.aboutus_wrapper {
   display: flex;
   width: 100%;
   align-items: center;
   overflow: hidden;
   flex-direction: column;

   img {
      border-radius: 12px !important;
   }
}
.aboutus__section {
   display: flex;
   align-items: center;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .aboutus__title {
      font-style: normal;
      font-weight: 700;
      display: inherit;
      // font-size: 54px;
      // line-height: 62px;
      /* identical to box height, or 115% */
      @include HeaderFontResponsive(54, 62);
      /* Grey/700 */
      text-align: center;
      color: #374253;
      .aboutus__lines {
         margin-left: 5px;
         margin-bottom: 30px;
      }
   }
   .aboutus__text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      /* or 144% */

      /* Grey/DEF */
      text-align: center;
      color: #7b8698;
      max-width: 75vw;
      margin-top: 24px;
      @include breakpoint(mobile) {
         max-width: 95vw;
         font-size: 14px;
         line-height: 20px;
      }
   }
   .aboutus__message__title {
      margin-top: 34px;
      width: 100%;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 115% */
      /* Grey/700 */
      text-align: left;
      color: #374253;
   }
   .aboutus__message__text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      /* or 144% */

      /* Grey/DEF */

      color: #7b8698;
      max-width: 75vw;
      margin-top: 14px;
   }
   .aboutus__image {
      margin-top: 40px;
      object-fit: contain;
      max-height: 507px;
      overflow: hidden !important;
      border-radius: 12px !important;
      width: 100%;
      @include breakpoint(mobile) {
         height: auto;
         width: 100%;
         margin-top: 20px;
      }
   }
   .about_us_second {
      img {
         border-radius: 12px !important;
      }
   }

   @include breakpoint(mobile) {
      height: auto;
      width: 100%;
      margin-top: 20px;
   }

   .aboutus__client_info {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 10px;
      .aboutus__client_info__item {
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-top: 55px;
         flex-basis: 24%;
         @include breakpoint(mobile) {
            margin-top: 20px;
            flex-basis: 45%;
         }
         .aboutus__client_info__title {
            font-style: normal;
            font-weight: 600;
            color: #374253;
            margin-bottom: 15px;
            @include HeaderFontResponsive(40, 52);
            @include breakpoint(mobile) {
               margin-bottom: 5px;
            }
         }
         .aboutus__client_info__text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #7b8698;
            @include breakpoint(mobile) {
               font-size: 14px;
               line-height: 20px;
               margin-bottom: 10px;
            }
         }
      }
   }
}
.aboutus__section {
   .aboutus__section_two__img {
      flex-basis: 50%;
      img {
         width: 100%;
         height: auto;
      }
   }
   .aboutus__section_two__text {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-left: 12px;
      flex-basis: 50%;
      .aboutus__title {
         text-align: left;
         @include HeaderFontResponsive(40, 52);
      }
      .aboutus__text {
         text-align: left;
      }
   }
}

.aboutus__section {
   .aboutus__section_three {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      @include breakpoint(mobile) {
         flex-direction: column-reverse;
      }
      @include breakpoint(phablet) {
         flex-direction: column-reverse;
      }
      @include breakpoint(tablet) {
         flex-direction: column-reverse;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
      }
      @include breakpoint(2k) {
         flex-direction: row;
      }
      .aboutus__section_three__item {
         display: flex;
         flex-direction: column;
         flex-basis: 70%;
         padding-left: 10px;
         padding-right: 6px;
         .aboutus__section_three__title {
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 52px;
            color: #192638;
            margin-bottom: 70px;
            @include breakpoint(mobile) {
               margin-bottom: 30px;
               font-size: 34px;
               line-height: 40px;
            }
         }
         .aboutus__section_three__card_container {
            display: grid;
            grid-auto-flow: row;
            margin-bottom: 60px;
            justify-items: center;
            gap: 15px;
            @include breakpoint(mobile) {
               grid-template-columns: 1fr;
            }
            @include breakpoint(phablet) {
               grid-template-columns: 1fr;
            }
            @include breakpoint(tablet) {
               grid-template-columns: 1fr 1fr;
            }
            @include breakpoint(laptop) {
               grid-template-columns: 1fr 1fr;
            }
            @include breakpoint(desktop) {
               grid-template-columns: 1fr 1fr;
            }
            @include breakpoint(2k) {
               grid-template-columns: 1fr 1fr;
            }
            .aboutus__section_three__card {
               width: 100%;
               max-width: 320px;
               .aboutus__section_three__card_title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: #374253;
                  margin-bottom: 10px;
                  @include breakpoint(mobile) {
                     font-size: 18px;
                     line-height: 24px;
                     margin-bottom: 5px;
                  }
               }
               .aboutus__section_three__card_text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #374253;
                  margin: 0;
               }
            }
         }
      }
      .aboutus__section_three__img {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         flex-basis: 50%;
      }
   }
}

.aboutus_slider_container {
   display: flex;
   gap: 100px;
   margin-bottom: 240px;
   @include breakpoint(mobile) {
      align-items: center;
   }
   @include breakpoint(phablet) {
      align-items: center;
   }
   @include breakpoint(tablet) {
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: flex-start;
   }
   @include breakpoint(desktop) {
      align-items: flex-start;
   }
   @include breakpoint(2k) {
      align-items: flex-start;
   }
   @include breakpoint(mobile) {
      flex-direction: column-reverse;
   }
   @include breakpoint(phablet) {
      flex-direction: column-reverse;
   }
   @include breakpoint(tablet) {
      flex-direction: column-reverse;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .aboutUsSlider__img {
      position: relative;
      @include breakpoint(mobile) {
         margin-bottom: 190px;
      }
      @include breakpoint(phablet) {
         margin-bottom: 130px;
      }
      @include breakpoint(tablet) {
         margin-bottom: 110px;
      }
      @include breakpoint(laptop) {
         margin-bottom: 30px;
      }
      @include breakpoint(desktop) {
         margin-bottom: 30px;
      }
      @include breakpoint(2k) {
         margin-bottom: 30px;
      }
      img {
         border-radius: 8px;
         height: 700px;
         width: 550px;
         object-fit: contain;
      }
      .slider {
         position: absolute;
         top: 75%;
         box-shadow: 0px 7px 22px rgba(0, 0, 0, 0.18);
         background: #fff;
         @include breakpoint(mobile) {
            left: 9%;
         }
         @media screen and (max-width: 455px) {
            left: 16%;
         }
         @include breakpoint(phablet) {
            left: 5%;
         }
         @include breakpoint(tablet) {
            left: 1%;
         }
         @include breakpoint(laptop) {
            left: 10%;
         }
         @include breakpoint(desktop) {
            left: 20%;
         }
         @include breakpoint(2k) {
            left: 20%;
         }
         .splide {
            @include breakpoint(mobile) {
               width: 450px;
            }
            @media screen and (max-width: 455px) {
               width: 370px;
            }
            @include breakpoint(phablet) {
               width: 500px;
            }
            @include breakpoint(tablet) {
               width: 585px;
            }
            @include breakpoint(laptop) {
               width: 585px;
            }
            @include breakpoint(desktop) {
               width: 585px;
            }
            @include breakpoint(2k) {
               width: 585px;
            }
         }
         .splide__pagination {
            bottom: -3.5em;
            height: 45px;
         }
         .splide__pagination__page.is-active {
            background: #7b8698;
            transform: none;
         }
         .splide__arrow--next {
            @include breakpoint(mobile) {
               right: 9em !important;
            }
            @media screen and (max-width: 455px) {
               right: 8em !important;
            }

            @include breakpoint(phablet) {
               right: -3em !important;
            }
            @include breakpoint(tablet) {
               right: -3em !important;
            }
            @include breakpoint(laptop) {
               right: -18em !important;
            }
            @include breakpoint(desktop) {
               right: -20em !important;
            }
            @include breakpoint(2k) {
               right: -25em !important;
            }
         }
         .splide__arrow--prev {
            @include breakpoint(mobile) {
               left: 9em !important;
            }
            @media screen and (max-width: 455px) {
               left: 8em !important;
            }
            @include breakpoint(phablet) {
               left: -3em !important;
            }
            @include breakpoint(tablet) {
               left: -3em !important;
            }
            @include breakpoint(laptop) {
               left: 48em !important;
            }
            @include breakpoint(desktop) {
               left: 50em !important;
            }
            @include breakpoint(2k) {
               left: 54em !important;
            }
         }
         .splide__arrow {
            @include breakpoint(mobile) {
               top: 107% !important;
            }
            @media screen and (max-width: 455px) {
               top: 109% !important;
            }

            @include breakpoint(phablet) {
               top: 50% !important;
            }
            @include breakpoint(tablet) {
               top: 50% !important;
            }
            @include breakpoint(laptop) {
               top: 50% !important;
            }
            @include breakpoint(desktop) {
               top: 50% !important;
            }
            @include breakpoint(2k) {
               top: 50% !important;
            }
         }
         .slider__container {
            max-width: 580px;
            background: #ffffff;
            border-radius: 8px;
            padding: 40px;
            background: url("../../assets/quote.png") no-repeat;
            background-size: 40px 40px;
            background-position: left 25px top 20px;
            @include breakpoint(mobile) {
               width: 450px;
               padding: 30px;
            }
            @media screen and (max-width: 455px) {
               width: 360px;
               padding: 20px;
            }
            @include breakpoint(phablet) {
               width: 500px;
            }
            @include breakpoint(tablet) {
               width: 585px;
            }
            @include breakpoint(laptop) {
               width: 585px;
            }
            @include breakpoint(desktop) {
               width: 585px;
            }
            @include breakpoint(2k) {
               width: 585px;
            }

            .text {
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 32px;
               letter-spacing: -0.85px;
               color: #596579;
               margin-bottom: 20px;
               @include breakpoint(mobile) {
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: -0.75px;
               }
               // &::before {
               //    content: url("../../assets/quote.png");
               //    position: absolute;
               //    top: 24px;
               //    z-index: 0;
               // }
            }
            .name {
               font-style: normal;
               font-weight: 700;
               font-size: 18px;
               line-height: 32px;
               letter-spacing: -0.85px;
               color: #000000;
               margin-bottom: 2px;
               @include breakpoint(mobile) {
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: -0.75px;
               }
            }
            .position {
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 32px;
               letter-spacing: -0.85px;
               color: #7b8698;
               margin: 0px;
               @include breakpoint(mobile) {
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: -0.75px;
               }
            }
         }
      }
   }
   .aboutUsSlider__text {
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      padding-left: 10px;
      padding-right: 6px;
      .heading {
         font-family: "SF Pro Display";
         font-style: normal;
         font-weight: 700;
         // font-size: 40px;
         // line-height: 52px;
         max-width: 540px;
         color: #192638;
         margin-bottom: 24px;
         @include HeaderFontResponsive(40, 52);
         span {
            .border {
               width: 100%;
               height: 100%;
               position: absolute;
               border: 2px solid black;
               top: -10px;
               left: -10px;
               z-index: 10;
            }
         }
      }
      .text {
         font-family: "SF Pro Display";
         font-style: normal;
         letter-spacing: 0.3px;
         font-weight: 500;
         font-size: 18px;
         line-height: 32px;
         max-width: 540px;
         color: #596579;
      }
   }
}
@media only screen and (min-width: 1200px) {
   .aboutus_image {
      overflow: hidden;
      border-radius: 12px !important;
   }
}
