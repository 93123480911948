@import "../../scss/include/variables";

.nextmoviecard_wrapper {
   display: flex;
   flex-direction: column;
   max-width: 305px;
   .nextmoviecard_img {
      border-radius: 6px;
      position: relative;
      &:hover .nextmoviecard_img_img {
         opacity: 0.5;
      }
      &:hover .nextmoviecard_info {
         opacity: 1;
      }
      img {
         height: 370px;
         width: 250px;
         border-radius: 6px;
         transition: 0.5s ease;
         backface-visibility: hidden;
         object-fit: cover;
      }
      .nextmoviecard_info {
         transition: 0.3s ease;
         opacity: 0;
         position: absolute;
         height: 100%;
         width: 100%;
         top: 50%;
         left: 50%;
         border-radius: 6px;
         transform: translate(-50%, -50%);
         -ms-transform: translate(-50%, -50%);
         text-align: center;
         background: rgba(0, 0, 0, 0.6);
         //button alignment
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         .nextmoviecard_button {
            min-width: 157px;
            border: 1.2px solid #ffffff;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.05);
            color: #ffffff;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            text-transform: capitalize;
            margin: 6px 0px;
            padding: 12px 0px;
            cursor: pointer;
         }
      }
   }
   .nextmoviecard_text {
      text-align: center;
      p {
         font-style: normal;
         font-weight: 600;
         font-size: 20px;
         line-height: 28px;
         /* identical to box height, or 140% */
         margin-top: 16px;
         /* Grey/800 */
         color: #d0d7e2;
      }
   }
}
