@import "../../scss/include/variables";
.career_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
}
.career_banner {
   display: flex;
   height: 370px;
   align-items: center;
   justify-content: space-between;
   padding-left: 12px;
   .career_banner_text {
      display: flex;
      flex-direction: column;
      .career {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #7b8698;
      }
      .career_header {
         font-style: normal;
         font-weight: 700;
         font-size: 54px;
         line-height: 62px;
         color: #192638;
         max-width: 450px;
         span {
            color: #0e9e49;
         }
      }
      .career_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 450px;
      }
   }
   .career_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}

.career_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 80px;
   .career_title {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 52px;
      color: #192638;
      span {
         color: #ed1c24;
      }
      margin-bottom: 90px;
   }
   .career_card_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 6px;
      padding-right: 2px;
      @include breakpoint(mobile) {
         justify-content: center;
      }
      @include breakpoint(phablet) {
         justify-content: center;
      }
      @include breakpoint(tablet) {
         justify-content: space-between;
      }
      @include breakpoint(laptop) {
         justify-content: space-between;
      }
      @include breakpoint(desktop) {
         justify-content: space-between;
      }
      @include breakpoint(2k) {
         justify-content: space-between;
      }
      .career_card {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         max-width: 330px;
         img {
            margin-bottom: 20px;
         }
         .card_title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #192638;
            margin-bottom: 14px;
         }
         .card_text {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #596579;
            max-width: 310px;
         }
      }
   }
   .career_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 100px;
      .career_container_title_container {
         display: flex;
         width: 100%;
         justify-content: space-between;
         @include breakpoint(mobile) {
            flex-direction: column;
         }
         @include breakpoint(phablet) {
            flex-direction: column;
         }
         @include breakpoint(tablet) {
            flex-direction: row;
         }
         @include breakpoint(laptop) {
            flex-direction: row;
         }
         @include breakpoint(desktop) {
            flex-direction: row;
         }
         @include breakpoint(2k) {
            flex-direction: row;
         }
         .titleContainer {
            display: flex;
            flex-direction: column;
            padding-left: 12px;
            .title {
               font-weight: 600;
               font-size: 24px;
               line-height: 36px;
               color: #ed1c24;
            }
            .sub_caption {
               font-weight: 400;
               font-size: 16px;
               line-height: 24px;
               color: #596579;
            }
         }
         .searchContainer {
            align-self: center;
            @include breakpoint(mobile) {
               align-self: flex-start;
               padding-left: 10px;
            }
            #search-icon {
               border: 1px solid #d0d7e2;
               background-color: #fff;
               border-right: none;
               border-radius: 8px;
               border-top-right-radius: 0;
               border-bottom-right-radius: 0;
            }
            .searchInput {
               border: 1px solid #d0d7e2;

               background-color: #fff;
               border-left: none;
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 16px;
               text-align: left;
               color: #7b8698;
               border-radius: 8px;
               border-top-left-radius: 0;
               border-bottom-left-radius: 0;
            }
         }
      }
      .career_opportunity {
         display: flex;
         flex-direction: column;
         padding-left: 12px;
         padding-right: 8px;
         .career_opportunity_title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #192638;
            margin-top: 80px;
            padding-bottom: 24px;
            border-bottom: 1px solid #e7e6eb;
         }
         .job_tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            border-bottom: 1px solid #e7e6eb;
            .job_title {
               font-style: normal;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
               color: #374253;
               margin: 0px;
            }
            .apply_now_buttton {
               border: none;
               color: #1a63f4;
               background: none;
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
            }
         }
      }
   }
   .career_footer_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-top: 120px;
      .caption {
         font-style: normal;
         font-weight: 600;
         font-size: 40px;
         line-height: 52px;
         color: #374253;
         span {
            color: #0e9e49;
         }
      }
      .subcaption {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #596579;
         max-width: 750px;
         a {
            text-decoration: none;
            color: #ed1c24;
         }
      }
   }
}
.job_application__wrapper {
   display: flex;
   gap: 40px;
   justify-content: space-around;
   padding-bottom: 15px;
   padding: 35px;
   position: relative;
   @include breakpoint(mobile) {
      flex-direction: column;
      padding: 15px 5px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .closeIcon {
      position: absolute;
      right: -2px;
      top: -16px;
      font-size: 18px;
   }
   .job__info {
      flex-basis: 70%;
      overflow-y: scroll;
      padding-right: 12px;
      flex-basis: 60%;
      .jobtitle {
         font-style: normal;
         font-weight: 600;
         font-size: 30px;
         line-height: 52px;
         /* identical to box height, or 130% */

         /* Grey/900 */

         color: #0f1825;

         margin-bottom: 24px;
      }
      .jobdescription {
         font-weight: 400;
         // font-size: 18px;
         line-height: 26px;
         color: #596579;
         margin-bottom: 10px;
         b {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            /* Grey/700 */

            color: #374253;
         }
         // b {
         //    font-weight: 600;
         //    font-size: 20px;
         //    line-height: 28px;
         // }
      }
      .jobdescription {
         p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
            margin-bottom: 0px;
         }
      }
   }
   .job_application_container {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-basis: 40%;
      input {
         width: 100%;
         max-width: 100%;
         margin-bottom: 8px !important;
      }
      .gender_checkbox_container {
         display: flex;
         align-items: center;
         margin: 2px 0px;
         label {
            margin: 0;
         }
         input {
            width: auto;
            margin-bottom: 0px !important;
            margin-right: 4px;
            appearance: #0e9e49;
         }
      }
      .applynow_btn_inactive,
      .applynow_btn {
         margin-top: 6px;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 10px 0px;
         width: 100%;
         font-size: 18px;
         line-height: 24px;
         font-weight: 500;
         color: #e7e6eb;
         background-color: #ed1c24;
         border: none;
         border-radius: 8px;
         &:hover {
            background: #d31118 !important;
         }
      }
      .applynow_btn_inactive {
         background-color: #374253;
      }
   }
}
.careers-description {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
   /* or 157% */

   /* Grey/600 */

   color: #596579;
}
.jobdescription_location {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 36px;
   /* identical to box height, or 150% */

   /* Grey/700 */

   color: #596579;
}

.job_application_modal {
   .modal-dialog {
      width: 100%;
      max-width: 1000px;
      @include breakpoint(mobile) {
         margin: 0px;
      }
   }
}
.input-group {
   flex-wrap: nowrap;
}
.applynow_btn_cancel {
   margin-top: 6px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px 0px;
   width: 100%;
   font-size: 18px;
   line-height: 24px;
   font-weight: 500;
   color: #596579;
   background: #f4f7fb;
   border: none !important;
   border-radius: 8px;
   &:hover {
      background-color: #e8ecf2;
   }
}
