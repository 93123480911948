$primary: #c91319;
$white: #e6eaf0;
$grey: #192638;

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
   @if $point == 2k {
      @media screen and (min-width: 1800px) {
         @content;
      }
   } @else if $point == desktop {
      @media screen and (min-width: 1400px) {
         @content;
      }
   } @else if $point == laptop {
      @media screen and (min-width: 992px) {
         @content;
      }
   } @else if $point == tablet {
      @media screen and (min-width: 768px) {
         @content;
      }
   } @else if $point == phablet {
      @media screen and (min-width: 600px) {
         @content;
      }
   } @else if $point == mobile {
      @media screen and (max-width: 600px) {
         @content;
      }
   }
}

//usuage

/*
@include breakpoint(mobile){
   style for desktop
}
@include breakpoint(phablet){
    style for desktop
}
@include breakpoint(tablet){
    style for desktop
}
@include breakpoint(laptop){
    style for desktop
}
@include breakpoint(desktop){
    style for desktop
}
@include breakpoint(2k){
    style for desktop
}

*/

//Font Mixin Header
@mixin BigHeaderFontResponsive($size, $lineHeight) {
   @include breakpoint(mobile) {
      font-size: ($size - 20) + px;
      line-height: ($lineHeight - 20) + px;
   }
   @include breakpoint(phablet) {
      font-size: ($size - 16) + px;
      line-height: ($lineHeight - 16) + px;
   }
   @include breakpoint(tablet) {
      font-size: ($size - 10) + px;
      line-height: ($lineHeight - 10) + px;
   }
   @include breakpoint(laptop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(desktop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(2k) {
      line-height: $lineHeight + px;

      font-size: $size + px;
   }
}

@mixin HeaderFontResponsive($size, $lineHeight) {
   @include breakpoint(mobile) {
      font-size: ($size - 12) + px;
      line-height: ($lineHeight - 12) + px;
   }
   @include breakpoint(phablet) {
      font-size: ($size - 10) + px;
      line-height: ($lineHeight - 10) + px;
   }
   @include breakpoint(tablet) {
      font-size: ($size - 6) + px;
      line-height: ($lineHeight - 6) + px;
   }
   @include breakpoint(laptop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(desktop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(2k) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
}

//mixin paragraph responsive

@mixin Paragraph($size, $lineHeight) {
   @include breakpoint(mobile) {
      font-size: ($size - 8) + px;
      line-height: ($lineHeight - 8) + px;
   }
   @include breakpoint(phablet) {
      font-size: ($size - 6) + px;
      line-height: ($lineHeight - 6) + px;
   }
   @include breakpoint(tablet) {
      font-size: ($size - 4) + px;
      line-height: ($lineHeight - 4) + px;
   }
   @include breakpoint(laptop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(desktop) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
   @include breakpoint(2k) {
      font-size: $size + px;
      line-height: $lineHeight + px;
   }
}
