@import "../../scss/include/variables";
.Login_Wrapper {
   align-items: center;
   justify-content: space-between;
   width: 100%;
   display: flex;
}

.Logo {
   margin-top: 10px;
}
.Login_Form {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   @include breakpoint(mobile) {
      align-items: center;
   }
   @include breakpoint(phablet) {
      align-items: center;
   }
   @include breakpoint(tablet) {
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: stretch;
   }
   @include breakpoint(desktop) {
      align-items: stretch;
   }
   @include breakpoint(2k) {
      align-items: stretch;
   }
   .Login_Alert {
      display: none;
   }
   .Login_Alert_show {
      max-width: 400px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: $primary;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      margin-top: 8px;
      align-self: flex-start;
      svg {
         display: none;
      }
   }
   .Login_Header {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #192638;
   }
   .Register_Header {
      margin-bottom: 40px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #192638;
      a {
         text-decoration: none;
         color: #1a63f4;
      }
   }
   @include breakpoint(mobile) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(tablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(phablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(desktop) {
      width: 50%;
   }
   @include breakpoint(laptop) {
      width: 50%;
   }
}
.Login_Container {
   display: flex;
   flex-direction: column;
   padding-top: 15px;
   .Email_Input {
      padding-right: 38px;
   }

   .Signin_Button {
      background: #ed1c24;
      border-radius: 8px;
      border: none;
      height: 44px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      max-width: 400px;
      width: 100%;
      margin-top: 20px;
      &:hover {
         background: #d31118 !important;
      }
   }
   .disabled {
      background: $grey !important;
      cursor: no-drop;
   }
   .ForgetPassword_Wrapper_Modal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 400px;
      margin-bottom: 35px;

      ._checkbox {
         display: flex;
         align-items: center;
         .CheckBox {
            margin: 0 !important;

            margin-right: 4px !important;
            height: 20px !important;
            width: 20px !important ;
         }
         label {
            margin: 0;
         }
      }
   }
   .ForgetPassword_Wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 400px;
      margin-bottom: 35px;
      flex-direction: column;
      .checkbox_Wrapper {
         display: flex;
         width: 100%;
         justify-content: space-between;
         ._checkbox {
            display: flex;
            align-items: center;
            .CheckBox {
               margin: 0 !important;

               margin-right: 4px !important;
               height: 20px !important;
               width: 20px !important ;
            }
            label {
               margin: 0;
            }
         }
      }
   }

   .ForgetPassword_Link {
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #1a63f4;
   }
   input {
      margin-bottom: 32px !important;
      width: 100% !important;
      height: 44px !important;
   }
}
.Login_Image {
   flex-basis: 50%;
   width: 100%;
   max-height: 477px;
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(laptop) {
      display: flex;
   }
   @include breakpoint(desktop) {
      display: flex;
   }
   img {
      object-fit: cover;
      width: 100%;
      border-radius: 12px;
   }
}
.myCheckbox input {
   border-radius: 5px !important;
   border: 1px solid #d7cce4 !important;
   background: #ffffff !important;
}
input[type="checkbox"] {
   border-radius: 5px !important;
   border: 1px solid #d7cce4 !important;
   // background: #ffffff;
   z-index: 1;
}
.otp-login {
   margin-top: 30px;
   padding: 0 25px;
   gap: 15px;
   background: #fee9e9;
   height: 81px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   p {
      font-style: normal;
      font-weight: 500;
      margin: auto;
      font-size: 14px;
      line-height: 22px;
      /* or 157% */

      /* Grey/600 */

      color: #596579;
   }
}
#otp-href {
   color: #ed1c24;
   font-weight: 600;
   text-decoration: underline;
   cursor: pointer;
}
.ForgetPassword_Wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 400px;
   margin-bottom: 35px;
   flex-direction: column;
   .checkbox_Wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      ._checkbox {
         display: flex;
         align-items: center;
         .CheckBox {
            margin: 0 !important;

            margin-right: 4px !important;
            height: 20px !important;
            width: 20px !important ;
         }
         label {
            margin: 0;
         }
      }
   }
}
