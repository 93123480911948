@import "../include/variables";

.infocard_wrapper {
   box-shadow: 2px 3px 20px -8px rgba(35, 30, 36, 0.1);
   border-radius: 12px;
   flex-basis: 24%;
   max-width: 240px;
   min-width: 215px;
   height: 300px;
   display: flex;
   flex-direction: column;
   // width: fit-content;
   align-items: center;
   .img {
      margin-bottom: 20px;
      height: 60px;
      width: 60px;
      img {
         height: 100%;
         width: 100%;
         object-fit: contain;
      }
   }
   .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      /* identical to box height, or 100% */
      text-align: center;
      /* Grey/50 */
      color: #ffffff;
   }
   .description {
      margin-top: 15px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      /* or 144% */
      text-align: center;
      /* Grey/50 */
      color: #ffffff;
   }
}
