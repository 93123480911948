.search-title {
   text-align: center;
   padding-top: 20px;
   color: red;
   font-weight: 25px;
}
#search-link {
   font-size: 14px;
   color: #999;
   margin: 2px 0 8px 0;
   padding: 0;
   display: block;
   text-decoration: none;
}
#search-form {
   width: 100%;
   max-width: 100% !important;
}
#search-form-input {
   max-width: 1000vw !important;
   width: 100%;
}
#search-heading {
   margin-top: 20px;
   font-size: 22px;
   color: red;
   cursor: pointer;
}
.search-result-wrapper {
   margin-top: 15px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.18);
   margin-bottom: 20px;
}
mark {
   background-color: red;
   color: white;
}
