@import "../../scss/include/variables";
.info_banner {
   margin-top: 8%;
   background-image: url("../../assets/infobanner.webp");

   display: flex;
   justify-content: space-around;
   align-items: center;
   text-align: center !important;
}
.infobanner_container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 18px;
   @include breakpoint(mobile) {
      flex-direction: column-reverse;
   }
   @include breakpoint(phablet) {
      flex-direction: column-reverse;
   }
   @include breakpoint(tablet) {
      flex-direction: column-reverse;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .infobanner_text {
      background-image: url("../../assets/internet_bg.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      text-align: left;
      @include breakpoint(mobile) {
         width: 100%;
         padding-left: 25px;
      }
      @include breakpoint(phablet) {
         width: 100%;
         padding-left: 45px;
      }
      @include breakpoint(tablet) {
         width: 100%;
         padding-left: 85px;
      }
      @include breakpoint(laptop) {
         padding-left: 0px;
         width: 784px;
      }
      @include breakpoint(desktop) {
         padding-left: 0px;
         width: 784px;
      }
      @include breakpoint(2k) {
         padding-left: 0px;
         width: 784px;
      }
   }
}
.items {
   align-items: center;
}
.infobanner_heading {
   font-style: normal;
   font-weight: 700;
   // line-height: 50px;
   /* or 124% */
   @include HeaderFontResponsive(45, 50);
   /* Grey/50 */

   color: #ffffff;
   max-width: 420px;
}
.infobanner_para {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 26px;
   /* or 150% */

   /* Grey/200 */
   margin-bottom: 40px;
   color: #e6eaf0;
   max-width: 475px;
}
.packages_link {
   cursor: pointer;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 20px;
   color: #0faf51;
   text-decoration: none;
}
.infobanner_image {
   margin-top: 50px;
   width: 100%;
   max-width: 600px;
   .infoBanner__player-wrapper {
      position: relative;
      flex-basis: 35%;
      height: 361px;
      filter: drop-shadow(10px 15px 24px rgba(37, 36, 36, 0.25));
      @include breakpoint(laptop) {
         margin-bottom: 24px;
      }

      .react-player {
         position: relative;
         object-fit: cover;
         width: 100% !important;
         height: 360px;
         // &::before {
         //    content: "";
         //    position: absolute;
         //    top: 0;
         //    left: 0;
         //    width: 100%;
         //    height: 18%;
         //    background: rgba(0, 0, 0);
         //    z-index: 1;
         // }
         // &::after {
         //    content: "";
         //    position: absolute;
         //    bottom: 0;
         //    left: 0;
         //    width: 99%;
         //    height: 11%;
         //    background: #000;
         //    z-index: 2;
         // }
         video {
            object-fit: cover;
            border: 16px solid black;

            background-color: black;
         }
      }
   }
}
