@import "../../scss/include/variables";

.individualpackagecard_wrapper {
   overflow: hidden;
   display: flex;
   flex-direction: column;
   width: 380px;
   max-width: 400px;
   align-items: center;
   background: #fff;
   margin: 15px;
   background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(93.97deg, #ad00c9 2.18%, #00d1ff 50.68%) border-box;
   border-radius: 16px;
   border: 2px solid transparent;
}

@include breakpoint(mobile) {
}
@include breakpoint(tablet) {
}
@include breakpoint(phablet) {
}
@include breakpoint(laptop) {
}
@include breakpoint(desktop) {
}

.ribbon {
   width: 250px;
   background: #ed1c24;
   position: relative;
   top: 40px;
   right: -127px;
   left: auto;
   transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
   text-align: center;
   font-size: 18px;
   line-height: 32px;
   letter-spacing: 1px;
   /* identical to box height, or 150% */
   font-weight: 500;
   /* Grey/50 */
   color: #ffffff;
   @include breakpoint(mobile) {
      font-size: 16px;
   }
   @include breakpoint(tablet) {
      font-size: 16px;
      right: -148px;
   }
   @include breakpoint(phablet) {
      font-size: 17px;
      right: -94px;
   }
   @include breakpoint(laptop) {
      right: -127px;
      font-size: 18px;
   }
   @include breakpoint(desktop) {
      right: -127px;
      font-size: 18px;
   }
}

.info_wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   text-align: center;
   align-items: center;
   border-bottom: 1px solid #a7b2c3;
   .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height, or 130% */
      /* Grey/700 */
      color: #374253;
   }
   .description {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */
      max-width: 300px;
      /* Grey/800 */
      color: #192638;
      margin-bottom: 23px;
   }
   .price {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 46px;
      /* identical to box height, or 115% */

      /* Grey/900 */

      color: #0f1825;
      sup {
         vertical-align: super;
         font-weight: 500;
         font-size: 14px;
         line-height: 17px;
         /* identical to box height */

         /* Grey/700 */

         color: #374253;
      }

      sub {
         font-weight: 500;
         font-size: 14px;
         line-height: 17px;
         /* identical to box height */

         /* Grey/DEF */
         color: #7b8698;
      }
   }
}

.individualpackagecard_feature_wrapper {
   width: 100%;
   list-style-type: none;
   /* identical to box height, or 27px */
   border-bottom: 1px solid #a7b2c3;
   position: relative;
   /* Grey/700 */
   li {
      // padding-left: 3em;
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      /* identical to box height, or 27px */
      /* Grey/700 */
      color: #374253;
      @include breakpoint(mobile) {
         padding-left: 0px;
      }
      @include breakpoint(tablet) {
         padding-left: 0px;
      }
      @include breakpoint(phablet) {
         padding-left: 0px;
      }
      @include breakpoint(laptop) {
         padding-left: 1em;
      }
      @include breakpoint(desktop) {
         padding-left: 1em;
      }
      img {
         padding-right: 8px;
      }
   }
}

.duration_wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;
   .checkbox_wrapper {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      input[type="radio"] {
         height: 24px !important;
         width: 24px !important;
         margin: 1px;

         accent-color: #0e9e49;
      }
      label {
         font-weight: normal;
         font-size: 18px;
         /* identical to box height, or 133% */
         margin-left: 5px;
         /* Grey/700 */
         color: #374253;
      }
   }
   @include breakpoint(mobile) {
      padding-left: 0px !important;
   }
   @include breakpoint(tablet) {
      padding-left: 0px !important;
   }
   @include breakpoint(phablet) {
      padding-left: 0px !important;
   }
   @include breakpoint(laptop) {
      padding-left: 3em !important;
   }
   @include breakpoint(desktop) {
      padding-left: 3em !important;
   }
}

.order_button {
   background: #ed1c24;
   /* Primary/DEF */
   border: 1.9px solid #ed1c24;
   box-sizing: border-box;
   border-radius: 12px;
   padding: 12px 20px;
   width: 262px;
   color: #fff;
   font-style: normal;
   font-weight: 500;
   font-size: 23px;
   line-height: 20px;
   &:hover {
      background: #d31118;
   }
}
.li_alignment {
   display: flex;
   margin-bottom: -15px;
   align-items: baseline;
}
