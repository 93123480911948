@import "../../scss/include/variables";

.wrapper {
   position: relative;
   height: calc(100vh - 110px);
   overflow: hidden;
   width: 100%;
   position: relative;
   max-height: 750px;
   height: auto;
   .container {
      display: flex;
      height: 100%;
      .row {
         width: 100%;
      }
   }
   div {
      // padding-top: 0px !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      // padding-bottom: 0px !important;
   }
   @include breakpoint(mobile) {
      // height: calc(60vh - 110px);
      height: auto;
      min-height: 300px;
      div {
         padding-top: 0 !important;
      }
      .container {
         position: absolute;
         top: 0px;
      }
   }

   @include breakpoint(phablet) {
      min-height: 300px;
      height: auto;
      .container {
         position: absolute;
         top: 0px;
      }
   }
   @include breakpoint(tablet) {
      // height: calc(50vh - 110px);
      min-height: 400px;
      .container {
         position: relative;
         top: 0px;
      }
   }
   @include breakpoint(laptop) {
      // height: calc(100vh - 110px);
      min-height: 450px;
      .container {
         position: relative;
         top: 0px;
      }
   }
   @include breakpoint(desktop) {
      // height: calc(100vh - 110px);
      min-height: 650px;
      .container {
         position: relative;
         top: 0px;
      }
   }
   @include breakpoint(2k) {
      // height: calc(100vh - 110px);
      min-height: 750px;
      .container {
         position: relative;
         top: 0px;
      }
   }
   .py-5 {
      padding-bottom: 0 !important;
   }
   h1 {
      margin-top: 50px;
      font-style: normal;
      font-weight: bold;
      // font-size: 54px;
      // line-height: 64px !important;
      letter-spacing: -0.015em;
      color: #ffffff;
      @include BigHeaderFontResponsive(54, 64);
   }
   p {
      color: #ffffff;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
   }
   .row {
      --bs-gutter-x: 0;
   }
   .btn {
      background: #ffffff;
      border: none;
      border-radius: 60px;
      color: #ed1c24;
      transition: filter 0.3s;
      &:hover {
         filter: brightness(0.7);
      }
   }
   img {
      height: auto;
   }
   // .heroImage {
   //    height: 100%;
   //    @include breakpoint(mobile) {
   //       display: none !important;
   //    }
   //    @include breakpoint(phablet) {
   //       display: none !important;
   //    }
   //    @include breakpoint(tablet) {
   //       display: none !important;
   //    }
   //    @include breakpoint(laptop) {
   //       display: block !important;
   //    }
   //    @include breakpoint(desktop) {
   //       display: block !important;
   //    }
   //    @include breakpoint(2k) {
   //       display: block !important;
   //    }
   // }
}
