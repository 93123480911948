@import "../include/variables";
.row {
   justify-content: space-between;
}
.cartoon_image img {
   width: 100%;
}
.cartoon_text_wrapper {
   display: flex;
   align-items: center;
   @include breakpoint(mobile) {
      margin-top: 40px;
   }
   @include breakpoint(phablet) {
      margin-top: 40px;
   }
}
.cartoon_title {
   font-style: normal;
   font-weight: bold;
   // font-size: 40px;
   // line-height: 50px;
   /* or 124% */
   @include HeaderFontResponsive(40, 50);
   /* Success/DEF */
   color: black;
}
.cartoon_banner {
   background-image: url("../../assets/cartoonbackground.webp");
   background-repeat: no-repeat;
   margin-top: 5%;
   display: flex;
   justify-content: center;
   .cartoonBanner__player-wrapper {
      position: relative;
      flex-basis: 35%;
      height: 361px;
      filter: drop-shadow(10px 15px 24px rgba(37, 36, 36, 0.25));
      @include breakpoint(laptop) {
         margin-bottom: 24px;
      }

      .react-player {
         position: relative;
         object-fit: cover;
         width: 100% !important;
         height: 360px;
         // &::before {
         //    content: "";
         //    position: absolute;
         //    top: 0;
         //    left: 0;
         //    width: 100%;
         //    height: 18%;
         //    background: rgba(0, 0, 0);
         //    z-index: 1;
         // }
         // &::after {
         //    content: "";
         //    position: absolute;
         //    bottom: 0;
         //    left: 0;
         //    width: 99%;
         //    height: 11%;
         //    background: #000;
         //    z-index: 2;
         // }
         video {
            object-fit: cover;
            border: 16px solid black;

            background-color: black;
         }
      }
      .dongle {
         position: absolute;
         width: 133px;
         height: 219px;
         bottom: -23px;
         right: -10px;
         z-index: 1;
      }
   }
}
.cartoon_description {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 30px;
   /* or 150% */

   display: flex;
   align-items: center;

   /* Grey/700 */

   color: #374253;
}
.cartoon_button {
   background: #ed1c24 !important;
   border-radius: 12px !important;
   border: none !important;
   width: 193px;
   margin-top: 15px;
}
.cartoon_button:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
   background-color: #d31118 !important;
}
