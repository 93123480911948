@import "../include/variables";
.wishlist-wrapper {
   display: flex;
   gap: 20px;
   padding: 25px 15px;
   background-color: #eceff3;
   align-items: baseline;
   margin-top: 15px;
}
.wishlist-pic-wrapper {
   display: flex;
   gap: 20px;
   align-items: baseline;
   flex-basis: 52%;
   @include breakpoint(mobile) {
      gap: 10px;
   }
}
.wishlist-title {
   padding-top: 20px;
}
#wishlist-button {
   display: flex;
   flex-basis: 30%;
   flex-wrap: wrap;
   gap: 18px;
}

#wishlist-product-name {
   flex-basis: 30%;
}
#wishlist-delete {
   height: 42px;
   width: 50px;
   background-color: grey;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   @include breakpoint(mobile) {
      height: 35px;
      width: 45px;
   }
}
#wishlist-add {
   height: 42px;
   width: 50px;
   background-color: red;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   @include breakpoint(mobile) {
      height: 35px;
      width: 45px;
   }
}
#wishlist-product-title {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */

   /* Grey/700 */
   color: #374253;
   @include breakpoint(mobile) {
      font-size: 14px;
      line-height: 20px;
   }
}
#wishlist-product-name {
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #0e9e49;
   @include breakpoint(mobile) {
      font-size: 14px;
      line-height: 20px;
   }
}
.grey {
   &:hover {
      background-color: #737171 !important;
   }
}
