@import "../include/variables";
.channels {
   margin-top: 7%;
}
.available {
   color: #2caa60;
}
.channel_container {
   display: flex;
   justify-content: flex-start;
   gap: 22px;
   margin-top: 46px;
   margin-bottom: 100px;
   @include breakpoint(mobile) {
      justify-content: center;
      padding: 0px 10px 0 10px;
   }
}
.channel_child {
   display: flex;
   align-items: center;
   justify-content: center;
   .channel_box {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      /* Grey/100 */
      border: 1px solid #f4f7fb;
      box-sizing: border-box;
      box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
      border-radius: 4px;
      width: 110px;
      height: 110px;
      img {
         width: 90px;
         height: 80px;
         object-fit: contain;
      }
   }
}
.channels_title {
   font-style: normal;
   font-weight: 600;
   text-align: center;
   margin-top: 60px;
   @include HeaderFontResponsive(44, 52);
   color: #192638;
   @include breakpoint(mobile) {
      padding-left: 8px;
      padding-right: 8px;
   }
}

.channels_title > a {
   text-align: center;
}
.channels_link {
   font-style: normal;
   font-weight: 500;
   font-size: 15px;
   line-height: 20px;
   display: inline-block;
   color: #ed1c24;
   margin-top: 25px;
   margin-bottom: 50px;
   text-decoration: none;
}
// .channels_title {
//    font-style: normal;
//    font-weight: 600;
//    margin-top: 96px;
//    // font-size: 44px;
//    line-height: 60px;
//    /* or 115% */
//    text-align: center;
// }
