.recharge-wrapper {
   max-width: 500px;
   width: 100%;
   padding: 25px;
   display: flex;
   flex-direction: column;
   /* Grey/50 */
   margin: auto;
   background: #ffffff;
   /* Grey/200 */

   margin-top: 160px;
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.05);
   border-radius: 12px;
   input {
      max-width: 100%;
      width: 100%;
   }
   .buttonGroup_recharge_self_other {
      display: flex;
      gap: 10px;
      margin-bottom: 8px;
      .buttonGroup_recharge_self,
      .buttonGroup_recharge_other {
         background: #e9ecef;
         border-radius: 12px;
         width: 100%;
         height: 45px;
         border: none;
      }

      .recharge_active {
         background: #ed1c24;
         color: #fff;
      }
   }
}

.recharge-dropdown {
   .Dropdown-control {
      width: 100%;
      background: #f4f7fb;
   }
}
.recharge-dropdown {
   .Dropdown-menu {
      width: 88%;
      background: #f4f7fb;
   }
}

.recharge-casid {
   width: 400px;
   margin-bottom: 20px;
   padding: 10px;
   /* Information/100 */

   background: #e9f0fe;
   border-radius: 4px;
}
#recharge-radio {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/900 */

   color: #0f1825;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
   font-style: normal;
   font-family: "SF Pro Display", sans-serif !important;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   color: #0f1825;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
   color: #ed1c24 !important;
}
#recharge-p {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   /* or 157% */

   /* Grey/600 */

   color: #596579;
}
.apply-recharge-button {
   background: #e9ecef;
   border-radius: 0px 8px 8px 0px;
   color: #596579;
   border: none;
}
#recharge-btn {
   background: #ed1c24;
   border-radius: 12px;
   width: 100%;
   height: 45px;
   border: none;
   margin-top: 30px;
}
#recharge-btn-inactive {
   background: grey;
   cursor: not-allowed;
   border-radius: 12px;
   width: 100%;
   cursor: no-drop;
   height: 45px;
   border: none;
   margin-top: 30px;
}
#table-1 {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   text-align: center;

   /* Grey/DEF */

   color: #7b8698;
}
#table-recharge {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   text-align: center;

   /* Grey/800 */

   color: #192638;
}
#table-bonus {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   text-align: center;

   /* Success/DEF */

   color: #0e9e49;
}
#recharge-payment {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/700 */

   color: #374253;
}
#pay-border {
   box-sizing: border-box;
   border-radius: 8px;
}
#pay-border:hover {
   border: 1px solid #0e9e49;
}
#recharge-pay-wrapper {
   display: flex;
   gap: 20px;
   flex-wrap: wrap;
   img {
      cursor: pointer;
   }
}
