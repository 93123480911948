@import "../../scss/include/variables";
.tutorial_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
}

.tutorial_banner {
   display: flex;
   height: 370px;
   align-items: center;
   justify-content: space-between;
   padding-left: 12px;
   .tutorial_banner_text {
      display: flex;
      flex-direction: column;
      .tutorial {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #7b8698;
      }
      .tutorial_header {
         font-style: normal;
         font-weight: 700;
         font-size: 54px;
         line-height: 62px;
         color: #192638;
         max-width: 450px;
         span {
            color: #0e9e49;
         }
      }
      .tutorial_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 450px;
      }
   }
   .tutorial_banner_img {
      width: 35%;
      img {
         width: 100%;
         padding-right: 80px;
      }
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}
.tutorial_container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding-left: 12px;
   margin-top: 60px;

   .tutorial_container_title_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .titleContainer {
         display: flex;
         flex-direction: column;
         .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #ed1c24;
         }
         .sub_caption {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
         }
      }
      .searchContainer {
         #search-icon {
            border: 1px solid #d0d7e2;
            background-color: #fff;
            border-right: none;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
         }
         .searchInput {
            border: 1px solid #d0d7e2;

            background-color: #fff;
            border-left: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            text-align: left;
            color: #7b8698;
            border-radius: 8px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
         }
      }
   }
   .tutorial_video_container {
      display: flex;
      flex-wrap: wrap;
      @include breakpoint(mobile) {
         justify-content: center;
         gap: 18px;
      }
      @include breakpoint(phablet) {
         justify-content: center;
         gap: 18px;
      }
      @include breakpoint(tablet) {
         justify-content: flex-start;
         gap: 35px;
      }
      @include breakpoint(laptop) {
         justify-content: flex-start;
         gap: 16px;
      }
      @include breakpoint(desktop) {
         justify-content: flex-start;
         gap: 18px;
      }
      @include breakpoint(2k) {
         justify-content: flex-start;
         gap: 25px;
      }
      .video_card {
         flex-basis: 22%;
         display: flex;
         flex-direction: column;
         max-width: 300px;
         .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #192638;
            padding-left: 2px;
         }
         .description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
            padding-left: 2px;
            max-width: 300px;
         }
      }
   }
   .dashboard_pagination {
      justify-content: center;
      margin-top: 34px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
         border-color: #ed1c24;
      }
   }
}
