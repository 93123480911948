@import "./include/variables";
// @import url("http://fonts.cdnfonts.com/css/sf-pro-display");
// @font-face {
//    font-family: Imported;
//    src: url(../fonts/SF-Pro-Display-Regular.ttf);
// }
@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 100;
   src: url(../fonts/SF-Pro-Display-Ultralight.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 200;
   src: url(../fonts/SF-Pro-Display-Thin.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 300;
   src: url(../fonts/SF-Pro-Display-Light.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 400;
   src: url(../fonts/SF-Pro-Display-Regular.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   src: url(../fonts/SF-Pro-Display-Medium.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 600;
   src: url(../fonts/SF-Pro-Display-Semibold.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 700;
   src: url(../fonts/SF-Pro-Display-Bold.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 800;
   src: url(../fonts/SF-Pro-Display-Heavy.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 900;
   src: url(../fonts/SF-Pro-Display-Black.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 100;
   src: url(../fonts/SF-Pro-Display-UltralightItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 200;
   src: url(../fonts/SF-Pro-Display-ThinItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 300;
   src: url(../fonts/SF-Pro-Display-LightItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 400;
   src: url(../fonts/SF-Pro-Display-RegularItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 500;
   src: url(../fonts/SF-Pro-Display-MediumItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 600;
   src: url(../fonts/SF-Pro-Display-SemiboldItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 700;
   src: url(../fonts/SF-Pro-Display-BoldItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 800;
   src: url(../fonts/SF-Pro-Display-HeavyItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}
@font-face {
   font-family: "SF Pro Display";
   font-style: italic;
   font-weight: 900;
   src: url(../fonts/SF-Pro-Display-BlackItalic.woff) format("woff");
   /* (C) 2015 Apple Inc. All rights reserved.*/
}

body {
   font-family: "SF Pro Display", sans-serif !important;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
html,
body {
   overflow-x: hidden;
}

.btn:focus,
.btn:active {
   outline: none !important;
   box-shadow: none !important;
}
// .main_page {
//    margin-top: 110px;
// }

.fixed-width {
   @include breakpoint(mobile) {
      width: 100% !important;
      max-width: 100% !important;
      padding-left: 8px;
      padding-right: 8px;
   }
   @include breakpoint(phablet) {
      width: 100% !important;
      max-width: 100% !important;
      padding-left: 8px;
      padding-right: 8px;
   }
   @include breakpoint(tablet) {
      width: 90% !important;
      max-width: 90% !important;
   }
   @include breakpoint(laptop) {
      width: 80% !important;
      max-width: 80% !important;
   }
   @include breakpoint(desktop) {
      width: 75% !important;
      max-width: 75% !important;
   }
   @include breakpoint(2k) {
      width: 60% !important;
      max-width: 60% !important;
   }
}
.fixed-width75 {
   @include breakpoint(mobile) {
      width: 100% !important;
      max-width: 100% !important;
      padding-left: 8px;
      padding-right: 8px;
   }
   @include breakpoint(phablet) {
      width: 100% !important;
      max-width: 100% !important;
      padding-left: 8px;
      padding-right: 8px;
   }
   @include breakpoint(tablet) {
      width: 90% !important;
      max-width: 90% !important;
   }
   @include breakpoint(laptop) {
      width: 75% !important;
      max-width: 75% !important;
   }
   @include breakpoint(desktop) {
      width: 65% !important;
      max-width: 65% !important;
   }
   @include breakpoint(2k) {
      width: 60% !important;
      max-width: 60% !important;
   }
}
.cursor_pointer {
   cursor: pointer;
}

.cursor_normal {
   cursor: default;
}
//Scroll Bar

::-webkit-scrollbar {
   width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #b5b5ca;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: rgba(#ed1c24, 1);
   border-radius: 28px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555;
   cursor: none;
}

//Drop Down
.Dropdown-menu {
   border-radius: 8px;
}
.Dropdown-option {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   color: #374253;
}

.Dropdown-option:hover {
   background-color: #ed1c24 !important;
   color: #fff !important;
}
.Dropdown-option.is-selected {
   background-color: transparent;
}
.Dropdown-control {
   width: 120px;
   height: 44px;
   /* Grey/100 */
   background: #f4f7fb;
   border-radius: 8px !important;
}

.jobdescription {
   .u-row {
      max-width: 100% !important;
      width: 100% !important;
   }
}

.Dropdown-menu {
   z-index: 1 !important;
}

.grecaptcha-badge {
   z-index: 99;
}

#custom-switch {
   position: relative;
   display: inline-block;
   border-radius: 2em !important;
   &:checked {
      background-color: #ed1c24;
   }
}

.cookie-consent-accordion {
   button {
      padding: 5px;
   }
   label {
      margin: 0;
   }
}
