@import "../include/variables";

.internetFooter {
   margin-top: 100px;
   display: flex;
   background: url("../../assets/internetBg.webp");
   background-size: cover;
   background-repeat: no-repeat;
   background-color: #f8f9f9;
   border-radius: 12px;
   align-items: center;
   margin-bottom: 60px;
   justify-content: center;
   // gap: 100px;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .text {
      .heading {
         font-style: normal;
         font-weight: 600;
         // font-size: 40px;
         // line-height: 52px;
         color: #192638;
         margin-bottom: 20px;
         @include HeaderFontResponsive(40, 52);
         span {
            color: #14a850;
         }
      }
      .subheading {
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 22px;
         color: #192638;
         margin-bottom: 20px;
      }
      .btn {
         background: #14a850;
         border-radius: 24px;
         padding: 10px 14px;
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 16px;
         color: #ffffff;
         margin-bottom: 32px;
         &:hover {
            background: #0e7538 !important;
         }
      }
   }
}
