@import "../include/variables";

.dashboardNotifiaciton_Wrapper {
   .dashboardNotifiaciton_Header {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #192638;
   }
   .dashboardNotification_subHeading {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #7b8698;
   }
   .dashboardNotificaiton_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 48px;
      .dashboardNotification {
         background-color: rgb(244, 247, 251);
         padding: 18px;
         border-radius: 12px;
         max-width: 560px;
         &:hover {
            background-color: #bac0cdd6;
         }
         .notification_title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            display: flex;
            align-items: center;
            display: flex;
            justify-content: space-between;
            gap: 80px;
            color: #192638;
            span {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 24px;
               display: flex;
               align-items: flex-end;
               display: flex;
               color: #37577d;
               margin-right: 12px;
            }
         }
         .notification_description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            display: flex;
            color: #30353f;
            margin: 0px;
         }
      }
   }
   .dashboard_pagination {
      max-width: 560px;
      justify-content: center;
      margin-top: 34px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
      }
   }
   .dashboardNotification_empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .dashboardNotification_empty_description {
         font-style: normal;
         font-weight: 400;
         font-size: 18px;
         line-height: 24px;
         display: flex;
         align-items: center;
         color: #f25e65;
      }
   }
}
