@import "../include/variables";
.categories_title {
   font-style: normal;
   font-weight: 600;
   font-size: 32px;
   line-height: 32px;
   /* identical to box height, or 100% */

   text-align: center;

   /* Grey/800 */

   color: #192638;
}

.categories {
   margin-top: 90px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.arrival_box {
   margin-top: 20px;
}
#flex-item {
   margin-top: 15px;
   // margin-right: 20px;
}

.slider-category-image {
   height: 200px;

   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 8px;
}
.categories_description {
   margin-top: 15px;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 26px;
   margin-bottom: 40px;
   /* identical to box height, or 144% */

   text-align: center;

   /* Grey/600 */

   color: #596579;
}

.categories_item img {
   display: block;
   margin: auto auto;
   padding: 5px;
   object-fit: contain;
   border: 1px solid #e6eaf0;
   border-radius: 8px;
   &:hover {
      background: #ffffff;
      /* Grey/200 */

      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.1);
   }
}
.categories_item {
   /* Grey/200 */
   // width: 200px;
   // border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 8px;
   max-width: 310px;
   padding: 0 10px;
   cursor: pointer;
}
.slider-container {
   display: inline-block;
}
.categories_name {
   font-style: normal;
   font-weight: 500;
   margin-top: 20px;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */
   text-align: center;

   /* Grey/700 */

   color: #374253;
}
.categories_discount {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   text-align: center;

   /* Information/DEF */

   color: #1a63f4;
}

.categories_brief {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   text-align: center;

   /* Grey/600 */

   color: #596579;
}
.categories_list {
   width: 100%;
   display: flex;

   //    display: inline-block;
   .splide__pagination {
      display: none;
   }
   .slider_responsive {
      .splide {
         margin-left: auto;
         margin-right: auto;
         @include breakpoint(mobile) {
            width: 400px;
         }
         @media only screen and (max-width: 460px) {
            width: calc(100% - 80px);
         }
         @include breakpoint(phablet) {
            width: calc(100% - 120px);
         }
         @include breakpoint(tablet) {
            width: calc(100% - 40px);
         }
         @include breakpoint(laptop) {
            width: calc(100% - 80px);
         }
         @include breakpoint(desktop) {
            width: calc(100% - 80px);
         }
         @include breakpoint(2k) {
            width: 800px;
         }
      }
   }
}
.splide__arrow {
   top: 26% !important;
}
.splide__arrow--prev {
   left: -3em !important;
   @include breakpoint(mobile) {
      left: -2em !important;
   }
}
.splide__arrow--next {
   right: -2em !important;
}
