@import "../include/variables";

.streaming_banner {
   margin-top: 100px;
}
.streaming_wrapper {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: #ffffff;
   /* Grey/200 */
   cursor: pointer;
   border: 1px solid #e6eaf0;
   box-shadow: 0px 8px 12px rgba(202, 202, 202, 0.12);
   border-radius: 8px;
   @include breakpoint(mobile) {
      flex-direction: column-reverse;
   }
   @include breakpoint(phablet) {
      flex-direction: column-reverse;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
}
#streaming-watch {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #192638;
}
#streaming-title {
   font-style: normal;
   font-weight: 600;
   font-size: 30px;
   line-height: 36px;
   /* or 120% */

   /* Grey/800 */

   color: #192638;
}
#streaming-subtitle {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   /* Grey/DEF */

   color: #7b8698;
}

//AD BANNER
.ad-banner {
   display: flex;

   border-radius: 8px;
   gap: 45px;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
      justify-content: space-around;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      justify-content: space-between;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      justify-content: space-between;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      justify-content: space-between;
   }
}
.ad-banner-inside {
   display: flex;
   justify-content: space-between;
   background: #f4f7fb;
   align-items: center;
   cursor: pointer;
}
#banner-collection {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   /* identical to box height, or 100% */

   text-align: center;
   text-transform: uppercase;

   /* Grey/600 */

   color: #596579;
}
#banner-apple {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   /* or 120% */

   text-align: center;

   /* Grey/700 */

   color: #374253;
}
