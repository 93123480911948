@import "../../scss/include/variables";

.nextmovies_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   background-image: url(../../assets/ppvmovies/union.png);
   min-height: 986px;
   background-repeat: no-repeat;
   @include breakpoint(mobile) {
      background-size: auto;
   }
   @include breakpoint(phablet) {
      background-size: auto;
   }
   @include breakpoint(tablet) {
      background-size: auto;
   }
   @include breakpoint(laptop) {
      background-size: auto;
   }
   @include breakpoint(desktop) {
      background-size: 100% 100%;
   }
   @include breakpoint(2k) {
      background-size: 100% 100%;
   }
   .nextmovies_text,
   .nextmovies_moviecontainer {
      width: 75%;
      display: flex;
   }
   .nextmovies_text {
      display: flex;
      justify-content: center;
      .date_button {
         cursor: context-menu;
         background-color: transparent;
         // color: #d0d7e2;
         color: #0e9e49;
         border: none !important;
         margin: 0px 15px;
         text-align: center;
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
      }
      .date_active {
         color: #0e9e49;
      }
      h2 {
         font-weight: 700;
         font-size: 40px;
         line-height: 52px;
         /* identical to box height, or 130% */
         /* Grey/900 */
         color: #0f1825;
         margin: 0px;
      }
   }
   .nextmovies_moviecontainer {
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
      .splide {
         @include breakpoint(mobile) {
            width: 240px;
         }
         @include breakpoint(phablet) {
            width: 300px;
         }
         @include breakpoint(tablet) {
            width: 630px;
         }
         @include breakpoint(laptop) {
            width: 900px;
         }
         @include breakpoint(desktop) {
            width: 1220px;
         }
         @include breakpoint(2k) {
            width: 1220px;
         }
      }
      .splide__arrow {
         top: 40% !important;
      }
      .splide__arrow--prev {
         left: -3em !important;
      }
      .splide__arrow--next {
         right: -3em !important;
      }
   }
}
