@import "../include/variables";

.dashboardview__wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;
   .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: none !important;
   }

   .welcome__text {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #192638;
   }
   .welcome_subtext {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 6px;
      margin-bottom: 16px;
      color: #7b8698;
   }
   .rewardpoint {
      font-weight: 600;
      font-size: 30px;
      line-height: 32px;
      color: #ff6f00;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      gap: 8px;
   }
   .plan__wrapper {
      display: flex;
      gap: 20px;
      @include breakpoint(mobile) {
         flex-direction: column;
         align-items: center;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
         align-items: center;
         padding: 0;
         border: none;
      }
      @include breakpoint(tablet) {
         flex-direction: row;
         align-items: flex-start;
         padding: 0;
         border: none;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
         align-items: flex-start;
         padding: 0;
         border: none;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
         align-items: flex-start;
         padding: 0;
         border: none;
      }
      @include breakpoint(2k) {
         flex-direction: row;
         align-items: flex-start;
         padding: 0;
         border: none;
      }
      .plan__item__details {
         flex-basis: 70%;
         padding: 24px;
         border: 1px solid #e6eaf0;
         box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
         background: #fff;
         display: flex;
         flex-direction: column;
         border-radius: 15px;
         // max-height: 370px;
         @include breakpoint(mobile) {
            // width: 90%;
            max-width: calc(100vw - 22px);
         }
         @include breakpoint(phablet) {
            width: 80%;
            max-width: 480px;
         }
         @include breakpoint(tablet) {
            width: 90%;
            max-width: 480px;
         }
         @include breakpoint(laptop) {
            width: 90%;
            max-width: 580px;
         }
         @include breakpoint(desktop) {
            width: 90%;
            max-width: 580px;
         }
         @include breakpoint(2k) {
            width: 90%;
            max-width: 580px;
         }
         .current_active_plan {
            font-weight: 500;
            font-size: 20px;
            color: #374253;
            margin-bottom: 13px;
         }
         .Plan__item__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #f00;
            border-radius: 15px;
            padding: 12px 22px;
            margin-bottom: 16px;
            color: #f00;
            gap: 8px;
            .custombtn {
               border: 0.5px solid #f00;
               background: #fff;
               border-radius: 35px;
               font-weight: 400;
               font-size: 17px;
               color: #f00;
               @include breakpoint(mobile) {
                  font-size: 15px;
                  padding: 4px 8px;
               }
               @include breakpoint(phablet) {
                  font-size: 15px;
                  padding: 4px 8px;
                  line-height: 18px;
               }
               @include breakpoint(laptop) {
                  font-size: 17px;
                  line-height: 19px;
                  padding: 8px 12px;
               }
               &:hover {
                  background: #f00;
                  color: #fff;
               }
            }

            // margin-bottom: 12px;
            // border-bottom: 1px solid #e6eaf0;
            // @include breakpoint(mobile) {
            //    flex-direction: column;
            // }
            // @include breakpoint(phablet) {
            //    flex-direction: column;
            // }
            // @include breakpoint(tablet) {
            //    flex-direction: row;
            // }
            // @include breakpoint(laptop) {
            //    flex-direction: row;
            // }
            // @include breakpoint(desktop) {
            //    flex-direction: row;
            // }
            // @include breakpoint(2k) {
            //    flex-direction: row;
            // }
            p,
            h3 {
               margin: 0;
            }
            h3 {
               font-weight: 500;
               font-size: 20px;

               color: #374253;
            }
            p {
               font-weight: 500;
               font-size: 17px;
               display: flex;
               align-items: center;
               gap: 12px;
               color: #3c3d3f;
               @include breakpoint(mobile) {
                  font-size: 15px;
                  img {
                     height: 16px;
                     width: 18px;
                  }
               }
               @include breakpoint(phablet) {
                  font-size: 15px;
                  img {
                     height: 16px;
                     width: 18px;
                  }
               }
               @include breakpoint(laptop) {
                  font-size: 17px;
                  img {
                     height: 20px;
                     width: 24px;
                  }
               }
            }
         }
         .Plan__item__features {
            display: flex;
            flex-direction: column;
            padding-bottom: 12px;
            padding-top: 8px;
            border-bottom: 1px solid #e6eaf0;
            border-top: 1px solid #e6eaf0;
            .item__feature {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               padding: 12px 0px;
               align-items: center;
               h2 {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  margin: 0;
                  color: #596579;
                  display: flex;
                  align-items: center;
               }
               p {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #374253;
                  margin: 0px;
               }
            }
         }
         .Plan__item__price {
            display: flex;
            width: 100%;
            padding: 12px 0px;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #192638;
            p {
               margin: 0;
            }
            sub {
               color: #596579;
               font-size: 18px;
               line-height: 26px;
            }
         }
      }
      .Plan__chart {
         display: flex;
         flex-direction: column;
         padding: 24px 30px;
         border: 1px solid #e6eaf0;
         border-radius: 8px;
         align-items: center;
         max-width: 320px;
         height: 320px;
         @include breakpoint(mobile) {
            max-width: 350px;
         }
         @include breakpoint(phablet) {
            width: 60%;
         }
         @include breakpoint(tablet) {
            width: 90%;
         }
         @include breakpoint(laptop) {
            width: 90%;
         }
         @include breakpoint(desktop) {
            width: 90%;
         }
         @include breakpoint(2k) {
            width: 90%;
         }
         .piechart_title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: #374253;
         }
      }
   }
}

.red {
   &:hover {
      background: #d31118 !important;
   }
}
.white {
   &:hover {
      background-color: #e8ecf2 !important;
      color: black !important;
   }
}

.green {
   &:hover {
      background: #096f33 !important;
   }
}
.button_container_activepackage {
   display: flex;
   gap: 30px;
   margin-top: 34px;
   justify-content: space-around;
   flex-wrap: wrap;
   align-items: center;
}
