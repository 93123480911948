.iptv_price_list {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   .price {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      /* identical to box height, or 144% */
      text-align: center;
      /* Grey/50 */
      color: #0e9e49;
      margin-right: 14px;
      padding: 4px 6px;
      border: 1px solid #0e9e49;
      border-radius: 6px;
      cursor: pointer;
   }
   .active {
      background: #0e9e49;
      color: #fff;
      border: 1px solid #0e9e49;
   }
}
.iptv_buy_now_btn {
   .buy_now_btn {
      background: #ed1c24;
      width: 100%;
      text-align: center;
      padding: 12px 20px;
      box-sizing: border-box;
      border-radius: 12px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      border: none;
   }
}

.iptv_payment_modal {
   justify-content: space-around;
   .payment_method {
      display: flex;
      flex-direction: column;
      .payment_method_wrapper {
         display: flex;
         flex-direction: row;
         gap: 16px;
         .payment_options {
            border-radius: 20px;
         }
      }
   }
   .buy_now_btn {
      background: #ed1c24;
      width: 100%;
      text-align: center;
      padding: 10px 20px;
      box-sizing: border-box;
      border-radius: 12px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      border: none;
   }
}
