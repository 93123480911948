@import "../../scss/include/variables";

.movie_payment_wrapper {
   display: flex;
   width: 100%;
   justify-content: center;
}
.movie_payment_container {
   width: 75%;
   display: flex;
   gap: 15px;
   @include breakpoint(mobile) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
   }
   .movie_payment_stbNumber {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      padding-right: 10px;
      .stb_Alert {
         display: none;
      }
      .stb_Alert_show {
         max-width: 400px;
         width: fit-content;
         display: flex;
         align-items: center;
         padding: 12px;
         font-weight: 400;
         font-size: 17px;
         line-height: 24px;
         color: $primary;
         display: flex;
         align-items: center;
      }
      .stb_btn {
         display: flex;
         align-items: center;
         justify-content: center;
         background-color: #ed1c24;
         padding: 12px;
         width: 100%;
         max-width: 165px;
         font-weight: 500;
         font-size: 17px;
         line-height: 24px;
         color: #fff;
         border: none;
         border-radius: 4px;
         margin-top: 20px;
         cursor: pointer;
      }
      .stb_title {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         /* identical to box height, or 150% */
         text-align: left;

         /* Grey/800 */

         color: #192638;
      }
   }
   .payment_method {
      .payment_title {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         /* identical to box height, or 150% */
         text-align: left;

         /* Grey/800 */

         color: #192638;
      }
      .payment_options {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-evenly;
         max-width: 570px;
         .option {
            border: none;
            background-color: transparent;
            margin-right: 20px;
         }
      }
   }
   .summary_wrapper {
      display: flex;
      flex-direction: column;
      background: #f4f7fb;
      /* Grey/200 */
      border: 1px solid #e6eaf0;
      border-radius: 10px;
      padding: 24px;
      height: fit-content;
      max-width: 410px;
      .summary_wrapper_text {
         font-style: normal;
         font-weight: 500;
         font-size: 20px;
         line-height: 28px;
         /* identical to box height, or 140% */
         /* Grey/900 */

         color: #0f1825;
      }
      .summary_movie_details {
         display: flex;
         border-bottom: 1px solid #e6eaf0;
         padding-bottom: 26px;
         .img_container {
            border-radius: 8px;
            margin-right: 37px;
            img {
               width: 150px;
               height: 180px;
               object-fit: contain;
            }
         }
         .movie_details {
            text-align: right;
            .movie_title {
               font-style: normal;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
               /* identical to box height, or 140% */
               /* Grey/700 */
               color: #374253;
            }
            .showtime,
            .channel_name,
            .amount {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 24px;
               /* identical to box height, or 150% */
               /* Grey/700 */
               color: #374253;
            }
         }
      }
      .summary_price {
         display: flex;
         justify-content: space-between;
         .total_text {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #596579;
         }
         .price {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #0e9e49;
         }
      }
      .pay_now {
         background: #0e9e49;
         border-radius: 12px;
         border: none;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         text-align: center;
         text-transform: capitalize;
         color: #ffffff;
         padding: 12px 20px;
      }
   }
}
