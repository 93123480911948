@import "../include/variables";
.tv_package_wrapper {
   background-color: #f8f8f8;
   display: flex;
   flex-direction: column;
   align-items: center;
   .searchContainer {
      margin-bottom: 8px;
      align-self: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      @include breakpoint(mobile) {
         align-self: flex-start;
         padding-left: 10px;
      }
      #search-icon {
         border: 1px solid #d0d7e2;
         background-color: #fff;
         border-right: none;
         border-radius: 8px;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }
      .searchInput {
         border: 1px solid #d0d7e2;
         margin-bottom: 0px !important;
         background-color: #fff;
         border-left: none;
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 16px;
         text-align: left;
         color: #7b8698;
         border-radius: 8px;
         width: 100%;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }
      .searchResult {
         display: flex;
         flex-direction: column;
         position: absolute;
         z-index: 5;
         top: 45px;
         background-color: #fff;
         border-radius: 8px;
         width: 100%;
         max-height: 310px;
         overflow-y: auto;
         .searchResultItem {
            padding: 10px;
            border-bottom: 1px solid #d0d7e2;
            cursor: pointer;
            &:last-child {
               border-bottom: none;
            }
         }
      }
   }
}
.order_info .Dropdown-control {
   width: 509px;
   background: #f4f7fb;
   /* Grey/300 */
   max-width: 515px;
   border: 1px solid #d0d7e2;
   border-radius: 8px;
}
.recharge_amount_container .Dropdown-control {
   width: 100%;
   background: #f4f7fb;
   /* Grey/300 */

   border: 1px solid #d0d7e2;
   border-radius: 8px;
}
.district_container .Dropdown-control {
   // width: 250px !important;
   background: #f4f7fb;
}
.television_no_container .Dropdown-control {
   width: 100%;
   background: #f4f7fb;
   /* Grey/300 */

   border: 1px solid #d0d7e2;
   border-radius: 8px;
}
.dh_banner {
   width: 100% !important;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   height: 72px;
   background: #ffffff;
   border: 1px solid #f4f7fb;
   box-shadow: 0px 4px 12px rgba(171, 171, 171, 0.25);
   .dh_banner_text {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #192638;
      span {
         color: #0e9e49;
      }
   }
   .Logo {
      position: absolute;
      left: 30px;
      top: 0;
   }
}

.tvpackage_order_info {
   display: flex;
   justify-content: space-evenly;
   gap: 60px;
   @include breakpoint(mobile) {
      flex-direction: column-reverse;
      align-items: stretch;
      padding-left: 10px !important;
      padding-right: 10px !important;
   }
   @include breakpoint(phablet) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 0;
   }
   @include breakpoint(tablet) {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: flex-start;
      padding: 0;
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      align-items: flex-start;
      padding: 0;
      flex-direction: row;
   }
   @include breakpoint(2k) {
      align-items: flex-start;
      padding: 0;
      flex-direction: row;
   }
   .Dropdown-control {
      width: 100%;
   }
   label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 100% */
      /* Grey/700 */
      color: #3d3358;
      margin-bottom: 6px;
   }
   select,
   input {
      color: #3d3358;
      margin-bottom: 32px;
      background: #f4f7fb;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      max-width: 516px;
   }
   .order_info {
      display: flex;
      flex-direction: column;
      max-width: 550px;
      .Order_error_hide {
         display: none;
      }
      .text {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         /* identical to box height, or 144% */
         color: #000000;
      }
      .input_container {
         max-width: 516px;
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         @include breakpoint(mobile) {
            flex-wrap: wrap;
         }
         @include breakpoint(phablet) {
            flex-wrap: nowrap;
         }
         @include breakpoint(tablet) {
            flex-wrap: nowrap;
         }
         @include breakpoint(laptop) {
            flex-wrap: nowrap;
         }
         @include breakpoint(desktop) {
            flex-wrap: nowrap;
         }
         @include breakpoint(2k) {
            flex-wrap: nowrap;
         }
         div {
            display: flex;
            flex-direction: column;
            z-index: 5;
            @include breakpoint(mobile) {
               width: 100%;
            }
            @include breakpoint(phablet) {
               width: 250px;
            }
            @include breakpoint(tablet) {
               width: 250px;
            }
            @include breakpoint(laptop) {
               width: 250px;
            }
            @include breakpoint(desktop) {
               width: 250px;
            }
            @include breakpoint(2k) {
               width: 250px;
            }
            input,
            select {
               @include breakpoint(mobile) {
                  width: 100%;
                  margin-bottom: 15px !important;
               }
               @include breakpoint(phablet) {
                  width: 250px;
               }
               @include breakpoint(tablet) {
                  width: 250px;
               }
               @include breakpoint(laptop) {
                  width: 250px;
               }
               @include breakpoint(desktop) {
                  width: 250px;
               }
               @include breakpoint(2k) {
                  width: 250px;
               }
            }
         }
      }
      .address_input {
         max-width: 516px;
         width: 100%;
      }
      .order_btn {
         max-width: 516px;
         background: #ed1c24 !important;
         border-radius: 12px;
         justify-content: center;
         align-items: center;
         display: flex;
         border: none;
         padding: 12px 20px;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         color: #ffffff;
      }
   }
   .price_card {
      .pricecard_wrapper {
         background: #ffffff;
         border: 1px solid #f4f7fb;
         box-sizing: border-box;
         box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.05);
         border-radius: 10px;
         min-width: 370px;
         max-width: 550px;
         .title_wrapper {
            background-color: #1a63f4;
            border-radius: 10px 10px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .internet-title_wrapper {
               background-color: #ed1c24;
               border-radius: 10px 10px 0 0;
               display: flex;
               flex-direction: column;
               justify-content: center;
               .Dropdown-control {
                  background-color: #ed1c24 !important;
                  border: none;
               }
            }
            select {
               width: fit-content;
               margin-bottom: 20px !important;
            }
            .package_name {
               text-align: left;
               font-style: normal;
               font-weight: 700;
               font-size: 20px;
               line-height: 26px;
               color: #fff;
            }
            .internet-pacakge_name {
               font-style: normal;
               font-weight: 700;
               font-size: 20px;
               line-height: 26px;
               color: #fff;
            }
         }
         .internet-title_wrapper {
            background-color: #ed1c24;
            border-radius: 10px 10px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
         }
         .price_div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            color: #374253;
            border-bottom: 1px solid #e6eaf0;
            margin-left: 24px;
            margin-right: 24px;
            &:last-child {
               border-bottom: none;
            }
            #bold-text {
               font-weight: 600;
            }
            .total_price_disable {
               text-decoration: line-through;
            }
         }
      }
   }
}

#month {
   background: transparent;
   color: #fff;
   border: none;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
}

#internet-id {
   background-color: #fff !important;
   border: 1px solid #e6eaf0;
   box-shadow: 0px 4px 16px rgba(36, 44, 55, 0.1);
   border-radius: 6px;
   color: #374253;
}
#internet-id :hover {
   box-shadow: 0 0 10px 100px #ed1c24 inset;
}
#select-payment {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */

   /* Grey/800 */

   color: #192638;
}
.payment-box-wrapper {
   width: 146px;
   height: 146px;
   background: #f5f4f6;
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.cart_container_address_li {
   list-style-type: none;
}
.payment_methods {
   max-width: 1150px;
   margin: 0 auto;
   // padding: 0 10px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 2rem;
}
.payment-method-container {
   .order_btn {
      max-width: 516px;
      background: #ed1c24 !important;
      border-radius: 12px;
      justify-content: center;
      align-items: center;
      display: flex;
      border: none;
      padding: 12px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      &:hover {
         background: #d31118;
      }
   }
}
.order_btn_inactive {
   max-width: 516px;
   background: grey;
   border-radius: 12px;
   justify-content: center;
   align-items: center;
   display: flex;
   border: none;
   padding: 12px 20px;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   color: #ffffff;
   cursor: not-allowed !important;
}
.order_btn_inactive:hover {
   background: grey !important;
}
.order_btn_loading {
   max-width: 516px;
   background: #ed1c24 !important;
   border-radius: 12px;
   justify-content: center;
   align-items: center;
   display: flex;
   border: none;
   padding: 12px 20px;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   color: #ffffff;
   cursor: not-allowed;
}
.internet-title_wrapper {
   background-color: #ed1c24;
   border-radius: 10px 10px 0 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   .Dropdown-control {
      background-color: #ed1c24 !important;
      border: none;
      width: 150px;
   }
   .Dropdown-placeholder {
      color: #fff;
   }
   .Dropdown-menu {
      width: 43%;
   }
   .Dropdown-arrow {
      border-color: #fff transparent transparent;
   }
}
.title_wrapper {
   .Dropdown-control {
      background-color: #1a63f4 !important;
      border: none;
      width: 150px;
   }
   .Dropdown-placeholder {
      color: #fff;
   }
   .Dropdown-menu {
      width: 43%;
   }
   .Dropdown-arrow {
      border-color: #fff transparent transparent;
   }
}
.tvpackage_order_info .order_info .input_container .dropdown-color {
   border: 1px solid red !important;
   border-radius: 8px;
}
.input_container .dropdown-color {
   border: 1px solid red !important;
   border-radius: 8px;
}

// .tvpackage_order_info .order_info .input_container .dropdown-color {
//    border: 1px solid red;
//    border-radius: 8px;
// }

.container {
   .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      justify-content: center !important;
   }
}
.Dropdown-menu {
   overflow-x: hidden;
   z-index: 15 !important;
}

.order_map_btn {
   width: 106px;
   margin-top: 15px;
   background: #ed1c24;
   border-radius: 12px;
   justify-content: center;
   align-items: center;
   display: flex;
   border: none;
   padding: 12px 20px;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   color: #ffffff;
   &:hover {
      background: #d31118;
   }
}
