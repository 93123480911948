.successMessage {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 50px 0px;
   .successMessage-image {
   }
   .successMessage-header {
      text-align: center;
      h1 {
         font-style: normal;
         font-weight: 700;
         font-size: 32px;
         line-height: 42px;
         color: #192638;
      }
   }
   .successMessage-body {
      p {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #374253;
      }
   }
   .successMessage-button {
      .successMessage-button-button {
         border: none;
         background: #ed1c24;
         border-radius: 12px;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         text-align: center;
         text-transform: capitalize;
         color: #ffffff;
         padding: 12px 22px;
      }
   }
}
