.video {
   background: linear-gradient(201.19deg, #f5a112 13.97%, #eb6f5b 49.39%, #c95c90 89.72%);
   border-radius: 10px;

   height: 250px;
   width: 345px;
   display: flex;

   justify-content: space-around;
}
.video1 {
   background: #3117ab;
   border-radius: 10px;

   height: 250px;
   width: 345px;
   display: flex;

   justify-content: space-around;
}
.video2 {
   background: #2e3c8c;
   border-radius: 10px;

   height: 250px;
   width: 345px;
   display: flex;

   justify-content: space-around;
}
.video_container {
   margin-top: 90px;
   display: flex;
   gap: 10px;
}
.video_mobile {
   flex: 1 1 auto;
}
.video_image {
   flex: 1 1 auto;
}
.video_image img {
   margin-top: 50px;
   height: 200px;

   margin-left: 42px;
}
.video_text {
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 26px;
   margin-left: 10px;
   /* identical to box height */

   text-align: center;

   /* Grey/50 */

   color: #ffffff;
}
.video_title {
   margin-top: 30px;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 19px;
   /* identical to box height */

   text-align: center;

   /* Grey/50 */

   color: #ffffff;
}
@media only screen and (max-width: 600px) {
   .video_container {
      margin-left: 25px;
   }
}
