.textarea-dashboard {
   height: 195px;
   background: #f4f7fb;
   max-width: 600px;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
}

.support-form-container {
   display: flex;
   flex-direction: column;

   #form-contact {
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;
      div {
         width: 100%;
         max-width: 290px;
         #input-dashboard {
            width: 100%;
         }
      }
   }
}
#support-request {
   display: flex;
   justify-content: flex-end;
}
