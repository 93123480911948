@import "../include/variables";
.locate-dealer {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 600;
   font-size: 24px;
   line-height: 36px;
   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #192638;
}
.locate-nearest {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   /* Grey/DEF */

   color: #7b8698;
}

.filter-section-locate {
   width: 100%;
   margin-bottom: 16px;
   background: #fafbfd;
   /* Grey/100 */
   display: flex;
   padding: 15px;
   gap: 45px;
   border: 1px solid #f4f7fb;
   box-sizing: border-box;
   box-shadow: 0px 12px 24px rgba(202, 202, 202, 0.25);
   border-radius: 10px 10px 0px 0px;
   @include breakpoint(mobile) {
      flex-direction: column;
      gap: 10px;
   }
}

.district_province {
   @include breakpoint(mobile) {
      flex-wrap: nowrap;
   }
}
#search-locate {
   /* Grey/50 */

   background: #ffffff;
   /* Grey/300 */
   border-left: none !important;
   border: 1px solid #d0d7e2;
   @include breakpoint(mobile) {
      max-width: 100%;
   }
}
// .search-icon-locate {
//    position: absolute;
//    left: 15px;
//    top: 5px;
// }
#search-locate-icon {
   background-color: white !important;
   border-right: none !important;
   border: 1px solid #a7b2c3;
}
#locate-title {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   /* Grey/700 */

   color: #374253;
}
.dealer-wrapper {
   display: flex;
   gap: 20px;
   margin-bottom: 35px;
   @include breakpoint(mobile) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
   }
   @include breakpoint(phablet) {
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
   }
   @include breakpoint(tablet) {
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
   }
   @include breakpoint(laptop) {
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
   }
   @include breakpoint(desktop) {
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
   }
   @include breakpoint(2k) {
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
   }
}
.dealer-address {
   display: flex;
   padding: 15px;
   background-color: aliceblue;
   border-radius: 8px;
   align-items: center;
   @include breakpoint(mobile) {
      gap: 0px;
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      gap: 0px;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
      gap: 0px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      gap: 45px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      gap: 45px;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 45px;
   }
}
.dealer-address > div {
   flex-basis: 25%;
   width: 247px;
   margin-left: 4px;
}
.filter-section-locate > div {
   flex-basis: 33%;
   @include breakpoint(mobile) {
      flex-basis: 45%;
   }
   .Dropdown-control {
      width: 100%;
   }
}
.district_province {
   .dropdown_name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #374253;
      margin-bottom: 6px;
      margin-left: 4px;
   }
}

.district_province > div {
   flex-basis: 50%;
   .Dropdown-control {
      width: 100%;
   }
}

#dealer-address-title {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */

   /* Grey/700 */

   color: #374253;
}
#dealer-address-p {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */

   /* Grey/700 */

   color: #374253;
}
#dealer-phone {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/DEF */

   color: #7b8698;
}

.locateus_wrapper {
   .dashboard_pagination {
      justify-content: center;
      margin-top: 34px;
      margin-bottom: 24px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
         border-color: #ed1c24;
      }
   }
   .nodatafound {
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #ed1c24;
      margin-top: 60px;
      margin-bottom: 55px;
   }
}
