.Compare__widthContainer {
   flex-direction: column;
}
.compare-header {
   padding-top: 150px;
   height: 450px;
   background: #f2eae3;
   display: flex;
   justify-content: center;
   width: 100%;
}
.header-heading {
   font-style: normal;
   font-weight: 700;
   font-size: 40px;
   line-height: 64px;
   letter-spacing: -0.015em;

   /* Grey/800 */

   color: #192638;
}

.form-wrapper .Dropdown-control {
   width: 100%;
   background: #f4f7fb;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
   padding-left: 15px;
   padding-right: 32px;
   @media screen and (max-width: 530px) {
      width: 160px;
      overflow: hidden;
      .is-selected {
         display: flex;
         height: 25px;
         overflow: hidden;
      }
   }
}
option:hover {
   background-color: red !important;
}
select option:checked,
select option:hover {
   box-shadow: 0 0 10px 100px #000 inset;
}
#plan-price {
   font-style: normal;
   font-weight: 600;
   font-size: 26px;
   line-height: 28px;
   margin-left: 5px;
   /* or 140% */

   /* Grey/DEF */

   color: black;
}
select > option:hover {
   color: red;
   cursor: pointer;
}
#plan-mo {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 28px;
   /* identical to box height, or 140% */

   /* Grey/600 */

   color: #596579;
}
#plan-des {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 24px;
   margin-top: 5px;
   /* identical to box height, or 150% */

   /* Grey/600 */

   color: #596579;
}
.compare-text {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 28px;
   /* identical to box height, or 140% */

   /* Grey/700 */
   color: #374253;
}
.text-wrapper {
   margin-left: 240px;
}
.dropdown-wrapper {
   padding: 10px;
   background: #ffffff;
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   box-shadow: 0px 4px 4px rgba(160, 160, 160, 0.12);
   border-radius: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
}
#form-header {
   background: #f4f7fb;
   /* Grey/300 */

   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
}

.package-dropdown {
   display: flex;
   justify-content: space-around;
   margin-top: 30px;
   flex-wrap: wrap;
   align-items: center;
}
.select-package {
   font-style: normal;
   margin-left: 20px;
   font-size: 16px;
   line-height: 26px;
   color: #0e9e49;
}
.pricing-container {
   margin-top: 50px;
}
.pricing-package {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 28px;
   /* identical to box height, or 140% */

   /* Grey/600 */

   color: #596579;
}
.pricing-description {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */

   /* Grey/700 */

   color: #374253;
}
.drop-container {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   gap: 35px;
   width: 100%;
   p {
      margin-bottom: 0;
   }
}
#container {
   margin-left: 150px;
}
@media only screen and (max-width: 600px) {
   #container {
      margin-left: 25px;
   }
   .drop-container {
      flex-direction: column;
   }

   .dropdown-wrapper {
      margin-left: 0px;
      width: 100%;
      margin-top: 30px;
   }
}
@media only screen and (max-width: 600px) {
   .drop-container {
      flex-direction: column;
   }
   #container {
      margin-left: 25px;
   }
   .dropdown-wrapper {
      margin-left: 0px;
      width: 100%;
      margin-top: 30px;
   }
}
@media only screen and (min-width: 768px) {
   .drop-container {
      flex-direction: column;
   }
}
@media only screen and (min-width: 992px) {
   .drop-container {
      flex-direction: row;
   }

   .dropdown-wrapper {
      width: 100%;
   }
}
