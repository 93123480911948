@import "../include/variables";
.discount_banner {
   margin-top: 50px;
   position: relative;
}
.discount_text {
   margin-top: 10%;
}
.discount_title {
   font-style: normal;
   font-weight: 600;
   // font-size: 40px;
   // line-height: 48px;
   /* or 124% */
   text-align: center;
   /* Grey/800 */
   @include HeaderFontResponsive(40, 48);
   color: #192638;
}
.iphone13 {
   margin-left: 280px;
   margin-top: 50px;
   font-size: 15px;
   line-height: 28px;
   /* identical to box height, or 140% */

   /* Grey/800 */

   color: #192638;
}
.discount_container {
   // background-image: url("../../assets/iphonebackground.png");
   background-repeat: no-repeat;
   display: flex;
   // justify-content: space-between;
   align-items: center;
   @include breakpoint(mobile) {
      flex-direction: column;
      img {
         width: 220px;
         height: 220px;
         margin-bottom: 24px;
      }
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      img {
         width: 240px;
         height: 240px;
         margin-bottom: 24px;
      }
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      img {
         width: 260px;
         height: 260px;
      }
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      img {
         position: relative;

         top: 123px;
         width: 400px;
         height: 350px;
         right: 90px;
      }
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      img {
         width: 400px;
         height: 400px;
      }
   }
   @include breakpoint(2k) {
      flex-direction: row;
      justify-content: space-around;
      img {
         top: 110px;
         width: 480px;
         height: 400px;
      }
   }
}
.discount_button {
   display: flex;
   justify-content: center;
}
.discount_subtext {
   font-style: normal;
   font-weight: 600;
   margin-bottom: 24px;
   font-size: 20px;
   line-height: 36px;
   /* identical to box height, or 150% */

   text-align: center;
   color: #ff04d7;
}
.deal {
   background: #ed1c24 !important;
   border-radius: 12px !important;
   border: none !important;
   padding: 10px 15px;
   width: 150px;
   &:hover {
      background-color: #d31118 !important;
   }
}
.explore {
   background: none !important;
   color: #ed1c24;
   border-color: #ed1c24;
   padding: 10px 15px;
   width: 150px;
   border-radius: 12px !important;
   margin-left: 15px;
   &:hover {
      opacity: 0.7 !important;
      color: #ed1c24 !important;
      border-color: #ed1c24 !important;
   }
}

//PRODUCT
.product_container {
   margin: 0 auto;
   padding: 0 10px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   // grid-gap: 0.5rem;
   margin-top: 50px;
   align-items: center;
   grid-gap: 15px !important;
   @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
   }
   @include breakpoint(phablet) {
      grid-template-columns: repeat(1, 1fr);
   }
   @include breakpoint(tablet) {
      grid-template-columns: repeat(2, 1fr);
   }
   @include breakpoint(laptop) {
      grid-template-columns: repeat(3, 1fr);
   }
   @include breakpoint(desktop) {
      grid-template-columns: repeat(3, 1fr);
   }
   @include breakpoint(2k) {
      grid-template-columns: repeat(3, 1fr);
   }
}
#product_bg {
   position: absolute;
}
.product-iphone2 {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 550px;
   background-image: url("../../assets/productbg.png") !important;
   background-position: center !important;
   background-repeat: no-repeat !important;
   // background-size: 300px 100px;
   background-size: cover !important;
   max-width: 400px;
   margin-top: 26px;
   /* Grey/50 */
   flex-basis: 32%;
   background: #ffffff;
   /* Grey/200 */
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   box-shadow: 8px 6px 30px -4px rgba(117, 113, 113, 0.12);
   border-radius: 25px;
}
.product-iphone {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 550px;

   max-width: 400px;
   margin-top: 26px;
   /* Grey/50 */
   flex-basis: 32%;
   background: #ffffff;
   /* Grey/200 */
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   box-shadow: 8px 6px 30px -4px rgba(117, 113, 113, 0.12);
   border-radius: 25px;
   .inner_product {
      width: 90%;
      height: 150px;
      margin: 15px;
      background: linear-gradient(103.52deg, #7910ab 1.33%, #541dca 100%);
      box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
      border-radius: 12px;

      .title {
         font-style: normal;
         -webkit-box-orient: vertical;
         display: -webkit-box;
         overflow: hidden;
         -webkit-line-clamp: 1;
         font-weight: bold;
         font-size: 20px;
         line-height: 30px;
         /* identical to box height */
         padding: 10px 6px 5px;
         text-align: center;
         letter-spacing: -0.015em;

         color: #ffffff;
      }
      .subtitle {
         font-style: normal;
         font-weight: normal;
         font-size: 15px;
         line-height: 10px;
         text-align: center;

         color: #ffffff;
      }
      .cost {
         color: #0faf51 !important;
         font-style: normal;
         font-weight: bold;
         font-size: 15px;
         line-height: 21px;
         text-align: center;
      }
   }
}

.inner_product2 {
   width: 90%;
   height: 150px;
   margin: 15px;
   background: linear-gradient(103.52deg, #040915 1.33%, #0c0a3d 100%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px;

   .title {
      display: -webkit-box;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      line-height: 30px;
      padding: 10px 6px 5px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
   }
   .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 10px;
      text-align: center;

      color: #ffffff;
   }
   .cost {
      color: #0faf51 !important;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
   }
}
.inner_product3 {
   width: 90%;
   height: 150px;
   margin: 15px;
   background: linear-gradient(103.52deg, #05b3a8 1.33%, #5a00ed 100%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px;

   .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      text-align: center;
      letter-spacing: -0.015em;

      color: #ffffff;
      padding: 10px 6px 5px;
   }
   .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 10px;
      text-align: center;

      color: #ffffff;
   }
   .cost {
      color: #0faf51 !important;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
   }
}
.inner_product_none {
   width: 90%;
   height: 150px;
   margin: 15px;
   // background: linear-gradient(103.52deg, #040915 1.33%, #0c0a3d 100%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 12px;

   .title {
      display: -webkit-box;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      line-height: 30px;
      padding-top: 15px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
   }
   .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 10px;
      text-align: center;

      color: #ffffff;
   }
   .cost {
      color: #ffffff;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
   }
}

.cost {
   color: #374253;

   font-style: normal;
   font-weight: 600;
   font-size: 30px;
   line-height: 46px;
   text-align: center;
}
.iphone_button {
   max-width: 250px;
   width: 90%;
   margin-top: 10px;
   /* Primary/DEF */
   border: none !important;
   background: #ed1c24 !important;
   border-radius: 20px !important;
   &:hover {
      background-color: #d31118 !important;
   }
}
.iphone_button_white {
   color: #374253;
   max-width: 250px;
   width: 90%;
   margin-top: 10px;
   /* Primary/DEF */
   border: none !important;
   background: #ffffff !important;
   border-radius: 20px !important;
   &:hover {
      background-color: #e6eaf0 !important ;
      color: black;
   }
}
#background_wrapper {
   position: absolute;
   width: 400px;
   z-index: -1;
   @include breakpoint(mobile) {
      width: 75%;
      top: -8em;
   }
   @include breakpoint(phablet) {
      width: 65%;
      top: -14em;
   }
   @include breakpoint(tablet) {
      width: 40%;
      top: -5.2em;
   }
   @include breakpoint(laptop) {
      width: 400px;
      top: 0;
   }
   @include breakpoint(desktop) {
      width: 400px;
      top: 0;
   }
   @include breakpoint(2k) {
      width: 400px;
      top: 0;
      display: none;
   }
}
#sup-style {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   /* identical to box height */

   /* Grey/700 */
}
