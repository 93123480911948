/*
====================================================

* 	[Master Stylesheet]
	
	Theme Name :  
	Version    :  
	Author     :  
	Author URI :  

====================================================

	TOC
	
	1. PRIMARY STYLES
	2. COMMONS FOR PAGE DESIGN
		JQUERY LIGHT BOX
	3. LEFT SECTION
	4. RIGHT SECTION

====================================================

/* ---------------------------------
1. PRIMARY STYLES
--------------------------------- */
.Error500 {
   height: 100vh;
   overflow: hidden;
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   p,
   a,
   ul,
   span,
   li,
   img,
   input,
   button {
      margin: 0;
      padding: 0;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      line-height: 1.5;
      font-weight: inherit;
   }

   h1,
   h2,
   h3 {
      font-family: "Poppins", sans-serif;
   }

   p {
      line-height: 1.6;
      font-size: 1.05em;
      font-weight: 400;
      color: #555;
   }

   h1 {
      font-size: 3.5em;
      line-height: 1;
   }
   h2 {
      font-size: 3em;
      line-height: 1.1;
   }
   h3 {
      font-size: 2.5em;
   }
   h4 {
      font-size: 1.5em;
   }
   h5 {
      font-size: 1.2em;
   }
   h6 {
      font-size: 0.9em;
      letter-spacing: 1px;
   }

   a,
   button {
      display: inline-block;
      text-decoration: none;
      color: inherit;
      transition: all 0.3s;
      line-height: 1;
   }

   a:focus,
   a:active,
   a:hover,
   button:focus,
   button:active,
   button:hover,
   a b.light-color:hover {
      text-decoration: none;
      color: #e45f74;
   }

   b {
      font-weight: 500;
   }

   img {
      width: 100%;
   }

   li {
      list-style: none;
      display: inline-block;
   }

   span {
      display: inline-block;
   }

   button {
      outline: 0;
      border: 0;
      background: none;
      cursor: pointer;
   }

   b.light-color {
      color: #444;
   }

   .icon {
      font-size: 1.1em;
      display: inline-block;
      line-height: inherit;
   }

   [class^="icon-"]:before,
   [class*=" icon-"]:before {
      line-height: inherit;
   }

   /* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

   .center-text {
      text-align: center;
   }

   .display-table {
      display: table;
      height: 100%;
      width: 100%;
   }

   .display-table-cell {
      display: table-cell;
      vertical-align: middle;
   }

   ::-webkit-input-placeholder {
      font-size: 0.9em;
      letter-spacing: 1px;
   }

   ::-moz-placeholder {
      font-size: 0.9em;
      letter-spacing: 1px;
   }

   :-ms-input-placeholder {
      font-size: 0.9em;
      letter-spacing: 1px;
   }

   :-moz-placeholder {
      font-size: 0.9em;
      letter-spacing: 1px;
   }

   .full-height {
      height: 100%;
   }

   .position-static {
      position: static;
   }

   .main-area {
      position: relative;
      height: 100vh;
   }

   .left-section {
      float: left;
      width: 50%;
      position: relative;
   }

   .left-section .logo {
      position: absolute;
      top: 40px;
      height: 30px;
   }

   .left-section .logo img {
      width: 55px;
   }

   .left-section .main-content {
      padding: 40px 40px 40px 0;
   }

   .main-content .title {
      margin-bottom: 15px;
   }

   .main-content .email-input-area {
      margin: 40px 0 20px;
      position: relative;
      width: 400px;
      height: 47px;
   }

   .main-content .email-input-area .email-input {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 40px;
      border: 0;
      outline: 0;
      padding: 0 140px 0 25px;
      transition: all 0.2s;
      background: #f1f2f3;
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      border: 1px solid transparent;
   }

   .main-content .email-input-area .email-input:focus {
      border-color: #f89fbc;
   }

   .main-content .email-input-area .submit-btn {
      width: 120px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      font-size: 0.9em;
      border-radius: 0 40px 40px 0;
      transition: all 0.3s;
      background: #f84982;
      color: #fff;
   }

   .main-content .email-input-area .submit-btn:hover {
      background: #e40b52;
   }

   .main-content .post-desc {
      color: #999;
   }

   .left-section .footer-icons {
      position: absolute;
      bottom: 30px;
      display: flex;
      gap: 10px;
   }

   .left-section .footer-icons > li > a {
      display: inline-block;
      font-size: 1.07em;
      padding: 0 0px;
   }

   .left-section .footer-icons > li:first-child {
      margin-right: 10px;
   }

   .left-section .footer-icons > li > a > i {
      display: inline-block;
      height: 35px;
      line-height: 33px;
      transition: all 0.2s;
      border-radius: 40px;
      text-align: center;
      width: 35px;
   }

   .left-section .footer-icons > li > a:hover > i {
      border: 1px solid;
   }

   .left-section .footer-icons > li > a > i[class*="facebook"] {
      color: #2a61d6;
      border-color: #2a61d6;
   }
   .left-section .footer-icons > li > a > i[class*="twitter"] {
      color: #3aa4f8;
      border-color: #3aa4f8;
   }
   .left-section .footer-icons > li > a > i[class*="google"] {
      color: #f43846;
      border-color: #f43846;
   }
   .left-section .footer-icons > li > a > i[class*="instagram"] {
      color: #8f614a;
      border-color: #8f614a;
   }
   .left-section .footer-icons > li > a > i[class*="pinterest"] {
      color: #e1c013;
      border-color: #e1c013;
   }

   .right-section {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50%;
      background-size: cover;
      z-index: 1;
   }

   .right-section:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -2;
      opacity: 0.3;
      background: linear-gradient(to right, #23074d, #cc5333);
   }

   #rounded-countdown {
      width: 80%;
      margin: 0 auto;
   }
}
