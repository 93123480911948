.support_history_wrapper {
   display: flex;
   flex-direction: column;
   .support_history_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #192638;
      margin-bottom: 6px;
   }
   .support_sub_heading {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b8698;
      margin-bottom: 28px;
      display: flex;
   }
   .support_heading {
      display: flex;
      margin-bottom: 12px;
      .support_heading_title {
         flex-basis: 25%;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 14px;
         color: #7b8698;
      }
   }

   .support_history_item {
      display: flex;
      .support_heading_title {
         flex-basis: 25%;
         font-style: normal;
         font-weight: 400;
         font-size: 13px;
         line-height: 13px;
         color: #374253;
      }
   }
   .no_support_history {
      margin-top: 28px;
      .no_support_history_text {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #ed1c24;
      }
   }

   .dashboard_pagination {
      justify-content: center;
      margin-top: 40px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
      }
   }
}
