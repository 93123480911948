@import "../include/variables";
.payment {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   align-items: center;
   margin-top: 30px;
   @include breakpoint(mobile) {
      flex-direction: column-reverse;
      gap: 35px;
      margin-bottom: 20px;
   }
   .payment_child1 {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include breakpoint(mobile) {
         display: flex;
         align-items: center;
      }
   }
   .payment_wrapper {
      flex-basis: 65%;
   }
}
.payment_option {
   cursor: pointer;
}
.payment_container {
   border-top: 1px solid #e5e5e5;
   background: #ffffff;
   box-shadow: 0px 0px 0px #e6eaf0;
   margin-top: 50px;
   .fixed-width {
      @include breakpoint(mobile) {
         width: 100% !important;
         max-width: 100% !important;
         padding-left: 16px;
         padding-right: 16px;
      }
   }
}
.payment_partners {
   display: flex;
   justify-content: flex-start;
   gap: 30px;
   flex-wrap: wrap;

   @include breakpoint(mobile) {
      gap: 60px;
      justify-content: center;
   }
}
// .payment_partners img {
//     margin-right: 20px;
// }
.payment_description {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */
   /* Grey/700 */
   color: #374253;
   margin-bottom: 5px;
}
.payment_title {
   font-style: normal;
   font-weight: bold;
   font-size: 33.5143px;
   line-height: 47px;
   /* identical to box height, or 139% */
   text-align: center;
   color: #ed1c24;
   margin-bottom: 8px;
   img {
      display: inline-block;
   }
   &:hover {
      opacity: 0.7;
   }
}
.payment_text {
   font-style: normal;
   font-weight: 500;
   font-size: 15px;
   line-height: 24px;
   /* identical to box height, or 160% */

   /* Grey/600 */
   margin-bottom: 0.5rem;

   color: #596579;
   @include breakpoint(mobile) {
      text-align: center;
   }
}
#payment_hover {
   &:hover {
      opacity: 0.7;
   }
}
