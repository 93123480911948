@import "../include//variables";
.internetcard {
   margin-top: 40px;
   background: linear-gradient(
      180deg,
      rgba(243, 247, 254, 0.5) 0%,
      rgba(243, 247, 254, 0.18) 92.19%,
      rgba(243, 247, 254, 0) 100%
   );
   .internet_toggle {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      p {
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         color: $grey;
      }
      .togglebutton {
         div {
            height: 30px !important;
            div {
               &:nth-child(1) {
                  height: 25px !important;
               }
               &:nth-child(2) {
                  height: 100% !important;
                  div {
                     height: 20px !important;
                  }
               }
            }
         }
      }
      p {
         margin: 0;
      }
   }
   .saveText {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 35px;
      .text {
         font-family: "Yatra One";
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 14px;
         color: #0e9e49;
         margin: 0;
      }
      img {
         margin-bottom: 30px;
         margin-left: 5px;
      }
   }
}
.internet-packagetext {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 26px;
   /* identical to box height, or 144% */
   margin-top: 10px;
   text-align: center;
   /* Information/DEF */

   color: #1a63f4;
}
.super-internet {
   font-style: normal;
   font-weight: 600;
   // font-size: 40px;
   // line-height: 62px;
   /* identical to box height, or 115% */
   text-align: center;
   @include Paragraph(40, 62);
   /* Primary/DEF */
   color: #ed1c24;
}
#foryou {
   color: black;
}
.best-package {
   max-width: 350px;
   height: 540px;
   margin-top: 30px;
   background: #ffffff;
   /* Grey/200 */
   min-width: 300px;
   border-radius: 12px 12px 0 0;
   width: 100%;
   border: 1px solid #e6eaf0;
   box-sizing: border-box;

   position: relative;
}
.inside-internet0 {
   /* Information/DEF */
   background-size: 100% 100%;
   background-image: url("../../assets/internet/back.png");
   /* width: 330px; */
   /* object-fit: contain; */
   height: 200px;
}
.inside-internet1 {
   /* Information/DEF */
   background-size: 100% 100%;
   background-image: url("../../assets/internet/backred.png");
   /* width: 330px; */
   /* object-fit: contain; */
   height: 200px;
}
.inside-internet2 {
   /* Information/DEF */
   background-size: 100% 100%;
   background-image: url("../../assets/internet/back2.png");
   /* object-fit: contain; */
   height: 200px;
}
.internet-speed {
   font-style: normal;
   font-weight: 600;
   font-size: 25px;
   padding-top: 10px;
   line-height: 46px;
   /* identical to box height, or 115% */
   text-align: center;
   /* Grey/50 */

   color: #ffffff;
}
.internet-description {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   font-style: normal;
   font-weight: 200;
   font-size: 16px;
   line-height: 24px;
   /* or 150% */
   width: 100%;
   padding: 0 55px;

   text-align: center;

   /* Grey/50 */

   color: #ffffff;
}
#mbps {
   font-style: normal;
   font-weight: 300;
   font-size: 20px;
   line-height: 46px;
   /* identical to box height, or 115% */
   text-align: center;
   /* Grey/50 */

   margin-top: 10px;
   color: #ffffff;
}
.internet-li {
   list-style-type: none;
   margin-top: 5px;
   padding-left: 24px;
   padding-right: 22px;
}
.internet-feature {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 150%;
   /* identical to box height, or 27px */

   margin-top: 10px;
   /* Grey/700 */

   color: #374253;
   img {
      display: inline-block;
   }
}
.li-price {
   margin-top: 40px;
   margin-left: 35px;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 20px;
   /* or 140% */
   position: absolute;
   /* Grey/900 */
   bottom: 75px;
   color: #0f1825;
}
#li-subprice {
   font-style: normal;
   font-weight: 500;
   font-size: 15px;
   line-height: 28px;
   /* or 140% */
}
.choose-btn {
   margin: auto;
   justify-content: center;
   background: #ed1c24 !important;
   border-radius: 12px !important;
   border: none !important;
   width: 250px;

   height: 44px;
   margin-bottom: 30px;
   &:hover {
      background: #d31118 !important;
   }
}
.internet-button {
   display: flex;
   position: absolute;
   bottom: 0px;
   width: 100%;
}

#styling {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
}
