@import "../include/variables";

.Category__wrapper {
   display: flex;
   margin-top: 60px;
   flex-direction: column;
   align-items: center;
   .Category__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      .category__img {
         height: 140px;
         width: 140px;
         border-radius: 28px;
         border: 1px solid rgb(229, 57, 53, 0.1);
         box-shadow: -2px 13px 18px -9px rgba(229, 57, 53, 0.25);
      }
      .category__title {
         font-family: "SF Pro Display";
         font-style: normal;
         font-weight: 700;
         font-size: 42px;
         line-height: 62px;
         margin-top: 16px;
         margin-bottom: 28px;
         color: #192638;
      }
   }
   .category_card_wrapper {
      width: 100%;
      min-width: 250px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 40px;
      .HD_package {
         height: 440px;
      }
   }
   .Dropdown-root {
      align-self: flex-start;
      margin: 8px 0px;
      margin-left: 2%;

      .Dropdown-control {
         width: 100%;
         max-width: 280px;
      }
   }
}
