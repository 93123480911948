@import "../../scss/include/variables";

.trailer_modal {
   width: 100%;
   max-width: 100%;
   justify-content: center;
   .modal-content {
      width: auto;
      background-color: transparent;
   }
   .modal-body {
      padding: 0;
   }
}
.currentmoviecard_wrapper {
   display: flex;
   flex-direction: column;
   max-width: 305px;
   .currentmoviecard_img {
      border-radius: 6px;
      position: relative;
      height: 420px;
      width: 305px;
      &:hover .movie_img {
         opacity: 0.5;
      }
      &:hover .currentmoviecard_info {
         opacity: 1;
      }
      img {
         // height: 411px;
         // width: 305px;
         height: 100%;
         width: 100%;
         border-radius: 6px;
         transition: 0.5s ease;
         backface-visibility: hidden;
         object-fit: cover;
      }
      .currentmoviecard_info {
         transition: 0.3s ease;
         opacity: 0;
         position: absolute;
         height: 100%;
         width: 100%;
         top: 50%;
         left: 50%;
         border-radius: 6px;
         transform: translate(-50%, -50%);
         -ms-transform: translate(-50%, -50%);
         text-align: center;
         background: rgba(0, 0, 0, 0.6);
         //button alignment
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         .currentmoviecard_button {
            min-width: 157px;
            border: 1.2px solid #ffffff;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.05);
            color: #ffffff;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            text-transform: capitalize;
            margin: 6px 0px;
            padding: 12px 0px;
         }
      }
   }

   .currentmoviecard_text {
      color: #192638;
      text-align: center;
      h4 {
         font-style: normal;
         font-weight: 600;
         font-size: 20px;
         line-height: 28px;
         /* identical to box height, or 140% */
         margin-top: 16px;
      }
      p {
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         /* identical to box height, or 150% */
      }
   }
   .currentmoviecard_showtime {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      cursor: default;
      .showtime {
         padding: 6px 10px;
         margin-right: 10px;
         margin-top: 12px;
         background: #0e9e49;
         border-radius: 6px;
         border: none;
         cursor: context-menu;
         color: #fff;
         font-weight: 600;
         font-size: 14px;
         line-height: 22px;
         text-align: center;
      }
   }
}
