@import "../include/variables";
.recommended {
   margin-top: 79px;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.recommended-title {
   font-style: normal;
   font-weight: 600;
   // font-size: 54px;
   // line-height: 62px;
   /* identical to box height, or 115% */
   @include HeaderFontResponsive(54, 62);
   text-align: center;
   /* Grey/800 */

   color: #192638;
}
.recommended-subtitle {
   font-style: normal;
   font-weight: 600;
   // font-size: 24px;
   // line-height: 36px;
   @include Paragraph(24, 36);
   /* identical to box height, or 150% */

   text-align: center;

   /* Grey/700 */

   color: #374253;
}
.green-text {
   color: #0e9e49;
}
.recommended-box {
   margin-top: 50px;

   background: #f3f7fe;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   &:hover {
      background: #e9f0fe;
   }
   @include breakpoint(mobile) {
      width: 220px;
      height: 220px;
   }
   @include breakpoint(phablet) {
      width: 240px;
      height: 240px;
   }
   @include breakpoint(tablet) {
      width: 260px;
      height: 260px;
   }
   @include breakpoint(laptop) {
      width: 260px;
      height: 260px;
   }
   @include breakpoint(desktop) {
      width: 290px;
      height: 300px;
   }
   @include breakpoint(2k) {
      width: 330px;
      height: 310px;
   }
}
.recommended-image {
   display: flex;
   justify-content: center;
}
.recommended-image img {
   margin-top: 40px;
}
#internet-mbps {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 15px;
   flex-wrap: wrap;
}
.recommended-text {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   margin-top: 30px;
   color: #374253;
   max-width: 250px;
   text-align: center;
}
#unique-container {
   background: #fef3f3;
}
.availaibility {
   display: flex;
   justify-content: center;
   gap: 25px;
   margin-top: 80px;
   width: 100%;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .girl-image {
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(laptop) {
         flex-basis: 40%;
      }
      @include breakpoint(desktop) {
         flex-basis: 40%;
      }
      @include breakpoint(2k) {
         flex-basis: 40%;
      }
   }
}

.availaibility-description {
   margin-top: 10px;
   margin-bottom: 20px;
   display: flex;
   flex-direction: column;
   @include breakpoint(mobile) {
      align-items: center;
   }
   @include breakpoint(phablet) {
      align-items: center;
   }
   @include breakpoint(tablet) {
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: flex-start;
   }
   @include breakpoint(desktop) {
      align-items: flex-start;
   }
   @include breakpoint(2k) {
      align-items: flex-start;
   }
}
.availaibility-title {
   font-style: normal;
   font-weight: 600;
   // font-size: 40px;
   // line-height: 52px;
   /* identical to box height, or 130% */
   @include HeaderFontResponsive(40, 52);
   /* Grey/800 */
   color: #192638;
}
.availaibility-subtitle {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* or 150% */
   /* Grey/700 */
   max-width: 480px;
   color: #374253;
   @include breakpoint(mobile) {
      text-align: center;
   }
}
.availaibility-text {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 32px;
   color: #192638;
   margin-bottom: 0px;
}
.avaialibility-answer {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/700 */

   color: #374253;
}
.availaibility-li {
   list-style-type: none;
}
.li-image {
   height: 60px;
}
.availaibility-button {
   border-radius: 30px !important;
   background-color: white !important;
   color: #3777f6 !important;
   margin-left: 0px;
   &:hover {
      opacity: 0.7;
   }
}
#recommended_box1 {
   background-color: #fef3f3;
   &:hover {
      background: #fee9e9;
   }
}
#recommended_box2 {
   background-color: #e4feef;
   &:hover {
      background: #d1fbe2;
   }
}
