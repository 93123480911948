@import "../include/variables";
.quicklink {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-top: 50px;
   .fixed-width {
      @include breakpoint(mobile) {
         width: 100% !important;
         max-width: 100% !important;
         padding-left: 16px;
         padding-right: 16px;
      }
   }
}
#quick-buy {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 26px;
   /* identical to box height, or 144% */

   /* Grey/100 */

   color: #f4f7fb;
}
#quick-price {
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 28px;
   margin-bottom: 26px;
   /* identical to box height, or 140% */

   /* Grey/100 */

   color: #f4f7fb;
}
#quick-free {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   /* or 150% */

   /* Grey/100 */

   color: #f4f7fb;
}
.footer__offer_img {
   height: 100%;
   max-height: 480px;
   left: 1186px;
   top: 8316px;
   display: flex;
   justify-content: center;
   background: linear-gradient(134.47deg, #743ceb -6.99%, #6e1acd 35.72%, #f481c6 128.35%);
   box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.15);
   border-radius: 25px;
   align-items: center;
   @include breakpoint(mobile) {
      width: fit-content;
      padding: 10px;
   }
   @include breakpoint(phablet) {
      width: fit-content;
      padding: 10px;
   }
   @include breakpoint(tablet) {
      width: fit-content;
      padding: 10px;
   }
   @include breakpoint(laptop) {
      width: 100%;
   }
   @include breakpoint(desktop) {
      width: 100%;
   }
   @include breakpoint(2k) {
      width: 414px;
   }
}

.quicklink__container {
   display: flex;
   flex-direction: row;

   @include breakpoint(mobile) {
      gap: 20px;
      flex-direction: column;
      justify-content: center;
   }
   @include breakpoint(phablet) {
      gap: 20px;
      flex-direction: column;
      justify-content: center;
   }
   @include breakpoint(tablet) {
      gap: 20px;
      flex-direction: column;
      justify-content: center;
   }
   @include breakpoint(laptop) {
      gap: 20px;
      flex-direction: row;
      justify-content: space-between;
   }
   @include breakpoint(desktop) {
      gap: 20px;
      flex-direction: row;
      justify-content: space-between;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 20px;
      justify-content: space-between;
   }

   .footer__dishhome {
      flex-basis: 28%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      // p {
      //    width: 100%;
      // }
      @include breakpoint(mobile) {
         margin-bottom: 18px;
      }
      @include breakpoint(phablet) {
         margin-bottom: 18px;
      }
      @include breakpoint(tablet) {
         margin-bottom: 18px;
      }
      @include breakpoint(laptop) {
         margin-bottom: 0;
      }
      @include breakpoint(desktop) {
         margin-bottom: 0;
      }
      @include breakpoint(2k) {
         margin-bottom: 0;
      }
      button {
         width: 100%;
         max-width: 400px;
         margin-top: 10px;
         height: 40px;
      }
   }
   .footer__links__container {
      display: flex;
      flex-direction: column;
      flex-basis: 35%;
      align-items: center;
      @include breakpoint(mobile) {
         flex-direction: row;
         justify-content: space-between;
         align-items: flex-start;
      }
   }
   .footer__links {
      text-align: left;
      display: flex;
      justify-content: center;
      width: 100%;

      @include breakpoint(mobile) {
         gap: 50px;
         flex-direction: column;
         margin-left: 10px;
      }
      @include breakpoint(phablet) {
         gap: 100px;
         margin-left: 180px;
      }
      @include breakpoint(tablet) {
         gap: 110px;
         margin-left: 210px;
      }
      @include breakpoint(laptop) {
         gap: 30px;
         margin-left: 0px;
      }
      @include breakpoint(desktop) {
         margin-left: 0px;
         gap: 50px;
      }
      @include breakpoint(2k) {
         margin-left: 0px;
         gap: 50px;
      }
   }
   .footer__offer {
      flex-basis: 32%;
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
         align-items: center;
      }
      @include breakpoint(phablet) {
         align-items: center;
      }
      @include breakpoint(tablet) {
         align-items: center;
      }
      @include breakpoint(laptop) {
         align-items: flex-start;
      }
      @include breakpoint(desktop) {
         align-items: flex-start;
      }
      @include breakpoint(2k) {
         align-items: flex-start;
      }
   }
}
.touch {
   margin-top: 20px;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;

   /* identical to box height, or 140% */
   /* Grey/800 */
   color: #192638;
}
.description {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   margin-top: 5px;
   // line-height: 15px;
   /* or 157% */
   /* Grey/700 */
   color: #374253;
   margin-bottom: 0px;
}
.subscribe {
   background: #374253 !important;
   border-radius: 12px !important;
   width: 287px;
   border: none !important;
   margin-top: 5px;
   &:hover {
      background: #515a67 !important;
   }
}
.email {
   border-radius: 12px !important;
   width: 287px;
   border: none !important;
   margin-top: 15px;
   color: #7b8698 !important;
   background: #ffffff !important;
   box-shadow: 0px 1px 0px #e6eaf0;
   border: 1.2px solid #7b8698 !important;
}
.quicklink_link {
   list-style-type: none;
   margin-bottom: 30px;
   padding: 0px;
   flex-basis: 45%;
}
.link_title {
   width: fit-content;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */
   /* Grey/800 */
   color: #192638;
}
.link_list {
   width: fit-content;

   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 35px;
   /* identical to box height, or 150% */

   /* Grey/700 */

   color: #374253;
   span {
      margin-left: 3px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      padding: 5px 8px;
      background: #0e9e49;
      border-radius: 24px;
   }
   &:hover {
      color: #083ca4;
   }
}

.latest_offer {
   margin-left: 15px;
   color: #192638;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   width: 100%;
   margin-left: 0;
}
#buynow_footer {
   &:hover {
      opacity: 0.7;
   }
}
#learnmore_footer {
   &:hover {
      opacity: 0.7;
   }
}
