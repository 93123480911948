
:root {
  --bs-primary:#3147FF;
  --bs-primary-rgb:49, 71, 255;
  --l2-primary:#A259FF;
  --l2-primary-rgb:162, 89, 255;
  --l2-secondary:#0A102F;
  --l2-secondary-rgb:10, 16, 47;
  --l3-primary:#FFC900;
  --l3-primary-rgb:255, 201, 0;
  --l4-primary:#4B2AAD;
  --l4-primary-rgb:75, 42, 173;
  --l5-primary:#FF9966;
  --l5-primary-rgb:255, 153, 102;
  --l5-secondary:#1B1C1D;
  --l5-secondary-rgb:27, 28, 29;
  --l6-primary:#3147FF;
  --l6-primary-rgb:49, 71, 255;
  --l7-primary:#191919;
  --l7-primary-rgb:25, 25, 25;
  --l7-secondary:#A259FF;
  --l7-secondary-rgb:162, 89, 255;
  --l8-primary:#C1FF00;
  --l8-primary-rgb:193, 255, 0;
  --l9-primary:#324438;
  --l9-primary-rgb:50, 68, 56;
  --l9-secondary:#F4B905;
  --l9-secondary-rgb:244, 185, 5;
  --l10-primary:#2D2B92;
  --l10-primary-rgb:45, 43, 146;
  --l10-secondary:#FFD874;
  --l10-secondary-rgb:255, 216, 116;
  --body-color:#0A102F;
  --body-color-rgb:10, 16, 47;
  --black-2:#121212;
  --black-2-rgb:18, 18, 18;
  --black-3:#1B1C1D;
  --black-3-rgb:27, 28, 29;
  --black-4:#191919;
  --black-4-rgb:25, 25, 25;
  --cream:#FDFBF9;
  --cream-rgb:253, 251, 249;
  --offwhite:#FAF9F5;
  --offwhite-rgb:250, 249, 245;
  --font-family-base:Inter, sans-serif;
  --font-family-heading:Plus Jakarta Sans, sans-serif;
  --font-wight-heading:800;
  --font-dm-sans:DM Sans, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family-base);
  font-size: 1rem;
  line-height: 1.56;
}

.custom-page-wrapper  span,
a {
  display: inline-block;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: initial;
}

img {
  display: block;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-heading);
  font-family: var(--font-family-heading);
}

.custom-page-wrapper p {
  font-size: 16px;
  line-height: 1.67;
  margin-bottom: initial;
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 576px) {
  p {
    font-size: 18px;
  }
}

.position-relative {
  position: relative;
}

.list-style-bullet li {
  display: flex;
  column-gap: 8px;
}
.list-style-bullet li::before {
  content: "";
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-width: 8px;
  min-height: 8px;
  max-height: 8px;
  border-radius: 500px;
  background-color: #0A102F;
  top: 10px;
  position: relative;
}

.inner-block-title {
  line-height: 1.5;
  letter-spacing: 0em;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
}
@media (min-width: 768px) {
  .inner-block-title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .inner-block-title {
    font-size: 24px;
  }
}

/*!
 * Bootstrap Grid v5.3.0-alpha3 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 480px) {
  .col-xs {
    flex: 1 0 0%;
  }
  .row-cols-xs-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xs-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xs-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xs-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xs-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xs-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }
  .g-xs-0,
  .gx-xs-0 {
    --bs-gutter-x: 0;
  }
  .g-xs-0,
  .gy-xs-0 {
    --bs-gutter-y: 0;
  }
  .g-xs-1,
  .gx-xs-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xs-1,
  .gy-xs-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xs-2,
  .gx-xs-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xs-2,
  .gy-xs-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xs-3,
  .gx-xs-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xs-3,
  .gy-xs-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xs-4,
  .gx-xs-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xs-4,
  .gy-xs-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xs-5,
  .gx-xs-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xs-5,
  .gy-xs-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

.feature-card {
  background: #FFFFFF;
  border: 2px solid #0A102F;
  border-radius: 10px;
  padding: 30px;
  cursor: pointer;
  transition: 300ms;
}
.feature-card__icon {
  padding-bottom: 30px;
}
.feature-card__title {
  padding-bottom: 15px;
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: var(--body-color);
}
.feature-card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(var(--body-color-rgb), 0.8);
  margin-bottom: 0;
}
.feature-card p span {
  font-weight: 600;
}
.feature-card:hover {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}

/* ========== Component : Feature Card Style 3============= */
.feature-card-3 {
  padding: 28px;
  border: 4px solid #0A102F;
  border-radius: 10px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  background: #F5EFEB;
}
.feature-card-3__icon-wrapper {
  padding-bottom: 30px;
}
.feature-card-3__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  color: var(--body-color);
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .feature-card-3__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-card-3__title {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .feature-card-3:hover {
    transform: rotate(-3deg);
    background-color: #fff;
  }
}

/* ========== Component : Feature Card 5 Style ============= */
/* ========== Component : process Card Style ============= */
.process-card {
  padding: 30px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #fff;
  transition: 0.3s;
  flex-direction: column;
  row-gap: 20px;
}
@media (min-width: 768px) {
  .process-card {
    row-gap: 40px;
  }
}
@media (min-width: 992px) {
  .process-card {
    row-gap: 60px;
  }
}
.process-card:hover {
  transform: translateY(-10px);
}
.process-card__icon-block {
  max-width: 100px;
}
.process-card:hover .process-card__icon {
  color: #3147FF;
}
.process-card p {
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
}
.process-card__heading {
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .process-card__heading {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .process-card__heading {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .process-card__heading {
    font-size: 30px;
  }
}
.process-card__step {
  display: inline-block;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  color: #BEF8FC;
  position: relative;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .process-card__step {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .process-card__step {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .process-card__step {
    margin-bottom: 34px;
  }
}
.process-card__step::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: #BEF8FC;
  bottom: -4px;
  left: 0;
  border-radius: 500px;
}

/* ========== Component : Testimonial Card Style ============= */
.testimonial__widget {
  height: 100%;
  display: flex;
}
.testimonial__widget__rating-stars {
  width: 100%;
}

.testimonial-card {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 10px;
}
.testimonial-card__star {
  margin-bottom: 25px;
}
.testimonial-card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #0A102F;
  margin-bottom: 24px;
}
.testimonial-card__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
  font-weight: 600;
  color: var(--l2-secondary);
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .testimonial-card__title {
    font-size: 18px;
  }
}
.testimonial-card__author {
  display: flex;
  align-items: center;
  gap: 20px;
}
.testimonial-card__author-image img {
  border-radius: 50%;
}
.testimonial-card__author-info {
  display: flex;
  flex-direction: column;
}
.testimonial-card__author-info h4 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  font-family: var(--font-family-base);
  font-weight: 600;
  color: var(--l2-secondary);
}
@media (min-width: 576px) {
  .testimonial-card__author-info h4 {
    font-size: 18px;
  }
}
.testimonial-card__author-info span {
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(10, 16, 47, 0.7);
}

.testimonial-card-2 {
  background: #FFFFFF;
  border: 2px solid #191919;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}
.testimonial-card-2__image {
  display: inline-flex;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .testimonial-card-2__image {
    margin-bottom: 30px;
  }
}
.testimonial-card-2 p {
  color: rgba(10, 16, 47, 0.8);
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .testimonial-card-2 p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .testimonial-card-2 p {
    margin-bottom: 30px;
  }
}
.testimonial-card-2__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
  color: var(--l2-secondary);
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .testimonial-card-2__title {
    font-size: 18px;
  }
}
.testimonial-card-2__user-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .testimonial-card-2__user-name {
    font-size: 18px;
  }
}
.testimonial-card-2__user-position {
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.7);
}

/* ========== Component : Pricing Card Style 1============= */
.pricing-card {
  border: 2px solid #0A102F;
  border-radius: 10px;
  padding: 30px;
  transition: all 0.4s;
}
@media (min-width: 1200px) {
  .pricing-card {
    padding: 40px;
  }
}
.pricing-card__head {
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
  font-family: "Inter", sans-serif;
}
.pricing-card__plan {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
}
.pricing-card__price-block {
  font-weight: 400;
  font-size: 28px;
  line-height: 2.86;
  font-family: "Inter", sans-serif;
}
.pricing-card__price-block span {
  font-weight: 700;
  font-size: 58px;
  line-height: 1.38;
}
.pricing-card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.7);
}
.pricing-card__body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (min-width: 992px) {
  .pricing-card__body {
    row-gap: 35px;
  }
}
.pricing-card__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}
.pricing-card__list li {
  display: flex;
  column-gap: 12px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Inter", sans-serif;
  color: #0A102F;
}
.pricing-card__list li::before {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f005";
}
.pricing-card__list li.disabled {
  opacity: 0.2;
}
.pricing-card.active {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}
.pricing-card:hover {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}

/* ========== Component : Pricing Card Style 2 ============= */
.pricing-card-2 {
  background: #FDFBF9;
  border-radius: 5px;
  padding: 40px;
}
.pricing-card-2__head {
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(27, 28, 29, 0.1);
}
.pricing-card-2__icon {
  margin-bottom: 30px;
}
.pricing-card-2__plan {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
  margin-bottom: 10px;
  color: #1B1C1D;
}
@media (min-width: 576px) {
  .pricing-card-2__plan {
    font-size: 20px;
  }
}
.pricing-card-2__price {
  font-family: "General Sans", sans-serif;
  color: #1B1C1D;
  font-weight: 600;
  line-height: 1.18;
  font-size: 52px;
}
@media (min-width: 1200px) {
  .pricing-card-2__price {
    font-size: 56px;
  }
}
@media (min-width: 1400px) {
  .pricing-card-2__price {
    font-size: 72px;
  }
}
.pricing-card-2__time {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  font-family: "Inter", sans-serif;
  color: rgba(27, 28, 29, 0.8);
}
.pricing-card-2 p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.7);
}
.pricing-card-2__body {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}
@media (min-width: 992px) {
  .pricing-card-2__body {
    row-gap: 13px;
  }
}
.pricing-card-2__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
}
.pricing-card-2__list li {
  display: flex;
  column-gap: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.67;
  color: rgba(27, 28, 29, 0.8);
}

/* ========== Component : Pricing Card Style 3 ============= */
.pricing-card-3 {
  background: #FFFFFF;
  border: 2px solid #191919;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  transition: all 0.4s;
}
.pricing-card-3__head {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
}
@media (min-width: 1200px) {
  .pricing-card-3__head {
    flex-direction: row;
  }
}
.pricing-card-3__plan {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.5px;
}
.pricing-card-3__price-block {
  text-align: center;
}
.pricing-card-3__price-sign {
  font-size: 28px;
  font-family: "Inter", sans-serif;
}
.pricing-card-3__price {
  font-weight: 700;
  font-size: 58px;
  line-height: 1.2;
  font-family: var(--font-family-base);
}
.pricing-card-3__price-time {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.5px;
}
.pricing-card-3__body {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 9px) {
  .pricing-card-3__body {
    row-gap: 50px;
  }
}
.pricing-card-3__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 18px;
}
.pricing-card-3__list li {
  display: flex;
  column-gap: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.pricing-card-3__list li::before {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f00c";
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--black-3);
  background-color: var(--black-3);
  color: #fff;
  font-size: 11px;
}
.pricing-card-3__list li.disabled::before {
  background-color: transparent;
  color: var(--black-3);
}
.pricing-card-3__button {
  margin-top: auto;
}
.pricing-card-3__button .btn-primary-l07 {
  pointer-events: none;
}
.pricing-card-3__button .btn-outline {
  --btn-hover-bg: #191919;
  --btn-hover-border: #191919;
}
.pricing-card-3.active {
  box-shadow: 10px 10px #A259FF;
  border-radius: 10px;
}
.pricing-card-3:hover {
  box-shadow: 10px 10px #A259FF;
  border-radius: 10px;
}

/* ========== Component : Pricing Card Style 4 ============= */
.pricing-card-4 {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-radius: 20px;
  padding: 40px;
}
@media (min-width: 992px) {
  .pricing-card-4 {
    row-gap: 40px;
  }
}
.pricing-card-4__head {
  color: #FFFFFF;
}
.pricing-card-4__plan {
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .pricing-card-4__plan {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .pricing-card-4__plan {
    font-size: 24px;
  }
}
.pricing-card-4__price {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.38;
  font-family: var(--font-family-base);
}
@media (min-width: 768px) {
  .pricing-card-4__price {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  .pricing-card-4__price {
    font-size: 44px;
  }
}
@media (min-width: 1200px) {
  .pricing-card-4__price {
    font-size: 52px;
  }
}
@media (min-width: 1400px) {
  .pricing-card-4__price {
    font-size: 58px;
  }
}
.pricing-card-4__time {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  font-weight: 400;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .pricing-card-4__time {
    font-size: 20px;
  }
}
.pricing-card-4 p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.7);
}
.pricing-card-4__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
}
.pricing-card-4__list li {
  display: flex;
  column-gap: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  line-height: 1.4;
  color: #FFFFFF;
}
@media (min-width: 576px) {
  .pricing-card-4__list li {
    font-size: 20px;
  }
}
.pricing-card-4__list li::before {
  content: "";
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  border-radius: 500px;
  position: relative;
  top: 12px;
  background-color: #FFFFFF;
}
.pricing-card-4__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  margin-top: auto;
}
.pricing-card-4__button-bottom-text {
  font-size: 16px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
}

/* ========== Component : Pricing Card Style 5 ============= */
.pricing-card-5 {
  background: #FFFFFF;
  border: 2px solid #191919;
  border-radius: 10px;
  padding: 30px;
}
.pricing-card-5__head {
  display: flex;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
}
.pricing-card-5__plan {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.5px;
}
.pricing-card-5__price-block {
  text-align: center;
}
.pricing-card-5__price-sign {
  font-size: 28px;
  line-height: 2.86;
  font-family: "Inter", sans-serif;
}
.pricing-card-5__price {
  font-weight: 700;
  font-size: 58px;
  line-height: 1.38;
  font-family: var(--font-family-base);
}
.pricing-card-5__price-time {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.5px;
}
.pricing-card-5__body {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .pricing-card-5__body {
    row-gap: 50px;
  }
}
.pricing-card-5__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 18px;
}
.pricing-card-5__list li {
  display: flex;
  column-gap: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.pricing-card-5__list li::before {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f058";
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}
.pricing-card-5__list li.disabled::before {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f00c";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #191919;
  height: 18px;
  min-height: 18px;
  max-height: 18px;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  border-radius: 500px;
}
.pricing-card-5.active {
  box-shadow: 10px 10px #A259FF;
  border-radius: 10px;
}

/* ========== Component : Pricing Card Style 6 ============= */
.pricing-card-6 {
  background: #FFFFFF;
  border: 1px solid var(--black-2);
  border-radius: 5px;
}
@media (min-width: 767px) {
  .pricing-card-6 {
    display: flex;
    flex-wrap: wrap;
  }
}
.pricing-card-6__head, .pricing-card-6__body {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 480px) {
  .pricing-card-6__head, .pricing-card-6__body {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .pricing-card-6__head, .pricing-card-6__body {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .pricing-card-6__head, .pricing-card-6__body {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.pricing-card-6__head {
  border-bottom: 1px solid rgba(27, 28, 29, 0.1);
}
@media (min-width: 992px) {
  .pricing-card-6__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(27, 28, 29, 0.1);
    border-bottom: 0;
  }
}
.pricing-card-6__head p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  color: rgba(var(--body-color-rgb), 0.8);
  max-width: 381px;
}
@media (min-width: 576px) {
  .pricing-card-6__head p {
    font-size: 18px;
  }
}
.pricing-card-6__plan {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: -0.025em;
  margin-bottom: 20px;
  color: var(--black-2);
}
@media (min-width: 576px) {
  .pricing-card-6__plan {
    font-size: 20px;
  }
}
.pricing-card-6__price {
  font-family: "Public Sans", sans-serif;
  font-size: 32px;
  line-height: 1.14;
  font-weight: 700;
}
@media (min-width: 576px) {
  .pricing-card-6__price {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .pricing-card-6__price {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .pricing-card-6__price {
    font-size: 56px;
  }
}
.pricing-card-6__price-plan {
  margin-bottom: 24px;
}
.pricing-card-6__time {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  font-family: "Inter", sans-serif;
  color: rgba(var(--black-2), 0.8);
}
@media (min-width: 576px) {
  .pricing-card-6__time {
    font-size: 18px;
  }
}
.pricing-card-6__body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1400px) {
  .pricing-card-6__body {
    padding-left: 128px;
  }
}
.pricing-card-6__badge {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: "Inter", sans-serif;
  margin-bottom: 24px;
  color: var(--black-2);
}
@media (min-width: 576px) {
  .pricing-card-6__badge {
    font-size: 18px;
  }
}
.pricing-card-6__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .pricing-card-6__list {
    margin-bottom: 50px;
  }
}
.pricing-card-6__list li {
  display: flex;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  align-items: flex-start;
}
@media (min-width: 576px) {
  .pricing-card-6__list li {
    font-size: 18px;
  }
}
.pricing-card-6__list li .icon {
  margin-top: 3px;
}
.pricing-card-6__list li span {
  color: rgba(var(--black-2), 0.8);
}

/* ========== Component : Course Card ============= */
.course-card {
  padding: 30px;
  border-radius: 10px;
  background: #FDFBF9;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.course-card__image {
  border-radius: 10px;
  position: relative;
}
.course-card__image::before {
  content: "";
  background-color: rgba(10, 16, 47, 0.1);
  position: absolute;
  inset: 0;
  border-radius: inherit;
}
.course-card__image img {
  border-radius: inherit;
}
.course-card__meta {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
  color: rgb(var(--body-color-rgb), 0.8);
  font-weight: 600;
  line-height: 1.43;
  font-size: 14px;
}
.course-card__meta-tag {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  column-gap: 8px;
}
@media (min-width: 576px) {
  .course-card__meta-tag {
    flex-direction: row;
    align-items: center;
  }
  .course-card__meta-tag > *:not(:last-child) {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .course-card__meta-tag > *:not(:last-child):after {
    content: "";
    background-color: rgb(var(--body-color-rgb), 0.8);
    display: block;
    border-radius: 500px;
    min-width: 6px;
    max-width: 6px;
    min-height: 6px;
    max-height: 6px;
    border-radius: 500px;
  }
}
.course-card__meta-rating {
  display: flex;
  column-gap: 8px;
  align-items: center;
}
.course-card__meta-rating i {
  color: #FFC900;
}
.course-card__title {
  font-family: var(--font-family-base);
  font-weight: 700;
  line-height: 1.33;
  font-size: 20px;
}
@media (min-width: 768px) {
  .course-card__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .course-card__title {
    font-size: 24px;
  }
}
.course-card__footer {
  margin-top: auto;
}

/* ========== Component : Blog Card ============= */
.blog-card {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #121212;
  border-radius: 5px;
}
.blog-card__image {
  position: relative;
  margin-bottom: 24px;
}
.blog-card__image img {
  width: 100%;
}
.blog-card__badge {
  position: absolute;
  top: 3%;
  right: 2%;
  padding: 10.5px 20.5px;
  background: #FDFBF9;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
  color: #121212;
}
.blog-card__meta {
  display: flex;
  column-gap: 15px;
  margin-bottom: 15px;
}
.blog-card__meta span {
  display: flex;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #121212;
}
.blog-card__title {
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  line-height: 1.36;
  font-size: 22px;
  font-weight: 700;
  color: var(--black-2);
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .blog-card__title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .blog-card__title {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .blog-card__title {
    margin-bottom: 30px;
  }
}
.blog-card__link {
  display: flex;
  column-gap: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #121212;
}
.blog-card__link i {
  position: relative;
  top: 5px;
}

/* ========== Component : Blog Card Large ============= */
.blog-card-large {
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  transition: 0.4s;
}
.blog-card-large a {
  color: #0A102f;
}
.blog-card-large:hover {
  box-shadow: 0px 4px 60px rgba(10, 16, 47, 0.08);
}
.blog-card-large__image img {
  width: 100%;
}
.blog-card-large__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 15px;
  column-gap: 24px;
  margin-bottom: 30px;
}
.blog-card-large__meta span {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.blog-card-large__user {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large__user img {
  height: 45px;
}
.blog-card-large__time {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large__category {
  padding: 5.5px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: rgba(10, 16, 47, 0.05);
  color: rgba(10, 16, 47, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large__body {
  padding: 30px;
}
.blog-card-large__body p {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .blog-card-large__body p {
    max-width: 760px;
  }
}
.blog-card-large__title {
  font-weight: 700;
  letter-spacing: -0.0333em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  margin-bottom: 15px;
  font-family: var(--font-family-base);
}
@media (min-width: 768px) {
  .blog-card-large__title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .blog-card-large__title {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .blog-card-large__title {
    font-size: 30px;
  }
}
.blog-card-large__link {
  display: flex;
  column-gap: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #0A102F;
}
.blog-card-large__link:hover {
  color: #3147FF;
}
.blog-card-large__link i {
  position: relative;
  top: 5px;
}
.blog-card-large-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .blog-card-large-row {
    row-gap: 40px;
  }
}

.blog-card-large-2__image img {
  width: 100%;
}
.blog-card-large-2__meta {
  display: flex;
  column-gap: 24px;
}
.blog-card-large-2__meta span {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.blog-card-large-2__user {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large-2__time {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large-2__category {
  padding: 5.5px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: rgba(10, 16, 47, 0.05);
  color: rgba(10, 16, 47, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.blog-card-large-2__body {
  padding: 30px;
}
.blog-card-large-2__body p {
  margin-bottom: 30px;
}

/* ========== Component : Team Card Style ============= */
.team-card {
  overflow: hidden;
  position: relative;
}
.team-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
@media (min-width: 768px) {
  .team-card img {
    border-radius: 0px;
  }
}
.team-card__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: var(--black-2);
  margin-bottom: 5px;
}
.team-card__position {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(var(--black-2-rgb), 0.8);
}
.team-card__body {
  padding: 20px;
  background: #F5F3EA;
  border-radius: 5px;
  transition: 0.5s;
}
@media (min-width: 768px) {
  .team-card__body {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}
@media (min-width: 992px) {
  .team-card__body {
    padding: 20px;
    bottom: 0px;
    transform: translateY(100%);
  }
}
.team-card__body-bottom {
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.team-card__social-links {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.team-card__social-links a {
  color: var(--black-2);
  transition: 0.4s;
}
.team-card__social-links a:hover {
  color: var(--l5-primary);
}
@media (min-width: 992px) {
  .team-card:hover .team-card__body {
    bottom: 20px;
    transform: translateY(0);
  }
}

/* ========== Component : Team Card 2 Style ============= */
.team-card-2 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: #FAF9F5;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.4s;
}
.team-card-2:hover {
  background: #FFFFFF;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
}
.team-card-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.team-card-2__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 10px;
}
.team-card-2__name {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #0A102F;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .team-card-2__name {
    font-size: 20px;
  }
}
.team-card-2__position {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: rgba(10, 16, 47, 0.8);
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .team-card-2__position {
    font-size: 18px;
  }
}
.team-card-2__social-links {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.team-card-2__social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  max-width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 500px;
  color: var(--black-2);
  background-color: rgba(10, 16, 47, 0.05);
  transition: 0.4s;
}
.team-card-2__social-links a:hover {
  color: #FFFFFF;
  background-color: var(--black-2);
}
.team-card-2__social-links.hvr-blue a:hover {
  color: #FFFFFF;
  background-color: var(--bs-primary);
}

.team-card-2-row {
  justify-content: center;
  --bs-gutter-y: 30px;
}

/* ========== Component : service Card Style ============= */
.service-card {
  padding: 40px;
  display: flex;
  column-gap: 40px;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  transition: 0.3s;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 25px;
  background: #FFFFFF;
}
@media (min-width: 576px) {
  .service-card {
    flex-direction: initial;
    align-items: initial;
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .service-card {
    padding: 40px;
  }
}
.service-card__icon {
  color: #0A102F;
  max-width: 100px;
}
.service-card__icon svg,
.service-card__icon img {
  width: 100%;
}
.service-card__icon svg path,
.service-card__icon img path {
  fill: currentColor;
}
.service-card__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #0A102F;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .service-card__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .service-card__title {
    font-size: 24px;
  }
}
.service-card p {
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 576px) {
  .service-card p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .service-card p {
    margin-bottom: 30px;
  }
}
.service-card:hover .service-card__icon {
  color: #3147FF;
}
.service-card:hover .service-card__link {
  color: #3147FF;
}
.service-card:hover .service-card__link:after {
  left: 4px;
}
.service-card.hvr-fill:hover {
  background: #FAF9F5;
  border-color: #FAF9F5;
}
.service-card.background-2 {
  background: #FFFFFF;
  border: 0px;
  border-radius: 10px;
}
.service-card.background-2:hover {
  box-shadow: 0px 4px 60px rgba(10, 16, 47, 0.06);
}

/* ========== Component : Service Card Style ============= */
.service-card-2 {
  background: #FFFFFF;
  border: 2px solid #191919;
  border-radius: 10px;
  padding: 30px;
  transition: 300ms;
  height: 100%;
}
.service-card-2__icon {
  padding-bottom: 30px;
}
.service-card-2__title {
  margin-bottom: 15px;
  font-family: var(--font-family-base);
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  color: var(--body-color);
}
@media (min-width: 768px) {
  .service-card-2__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .service-card-2__title {
    font-size: 24px;
  }
}
.service-card-2 p {
  color: rgba(25, 25, 25, 0.8);
  margin-bottom: 0;
}
.service-card-2:hover {
  box-shadow: 10px 10px #A259FF;
  border-radius: 10px;
}

/* ========== Component : Career Card Style ============= */
.career-card {
  padding: 30px;
  display: flex;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  transition: 0.3s;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 25px;
  background: #FFFFFF;
}
@media (min-width: 576px) {
  .career-card {
    align-items: initial;
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .career-card {
    padding: 40px;
    row-gap: 30px;
  }
}
.career-card__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #0A102F;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .career-card__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .career-card__title {
    font-size: 24px;
  }
}
.career-card__job-info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 15px;
}
.career-card__job-info span {
  display: flex;
  column-gap: 5px;
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: #0A102F;
}
@media (min-width: 576px) {
  .career-card__job-info span {
    font-size: 18px;
  }
}
.career-card:hover {
  background: #FAF9F5;
}
.career-card:hover .career-card__link {
  color: #3147FF;
}

.career-card-row {
  display: flex;
  flex-wrap: wrap;
  --bs-gutter-y: 18px;
}
@media (min-width: 992px) {
  .career-card-row {
    --bs-gutter-y: 24px;
  }
}

/* ========== Component : List Style  ============= */
.list-style-check {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: left;
  margin-bottom: 0;
}
.list-style-check li {
  display: flex;
  column-gap: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #0A102F;
  font-size: 16px;
}
@media (min-width: 576px) {
  .list-style-check li {
    font-size: 18px;
  }
}
.list-style-check li img {
  max-width: 22px;
  max-height: 22px;
}
@media (min-width: 576px) {
  .list-style-check li img {
    max-width: 34px;
    max-height: 34px;
  }
}

.list-social {
  --color: #0A102F;
  --backgroundColor: rgba(10, 16, 47, 0.1);
  --hovBackground: #3147FF;
  --hovColor: #FFFFFF;
  display: inline-flex;
  column-gap: 12px;
  list-style: none;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;
  z-index: 99;
}
.list-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 500px;
  font-size: 13px;
  background: var(--backgroundColor);
  color: var(--color);
  transition: 0.4s;
}
.list-social li a:hover {
  color: var(--hovColor);
  background-color: var(--hovBackground);
  border-color: var(--hovBackground);
}
.list-social--dark {
  --border-color: rgba(255, 255, 255, 0.1);
  --color: #FFFFFF;
  --hovBackground: #FFFFFF;
  --hovColor: #0A102F;
}
.list-social--hvr-black {
  --hovBackground: #0A102F;
  --hovColor: #FFFFFF;
}
.list-social--hvr-primary-l3 {
  --hovBackground: var(--l3-primary);
  --hovColor: #0A102F;
}
.list-social--primary-l5 {
  --backgroundColor: rgba(253, 251, 249, 0.1);
  --border-color: rgba(255, 255, 255, 0);
  --color: #FFFFFF;
  --hovBackground: #FF9966;
  --hovColor: var(--black-2);
}

.navigation-list .btn {
  background-color: rgba(10, 16, 47, 0.0392156863);
  color: rgba(10, 16, 47, 0.6);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.navigation-list li.active .btn {
  color: white !important;
}
.navigation-list--inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-bottom: 80px;
}
.navigation-list--inline li.active {
  background-color: #3147FF;
  color: #fff;
  border-radius: 500px;
}
.navigation-list--inline li .nav-list-btn {
  font-weight: 700;
}
.navigation-list--inline .nav-list-btn-2 {
  background-color: rgba(10, 16, 47, 0.0392156863);
  color: #fff;
}
.navigation-list--block {
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: left;
  margin-bottom: 40px;
  justify-content: center;
}
@media (min-width: 1200px) {
  .navigation-list--block {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.navigation-list--block li.active .nav-list-btn {
  color: #3147FF;
}
.navigation-list--block li .nav-list-btn {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  padding: 0;
  outline: none;
  background: none;
  border: none;
}
@media (min-width: 1200px) {
  .navigation-list--block li .nav-list-btn {
    font-size: 30px;
  }
}
.navigation-list--block li .nav-list-btn:active {
  border: none;
}

/* Sideabr List Styles */
.sidebar-tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.sidebar-tag-list-item a {
  padding: 4.5px 23px;
  border-radius: 500px;
  background: rgba(10, 16, 47, 0.04);
  font-size: 18px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.8);
  transition: 0.4s;
}
.sidebar-tag-list-item a:hover {
  background-color: var(--bs-primary);
  color: #FFF;
}

.sidebar-category-list {
  display: flex;
  flex-direction: column;
}
.sidebar-category-list > * {
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
}
.sidebar-category-list > *:last-child {
  border-bottom: 0px solid rgba(10, 16, 47, 0.1);
  padding-bottom: 0px;
}

/* ========== Component  : Portfolio Widget Style ============= */
.portfolio-widget_image-block {
  margin-bottom: 24px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}
.portfolio-widget_image-block img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  transition: all 0.4s;
}
.portfolio-widget_image-block img:hover {
  transform: scale(1.1) rotate(3deg);
}
.portfolio-widget__title {
  font-family: var(--font-family-base);
  color: #0A102F;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
}
@media (min-width: 768px) {
  .portfolio-widget__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .portfolio-widget__title {
    font-size: 24px;
  }
}
.portfolio-widget__title a {
  color: inherit;
}
.portfolio-widget__title a:hover {
  color: inherit;
}
.portfolio-widget__title-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  row-gap: 10px;
}
@media (min-width: 576px) {
  .portfolio-widget__title-block {
    row-gap: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .portfolio-widget__title-block {
    flex-direction: initial;
    margin-bottom: 20px;
    column-gap: 20px;
  }
}
.portfolio-widget__category {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  line-height: 1.67;
  color: #0A102F;
}
@media (min-width: 576px) {
  .portfolio-widget__category {
    font-size: 18px;
  }
}
.portfolio-widget:hover .btn-link {
  color: var(--bs-primary);
}
.portfolio-widget:hover .btn-link:after {
  left: 10px;
}
.portfolio-widget--overlay {
  position: relative;
}
.portfolio-widget--overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 24px);
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.portfolio-widget--overlay:hover::before {
  opacity: 1;
}
.portfolio-widget--overlay:hover .portfolio-widget__title-block {
  opacity: 1;
}
.portfolio-widget--overlay .portfolio-widget__title-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  margin-bottom: initial;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.portfolio-widget--overlay .portfolio-widget__title-block h3,
.portfolio-widget--overlay .portfolio-widget__title-block span {
  color: #fff;
}
.portfolio-widget--shadow .portfolio-widget_image-block {
  transition: all 0.2s ease-in-out;
}
.portfolio-widget--shadow:hover .portfolio-widget_image-block {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}

/* ========== Component  : Tastimonial widget Large Style ============= */
.testimonial-widget-large {
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large {
    text-align: initial;
  }
}
.testimonial-widget-large .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large .row--custom {
    --bs-gutter-x: 24px;
    justify-content: initial;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .testimonial-widget-large__image {
    padding-right: 48px;
  }
}
@media (min-width: 1400px) {
  .testimonial-widget-large__image {
    padding-right: 0;
  }
}
.testimonial-widget-large__image img {
  width: 100%;
}
@media (min-width: 1400px) {
  .testimonial-widget-large__body {
    padding-left: 58px;
  }
}
.testimonial-widget-large__review-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: #0A102F;
  margin-bottom: 17px;
}
@media (min-width: 992px) {
  .testimonial-widget-large__review-text {
    margin-bottom: 27px;
  }
}
.testimonial-widget-large__icon {
  display: block;
  margin-bottom: 12px;
  max-width: 50px;
}
@media (min-width: 1200px) {
  .testimonial-widget-large__icon {
    display: inline-block;
    margin-bottom: 25px;
    max-width: 100%;
  }
}
.testimonial-widget-large__icon img {
  max-width: 100%;
}
.testimonial-widget-large__user-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  color: #0A102F;
  font-family: "Inter", sans-serif;
}
.testimonial-widget-large__user-position {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #0A102F;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .testimonial-widget-large__user-position {
    margin-bottom: 50px;
  }
}
.testimonial-widget-large__link {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: var(--bs-primary);
}
.testimonial-widget-large__link a {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  color: inherit;
}
@media (min-width: 992px) {
  .testimonial-widget-large__link a {
    justify-content: initial;
  }
}

/* ========== Component  : Tastimonial widget Large 2 Style ============= */
.testimonial-widget-large-2 {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  text-align: center;
  align-items: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large-2 {
    row-gap: 40px;
    text-align: initial;
    align-items: initial;
  }
}
.testimonial-widget-large-2__review-text {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  color: #221F1A;
}
@media (min-width: 768px) {
  .testimonial-widget-large-2__review-text {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .testimonial-widget-large-2__review-text {
    font-size: 24px;
  }
}
.testimonial-widget-large-2__body {
  display: flex;
  column-gap: 15px;
  color: #221F1A;
}
.testimonial-widget-large-2__user-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
}
@media (min-width: 576px) {
  .testimonial-widget-large-2__user-name {
    font-size: 20px;
  }
}
.testimonial-widget-large-2__user-position {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
}
@media (min-width: 576px) {
  .testimonial-widget-large-2__user-position {
    font-size: 18px;
  }
}
.testimonial-widget-large-2__link a {
  display: flex;
  column-gap: 12px;
  color: #324438;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.testimonial-widget-large-2__link a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 500px;
  color: #F4B905;
  background-color: #324438;
}

/* ========== Component : Tastimonial widget Large 3 Style ============= */
.testimonial-widget-large-3 {
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large-3 {
    text-align: initial;
  }
}
@media (min-width: 1200px) {
  .testimonial-widget-large-3__image {
    margin-left: 25px;
  }
}
@media (min-width: 1400px) {
  .testimonial-widget-large-3__image {
    margin-left: 50px;
  }
}
.testimonial-widget-large-3__image img {
  width: 100%;
}
.testimonial-widget-large-3__body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large-3__body {
    text-align: initial;
  }
}
@media (min-width: 1200px) {
  .testimonial-widget-large-3__body {
    padding-left: 25px;
    row-gap: 30px;
  }
}
@media (min-width: 1400px) {
  .testimonial-widget-large-3__body {
    padding-left: 60px;
    row-gap: 40px;
  }
}
.testimonial-widget-large-3__body p {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
}
@media (min-width: 768px) {
  .testimonial-widget-large-3__body p {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .testimonial-widget-large-3__body p {
    font-size: 24px;
  }
}
.testimonial-widget-large-3__user-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #221F1A;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .testimonial-widget-large-3__user-name {
    font-size: 20px;
  }
}
.testimonial-widget-large-3__user-position {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 1.67;
}
@media (min-width: 576px) {
  .testimonial-widget-large-3__user-position {
    font-size: 18px;
  }
}

.testimonial-widget-large-3-row {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .testimonial-widget-large-3-row {
    --bs-gutter-x: 24px;
    justify-content: initial;
    align-items: center;
  }
}

/* ========== widget : Tastimonial widget 2============= */
.testimonial-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  row-gap: 30px;
}
@media (min-width: 1200px) {
  .testimonial-widget {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.testimonial-widget__stars {
  padding-bottom: 28px;
  display: inline-block;
}
.testimonial-widget p {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  color: #0A102F;
}
.testimonial-widget__rating strong {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--body-color);
}
.testimonial-widget__rating span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6B6F7B;
}

/* ========== widget : Tastimonial widget 2============= */
.testimonial-widget-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-2 {
    align-items: initial;
    text-align: initial;
  }
}
.testimonial-widget-2__star {
  margin-bottom: 24px;
}
.testimonial-widget-2 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #0A102F;
  margin-bottom: 24px;
}
.testimonial-widget-2__user-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  column-gap: 12px;
}
@media (min-width: 992px) {
  .testimonial-widget-2__user-block {
    align-items: initial;
    flex-direction: row;
  }
}
.testimonial-widget-2__user-name {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #0A102F;
}
.testimonial-widget-2__user-position {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.7);
}

/* ========== widget : Tastimonial widget 3============= */
.testimonial-widget-3 {
  display: flex;
  flex-direction: column;
}
.testimonial-widget-3-text-content img {
  padding-bottom: 20px;
}
.testimonial-widget-3-text-content p {
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #1B1C1D;
  padding-bottom: 20px;
}
.testimonial-widget-3-text-content__user {
  padding-bottom: 45px;
}
.testimonial-widget-3-text-content__user h4 {
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #1B1C1D;
  padding-bottom: 5px;
}
.testimonial-widget-3-text-content__user span {
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1B1C1D;
}

/* ========== widget : Tastimonial widget 4============= */
.testimonial-widget-4 {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-4 {
    align-items: initial;
    text-align: initial;
  }
}
.testimonial-widget-4 p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  font-style: italic;
}
@media (min-width: 576px) {
  .testimonial-widget-4 p {
    font-size: 18px;
  }
}
.testimonial-widget-4__body {
  display: flex;
  column-gap: 15px;
}
.testimonial-widget-4__user {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .testimonial-widget-4__user {
    font-size: 18px;
  }
}
.testimonial-widget-4__user-position {
  font-size: 14px;
  line-height: 1.43;
  color: rgba(10, 16, 47, 0.8);
}

/* ========== Component  : Feature Widget Style 1============= */
.feature-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 24px;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .feature-widget {
    flex-direction: row;
    justify-content: initial;
    align-items: initial;
    text-align: initial;
  }
}
@media (min-width: 1400px) {
  .feature-widget {
    padding-right: 40px;
  }
}
.feature-widget__title {
  padding-bottom: 15px;
  font-family: var(--font-family-base);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: var(--body-color);
}
.feature-widget__body p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(var(--body-color-rgb), 0.8);
  margin-bottom: 0;
}

/* ========== Component  : Feature Widget Style 2 ============= */
.feature-widget-2 {
  position: relative;
  text-align: center;
}
@media (min-width: 1200px) {
  .feature-widget-2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.feature-widget-2__icon {
  margin-bottom: 30px;
  min-height: 70px;
}
.feature-widget-2__icon img {
  display: inline-block;
}
.feature-widget-2 p {
  color: rgba(27, 28, 29, 0.8);
}
.feature-widget-2__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .feature-widget-2__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-widget-2__title {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .feature-widget-2__title {
    font-size: 22px;
  }
}
@media (min-width: 1400px) {
  .feature-widget-2__title {
    font-size: 24px;
  }
}

/* Applied on : Landing - SEO Agency*/
.feature-widget-devider {
  --bs-gutter-y:20px;
  --bs-gutter-x:30px;
}
@media (min-width: 1400px) {
  .feature-widget-devider {
    --bs-gutter-x:52px;
  }
}
.feature-widget-devider > * {
  position: relative;
}
.feature-widget-devider > *:after {
  content: "";
  width: 2px;
  height: 50%;
  min-height: 120px;
  background-color: rgba(27, 28, 29, 0.1);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: none;
}
@media (min-width: 1200px) {
  .feature-widget-devider > *:not(:last-child):after {
    display: block;
  }
}

/* ========== Component  : Feature Widget Style 3 ============= */
.feature-widget-3 {
  padding: 40px;
  display: flex;
  column-gap: 25px;
}
@media (min-width: 992px) {
  .feature-widget-3 {
    column-gap: 40px;
  }
}
.feature-widget-3__title {
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Syne", sans-serif;
}
@media (min-width: 768px) {
  .feature-widget-3__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-widget-3__title {
    font-size: 24px;
  }
}
.feature-widget-3 p {
  color: rgba(34, 31, 26, 0.8);
  line-height: 1.67;
}
@media (min-width: 992px) {
  .feature-widget-3 p {
    max-width: 395px;
  }
}

.feature-widget-3-row > * {
  border-bottom: 1px solid #221F1A;
  position: relative;
}
@media (min-width: 992px) {
  .feature-widget-3-row > *:nth-last-child(-n+2) {
    border-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .feature-widget-3-row > *:after {
    content: "";
    width: 1px;
    height: 50%;
    max-height: 100px;
    background-color: #221F1A;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.feature-widget-3-row > *:nth-of-type(2n):after {
  display: none;
}

/* ========== Component  : Feature Widget Style 5 ============= */
.feature-widget-5 {
  display: flex;
  column-gap: 20px;
  border-radius: 20px;
  transition: 300ms;
  height: 100%;
  row-gap: 15px;
  flex-direction: column;
  padding: 20px;
}
@media (min-width: 480px) {
  .feature-widget-5 {
    padding: 30px;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .feature-widget-5 {
    column-gap: 25px;
    border-radius: 25px;
  }
}
@media (min-width: 1200px) {
  .feature-widget-5 {
    column-gap: 40px;
    border-radius: 40px;
  }
}
.feature-widget-5__icon img {
  max-width: 50px;
}
@media (min-width: 768px) {
  .feature-widget-5__icon img {
    max-width: initial;
  }
}
.feature-widget-5__title {
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.04166666em;
  font-size: 20px;
  font-family: "Clash Display", sans-serif;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .feature-widget-5__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-widget-5__title {
    font-size: 24px;
  }
}
.feature-widget-5 p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
}
@media (min-width: 576px) {
  .feature-widget-5 p {
    font-size: 18px;
  }
}

.feature-widget-5-divider > * {
  border-bottom: 1px solid rgba(34, 31, 26, 0.1);
}
@media (min-width: 992px) {
  .feature-widget-5-divider > * {
    border-right: 1px solid rgba(34, 31, 26, 0.1);
  }
  .feature-widget-5-divider > *:nth-last-child(-n+2) {
    border-bottom: 0;
  }
  .feature-widget-5-divider > *:nth-last-child(-n+2) > div {
    padding-bottom: 0;
  }
  .feature-widget-5-divider > *:nth-of-type(-n + 2) > div {
    padding-top: 0;
  }
  .feature-widget-5-divider > *:nth-of-type(2n) {
    border-right: 0px;
  }
}

/* ========== Widget : Feature Style 6 ============= */
.feature-widget-6 {
  max-width: 430px;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 768px) {
  .feature-widget-6 {
    align-items: initial;
    text-align: initial;
    flex-direction: row;
  }
}
.feature-widget-6__icon {
  margin-bottom: 30px;
}
.feature-widget-6__icon img {
  display: inline-block;
}
.feature-widget-6__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
}
@media (min-width: 768px) {
  .feature-widget-6__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-widget-6__title {
    font-size: 24px;
  }
}
.feature-widget-6 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(var(--body-color-rgb), 0.8);
  margin-bottom: 0;
}
.feature-widget-6 p span {
  font-weight: 600;
}

.feature-widget-6-row {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
@media (min-width: 992px) {
  .feature-widget-6-row {
    row-gap: 60px;
  }
}

/* ========== widget : Feature Style 1============= */
.feature-widget-7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 20px;
  row-gap: 17px;
}
@media (min-width: 1400px) {
  .feature-widget-7 {
    column-gap: 24px;
  }
}
@media (min-width: 992px) {
  .feature-widget-7 {
    flex-direction: row;
    justify-content: initial;
    align-items: initial;
    text-align: initial;
  }
}
.feature-widget-7__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .feature-widget-7__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .feature-widget-7__title {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .feature-widget-7__title {
    margin-bottom: 15px;
  }
}

/* ============Component  : Process Widget ============== */
.process-widget-row {
  --bs-gutter-y: 30px;
  justify-content: center;
}
@media (min-width: 992px) {
  .process-widget-row {
    justify-content: space-between;
  }
}
.process-widget-row > * {
  position: relative;
  z-index: 1;
}
.process-widget-row > *::before {
  content: "";
  border: 2px dashed #1B1C1D;
  width: 80%;
  position: absolute;
  top: 16%;
  transform: translateX(71.5%);
  z-index: -1;
  display: none;
}
@media (min-width: 992px) {
  .process-widget-row > *:not(:last-child):before {
    display: block;
  }
}
.process-widget-row .process-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 30px;
}
@media (min-width: 1400px) {
  .process-widget-row .process-widget {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.process-widget-row .process-widget__count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 500px;
  background: #FDFBF9;
  border: 1px solid #1B1C1D;
  font-weight: 600;
  font-size: 34px;
  line-height: 1.2;
  color: #1B1C1D;
}
@media (min-width: 768px) {
  .process-widget-row .process-widget__count {
    width: 70px;
    height: 70px;
  }
}
@media (min-width: 992px) {
  .process-widget-row .process-widget__count {
    width: 80px;
    height: 80px;
  }
}
.process-widget-row .process-widget__count:hover {
  background: #FF9966;
}
.process-widget-row .process-widget__title {
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  color: #1B1C1D;
  font-family: "General Sans", sans-serif;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .process-widget-row .process-widget__title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .process-widget-row .process-widget__title {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .process-widget-row .process-widget__title {
    font-size: 30px;
  }
}
.process-widget-row .process-widget p {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: rgba(27, 28, 29, 0.8);
}
@media (min-width: 1400px) {
  .process-widget-row .process-widget p {
    max-width: 336px;
  }
}

/* ============ Process Widget 2 ============== */
.process-widget-2-row {
  --bs-gutter-y: 30px;
  justify-content: center;
}
.process-widget-2-row > * {
  position: relative;
  z-index: 1;
}
.process-widget-2-row > *::before {
  content: "";
  border: 2px dashed #221F1A;
  width: 90%;
  position: absolute;
  top: 40px;
  transform: translateX(50%);
  z-index: -1;
  display: none;
}
@media (min-width: 992px) {
  .process-widget-2-row > *:not(:last-child):before {
    display: block;
  }
}

.process-widget-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 30px;
  max-width: 336px;
}
.process-widget-2__count {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF4D9;
  border: 1px solid rgba(34, 31, 26, 0.1);
  width: 60px;
  height: 60px;
  border-radius: 500px;
  font-weight: 600;
  font-family: "General Sans", sans-serif;
  line-height: 1.2;
  font-size: 26px;
  color: #221F1A;
}
@media (min-width: 768px) {
  .process-widget-2__count {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .process-widget-2__count {
    font-size: 34px;
  }
}
@media (min-width: 768px) {
  .process-widget-2__count {
    width: 70px;
    height: 70px;
  }
}
@media (min-width: 992px) {
  .process-widget-2__count {
    width: 80px;
    height: 80px;
  }
}
.process-widget-2__count:hover {
  background: #F65510;
  color: #FFF4D9;
}
.process-widget-2__title {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 1px;
  color: #221F1A;
  font-family: "Clash Display", sans-serif;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .process-widget-2__title {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .process-widget-2__title {
    font-size: 30px;
  }
}
.process-widget-2 p {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
  color: rgba(34, 31, 26, 0.8);
  letter-spacing: -0.5px;
}

/* ========== Component   : Video Widget ============= */
.video-widget__thumbnail {
  position: relative;
  margin-bottom: 23px;
  border-radius: 10px;
}
.video-widget__thumbnail img {
  width: 100%;
  border-radius: inherit;
}
.video-widget__thumbnail::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--body-color);
  mix-blend-mode: multiply;
  opacity: 0.4;
  border-radius: inherit;
}
.video-widget__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  margin-bottom: 17px;
  font-family: var(--font-family-base);
  color: #0A102F;
}
@media (min-width: 768px) {
  .video-widget__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .video-widget__title {
    font-size: 24px;
  }
}
.video-widget p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: rgba(var(--body-color-rgb), 0.8);
}
@media (min-width: 576px) {
  .video-widget p {
    font-size: 18px;
  }
}
@media (min-width: 1400px) {
  .video-widget p {
    max-width: 350px;
  }
}

/* ========== Component   : service Style ============= */
.service-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.service-widget__head {
  margin-bottom: 30px;
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
}
@media (min-width: 768px) {
  .service-widget__head {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .service-widget__head {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .service-widget__head {
    font-size: 30px;
  }
}
.service-widget__body {
  padding-top: 30px;
  border-top: 2px solid rgba(253, 251, 249, 0.1);
}
.service-widget__title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  color: #FDFBF9;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .service-widget__title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .service-widget__title {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .service-widget__title {
    font-size: 30px;
  }
}
.service-widget__heading {
  padding-bottom: 5px;
}
.service-widget p {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.025em;
  color: rgba(253, 251, 249, 0.8);
  margin-bottom: 30px;
  max-width: 380px;
}
@media (min-width: 576px) {
  .service-widget p {
    font-size: 20px;
  }
}
.service-widget__link {
  font-family: "General Sans", sans-serif;
  font-size: 16px;
  line-height: 1.44;
  font-weight: 600;
  color: #FDFBF9 !important;
}
@media (min-width: 576px) {
  .service-widget__link {
    font-size: 18px;
  }
}
.service-widget__link:hover {
  color: #FF9966 !important;
}
.service-widget.hover-enable:hover {
  background: #FAF9F5;
}

/* ========== Component   : rating Style ============= */
.rating-widget {
  display: flex;
  align-items: center;
  gap: 15px;
}
.rating-widget__rating-text {
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #221F1A;
  opacity: 0.7;
}
.rating-widget div {
  display: flex;
  gap: 15px;
  margin-top: 7px;
}

/* ========== Component  :Team Widget =========== */
.team-widget {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.team-widget__image-block {
  margin-bottom: 24px;
  overflow: hidden;
}
.team-widget__image-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.team-widget__user-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #0A102F;
  font-family: "Inter", sans-serif;
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .team-widget__user-name {
    font-size: 20px;
  }
}
.team-widget__user-position {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 576px) {
  .team-widget__user-position {
    font-size: 18px;
  }
}

/* ========== Component : Fact Widget   ============= */
.fact-widget {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  position: relative;
}
@media (min-width: 768px) {
  .fact-widget {
    flex-direction: row;
    align-items: flex-start;
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .fact-widget {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .fact-widget {
    gap: 24px;
  }
}
@media (min-width: 1200px) {
  .fact-widget {
    max-width: 307px;
  }
}
.fact-widget_title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .fact-widget_title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .fact-widget_title {
    font-size: 24px;
  }
}

/* Applied on : Landing - Web Hosting*/
.fact-widget-divider {
  --bs-gutter-y: 30px;
}
@media (min-width: 1400px) {
  .fact-widget-divider {
    --bs-gutter-x: 35px;
  }
}
.fact-widget-divider > * {
  position: relative;
}
@media (min-width: 768px) {
  .fact-widget-divider > *:nth-child(-n+2):after {
    content: "";
    width: 2px;
    height: 50%;
    min-height: 70px;
    background-color: rgba(27, 28, 29, 0.1);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
  }
}

/* ========== widget : Sidebar BLog============= */
.sidebar-blog-widget {
  display: flex;
  column-gap: 16px;
}
.sidebar-blog-widget__image img {
  min-width: 150px;
  border-radius: 5px;
}
.sidebar-blog-widget__date {
  display: flex;
  column-gap: 10px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.43;
  color: #13111A;
}
.sidebar-blog-widget__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #13111A;
  font-family: "Inter", sans-serif;
}
.sidebar-blog-widget a {
  color: inherit;
}

/* ========== widget : Blog Comment Widget============= */
.comment-widget__inner {
  display: flex;
  column-gap: 20px;
}
.comment-widget__image {
  min-width: 80px;
  max-width: 80px;
  min-height: 8px;
  max-height: 80px;
  border-radius: 500px;
}
.comment-widget__image img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}
.comment-widget__body {
  width: 100%;
}
.comment-widget__body p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: rgba(10, 16, 47, 0.8);
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
  margin-bottom: 24px;
  max-width: 590px;
}
@media (min-width: 576px) {
  .comment-widget__body p {
    font-size: 18px;
  }
}
.comment-widget__body p:last-child {
  padding-bottom: 40px;
}
.comment-widget__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment-widget__user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
}
.comment-widget__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
}
.comment-widget__button a {
  font-weight: 700;
  font-size: 16px;
  color: rgba(10, 16, 47, 0.8);
}
.comment-widget__button a:hover {
  color: var(--bs-primary);
}
@media (min-width: 576px) {
  .comment-widget > .comment-widget {
    padding-left: 100px;
  }
}
.comment-widget > .comment-widget .comment-widget__image {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
}
.comment-widget > .comment-widget .comment-widget__image img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}
.comment-widget > .comment-widget p {
  max-width: 525px;
}

/* ========== Component : Content Widget   ============= */
.content-widget {
  display: flex;
  column-gap: 15px;
}
@media (min-width: 992px) {
  .content-widget {
    max-width: 630px;
  }
}
.content-widget__icon {
  position: relative;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 500px;
  background: #324438;
}
.content-widget__title {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0em;
  font-size: 20px;
  line-height: 1.25;
  color: #221F1A;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .content-widget__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .content-widget__title {
    font-size: 24px;
  }
}

.content-widget-2__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.content-widget-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 992px) {
  .content-widget-2 {
    align-items: initial;
  }
}
.content-widget-2__title {
  display: flex;
  column-gap: 8px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0em;
  font-size: 20px;
  font-family: "General Sans", sans-serif;
  color: #0A102F;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .content-widget-2__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .content-widget-2__title {
    font-size: 24px;
  }
}
.content-widget-2 img {
  position: relative;
  top: 3px;
  max-width: 24px;
  max-height: 24px;
}
@media (max-width: 991px) {
  .content-widget-2 img {
    display: none;
  }
}
.content-widget-2 p {
  max-width: 505px;
}

/* ========== Component : Career Widget   ============= */
.career-details_widget {
  display: flex;
  column-gap: 20px;
}
.career-details_widget__icon {
  background: #3147FF;
  min-width: 50px;
  max-width: 50px;
  max-height: 50px;
  min-height: 50px;
  height: 50px;
  width: 50px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.career-details_widget__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  font-weight: 600;
  color: #0A102F;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .career-details_widget__title {
    font-size: 20px;
  }
}
.career-details_widget span {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 576px) {
  .career-details_widget span {
    font-size: 18px;
  }
}

.career-details_widget-row {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .pagination {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .pagination {
    margin-top: 120px;
    margin-bottom: 0;
  }
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.pagination-wrapper .btn {
  width: 50px;
  height: 50px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(19, 17, 26, 0.6);
}
.pagination-wrapper .btn--arrow {
  color: #000;
}
.pagination-wrapper .btn.active {
  background-color: var(--bs-primary);
  color: #FFFFFF;
}
.pagination-wrapper .btn-main:hover {
  background-color: var(--bs-primary);
  color: #FFFFFF;
}

/* ========== Component : Accordion Style 1 ============= */
.accordion-style-1 {
  border-radius: 10px;
  border: 1px solid rgb(10, 16, 47);
}
.accordion-style-1 .accordion-item {
  padding: 30px;
  border-radius: 0;
}
.accordion-style-1 .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a102f;
}
.accordion-style-1 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-1 .accordion-item .accordion-button:after {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 10px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  padding-left: 3px;
  background-color: #3147ff;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.accordion-style-1 .accordion-item .accordion-button.collapsed::after {
  content: "+";
}
.accordion-style-1 .accordion-item .accordion-item__body {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #0a102f;
  opacity: 0.8;
  padding-top: 15px;
}
.accordion-style-1 .accordion-item:not(:last-child) {
  border-bottom: 0.5px solid rgb(10, 16, 47);
}

/* ========== Component : Accordion Style 2 ============= */
.accordion-style-2 {
  background: #FDFBF9;
  border-radius: 10px;
  border: 4px solid rgb(10, 16, 47);
}
.accordion-style-2 .accordion-item {
  padding: 30px;
  border-radius: 0;
  width: 100%;
}
.accordion-style-2 .accordion-item.collapsed {
  background: #F5EFEB;
}
.accordion-style-2 .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a102f;
}
.accordion-style-2 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-2 .accordion-item .accordion-button:after {
  content: "\f107";
  width: 20px;
  height: 20px;
  color: #0a102f;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 6 Free";
  padding-bottom: 1px;
  transition: 0.4s;
  transform: rotate(180deg);
}
.accordion-style-2 .accordion-item .accordion-button.collapsed::after {
  transform: rotate(0deg);
}
.accordion-style-2 .accordion-item .accordion-item__body {
  color: rgba(10, 16, 47, 0.8);
  opacity: 0.8;
  padding-top: 15px;
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  text-align: initial;
  max-width: 510px;
}
@media (min-width: 576px) {
  .accordion-style-2 .accordion-item .accordion-item__body {
    font-size: 18px;
  }
}
.accordion-style-2 .accordion-item:not(:last-child) {
  border-bottom: 4px solid rgb(10, 16, 47);
}

/* ========== Component : Accordion Style 3 ============= */
.accordion-style-3 .accordion-item {
  padding-bottom: 30px;
  padding-top: 30px;
}
.accordion-style-3 .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0em;
  font-size: 20px;
  color: #221f1a;
  font-family: "Syne", sans-serif;
}
@media (min-width: 576px) {
  .accordion-style-3 .accordion-item .accordion-button {
    font-size: 20px;
  }
}
.accordion-style-3 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-3 .accordion-item .accordion-button:after {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  vertical-align: middle;
  font-weight: 900;
  font-size: 10px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  color: #f4b905;
  background-color: #324438;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordion-style-3 .accordion-item .accordion-button.collapsed::after {
  content: "+";
}
.accordion-style-3 .accordion-item .accordion-item__body {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #221f1a;
  opacity: 0.8;
  padding-top: 15px;
}
.accordion-style-3 .accordion-item:not(:last-child) {
  border-bottom: 0.5px solid rgb(10, 16, 47);
}

/* ========== Component : Accordion Style 4 ============= */
.accordion-style-4 {
  border: 2px solid #221f1a;
  border-radius: 10px;
}
.accordion-style-4 .accordion-item {
  padding: 30px;
  border-radius: 0;
}
.accordion-style-4 .accordion-item .accordion-button {
  display: flex;
  column-gap: 15px;
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a102f;
}
.accordion-style-4 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-4 .accordion-item .accordion-item__body {
  color: #0a102f;
  opacity: 0.8;
  padding-top: 13px;
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
}
@media (min-width: 576px) {
  .accordion-style-4 .accordion-item .accordion-item__body {
    font-size: 18px;
  }
}
.accordion-style-4 .accordion-item:not(:last-child) {
  border-bottom: 2px solid #221f1a;
}

/* ========== Component : Accordion Style 5 ============= */
.accordion-style-5 {
  background: #fff4d9;
  border-radius: 10px;
  border: 2px solid #221f1a;
}
.accordion-style-5 .accordion-item {
  padding: 30px;
  border-radius: 0;
}
.accordion-style-5 .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  font-family: "Clash Display", sans-serif;
  color: #221f1a;
}
@media (min-width: 576px) {
  .accordion-style-5 .accordion-item .accordion-button {
    font-size: 20px;
  }
}
.accordion-style-5 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-5 .accordion-item .accordion-button:after {
  content: "\f107";
  width: 20px;
  height: 20px;
  color: #0a102f;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  padding-bottom: 1px;
  transition: 0.4s;
  transform: rotate(90deg);
}
.accordion-style-5 .accordion-item .accordion-button.collapsed::after {
  transform: rotate(180deg);
}
.accordion-style-5 .accordion-item .accordion-item__body {
  font-weight: 400;
  color: #0a102f;
  opacity: 0.8;
  padding-top: 15px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 576px) {
  .accordion-style-5 .accordion-item .accordion-item__body {
    font-size: 18px;
  }
}
.accordion-style-5 .accordion-item:not(:last-child) {
  border-bottom: 2px solid #221f1a;
}

/* ========== Component : Accordion Style 6 used in l06 ============= */
.accordion-style-6 > * {
  padding-top: 24px;
  padding-bottom: 24px;
}
.accordion-style-6 > *:first-child {
  padding-top: 0px;
}
.accordion-style-6 > *:last-child {
  padding-bottom: 0px;
}
.accordion-style-6 .accordion-item {
  border-radius: 0;
}
.accordion-style-6 .accordion-item .accordion-button {
  display: flex;
  column-gap: 10px;
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a102f;
}
.accordion-style-6 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-6 .accordion-item .accordion-button i {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #3147ff;
  font-size: 12px;
  padding: 5px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  border-radius: 500px;
}
.accordion-style-6 .accordion-item .accordion-button:after {
  content: "+";
  width: 20px;
  height: 20px;
  color: #0a102f;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 6 Free";
  padding-bottom: 1px;
  transition: 0.4s;
  transform: rotate(45deg);
}
.accordion-style-6 .accordion-item .accordion-button.collapsed::after {
  transform: rotate(0deg);
}
.accordion-style-6 .accordion-item .accordion-item__body {
  padding-left: 34px;
  color: #0a102f;
  opacity: 0.8;
  padding-top: 15px;
  padding-bottom: 15px;
}
.accordion-style-6 .accordion-item .accordion-item__body p {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
.accordion-style-6 .accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
}

/* ========== Component : Accordion Style 7 usen in faq page 3 ============= */
.accordion-style-7 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.accordion-style-7 .accordion-item {
  padding: 30px;
  border-radius: 0;
  border-radius: 10px;
  border: 2px solid #0a102f;
  box-shadow: 0;
  transition: 0.4s;
}
.accordion-style-7 .accordion-item:hover {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}
.accordion-style-7 .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a102f;
}
.accordion-style-7 .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-style-7 .accordion-item .accordion-button:after {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 10px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  padding-left: 3px;
  background-color: #3147ff;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.accordion-style-7 .accordion-item .accordion-button.collapsed::after {
  content: "+";
}
.accordion-style-7 .accordion-item .accordion-item__body {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
  color: #0a102f;
  opacity: 0.8;
  padding-top: 15px;
}

.accordion-style-7-wrapper {
  display: flex;
  flex-direction: column;
}
.accordion-style-7-wrapper:not(:last-child) {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .accordion-style-7-wrapper:not(:last-child) {
    margin-bottom: 60px;
  }
}

/* ======== Newsletter Section =========== */
/* ======== Newsletter Form 1 =========== */
.newsletter-form-1 {
  position: relative;
}
.newsletter-form-1 .form-control {
  width: 100%;
  border: 1px solid #0A102F;
  border-radius: 500px;
  padding-left: 24px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}
.newsletter-form-1 .form-control:focus {
  box-shadow: none;
}
.newsletter-form-1 .form-control::placeholder {
  font-weight: 700;
  color: rgba(10, 16, 47, 0.6);
}
.newsletter-form-1 button {
  width: 100%;
}
@media (min-width: 480px) {
  .newsletter-form-1 button {
    width: initial;
    position: absolute;
    right: 4px;
    top: 4px;
    text-align: initial;
    margin-top: initial;
    font-weight: 700;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

/* ======== Newsletter Form 2 =========== */
.newsletter-form-2 .form-control {
  width: 100%;
  background: #FFFFFF;
  border-radius: 3px;
  border-color: #FFFFFF;
  padding-left: 24px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 1.5;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}
.newsletter-form-2 .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #FFFFFF;
  color: initial;
}
.newsletter-form-2 .form-control::placeholder {
  font-weight: 600;
  color: rgba(18, 18, 18, 0.5);
}
.newsletter-form-2 button {
  width: 100%;
}
@media (min-width: 480px) {
  .newsletter-form-2 button {
    border-radius: 3px;
    width: initial;
    position: absolute;
    right: 1%;
    top: 5%;
    text-align: initial;
    margin-top: initial;
    font-weight: 700;
    height: 50px;
  }
}

/* ======== Form box Style =========== */
.form-box-style-heading__title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
  color: #0A102F;
}
@media (min-width: 768px) {
  .form-box-style-heading__title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .form-box-style-heading__title {
    font-size: 24px;
  }
}
.form-box-style__form-wrapper {
  border: 1px solid rgba(10, 16, 47, 0.5);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .form-box-style__form-wrapper {
    row-gap: 40px;
  }
}
.form-box-style__form-wrapper.border-none {
  border-width: 0px;
}
.form-box-style__form-wrapper .form-control {
  border: 1px solid rgba(10, 16, 47, 0.5);
  border-radius: 500px;
  padding: 15px 30px;
  width: 100%;
  color: rgba(10, 16, 47, 0.5);
  box-shadow: none;
}
.form-box-style__form-wrapper .form-control.textarea {
  height: 150px;
  border-radius: 30px;
}
.form-box-style__form-input {
  margin-bottom: 24px;
}
.form-box-style__form-input:last-child {
  margin-bottom: 0px;
}
.form-box-style-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
  margin-bottom: 10px;
  color: #0A102F;
}
@media (min-width: 576px) {
  .form-box-style-title {
    font-size: 18px;
  }
}
.form-box-style__form-input-group {
  display: flex;
  column-gap: 24px;
}
.form-box-style__condition {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  color: #0A102F;
}

/* ========== Component : Badge Style  ============= */
.badge {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #191919;
  padding: 5.5px 15.5px;
  border: 1px solid #191919;
  border-radius: 500px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .badge {
    margin-bottom: 20px;
  }
}

.badge--white {
  padding: 5.5px 18.5px;
  background: #FFFFFF;
}

.badge--purple {
  background: #B5A8F8;
}

.badge--yellow {
  background: #FFED88;
}

.badge--blue {
  background: #A2DFF5;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
.site-menu-main {
  margin-bottom: 0;
  padding-left: 0;
}
@media (min-width: 992px) {
  .site-menu-main {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
  }
}
.site-menu-main li {
  list-style: none;
}
.site-menu-main ul {
  list-style: none;
  margin-left: 0;
}
.site-menu-main a {
  text-decoration: none;
}

.site-menu-main .nav-item {
  display: inherit;
}
.site-menu-main .nav-link-item {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
  position: relative;
  transition: 0.3s;
}
.site-menu-main .nav-link-item:hover {
  color: #5e70ff;
}
@media (min-width: 992px) {
  .site-menu-main .nav-link-item {
    padding-top: 25px !important;
    padding-bottom: 23px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    color: #0a102f;
  }
  .site-menu-main .nav-link-item:hover {
    color: #0a102f;
  }
}
@media (min-width: 1200px) {
  .site-menu-main .nav-link-item {
    padding-top: 15px !important;
    padding-bottom: 13px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

.site-menu-main .sub-menu {
  z-index: 500;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, top 0.3s ease, margin-top 0.3s ease, background-color 0.3s ease;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
  display: flex;
  column-gap: 92px;
  min-width: 227px;
  margin-top: 25px;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu {
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
.site-menu-main .sub-menu_list {
  width: 100%;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu_list {
    padding: 12px 0;
  }
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu:not(.child-sub)::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 22px;
    height: 15px;
    width: 15px;
    background-color: white;
    transform: rotate(45deg) translateY(-11px);
    z-index: 999;
  }
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu .sub-menu {
    top: 0 !important;
    left: 100%;
  }
}
.site-menu-main .sub-menu--row {
  display: flex;
}
.site-menu-main .sub-menu--item {
  color: #0A102F !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .site-menu-main .sub-menu--item:not(.site-menu-main .sub-menu--item:last-child) {
    margin-bottom: 7px;
  }
}
.site-menu-main .sub-menu--item > a {
  transition: 0.4s;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item > a {
    transition: 0.4s;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 25px;
    padding-right: 25px;
    color: inherit;
  }
}
.site-menu-main .sub-menu--item > a > i {
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-menu-main .sub-menu--item:hover > a {
  color: red;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item:first-child a {
    padding-top: 0;
    padding-bottom: 2px;
  }
}
.site-menu-main .sub-menu--item:last-child {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.site-menu-main .sub-menu--item:last-child a {
  padding-bottom: 0;
}
@media (max-width: 991px) {
  .site-menu-main .sub-menu_item .child-sub ~ .sub-menu {
    margin-top: 30px;
    padding-top: 5px;
  }
}
.site-menu-main .sub-menu_item.nav-item-has-children a {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  li.nav-item-has-children {
    position: relative;
  }
}
li.nav-item-has-children .fa-angle-down,
li.nav-item-has-children .fa-angle-right {
  transition: 0.4s;
}
@media (min-width: 992px) {
  li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    pointer-events: visible;
    border-radius: 8px;
  }
  li.nav-item-has-children:hover .fa-angle-down {
    transform: rotate(180deg);
  }
}
@media (min-width: 992px) {
  li.nav-item-has-children:hover .drop-trigger:not(.child-sub)::before {
    top: 88%;
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
  }
}
li.nav-item-has-children.child-item:hover .fa-angle-right {
  transform: rotate(90deg);
}

.site-menu-main > li .sub-menu > ul > li > a {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  padding: 14px 21px;
  transition: all 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
}
@media (min-width: 992px) {
  .site-menu-main > li .sub-menu > ul > li > a {
    padding: 9px 21px;
    font-size: 16px;
    color: #0a102f;
  }
}
.site-menu-main > li .sub-menu > ul > li > a .menu-item-text {
  position: relative;
  transition: all 0.3s ease;
}
.site-menu-main > li .sub-menu > ul > li > a .menu-item-text::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: auto;
  right: 0;
  height: 2px;
  width: 0;
  background-color: #202030;
  transition: all 0.3s ease;
}
@media (min-width: 992px) {
  .site-menu-main > li .sub-menu > ul > li > a .menu-item-text::after {
    bottom: 2px;
  }
}
.site-menu-main > li .sub-menu > ul > li > a:hover .menu-item-text {
  transform: translateX(5px);
}
.site-menu-main > li .sub-menu > ul > li > a:hover .menu-item-text::after {
  left: 0;
  right: auto;
  width: 100%;
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .site-header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .site-header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #0A102F;
    width: 24px;
    position: relative;
  }
  .site-header .mobile-menu-trigger span:before,
  .site-header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0A102F;
  }
  .dark-mode-texts .mobile-menu-trigger span {
    background-color: #FFFFFF;
  }
  .dark-mode-texts .mobile-menu-trigger span:before,
  .dark-mode-texts .mobile-menu-trigger span:after {
    background-color: #FFFFFF;
  }
  .site-header .mobile-menu-trigger span:before {
    top: -6px;
  }
  .site-header .mobile-menu-trigger span:after {
    top: 6px;
  }
  .site-header .item-right {
    align-items: center;
  }
  .site-header .menu-block {
    position: fixed;
    width: 470px;
    max-width: 100%;
    background-color: #0a102f;
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(100%);
    padding: 0 16px 60px;
    z-index: 1099;
  }
  .site-header .menu-block.active {
    transform: translate(0%);
  }
  .site-menu-main > li {
    line-height: 1;
    margin: 0;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .site-menu-main > li > a {
    line-height: 50px;
    padding: 18px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .site-menu-main > li > a {
    display: block;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li > a i {
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(-90deg);
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .site-header .menu-block .mobile-menu-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    top: 0;
    padding-top: 20px;
    padding-bottom: 30px;
    background-color: #0a102f;
  }
  .site-header .menu-block .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    visibility: hidden;
  }
  .site-header .menu-block .mobile-menu-head.active .current-menu-title {
    visibility: visible;
  }
  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 40px;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    color: #000000;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .site-menu-main {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .site-menu-main > li .sub-menu {
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin: 0;
    padding: 0 10px 10px 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .site-menu-main > li .sub-menu_list {
    padding-left: 20px;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu.child-sub {
    padding-left: 36px;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .site-menu-main > li .sub-menu.child-sub .sub-menu_list {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu.active {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    z-index: 99;
    padding-top: 20px;
  }
  .site-menu-main .sub-menu_list .sub-menu_item:first-child a {
    padding-top: 0;
  }
  .site-menu-main > li .sub-menu > ul > li > a,
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }
  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
  }
  .menu-overlay.transition {
    transition: all 0.5s ease;
  }
  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}
/*Custom Css for menu*/
@media (min-width: 992px) {
  .site-menu-main a {
    display: flex;
    align-items: center;
  }
  .site-menu-main i {
    margin-left: 15px;
    font-size: 15px;
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  .site-header--menu-center .menu-block-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
.nav-item-has-children a {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .reveal-header .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .reveal-header .nav-link-item {
    color: #0A102F !important;
  }
}

@media (min-width: 992px) {
  .reveal-header li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
  }
  .reveal-header li.nav-item-has-children:hover > .sub-menu.megamenu {
    top: 100%;
  }
}

.menu-block.active {
  overflow-y: auto;
}

@media (min-width: 480px) {
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
/*Preloader css*/
div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

div#loading img {
  z-index: 9999;
  width: 25%;
}
@media (min-width: 567px) {
  div#loading img {
    width: 17%;
  }
}
@media (min-width: 768px) {
  div#loading img {
    width: 15%;
  }
}
@media (min-width: 992px) {
  div#loading img {
    width: 7%;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff !important;
}

.preloader img {
  width: 500px;
}

/*Header Css StaRT*/
.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .site-navbar {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-top: 0px;
  }
}
.site-navbar .menu-block-wrapper {
  flex-grow: 1;
  align-items: center;
}
@media (min-width: 992px) {
  .site-navbar .menu-block-wrapper {
    display: flex !important;
    flex-basis: auto;
    justify-content: center;
  }
}

.site-header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
}
.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.site-header--sticky:not(.mobile-sticky-enable) {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}
@media (min-width: 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    position: fixed !important;
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transform: translateY(-100%);
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 1000;
    background-color: #fff;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header .brand-logo .logo-dark {
    display: block;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header .brand-logo .logo-light {
    display: none;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header.bg--primary {
    background-color: var(--bs-primary);
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header.bg--primary .nav-link-item {
    color: #fff !important;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header.bg--primary .btn-masco--header-secondary {
    color: #fff !important;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header.bg--primary .btn-fill--up {
    --btn-border-color: #fff;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header .btn-link {
    color: var(--color-headings-2);
  }
}
.site-header--sticky.mobile-sticky-enable {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: 0.4s;
}
.site-header--sticky.mobile-sticky-enable.scrolling {
  transform: translateY(-100%);
  transition: 0.4s;
}
.site-header--sticky.mobile-sticky-enable.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
  height: 100%;
  background-color: transparent !important;
}
.site-header--sticky.mobile-sticky-enable.reveal-header .site-navbar {
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 5px;
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
}
@media (min-width: 768px) {
  .site-header--sticky.mobile-sticky-enable.reveal-header {
    height: auto;
  }
}
.site-header--menu-center .container {
  position: relative;
}
.site-header--menu-center .menu-block-wrapper {
  position: static;
}
@media (min-width: 992px) {
  .site-header--menu-center .menu-block {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .sub-menu.megamenu {
    left: 50%;
    transform: translateX(-50%) translateY(10px);
  }
}
@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-50%) translateY(-2px);
    left: 50%;
  }
}
.site-header--menu-left .menu-block .site-menu-main {
  justify-content: flex-start;
  padding-left: 15px;
}
@media (min-width: 1200px) {
  .site-header--menu-left .menu-block {
    width: 100%;
  }
}
.site-header--menu-right .menu-block {
  margin-left: auto;
}
@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .sub-menu.megamenu {
    left: 100%;
    transform: translateX(-100%) translateY(10px);
  }
}
@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-100%) translateY(-10px);
    left: 100%;
  }
}
.site-header--dark .nav-link-item {
  color: #fff;
}
.site-header--dark .btn {
  color: #fff;
}
.site-header--dark .btn--outline {
  border-color: #fff;
}
.site-header--dark .btn--outline:hover {
  background-color: #fff;
  color: var(--bs-primary);
}
.site-header--dark .btn:hover {
  color: var(--bs-primary);
}
.site-header--dark .logo-light {
  display: none;
}
.site-header--dark .logo-dark {
  display: block;
}
.site-header .header-cta-btn-wrapper {
  display: none;
  margin-left: 30px;
}
@media (min-width: 992px) {
  .site-header .header-cta-btn-wrapper {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
}
.site-header .header-cta-btn-wrapper a {
  --bs-btn-padding-y: 12px;
}
.site-header .header-cta-btn-wrapper a .icon {
  margin-right: 10px;
}
.site-header .header-cta-btn-mobile {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .site-header .header-cta-btn-mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .site-header .transition {
    transition: all 0.5s ease;
  }
}

@media (min-width: 480px) {
  .header-btns {
    margin-right: 15px;
  }
}
@media (min-width: 992px) {
  .header-btns {
    margin-right: 0;
  }
}

.brand-logo {
  display: flex;
}
.brand-logo img {
  max-height: 30px;
}
.brand-logo .logo-dark {
  display: none;
}

.dynamic-sticky-bg.reveal-header {
  background: var(--bg);
}
.dynamic-sticky-bg.dark-mode-texts .menu-block.active .nav-link-item {
  color: red !important;
}
.dynamic-sticky-bg.dark-mode-texts .menu-social-share li a {
  color: var(--color-headings-2);
}

.sticky-bg-white.reveal-header {
  background: #fff;
}
.sticky-bg-white.reveal-header .dark-version-logo {
  display: none !important;
}
.sticky-bg-white.reveal-header .light-version-logo {
  display: block !important;
}
.sticky-bg-white.reveal-header .menu-sep-left {
  position: relative;
}
.sticky-bg-white.reveal-header .menu-sep-left::before {
  content: "";
  border-left: rgba(255, 0, 0, 0.5) !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 26px;
  z-index: 9;
}

/* Landing Specific Header Adjustment */
/* Header */
/* ========== Component : Button Style  ============= */
.btn-masco {
  --btn-spacing-y: 18px;
  --btn-spacing-x: 42px;
  --btn-radius: 3px;
  --btn-bg: #3147FF;
  --btn-color: #fff;
  --btn-border-width: 1px;
  --btn-border-color: #3147FF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  z-index: 0;
  position: relative;
  border-width: var(--btn-border-width);
  border-style: solid;
  border-color: var(--btn-border-color);
  border-radius: var(--btn-radius);
  padding-left: var(--btn-spacing-x);
  padding-right: var(--btn-spacing-x);
  padding-top: var(--btn-spacing-y);
  padding-bottom: var(--btn-spacing-y);
  background-color: var(--btn-bg);
  color: var(--btn-color);
  transition: all 0.3s;
}
.btn-masco:not(.btn-fill--up):not(.btn-fill--down):not(.btn-fill--slide):not(.btn-fill--slide-reverse):not(.btn-fill--slide-left):not(.btn-shadow):not(.btn-shadow--reverse) {
  transition: all 0.3s ease-in-out;
}
.btn-masco:not(.btn-fill--up):not(.btn-fill--down):not(.btn-fill--slide):not(.btn-fill--slide-reverse):not(.btn-fill--slide-left):not(.btn-shadow):not(.btn-shadow--reverse):hover {
  background-color: var(--btn-hover-bg);
  color: var(--btn-hover-color);
  border-color: var(--btn-hover-border-color);
}
.btn-masco--header {
  --btn-spacing-y: 13px;
  --btn-spacing-x: 33px;
}
.btn-masco--header-secondary {
  background: none;
  border: none;
  padding: 0;
  color: #0A102F;
}
.btn-masco .play-icon {
  position: relative;
  height: 16px;
  width: 16px;
  background-color: #000;
  border-radius: 500px;
}
.btn-masco .play-icon::after {
  content: "\f04b";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 7px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(6px, 4px);
}

.btn-link {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #0A102F;
  transition: 0.3s;
  text-decoration: none;
}

.btn-arrow {
  display: inline-flex;
  column-gap: 10px;
}
.btn-arrow:hover::after {
  left: 5px;
}
.btn-arrow::after {
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  content: "\f061";
  font-size: inherit;
  position: relative;
  top: 1px;
  left: 0px;
  transition: 0.4s left;
  color: inherit;
}

/* ---------- Button Shadow ---------- */
.btn-shadow {
  z-index: 1;
  --btn-shadow-bg: #0A102F;
  --btn-shadow-border-color: #0A102F;
  --btn-shadow-border-width: 1px;
}
.btn-shadow::before {
  content: "";
  background-color: var(--btn-shadow-bg);
  position: absolute;
  left: 5px;
  top: 6px;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: inherit;
  border-width: var(--btn-shadow-border-width);
  border-style: solid;
  border-color: var(--btn-shadow-border-color);
  transition: 0.4s;
}
.btn-shadow:hover::before {
  left: 0px;
  top: 0px;
}
.btn-shadow--center::before {
  left: 0;
}
.btn-shadow:after {
  content: "";
  background-color: var(--btn-bg);
  position: absolute;
  border-width: var(--btn-border-width);
  border-style: solid;
  border-color: var(--btn-border-color);
  inset: -2px;
  z-index: -1;
  border-radius: inherit;
}
.btn-shadow--reverse::before {
  left: 0;
  top: 0;
}
.btn-shadow--reverse:hover::before {
  left: 5px;
  top: 6px;
}
.btn-shadow--reverse-center:hover::before {
  left: 0;
}

/* ---------- Button Fill Up ---------- */
.btn-fill--up {
  --btn-fill-bg: #fff;
  --btn-fill-color: $black;
  --btn-fill-border-color: #3147FF;
  overflow: hidden;
}
@keyframes fill-up-initial {
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
  }
}
@keyframes fill-up-end {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.btn-fill--up:hover {
  color: var(--btn-fill-color);
  border-color: var(--btn-fill-border-color);
}
.btn-fill--up span {
  position: relative;
  z-index: 10;
  display: block;
}
.btn-fill--up::before {
  position: absolute;
  top: -110%;
  left: -10%;
  padding-bottom: 120%;
  width: 120%;
  height: 0;
  border-radius: 50%;
  background: var(--btn-fill-bg);
  content: "";
  -webkit-transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}
.btn-fill--up::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--btn-fill-bg);
  content: "";
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn-fill--up:hover span {
  animation: fill-up-initial 0.3s forwards, fill-up-end 0.3s forwards 0.3s;
}
.btn-fill--up:hover::before {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn-fill--up:hover::after {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.05s;
  transition-duration: 0.05s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* ---------- Button Slide & Slide Reverse ---------- */
.btn-fill--slide,
.btn-fill--slide-reverse {
  overflow: hidden;
}
.btn-fill--slide span,
.btn-fill--slide-reverse span {
  position: relative;
  z-index: 10;
}
.btn-fill--slide::before,
.btn-fill--slide-reverse::before {
  position: absolute;
  top: 0;
  height: 100%;
  width: 120%;
  background: var(--btn-fill-bg);
  content: "";
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1), -webkit-transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  -webkit-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}
.btn-fill--slide:hover,
.btn-fill--slide-reverse:hover {
  border-color: var(--btn-fill-border-color);
}
.btn-fill--slide:hover::before,
.btn-fill--slide-reverse:hover::before {
  transform: translate3d(100%, 0, 0);
}

.btn-fill--slide {
  overflow: hidden;
}
.btn-fill--slide::before {
  left: -10%;
}
.btn-fill--slide-reverse::before {
  left: -130%;
}

/* ---------- Button Slide Down ---------- */
.btn-fill--down {
  z-index: 1;
  overflow: hidden;
}
.btn-fill--down::before, .btn-fill--down::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.btn-fill--down::before {
  background: var(--btn-fill-bg);
  opacity: 0.7;
}
.btn-fill--down::after {
  background: var(--btn-fill-bg);
}
.btn-fill--down:hover {
  color: var(--btn-fill-color);
}
.btn-fill--down:hover::before {
  transform: translate3d(0, 100%, 0);
}
.btn-fill--down:hover::after {
  transform: translate3d(0, 100%, 0);
  transition-delay: 0.175s;
}

/* ---------- Button Slide Left ---------- */
.btn-fill--slide-left {
  overflow: hidden;
}
@keyframes slide-left-initial {
  100% {
    -webkit-transform: translate3d(0, -105%, 0);
    transform: translate3d(0, -105%, 0);
  }
}
@keyframes slide-left-end {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.btn-fill--slide-left span {
  position: relative;
  display: block;
  overflow: hidden;
}
.btn-fill--slide-left::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  background: var(--btn-fill-bg);
  content: "";
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}
.btn-fill--slide-left:hover {
  color: var(--btn-fill-color);
  background-color: var(--btn-bg);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}
.btn-fill--slide-left:hover > span > span {
  -webkit-animation: slide-left-initial 0.2s forwards, slide-left-end 0.2s forwards 0.2s;
  animation: slide-left-initial 0.2s forwards, slide-left-end 0.2s forwards 0.2s;
}
.btn-fill--slide-left:hover::before {
  background-color: var(--btn-fill-bg);
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.btn-outline {
  --btn-bg: transparent;
  --btn-color: ;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-hover-bg: #0A102F;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: #0A102F;
}

.btn-primary-l02 {
  --btn-bg: #A259FF;
  --btn-color: #fff;
  --btn-border-color:#A259FF;
  --btn-fill-bg: #fff;
  --btn-fill-color: $black;
  --btn-fill-border-color:#A259FF;
}

.btn-secondary-l02 {
  --btn-bg: #0A102F;
  --btn-color: #fff;
  --btn-border-color:#0A102F;
  --btn-fill-bg:#A259FF;
  --btn-fill-color:#fff;
  --btn-fill-border-color:#A259FF;
}

.btn-outline-l02 {
  --btn-bg: transparent;
  --btn-color: #0A102F;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-fill-bg:#A259FF;
  --btn-fill-color:#fff;
  --btn-fill-border-color:#A259FF;
}

.btn-primary-l03 {
  --btn-bg: #FFC900;
  --btn-color: #0A102F;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-shadow-bg:#FFC900;
  --btn-shadow-border-color:#FFC900;
}

.btn-primary-l04 {
  --btn-bg: #4B2AAD;
  --btn-color: #FFFFFF;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-hover-bg: ;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: ;
  --btn-shadow-bg:#0A102F;
  --btn-shadow-border-color:#0A102F;
}

.btn-secondary-l04 {
  --btn-bg: var(--cream);
  --btn-color: #0A102F;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-hover-bg: ;
  --btn-hover-color: #0A102F;
  --btn-hover-border-color: ;
  --btn-shadow-bg:#0A102F;
  --btn-shadow-border-color:#0A102F;
}

.btn-primary-l05 {
  --btn-bg: #FF9966;
  --btn-color: #1B1C1D;
  --btn-border-color:#FF9966;
  --btn-hover-bg: #0A102F;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: #0A102F;
  --btn-fill-bg: #fff;
  --btn-fill-color: $black;
  --btn-fill-border-color:#fff;
}

.btn-secondary-l05 {
  --btn-bg: #1B1C1D;
  --btn-color: #fff;
  --btn-border-color:#1B1C1D;
  --btn-hover-bg: #FF9966;
  --btn-hover-color: #1B1C1D;
  --btn-hover-border-color: #FF9966;
  --btn-fill-bg: #fff;
  --btn-fill-color: $black;
  --btn-fill-border-color:#fff;
}

.btn-primary-l06 {
  --btn-bg: #3147FF;
  --btn-color: #FFFFFF;
  --btn-border-width:2px;
  --btn-border-color:#3147FF;
}

.btn-primary-l07 {
  --btn-bg: #191919;
  --btn-color: #FFFFFF;
  --btn-border-width:2px;
  --btn-border-color:#191919;
  --btn-hover-bg: #A259FF;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: #A259FF;
  --btn-shadow-bg:#A259FF;
  --btn-shadow-border-color:#191919;
}

.btn-primary-l08 {
  --btn-bg: #fff;
  --btn-color: #0A102F;
  --btn-border-width:2px;
  --btn-border-color:#C1FF00;
  --btn-hover-bg: #191919;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: #191919;
  --btn-fill-bg:#C1FF00;
  --btn-fill-color: $black;
  --btn-fill-border-color:#0A102F;
}

.btn-secondary-l08 {
  --btn-bg: transparent;
  --btn-color: #0A102F;
  --btn-border-width:2px;
  --btn-border-color:#0A102F;
  --btn-fill-bg:#C1FF00;
  --btn-fill-color: $black;
  --btn-fill-border-color:#C1FF00;
}

.btn-outline-l08 {
  --btn-bg: transparent;
  --btn-color: #000;
  --btn-border-width:2px;
  --btn-border-color:#000;
  --btn-hover-bg: #191919;
  --btn-hover-color: #FFFFFF;
  --btn-hover-border-color: #191919;
  --btn-fill-bg:#C1FF00;
  --btn-fill-color: $black;
  --btn-fill-border-color:#C1FF00;
}

.btn-primary-l09 {
  --btn-bg: #324438;
  --btn-color: #F4B905;
  --btn-border-color:#324438;
  --btn-fill-bg:#F4B905;
  --btn-fill-color:#324438;
  --btn-fill-border-color:#324438;
}

.btn-secondary-l09 {
  --btn-bg: #F4B905;
  --btn-color: #324438;
  --btn-border-color:#F4B905;
  --btn-fill-bg:#F4B905;
  --btn-fill-color:#324438;
  --btn-fill-border-color:#F4B905;
}

.btn-primary-l10 {
  --btn-bg: transparent;
  --btn-color: #FFD874;
  --btn-border-width:2px;
  --btn-border-color:#2D2B92;
  --btn-fill-bg:#2D2B92;
  --btn-fill-color:#2D2B92;
  --btn-fill-border-color:transparent;
}

.btn-secondary-l10 {
  --btn-bg: #FFD874;
  --btn-color: #FFD874;
  --btn-border-width:2px;
  --btn-border-color:#FFD874;
  --btn-fill-bg:#2D2B92;
  --btn-fill-color:#2D2B92;
  --btn-fill-border-color:#FFD874;
}

.btn-outline-l10 {
  --btn-bg: transparent;
  --btn-color: #2D2B92;
  --btn-border-width:2px;
  --btn-border-color:#2D2B92;
  --btn-fill-bg:#FFF4D9;
  --btn-fill-color:#FFD874;
  --btn-fill-border-color:transparent;
}

/* ========== Button Utilities  ============= */
.rounded-pill {
  --btn-radius: 500px;
}

.btn-padding-x-33 {
  --btn-spacing-x: 33px;
}

.btn-play {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--bs-primary);
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .btn-play {
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
    border-radius: 500px;
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .btn-play {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 500px;
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .btn-play {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 500px;
    font-size: 24px;
  }
}
.btn-play i {
  transform: scale(1);
  transition: 0.4s;
}
.btn-play:hover i {
  transform: scale(1.2);
}
.btn-play--outline {
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
}

.btn-play--black {
  background: #121212;
  color: #C1FF00;
}

.btn-play--green {
  background: #C1FF00;
  color: #121212;
}

.btn-play--blue {
  background: #2D2B92;
  color: #FFD874;
}

.btn-play--80 {
  border-radius: 500px;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  height: 60px;
  width: 60px;
  font-size: 17px;
}
@media (min-width: 992px) {
  .btn-play--80 {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    height: 80px;
    width: 80px;
    font-size: 18px;
  }
}

.btn-play--70 {
  border-radius: 500px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  width: 50px;
  font-size: 15px;
}
@media (min-width: 992px) {
  .btn-play--70 {
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
    height: 70px;
    width: 70px;
    font-size: 17px;
  }
}

.btn-play--120 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--bs-primary);
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 500px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .btn-play--120 {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 500px;
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .btn-play--120 {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 500px;
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .btn-play--120 {
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
    border-radius: 500px;
    font-size: 30px;
  }
}
.btn-play--120 i {
  transform: scale(1);
  transition: 0.4s;
}
.btn-play--120:hover i {
  transform: scale(1.2);
}

/* ========== Component : Ticker Style 1 ============= */
.ticker-01_section {
  background-color: #0A102F;
}
.ticker-01_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ticker-01_wrapper .ticker-item {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticker-01_wrapper .ticker-item p {
  font-size: 22px;
  color: inherit;
  margin: initial;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.03333333em;
  text-transform: uppercase;
  padding: initial;
}
@media (min-width: 576px) {
  .ticker-01_wrapper .ticker-item p {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .ticker-01_wrapper .ticker-item p {
    font-size: 30px;
  }
}
.ticker-01_content {
  display: flex;
  column-gap: 24px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 13px;
  animation: ticker 60s linear infinite;
}
.ticker-item {
  display: flex;
  column-gap: 24px;
}

@keyframes ticker {
  0% {
    transform: translateX(0, 0, 0);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* ========== Component : Ticker Style 1 ============= */
.ticker-02_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ticker-02_content {
  display: flex;
  column-gap: 24px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 13px;
  animation: ticker 60s linear infinite;
}
.ticker-02_content--reverse {
  animation: ticker-reverse 60s linear infinite;
}

.ticker-tag {
  position: relative;
  z-index: 1;
  background: #f5efeb;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0A102F;
  display: block;
  border: 2px solid #0A102F;
  border-radius: 500px;
  padding: 12px 22px;
}
@media (min-width: 768px) {
  .ticker-tag {
    padding: 16px 22px;
  }
}
.ticker-tag::before {
  content: "";
  background-color: #0A102F;
  position: absolute;
  left: 0px;
  top: 5px;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: inherit;
  border: 2px solid #0a102f;
  transition: 0.4s;
}
.ticker-tag:after {
  content: "";
  background-color: #f5efeb;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: #0A102F;
  inset: -1px;
  z-index: -1;
  border-radius: inherit;
}

@keyframes ticker {
  0% {
    transform: translateX(0, 0, 0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes ticker-reverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* ========== Component : Ticker Style 1 ============= */
.ticker-03_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ticker-03_wrapper .ticker-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticker-03_content {
  display: flex;
  column-gap: 24px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 13px;
  animation: ticker 60s linear infinite;
}
.ticker-03_content--reverse {
  animation: ticker-reverse 60s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translateX(0, 0, 0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes ticker-reverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* ========== Component : Content Section Style ============= */
.content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
@media (min-width: 992px) {
  .content {
    align-items: initial;
    row-gap: 48px;
  }
}
.content-text-block {
  text-align: center;
}
@media (min-width: 992px) {
  .content-text-block {
    text-align: initial;
    padding-right: 52px;
  }
}
@media (min-width: 1200px) {
  .content-text-block {
    padding-right: 0;
  }
}
.content-text-block p {
  line-height: 1.67;
  color: rgba(10, 16, 47, 0.8);
}
.content-text-block p:first-of-type {
  margin-top: 20px;
}
.content-text-block p:not(:first-of-type) {
  margin-top: 20px;
}
.content-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(10, 16, 47, 0.1);
}
.content-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.content-list-item {
  display: flex;
  column-gap: 12px;
  font-size: 18px;
  line-height: 1.67;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.content-list-item img {
  max-width: 22px;
  max-height: 22px;
  position: relative;
  top: 3px;
}
.content-list-item p {
  margin-bottom: initial;
}

@media (max-width: 991px) {
  .content-image--mobile-width {
    display: inline-block;
    max-width: 320px;
  }
}
@media (max-width: 479px) {
  .content-image--mobile-width {
    max-width: 260px;
  }
}

/* ========== Component : Pricing Control Style  ============= */
.pricing-control {
  max-width: 250px;
  min-width: 250px;
  display: inline-flex;
  padding: 3px;
  background: #0A102F;
  border-radius: 3px;
  height: 50px;
}
.pricing-control > * {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
}
.pricing-control > *.active {
  color: #fff;
}
.pricing-control > *:first-of-type::before {
  border-radius: 3px;
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background: #A259FF;
  z-index: -1;
  transition: 0.4s;
}
.pricing-control > *:first-of-type.active::before {
  left: 0%;
}

.pricing-control-2 {
  max-width: 265px;
  min-width: 265px;
  display: inline-flex;
  border-radius: 500px;
  height: 35px;
  align-items: center;
  column-gap: 24px;
}
.pricing-control-2 span {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(27, 28, 29, 0.6);
}
.pricing-control-2 span.active {
  color: var(--black-2);
}
.pricing-control-2__indicator {
  display: block;
  width: 70px;
  height: 35px;
  background: #1B1C1D;
  border-radius: 50px;
  position: relative;
}
.pricing-control-2__indicator::before {
  content: "";
  position: absolute;
  left: -1px;
  right: auto;
  width: 35px;
  height: 35px;
  background: #FF9966;
  border-radius: 500px;
  transition: 0.4s;
}
.pricing-control-2__indicator.active::before {
  color: #1B1C1D;
  transform: translateX(100%);
  left: 1px;
}

.pricing-control-3 {
  max-width: 265px;
  min-width: 265px;
  display: inline-flex;
  border-radius: 500px;
  height: 35px;
  align-items: center;
  column-gap: 24px;
}
.pricing-control-3 span {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(18, 18, 18, 0.6);
  cursor: pointer;
}
.pricing-control-3 span.active {
  color: #121212;
}
.pricing-control-3__indicator {
  display: block;
  width: 70px;
  height: 35px;
  background: #324438;
  border-radius: 50px;
  position: relative;
}
.pricing-control-3__indicator::before {
  content: "";
  position: absolute;
  left: 5px;
  right: auto;
  top: 5px;
  width: 25px;
  height: 25px;
  padding: 5px;
  background: #F4B905;
  border-radius: 500px;
  transition: 0.4s;
}
.pricing-control-3__indicator.active::before {
  color: var(--black-2);
  transform: translateX(144%);
  left: 4px;
}
.pricing-control-3__indicator.indicator-white {
  background: #3147FF;
}
.pricing-control-3__indicator.indicator-white::before {
  content: "";
  left: 5px;
  top: 5px;
  right: auto;
  width: 25px;
  height: 25px;
  background: #FFFFFF;
}
.pricing-control-3__indicator.indicator-white.active::before {
  color: #121212;
  transform: translateX(157%);
  left: 1px;
}
.pricing-control-3__indicator.indicator-green {
  background: var(--black-2);
}
.pricing-control-3__indicator.indicator-green::before {
  content: "";
  background: #C1FF00;
}

.pricing-control-rounded {
  max-width: 250px;
  min-width: 250px;
  height: 50px;
  display: inline-flex;
  padding: 3px;
  background: #A259FF;
  border-radius: 500px;
}
.pricing-control-rounded > * {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
}
.pricing-control-rounded > *.active {
  color: #fff;
}
.pricing-control-rounded > *:first-of-type::before {
  border-radius: 500px;
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background: #0A102F;
  z-index: -1;
  transition: 0.4s;
}
.pricing-control-rounded > *:first-of-type.active::before {
  left: 0%;
}

.pricing-control-5 {
  max-width: 265px;
  min-width: 265px;
  display: inline-flex;
  border-radius: 500px;
  height: 35px;
  align-items: center;
  column-gap: 24px;
}
.pricing-control-5 span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(var(--black-2), 0.6);
}
.pricing-control-5 span.active {
  color: var(--black-2);
}
.pricing-control-5__indicator {
  display: block;
  width: 70px;
  height: 35px;
  background: #1B1C1D;
  border-radius: 50px;
  position: relative;
}
.pricing-control-5__indicator::before {
  content: "";
  position: absolute;
  left: -1px;
  right: auto;
  width: 35px;
  height: 35px;
  background: #C1FF00;
  border-radius: 500px;
  transition: 0.4s;
}
.pricing-control-5__indicator.active::before {
  transform: translateX(100%);
  left: 1px;
}

.pricing-control-6 {
  max-width: 265px;
  min-width: 265px;
  display: inline-flex;
  border-radius: 500px;
  height: 35px;
  align-items: center;
  column-gap: 24px;
}
.pricing-control-6 span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(18, 18, 18, 0.6);
}
.pricing-control-6 span.active {
  color: #121212;
}
.pricing-control-6__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 35px;
  background: #3147FF;
  border-radius: 50px;
  position: relative;
}
.pricing-control-6__indicator::before {
  content: "";
  position: absolute;
  left: 5px;
  right: auto;
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  border-radius: 500px;
  transition: 0.8s;
}
.pricing-control-6__indicator.active::before {
  transform: translateX(100%);
  left: 1px;
}

.home-7_testimonial-card-wrapper .slick-dots {
  bottom: -10%;
  width: 95%;
}
@media (min-width: 992px) {
  .home-7_testimonial-card-wrapper .slick-dots {
    bottom: -16%;
  }
}
.home-7_testimonial-card-wrapper .slick-dots li button::before {
  content: "•";
  font-size: 10px;
}
.home-7_testimonial-card-wrapper .slick-dots .slick-active {
  width: 20px;
}
.home-7_testimonial-card-wrapper .slick-dots .slick-active button::before {
  content: "•";
  font-size: 10px;
}

.home-10_testimonial-widget-wrapper {
  position: relative;
}
.home-10_testimonial-widget-wrapper .slick-prev {
  width: fit-content;
  left: 89%;
  top: 79%;
  z-index: 1;
  position: absolute;
}
.home-10_testimonial-widget-wrapper .slick-prev::before {
  font-family: "Font Awesome 5 Free";
  content: "\f104";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
  background: #FFF4D9;
  border: 1px solid #221F1A;
  color: #2D2B92;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.home-10_testimonial-widget-wrapper .slick-prev:hover::before {
  color: #FFD874;
  background: #2D2B92;
}
.home-10_testimonial-widget-wrapper .slick-next {
  width: fit-content;
  left: 94.5%;
  top: 79%;
  position: absolute;
  z-index: 1;
}
.home-10_testimonial-widget-wrapper .slick-next::before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
  background: #FFF4D9;
  border: 1px solid #221F1A;
  color: #2D2B92;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.home-10_testimonial-widget-wrapper .slick-next:hover::before {
  color: #FFD874;
  background: #2D2B92;
}

.loading {
  overflow: hidden;
}

.preloader-wrapper {
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 99999999 !important;
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3147FF;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* ========== Home 1 : Hero Section Style ============= */
.home-1_hero-section {
  background-color: #FAF9F5;
  position: relative;
  z-index: 1;
  padding-top: 110px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .home-1_hero-section {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-1_hero-section {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1400px) {
  .home-1_hero-section {
    padding-top: 185px;
    padding-bottom: 120px;
  }
}
.home-1_hero-section .row--hero-content {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-1_hero-section .row--hero-content {
    flex-direction: row-reverse;
  }
}
.home-1_hero-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.home-1_hero-shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.home-1_hero-image-block {
  position: relative;
  max-width: 660px;
}
.home-1_hero-image img {
  width: 100%;
  border-radius: 10px;
}
.home-1_hero-content {
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-1_hero-content {
    text-align: initial;
    align-items: initial;
    row-gap: 50px;
  }
}
.home-1_hero-content p {
  max-width: 607px;
  color: rgba(10, 16, 47, 0.8);
}
.home-1_hero-newsletter {
  position: relative;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.home-1_hero-newsletter__bottom-text {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 15px;
  color: rgba(10, 16, 47, 0.5);
  text-align: initial;
}
@media (min-width: 480px) {
  .home-1_hero-newsletter__bottom-text {
    justify-content: center;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .home-1_hero-newsletter__bottom-text {
    justify-content: initial;
  }
}
.home-1_hero-newsletter__bottom-text img {
  height: 16px;
  width: 16px;
}

/* ========== Home 1 : Service Section Style ============= */
.home-1_service-section {
  position: relative;
}
.home-1_service-section-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.home-1_service-section-wrapper {
  border-top: 1px solid rgba(10, 16, 47, 0.1);
  padding-top: 60px;
}
@media (min-width: 576px) {
  .home-1_service-section-wrapper {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .home-1_service-section-wrapper {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .home-1_service-section-wrapper {
    padding-top: 120px;
  }
}

/* ========== Home 1 : Content Section 1 Style ============= */
.home-1_content-section-1 {
  background: var(--offwhite);
}
.home-1_content-section-1 .row--custom {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-1_content-section-1 .row--custom {
    flex-direction: row-reverse;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .home-1_content-section-1 .content-image--mobile-width {
    max-width: 360px;
  }
}
@media (max-width: 479px) {
  .home-1_content-section-1 .content-image--mobile-width {
    max-width: 260px;
  }
}
.home-1_content-image-1 {
  position: relative;
}
.home-1_content-image-1 img {
  width: 100%;
}
.home-1_content-image-1-shape {
  position: absolute;
  top: -16%;
  right: -16%;
  height: 14.7%;
  width: 38%;
}
.home-1_content-1-text-block {
  text-align: center;
}
@media (min-width: 992px) {
  .home-1_content-1-text-block {
    text-align: initial;
    padding-right: 52px;
  }
}
@media (min-width: 1200px) {
  .home-1_content-1-text-block {
    padding-right: 0;
  }
}
.home-1_content-1-text-block p {
  max-width: 598px;
  line-height: 1.67;
  color: rgba(10, 16, 47, 0.8);
}
.home-1_content-1-text-block p:not(:last-child) {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .home-1_content-1-text-block p:not(:last-child) {
    margin-bottom: 35px;
  }
}
.home-1_content-title {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.26;
  font-weight: 800;
}
@media (min-width: 576px) {
  .home-1_content-title {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .home-1_content-title {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .home-1_content-title {
    font-size: 46px;
  }
}

/* ========== Home 1 : Content Section 2 Style ============= */
.home-1_content-section-2 {
  background: var(--offwhite);
}
.home-1_content-section-2 .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-1_content-section-2 .row--custom {
    align-items: center;
    --bs-gutter-x: 60px;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .home-1_content-section-2 .content-image--mobile-width {
    max-width: 360px;
  }
}
@media (max-width: 479px) {
  .home-1_content-section-2 .content-image--mobile-width {
    max-width: 260px;
  }
}
@media (min-width: 992px) {
  .home-1_content-section-2 .content {
    padding-left: 30px;
  }
}
@media (min-width: 1200px) {
  .home-1_content-section-2 .content {
    padding-left: 0;
  }
}
.home-1_content-section-2 .content .content-list-item {
  color: #0A102F;
}
.home-1_content-image-2 {
  position: relative;
}
.home-1_content-image-2 img {
  width: 100%;
}
.home-1_content-image-2-shape {
  position: absolute;
  bottom: 0%;
  left: -5%;
  height: 25.3%;
  width: 24.4%;
}

/* ========= Home 1  : Portfolio Section Style ============= */
.home-1_portfolio-section {
  position: relative;
  z-index: 1;
}
.home-1_portfolio-section .portfolio-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-1_portfolio-section .row--portfolio-gutter {
  --bs-gutter-x:20px;
  --bs-gutter-y:30px;
  justify-content: center;
}
@media (min-width: 768px) {
  .home-1_portfolio-section .row--portfolio-gutter {
    --bs-gutter-x:30px;
  }
}
@media (min-width: 992px) {
  .home-1_portfolio-section .row--portfolio-gutter {
    --bs-gutter-x:45px;
  }
}
@media (min-width: 1200px) {
  .home-1_portfolio-section .row--portfolio-gutter {
    --bs-gutter-x:61px;
  }
}

/* ========== Home 1 : FAQ Section Style ============= */
.home-1_faq-section {
  position: relative;
  z-index: 1;
}
.home-1_faq-section .row--custom {
  justify-content: center;
}
.home-1_faq-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.home-1_faq-shape-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* ========== Home 2 : Hero Section Style ============= */
.home-2_hero-section .row--custom {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-2_hero-section .row--custom {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.home-2_hero-image-block {
  position: relative;
  margin-left: 27px;
}
.home-2_hero-image img {
  width: 100%;
}
.home-2_hero-image-shape {
  position: absolute;
  right: -9%;
  top: -14%;
  width: 17%;
}
.home-2_hero-image-man-1 {
  position: absolute;
  left: -7%;
  top: 30%;
  width: 13.3%;
}
.home-2_hero-image-man-1 img {
  width: 100%;
}
.home-2_hero-image-man-2 {
  position: absolute;
  right: -7%;
  bottom: 8%;
  width: 15%;
}
.home-2_hero-image-man-2 img {
  width: 100%;
}
.home-2_hero-content {
  max-width: 681px;
}
.home-2_hero-content-text {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-2_hero-content-text {
    text-align: initial;
  }
}
.home-2_hero-content-text p {
  max-width: 590px;
}
@media (min-width: 992px) {
  .home-2_hero-content-text {
    margin-bottom: 50px;
  }
}
.home-2_hero-button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .home-2_hero-button-group {
    justify-content: initial;
    margin-bottom: 20px;
  }
}
.home-2_hero-button-group .btn-outline {
  width: 239px;
}
@media (min-width: 576px) {
  .home-2_hero-button-group .btn-outline {
    width: initial;
  }
}
.home-2_hero-content-button__bottom-text span {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #0A102F;
}
@media (min-width: 992px) {
  .home-2_hero-content-button__bottom-text span {
    justify-content: initial;
  }
}

/* ========== Home 2 : Feature Section Style ============= */
.home-2_feature-section {
  background: rgba(162, 89, 255, 0.03);
}

/* ========== Home 2 : Pricing Section Style ============= */
.home-2_pricing-section .section-heading {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  text-align: center;
}
.home-2_pricing-section .section-heading__title {
  margin-bottom: 50px;
}

/* ========== Home 2 : Testimonial Section Style ============= */
.home-2_testimonial-section .row--testimonial-widget {
  --bs-gutter-y:40px;
  justify-content: center;
}

/* ========== Home 2 : Content Section 1 Style ============= */
@media (min-width: 1400px) {
  .home-2_content-section-1 {
    padding-bottom: 150px;
  }
}
.home-2_content-section-1 .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-2_content-section-1 .row--custom {
    align-items: center;
    --bs-gutter-x: 25px;
  }
}
@media (min-width: 992px) {
  .home-2_content-section-1 .content .content_text-block p {
    max-width: 635px;
  }
}
.home-2_content-section-1 .content .content-list-item {
  font-family: "Inter", sans-serif;
}
.home-2_content-image-1 img {
  width: 100%;
}

/* ========== Home 2 : Content Section 2 Style ============= */
.home-2_content-section-2 .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-2_content-section-2 .row--custom {
    align-items: center;
    --bs-gutter-x: 24px;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  .home-2_content-image-2 {
    max-width: 451.46px;
  }
}
.home-2_content-image-2 img {
  width: 100%;
}

/* ========== Home 2 : Content Section 3 Style ============= */
.home-2_content-section-3 .row--custom {
  justify-content: center;
}
.home-2_content-section-3 .section-heading {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-2_content-section-3 .section-heading {
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .home-2_content-section-3 .section-heading {
    margin-bottom: 80px;
  }
}
.home-2_content-section-3 .section-heading p {
  line-height: 1.67;
  color: rgba(10, 16, 47, 0.8);
}
.home-2_content-image-3 {
  position: relative;
}
.home-2_content-image-3 img {
  width: 100%;
}

/* ========== Home 2 : Cta ============= */
.cta-home-2 {
  background: #a259ff;
  position: relative;
}
.cta-home-2-shape-wrapper {
  display: none;
}
@media (min-width: 680px) {
  .cta-home-2-shape-wrapper {
    display: block;
  }
}
.cta-home-2-shape-1 {
  position: absolute;
  left: 8%;
  top: 12%;
}
.cta-home-2-shape-2 {
  position: absolute;
  right: 6%;
  bottom: 0;
}
.cta-home-2__title {
  margin-bottom: 0;
  color: #fff;
}
.cta-home-2 .cta-content-block {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}
@media (min-width: 680px) {
  .cta-home-2 .cta-content-block {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .cta-home-2 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-2 .cta-content-block .btn-secondary-l02 {
  --btn-fill-bg: #fff;
  --btn-fill-color: $black;
}

/* ========== Home 2 : Integration Section Style ============= */
.home-2_integration-section .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-2_integration-section .row--custom {
    --bs-gutter-x: 24px;
    align-items: center;
    flex-direction: row-reverse;
  }
}
.home-2_integration-section .content {
  text-align: center;
}
@media (min-width: 992px) {
  .home-2_integration-section .content {
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .home-2_integration-section p {
    max-width: 506px;
    color: rgba(10, 16, 47, 0.8);
  }
}
@media (min-width: 992px) {
  .home-2_integration-image {
    max-width: 472px;
  }
}
.home-2_integration-image img {
  width: 100%;
}

/* ========== Home 3 : Hero Section Style ============= */
.home-3_hero-section {
  padding-top: 100px;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (min-width: 576px) {
  .home-3_hero-section {
    padding-top: 110px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .home-3_hero-section {
    padding-top: 130px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-3_hero-section {
    padding-top: 148px;
    padding-bottom: 100px;
  }
}
.home-3_hero-section .row--custom {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-3_hero-section .row--custom {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.home-3_hero-shape {
  position: absolute;
  top: -29%;
  left: 0;
  width: 100%;
  z-index: -1;
}
.home-3_hero-shape img {
  width: 100%;
}
.home-3_hero-image-block {
  position: relative;
  z-index: 1;
  max-width: 220px;
}
@media (min-width: 992px) {
  .home-3_hero-image-block {
    margin-right: 18.5%;
    margin-left: 9%;
    max-width: initial;
  }
}
.home-3_hero-image img {
  width: 100%;
  border-radius: 10px;
}
.home-3_hero-image-shape-1 {
  position: absolute;
  bottom: 17%;
  left: -14%;
  width: 35%;
  z-index: -1;
}
.home-3_hero-image-shape-2 {
  position: absolute;
  top: 15%;
  right: -44%;
  width: 88%;
  z-index: -1;
}
.home-3_hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-3_hero-content {
    text-align: initial;
    align-items: initial;
    row-gap: 34px;
  }
}
.home-3_hero-content p {
  max-width: 680px;
  color: rgba(10, 16, 47, 0.8);
}
.home-3_hero-content .hero-content__title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: -1px;
  color: #0A102F;
  line-height: 1.11;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -0.0111111111em;
}
@media (min-width: 480px) {
  .home-3_hero-content .hero-content__title {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .home-3_hero-content .hero-content__title {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .home-3_hero-content .hero-content__title {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .home-3_hero-content .hero-content__title {
    font-size: 76px;
  }
}
@media (min-width: 1400px) {
  .home-3_hero-content .hero-content__title {
    font-size: 90px;
  }
}
.home-3_hero-content-stat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}
@media (min-width: 992px) {
  .home-3_hero-content-stat-wrapper {
    align-items: flex-start;
  }
}
.home-3_hero-content-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
@media (min-width: 576px) {
  .home-3_hero-content-stat {
    flex-direction: row;
  }
}
.home-3_hero-content-stat .divider {
  height: 39px;
  width: 2px;
  background-color: #0A102F;
  opacity: 0.1;
  border-radius: 100px;
  display: none;
}
@media (min-width: 576px) {
  .home-3_hero-content-stat .divider {
    display: block;
  }
}
.home-3_hero-content-stat p {
  font-weight: 700;
  letter-spacing: -0.033333em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  margin-bottom: initial;
  font-family: "Inter", sans-serif;
  color: #0A102F;
}
@media (min-width: 768px) {
  .home-3_hero-content-stat p {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .home-3_hero-content-stat p {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .home-3_hero-content-stat p {
    font-size: 30px;
  }
}
.home-3_hero-content-stat p span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
.home-3_hero-content__customer-count {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 480px) {
  .home-3_hero-content__customer-count {
    flex-direction: row;
  }
}
.home-3_hero-content__customer-count img {
  width: 146px;
}
.home-3_hero-content__customer-count p span {
  display: block;
}
.home-3_hero-content__rating-count {
  display: flex;
  align-items: center;
}
.home-3_hero-content__rating-count p span {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

/* ========== Home 3 : Video Section Style ============= */
.home-3_video-section {
  position: relative;
}
.home-3_video-shape {
  position: absolute;
  left: 0;
  top: 14%;
  display: none;
}
@media (min-width: 992px) {
  .home-3_video-shape {
    display: block;
  }
}

/* ========== Home 3 : Feature Section Style ============= */
.home-3_feature-section .row--custom {
  --bs-gutter-x: 80px;
  justify-content: center;
}

/* ========== Home 3 : Content Section 1 Style ============= */
.home-3_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-3_content-section-1 .row--custom {
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .home-3_content-section-1 .content {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .home-3_content-section-1 .content-text-block p {
    max-width: 598px;
  }
}
.home-3_content-image-1-block {
  position: relative;
  max-width: 282.64px;
  z-index: 1;
  display: inline-block;
}
.home-3_content-image-1 img {
  width: 100%;
}
.home-3_content-image-1-shape {
  width: 180%;
}
.home-3_content-image-1-shape.absolute-center {
  z-index: -1;
}
.home-3_content-image-1-shape img {
  width: 100%;
}

/* ========== Home 3 : Content Section 2 Style ============= */
.home-3_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-3_content-section-2 .row--custom {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
.home-3_content-section-2 .content {
  align-items: center;
}
@media (min-width: 992px) {
  .home-3_content-section-2 .content {
    align-items: initial;
  }
}
@media (min-width: 1200px) {
  .home-3_content-section-2 .content {
    margin-right: 60px;
  }
}
.home-3_content-section-2 .content .content-list {
  margin-bottom: 0px;
}
@media (min-width: 992px) {
  .home-3_content-section-2 .content-text-block p {
    max-width: 560px;
  }
}
.home-3_content-section-2 .content .content-list-item {
  font-family: "Inter", sans-serif;
  color: #0A102F;
}
.home-3_content-image-2-block {
  position: relative;
  max-width: 287.72px;
  z-index: 1;
  display: inline-block;
}
.home-3_content-image-2-block img {
  width: 100%;
}
.home-3_content-image-2-shape {
  width: 174%;
}
.home-3_content-image-2-shape.absolute-center {
  z-index: -1;
}
.home-3_content-image-2-shape img {
  width: 100%;
}

/* ========== Home 3 : Testimonial Section Style ============= */
.home-3_testimonial {
  background: var(--offwhite);
}
.home-3_testimonial .section-heading {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .home-3_testimonial .section-heading {
    margin-bottom: 80px;
  }
}
.home-3_testimonial .section-heading__title {
  max-width: 608px;
}
.home-3_testimonial .row--custom {
  justify-content: space-between;
  align-items: center;
  --bs-gutter-y: 30px;
}

/* ========== Home 3 : Cta ============= */
.cta-home-3 {
  padding-top: 40px;
}
@media (min-width: 576px) {
  .cta-home-3 {
    padding-top: 60px;
  }
}
@media (min-width: 768px) {
  .cta-home-3 {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .cta-home-3 {
    padding-top: 120px;
  }
}
@media (min-width: 1200px) {
  .cta-home-3 {
    padding-top: 200px;
  }
}
.cta-home-3__image-block {
  position: relative;
  padding-left: 8.9%;
  padding-right: 8.9%;
  z-index: 1;
  width: 100%;
  max-width: 200px;
}
@media (min-width: 768px) {
  .cta-home-3__image-block {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .cta-home-3__image-block {
    padding-right: 11.4%;
    max-width: 551.73px;
    margin-top: -15px;
  }
}
@media (min-width: 1200px) {
  .cta-home-3__image-block {
    margin-top: -115px;
  }
}
.cta-home-3__image-block img {
  width: 100%;
}
.cta-home-3__image-shape {
  position: absolute;
  bottom: 0%;
  left: -4%;
  z-index: -1;
  width: 115.6%;
}
@media (min-width: 480px) {
  .cta-home-3__image-shape {
    width: 100.6%;
    left: 3%;
  }
}
.cta-home-3__inner {
  background-color: #FFC900;
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  row-gap: 30px;
  border-radius: 15px;
}
@media (min-width: 576px) {
  .cta-home-3__inner {
    border-radius: 30px;
  }
}
@media (min-width: 992px) {
  .cta-home-3__inner {
    flex-direction: row;
    text-align: initial;
    border-radius: 50px;
    padding-left: 0;
    column-gap: 30px;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .cta-home-3__inner {
    column-gap: 43px;
    padding-right: 110px;
  }
}
.cta-home-3__content-block {
  max-width: 561px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 40px;
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .cta-home-3__content-block {
    margin-left: 30px;
    padding-top: 65px;
    padding-bottom: 65px;
    row-gap: 48px;
  }
}
@media (min-width: 1400px) {
  .cta-home-3__content-block {
    padding-top: 100px;
    padding-bottom: 92px;
  }
}
.cta-home-3 .cta-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
}
@media (min-width: 992px) {
  .cta-home-3 .cta-button-group {
    justify-content: initial;
  }
}
.cta-home-3 .cta-button-group img {
  width: 100%;
  height: 100%;
}

/* ========== Home 4 : Hero Section Style ============= */
.home-4_hero-section {
  padding-top: 100px;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (min-width: 576px) {
  .home-4_hero-section {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}
@media (min-width: 768px) {
  .home-4_hero-section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media (min-width: 992px) {
  .home-4_hero-section {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media (min-width: 1200px) {
  .home-4_hero-section {
    padding-top: 160px;
    padding-bottom: 155px;
  }
}
@media (min-width: 1400px) {
  .home-4_hero-section {
    padding-top: 170px;
    padding-bottom: 155px;
  }
}
.home-4_hero-section .row--custom {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-4_hero-section .row--custom {
    --bs-gutter-x: 105px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.home-4_hero-section .hero-shape-1 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: -1;
}
.home-4_hero-section .hero-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-4_hero-image {
  position: relative;
  max-width: 450px;
}
.home-4_hero-image img {
  width: 100%;
}
.home-4_hero-image-shape {
  position: absolute;
  top: 1%;
  right: -4%;
  width: 33.4%;
}
.home-4_hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-4_hero-content {
    max-width: 660px;
    text-align: initial;
    align-items: initial;
    row-gap: 34px;
  }
}
.home-4_hero-content .hero-content__title {
  margin-bottom: 24px;
  font-style: normal;
  line-height: 1.04;
  color: #0A102F;
}
.home-4_hero-content p {
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 1200px) {
  .home-4_hero-content p {
    max-width: 607px;
  }
}
.home-4_hero-content-stat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}
@media (min-width: 992px) {
  .home-4_hero-content-stat-wrapper {
    align-items: flex-start;
  }
}
.home-4_hero-content-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
@media (min-width: 576px) {
  .home-4_hero-content-stat {
    flex-direction: row;
  }
}
.home-4_hero-content-stat .divider {
  height: 39px;
  width: 2px;
  background-color: #0A102F;
  opacity: 0.1;
  border-radius: 100px;
  display: none;
}
@media (min-width: 576px) {
  .home-4_hero-content-stat .divider {
    display: block;
  }
}
.home-4_hero-content-stat p {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: -1px;
  margin-bottom: initial;
}
.home-4_hero-content-stat p span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
.home-4_hero-content__customer-count {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 480px) {
  .home-4_hero-content__customer-count {
    flex-direction: row;
  }
}
.home-4_hero-content__customer-count img {
  width: 146px;
}
.home-4_hero-content__customer-count p span {
  display: block;
}
.home-4_hero-content__rating-count {
  display: flex;
  align-items: center;
}
.home-4_hero-content__rating-count p span {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

/* ========== Home 4 : Feature Section Style ============= */
.home-4_feature-section__shape {
  position: absolute;
  left: 1%;
  top: -8%;
  width: 17%;
  z-index: -1;
  display: none;
}
@media (min-width: 992px) {
  .home-4_feature-section__shape {
    display: block;
  }
}
.home-4_feature-section__shape img {
  width: 100%;
}

/* ========== Home 4 : Content Section 1 Style ============= */
.home-4_content-section-1 {
  position: relative;
  z-index: 1;
}
.home-4_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
  --bs-gutter-x: 30px;
}
@media (min-width: 992px) {
  .home-4_content-section-1 .row--custom {
    align-items: center;
    justify-content: space-between;
  }
}
.home-4_content-section-1 .content-shape {
  position: absolute;
  right: 5%;
  bottom: -11%;
  z-index: -1;
  width: 15%;
  display: none;
}
@media (min-width: 768px) {
  .home-4_content-section-1 .content-shape {
    display: block;
  }
}
.home-4_content-section-1 .content-shape img {
  width: 100%;
}
@media (max-width: 991px) {
  .home-4_content-section-1 .content {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .home-4_content-section-1 .content {
    padding-left: 30px;
  }
}
@media (min-width: 1200px) {
  .home-4_content-section-1 .content {
    padding-left: 0;
  }
}
.home-4_content-section-1 .content-title {
  font-family: "DM Sans", sans-serif;
}
@media (min-width: 992px) {
  .home-4_content-section-1 .content-text-block p {
    max-width: 550px;
  }
}
.home-4_content-section-1 .content-title {
  font-weight: 700;
  letter-spacing: -1px;
}
.home-4_content-image-1 img {
  width: 100%;
}
@media (min-width: 1200px) {
  .home-4_content-image-1 {
    margin-right: 30px;
  }
}

/* ========== Home 4 : Content Section 2 Style ============= */
.home-4_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-4_content-section-2 .row--custom {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
.home-4_content-section-2 .content {
  align-items: center;
}
@media (min-width: 992px) {
  .home-4_content-section-2 .content {
    align-items: initial;
  }
}
@media (min-width: 992px) {
  .home-4_content-section-2 .content-text-block p {
    max-width: 585px;
  }
}
.home-4_content-section-2 .content-title {
  font-weight: 700;
  letter-spacing: -1px;
}
.home-4_content-image-2 img {
  width: 100%;
}
@media (min-width: 1200px) {
  .home-4_content-image-2 {
    margin-left: 30px;
  }
}

/* ========== Home 4 : Testimonial Section Style ============= */
.home-4_testimonial-section__shape {
  position: absolute;
  left: 2%;
  top: -21%;
  width: 20%;
  z-index: -1;
  display: none;
}
.home-4_testimonial-section__shape img {
  width: 100%;
}
@media (min-width: 992px) {
  .home-4_testimonial-section__shape {
    display: block;
  }
}
.home-4_testimonial-section__shape-2 {
  position: absolute;
  right: 4%;
  bottom: -30%;
  width: 11.5%;
  z-index: -1;
  display: none;
}
.home-4_testimonial-section__shape-2 img {
  width: 100%;
}
@media (min-width: 992px) {
  .home-4_testimonial-section__shape-2 {
    display: block;
  }
}
.home-4_testimonial-section .section-heading__title {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1px;
  color: #0A102F;
}

/* ========== Home 4 : Topic Section Style ============= */
.home-4_topic-section .ticker-02_wrapper .ticker-item {
  box-shadow: 1px 2px 3px 2px #0A102F;
  border-radius: 500px;
}

/* ========== Home 4 : FAQ Section Style ============= */
.home-4_faq-section .section-heading {
  text-align: center;
}
@media (min-width: 992px) {
  .home-4_faq-section .section-heading {
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .home-4_faq-section .section-heading p {
    max-width: 545px;
  }
}

/* ========== Home 4 : Cta ============= */
.cta-home-4 {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .cta-home-4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .cta-home-4 {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}
.cta-home-4__shape {
  position: absolute;
}
.cta-home-4__shape img {
  width: 100%;
}
.cta-home-4__shape.shape-1 {
  left: 0;
  top: -25%;
  max-width: 193px;
  width: 15%;
  min-width: 120px;
  display: none;
}
@media (min-width: 992px) {
  .cta-home-4__shape.shape-1 {
    display: block;
  }
}
.cta-home-4__shape.shape-2 {
  right: 0;
  bottom: -21%;
  max-width: 274px;
  width: 22%;
  min-width: 150px;
}
.cta-home-4 .cta-content-block {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  color: #0A102F;
}
@media (min-width: 992px) {
  .cta-home-4 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-4 .cta-content-block .heading-md {
  letter-spacing: -1px;
}
.cta-home-4-title {
  color: #0A102F;
}
.cta-home-4 .cta-button-group {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .cta-home-4 .cta-button-group {
    flex-direction: row;
    column-gap: 20px;
  }
}
.cta-home-4 .cta-button-group a {
  display: flex;
  align-items: stretch;
}

/* ========== Home 5 : Hero Section Style ============= */
.home-5_hero-section {
  padding-top: 110px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .home-5_hero-section {
    padding-top: 130px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-5_hero-section {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .home-5_hero-section {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1400px) {
  .home-5_hero-section {
    padding-top: 180px;
  }
}
.home-5_hero-section .row--custom {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-5_hero-section .row--custom {
    flex-direction: row-reverse;
  }
}
.home-5_hero-content__title {
  color: #1B1C1D;
  font-family: "General Sans", sans-serif;
}
.home-5_hero-image-block {
  max-width: 588.92px;
}
.home-5_hero-image img {
  width: 100%;
}
@media (min-width: 992px) {
  .home-5_hero-image {
    margin-right: -14%;
  }
}
.home-5_hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-5_hero-content {
    text-align: initial;
    align-items: initial;
    row-gap: 50px;
  }
}
.home-5_hero-content p {
  max-width: 673px;
  color: rgba(18, 18, 18, 0.8);
}
.home-5_hero-content-newsletter {
  position: relative;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.home-5_hero-content-review-text {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(18, 18, 18, 0.8);
  font-family: "Inter", sans-serif;
}
@media (min-width: 480px) {
  .home-5_hero-content-review-text {
    justify-content: center;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .home-5_hero-content-review-text {
    justify-content: initial;
  }
}

/* ========== Home 5 : Content Section 1 Style ============= */
.home-5_feature-section-wrapper {
  border-top: 1px solid rgba(27, 28, 29, 0.1);
  padding-top: 60px;
}
@media (min-width: 576px) {
  .home-5_feature-section-wrapper {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .home-5_feature-section-wrapper {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .home-5_feature-section-wrapper {
    padding-top: 120px;
  }
}

/* ========== Home 5 : Content Section 1 Style ============= */
.home-5_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-5_content-section-1 .row--custom {
    --bs-gutter-x: 35px;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .home-5_content-section-1 .content {
    align-items: center;
    text-align: center;
  }
}
.home-5_content-section-1 .content-text-block p {
  color: rgba(27, 28, 29, 0.8);
}
@media (min-width: 992px) {
  .home-5_content-section-1 .content-text-block p {
    max-width: 592px;
  }
}
.home-5_content-section-1 .content__stats-block {
  display: flex;
  justify-content: center;
  column-gap: 32px;
}
@media (min-width: 992px) {
  .home-5_content-section-1 .content__stats-block {
    justify-content: initial;
  }
}
.home-5_content-section-1 .content__stats-block .stat-single {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.home-5_content-section-1 .content__stats-block .stat-single__count {
  color: #FF9966;
  font-size: 32px;
  font-weight: 600;
  line-height: 0.94;
  font-family: "General Sans", sans-serif;
}
@media (min-width: 576px) {
  .home-5_content-section-1 .content__stats-block .stat-single__count {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .home-5_content-section-1 .content__stats-block .stat-single__count {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .home-5_content-section-1 .content__stats-block .stat-single__count {
    font-size: 66px;
  }
}
.home-5_content-section-1 .content__stats-block .stat-single__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
}
.home-5_content-image-1 {
  position: relative;
}
.home-5_content-image-1 img {
  width: 100%;
}
@media (min-width: 1200px) {
  .home-5_content-image-1 {
    margin-right: 60px;
  }
}
@media (min-width: 1400px) {
  .home-5_content-image-1 {
    margin-right: 85px;
  }
}
.home-5_content-image-1-shape {
  position: absolute;
  width: 49%;
  left: -25%;
  bottom: 11%;
}
@media (min-width: 1200px) {
  .home-5_content-image-1-shape {
    width: 33%;
    left: -16%;
    bottom: 11%;
  }
}

/* ========== Home 5 : Content Section 2 Style ============= */
.home-5_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-5_content-section-2 .row--custom {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .home-5_content-section-2 .content {
    align-items: center;
  }
}
.home-5_content-section-2 .content-text-block p {
  color: rgba(27, 28, 29, 0.8);
}
@media (min-width: 992px) {
  .home-5_content-section-2 .content-text-block p {
    max-width: 555px;
  }
}
.home-5_content-section-2 .content-title {
  font-weight: 600;
  font-family: "General Sans", sans-serif;
  line-height: 1.26;
  color: #1B1C1D;
}
.home-5_content-image-2 img {
  width: 100%;
}
@media (min-width: 1200px) {
  .home-5_content-image-2 {
    margin-left: 70px;
  }
}

/* ========== Home 5 : Pricing Section Style ============= */
.home-5_pricing-section .pricing-section-heading {
  max-width: 636px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-5_pricing-section .pricing-section-heading {
    margin-bottom: 60px;
  }
}
.home-5_pricing-section .pricing-section-heading .section-title {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-5_pricing-section .pricing-section-heading .section-title {
    margin-bottom: 50px;
  }
}
.home-5_pricing-section .row--custom {
  --bs-gutter-y: 30px;
}
.home-5_pricing-section .pricing-control {
  height: 50px;
}

/* ========== Home 5 : Testimonial Section Style ============= */
.home-5_testimonial-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-5_testimonial-section .row--custom {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
.home-5_testimonial-wrapper {
  border-top: 1px solid rgba(27, 28, 29, 0.1);
  padding-top: 60px;
}
@media (min-width: 576px) {
  .home-5_testimonial-wrapper {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .home-5_testimonial-wrapper {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .home-5_testimonial-wrapper {
    padding-top: 120px;
  }
}

.testimonial-widget-3 {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  text-align: center;
}
@media (min-width: 992px) {
  .testimonial-widget-3 {
    text-align: initial;
  }
}
.testimonial-widget-3__text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}
@media (min-width: 992px) {
  .testimonial-widget-3__text-block {
    align-items: initial;
  }
}
.testimonial-widget-3 p {
  font-family: var(--font-family-base);
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  color: #1B1C1D;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .testimonial-widget-3 p {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .testimonial-widget-3 p {
    font-size: 24px;
  }
}
.testimonial-widget-3__user-name {
  font-family: var(--font-family-base);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #1B1C1D;
  padding-bottom: 5px;
}
@media (min-width: 576px) {
  .testimonial-widget-3__user-name {
    font-size: 20px;
  }
}
.testimonial-widget-3__user-position {
  font-family: var(--font-family-base);
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: #1B1C1D;
}
@media (min-width: 576px) {
  .testimonial-widget-3__user-position {
    font-size: 18px;
  }
}
.testimonial-widget-3__image-block {
  position: relative;
  overflow: hidden;
  margin-right: 24px;
  margin-left: 24px;
}
.testimonial-widget-3__image-block img {
  width: 100%;
}
.testimonial-widget-3__image {
  border-radius: 61% 39% 59% 41%/53% 48% 52% 47%;
}
.testimonial-widget-3__image img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
.testimonial-widget-3__image.absolute-center {
  width: 95%;
  top: 48%;
  left: 50%;
}

/* ========== Home 5 : Service Section Style ============= */
.home-5_service-section {
  position: relative;
  background-color: #1B1C1D;
  color: #fff;
}
.home-5_service-section-shape {
  display: none;
  position: absolute;
  right: 12%;
  top: 16%;
}
@media (min-width: 992px) {
  .home-5_service-section-shape {
    display: block;
  }
}

/* ========== Home 5 : Team Section Style ============= */
.home-5_team-section {
  background: #FDFBF9;
}
.home-5_team-section .team-card-row {
  overflow: hidden;
  --bs-gutter-y:24px;
  --bs-gutter-x:0;
}
@media (min-width: 768px) {
  .home-5_team-section .team-card-row {
    --bs-gutter-y:0;
    border-radius: 5px;
  }
}
.home-5_team-section .btn-primary-l05 {
  --btn-fill-bg: #1B1C1D;
  --btn-fill-color: #fff;
}

/* ========== Home 5 : Process Section Style ============= */
@media (min-width: 1200px) {
  .home-5_process-section .section-heading {
    padding: 0 22px;
  }
}
.home-5_process-section .section-heading__title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
}
.home-5_process-devider {
  border-top: 1px solid rgba(18, 18, 18, 0.1);
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .home-5_process-devider {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-5_process-devider {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .home-5_process-devider {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/*=========== Home 5 : Cta ==========*/
.cta-home-5 {
  background: #1B1C1D;
}
.cta-home-5 .cta-home-5-wrapper {
  background-color: #FF9966;
  border-radius: 5px;
  padding: 30px;
}
@media (min-width: 992px) {
  .cta-home-5 .cta-home-5-wrapper {
    padding: 50px;
  }
}
.cta-home-5 .row--custom {
  justify-content: center;
  row-gap: 30px;
}
@media (min-width: 768px) {
  .cta-home-5 .row--custom {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .cta-home-5 .row--custom {
    justify-content: space-between;
  }
}
.cta-home-5 .cta-text-block {
  color: #1B1C1D;
  text-align: center;
}
@media (min-width: 992px) {
  .cta-home-5 .cta-text-block {
    text-align: initial;
  }
}
.cta-home-5 .cta-text-block .heading-md {
  margin-bottom: 0;
}
.cta-home-5 .cta-home-5-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) {
  .cta-home-5 .cta-home-5-button {
    justify-content: end;
  }
}

/* ========== Home 6 : Hero Section  Style ============= */
.home-6_hero-section {
  padding-top: 100px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 576px) {
  .home-6_hero-section {
    padding-top: 120px;
  }
}
@media (min-width: 992px) {
  .home-6_hero-section {
    padding-top: 150px;
  }
}
@media (min-width: 1200px) {
  .home-6_hero-section {
    padding-top: 180px;
  }
}
.home-6_hero-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
  text-align: center;
}
@media (min-width: 768px) {
  .home-6_hero-section .row--custom {
    --bs-gutter-y: 60px;
  }
}
@media (min-width: 992px) {
  .home-6_hero-section .row--custom {
    --bs-gutter-y: 80px;
  }
}
.home-6_hero-section .hero-shape-1 {
  position: absolute;
  top: 14%;
  right: 10%;
  z-index: -1;
  width: 3.5%;
}
@media (min-width: 992px) {
  .home-6_hero-section .hero-shape-1 {
    top: 22%;
  }
}
.home-6_hero-section .hero-shape-2 {
  position: absolute;
  top: 43%;
  left: 10%;
  z-index: -1;
  width: 4.25%;
}
.home-6_hero-section .hero-shape-3 {
  position: absolute;
  bottom: 5%;
  right: 10%;
  z-index: -1;
  width: 3.25%;
}
.home-6_hero-shape {
  display: none;
}
@media (min-width: 768px) {
  .home-6_hero-shape {
    display: block;
  }
}
.home-6_hero-content__button-text {
  font-size: 16px;
  line-height: 1.3;
  margin-top: 28px;
}
@media (min-width: 992px) {
  .home-6_hero-content__button-text {
    margin-top: 37px;
  }
}
.home-6_hero-content__button-text .dot {
  width: 6px;
  height: 6px;
  border-radius: 500px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 3px;
}
@media (max-width: 479px) {
  .home-6_hero-content__button-text .dot {
    display: block;
    background-color: transparent;
  }
}
.home-6_hero-content-block {
  max-width: 930px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 768px) {
  .home-6_hero-content-block {
    row-gap: 40px;
  }
}
@media (min-width: 992px) {
  .home-6_hero-content-block {
    row-gap: 54px;
  }
}
.home-6_hero-content-block p {
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 992px) {
  .home-6_hero-content-block p {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 1200px) {
  .home-6_hero-content-block p {
    padding-left: 110px;
    padding-right: 110px;
  }
}
.home-6_hero-content-block .hero-content__title-color-bg {
  background-color: #BEF8FC;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 992px) {
  .home-6_hero-content-block .hero-content__title {
    margin-bottom: 26px;
  }
}
.home-6_hero-content-block .hero-content__title > span {
  position: relative;
}
.home-6_hero-content-block .hero-content__title > span::after {
  position: absolute;
  content: "";
  top: -18%;
  left: 0;
  width: 104%;
  /* background-image: url(/image/home-6/shape.svg); */
  height: 100%;
  background-repeat: no-repeat;
  background-size: 5%;
  background-position: 100% 23%;
  display: none;
}
@media (min-width: 480px) {
  .home-6_hero-content-block .hero-content__title > span::after {
    display: block;
  }
}
@media (min-width: 1400px) {
  .home-6_hero-content-block .hero-content__title > span::after {
    background-position: 97% 23%;
  }
}
.home-6_hero-button-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.home-6_hero-button-block .btn {
  width: 100%;
}
@media (min-width: 480px) {
  .home-6_hero-button-block {
    flex-direction: row;
  }
  .home-6_hero-button-block .btn {
    width: initial;
  }
}
.home-6_hero-image-block img {
  width: 100%;
}

/* ========== Home 6 : Content Section 1 Style ============= */
.home-6_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-6_content-section-1 .row--custom {
    --bs-gutter-x: 35px;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .home-6_content-section-1 .row--custom {
    align-items: center;
  }
}
.home-6_content-section-1 .content {
  align-items: center;
  text-align: center;
}
@media (min-width: 992px) {
  .home-6_content-section-1 .content {
    align-items: initial;
    text-align: initial;
    padding-left: 30px;
  }
}
@media (min-width: 1200px) {
  .home-6_content-section-1 .content {
    padding-left: initial;
  }
}
.home-6_content-section-1 .content-text-block .content-title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
}
@media (min-width: 992px) {
  .home-6_content-section-1 .content-text-block p {
    max-width: 592px;
  }
}
.home-6_content-section-1 .content__stats-block {
  display: flex;
  justify-content: center;
  column-gap: 32px;
}
@media (min-width: 992px) {
  .home-6_content-section-1 .content__stats-block {
    justify-content: initial;
  }
}
.home-6_content-section-1 .content__stats-block .stat-single {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.home-6_content-section-1 .content__stats-block .stat-single__count {
  color: #3147FF;
  font-family: "General Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 0.94;
}
@media (min-width: 576px) {
  .home-6_content-section-1 .content__stats-block .stat-single__count {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .home-6_content-section-1 .content__stats-block .stat-single__count {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .home-6_content-section-1 .content__stats-block .stat-single__count {
    font-size: 66px;
  }
}
.home-6_content-section-1 .content__stats-block .stat-single__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.67;
}
.home-6_content-image-1-block {
  position: relative;
}
@media (min-width: 992px) {
  .home-6_content-image-1 {
    max-width: 561px;
  }
}
.home-6_content-image-1 img {
  width: 100%;
}
.home-6_content-image-1-card {
  position: absolute;
  width: 41%;
  left: -6%;
  bottom: -4%;
  box-shadow: 0px 0.890836px 2.67251px 0.890836px rgba(60, 64, 67, 0.15);
  filter: drop-shadow(3.20816px 3.20816px 16.0408px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0.890836px 1.78167px rgba(60, 64, 67, 0.3));
}
.home-6_content-image-1-card img {
  width: 100%;
  border-radius: 5px;
}

/* ========== Home 6 : Content Section 2 Style ============= */
.home-6_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-6_content-section-2 .row--custom {
    --bs-gutter-x: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .home-6_content-section-2 .row--custom {
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .home-6_content-section-2 .row--custom {
    --bs-gutter-x: 55px;
  }
}
@media (max-width: 991px) {
  .home-6_content-section-2 .content {
    align-items: center;
    text-align: center;
  }
}
.home-6_content-section-2 .content-text-block .content-title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
}
@media (min-width: 992px) {
  .home-6_content-section-2 .content-text-block p {
    max-width: 592px;
  }
}
.home-6_content-image-2-block {
  position: relative;
}
@media (min-width: 992px) {
  .home-6_content-image-2-block {
    max-width: 561px;
  }
}
.home-6_content-image-2 {
  margin-left: 12%;
}
.home-6_content-image-2 img {
  width: 100%;
}
.home-6_content-image-2-card {
  position: absolute;
  width: 29.5%;
  left: 3%;
  bottom: 27%;
  box-shadow: 3.08333px 3.08333px 15.4167px rgba(0, 0, 0, 0.05), 0px 0.856176px 2.56853px 0.856176px rgba(60, 64, 67, 0.15), 0px 0.856176px 1.71235px rgba(60, 64, 67, 0.3);
}
.home-6_content-image-2-card img {
  width: 100%;
  border-radius: 5px;
}

/* ========== Home 6 : Brand Section Style ============= */
.home-6_brands-wrapper-inner {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 576px) {
  .home-6_brands-wrapper-inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .home-6_brands-wrapper-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .home-6_brands-wrapper-inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.home-6_brand-title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: rgba(10, 16, 47, 0.7);
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .home-6_brand-title {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .home-6_brand-title {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .home-6_brand-title {
    margin-bottom: 40px;
  }
}
@media (min-width: 1400px) {
  .home-6_brand-title {
    margin-bottom: 60px;
  }
}
.home-6_brands-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1200px) {
  .home-6_brands-wrapper {
    justify-content: space-between;
  }
}
.home-6_single-brand {
  display: flex;
  justify-content: center;
  width: 50%;
}
@media (min-width: 480px) {
  .home-6_single-brand {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .home-6_single-brand {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .home-6_single-brand {
    width: auto;
  }
}

/* ========== Home 6 : process Section Style ============= */
.home-6_process-section {
  position: relative;
  background-color: #0A102F;
  color: #fff;
}
.home-6_process-section .process-shape {
  position: absolute;
  top: 200px;
  right: 25px;
  display: inline;
}
.home-6_process-section .row--custom {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 24px;
}

/* ========== Home 6 : process Section Style ============= */
.home-6_service-section {
  background: #FAFAFA;
}
.home-6_service-section .section-heading__title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
}
.home-6_service-section .service-card:hover {
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
}

/* ========== Home 6 : Testimonial Section Style ============= */
.home-6_testimonial {
  background: #FAFAFA;
}
.home-6_testimonial .section-heading {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .home-6_testimonial .section-heading {
    margin-bottom: 80px;
  }
}
.home-6_testimonial .section-heading__title {
  font-family: "General Sans", sans-serif;
  font-weight: 600;
}
.home-6_testimonial .row--custom {
  justify-content: space-between;
  align-items: center;
  --bs-gutter-y: 30px;
}

/* ========== Home 6 : FAQ Section Style ============= */
@media (min-width: 992px) {
  .home-6_faq-section .section-heading {
    padding-right: 30px;
  }
}

/*=========== Home 6 : Cta ==========*/
.cta-home-6 {
  background-color: var(--l6-primary);
}
@media (min-width: 576px) {
  .cta-home-6 {
    background-color: transparent;
  }
}
.cta-home-6 .cta-home-6-wrapper {
  background-color: var(--l6-primary);
  border-radius: 5px;
  padding-top: 50px;
  padding-bottom: 0;
  position: relative;
}
@media (min-width: 576px) {
  .cta-home-6 .cta-home-6-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .cta-home-6 .cta-home-6-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.cta-home-6-shape img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 24%;
}
.cta-home-6 .cta-content-block {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
}
@media (min-width: 992px) {
  .cta-home-6 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-6 .cta-button-group {
  display: flex;
  gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-6 .cta-button-group {
    gap: 20px;
  }
}
.cta-home-6 .cta-button-group a {
  display: flex;
  align-items: stretch;
}
.cta-home-6 .cta-button-group a img {
  width: 100%;
}

/* ========== Home 7 : Hero Section Style ============= */
.home-7_hero-section {
  padding-top: 100px;
  padding-bottom: 60px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: #FAF9F5;
}
@media (min-width: 576px) {
  .home-7_hero-section {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .home-7_hero-section {
    padding-top: 120px;
  }
}
@media (min-width: 992px) {
  .home-7_hero-section {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .home-7_hero-section {
    padding-top: 170px;
  }
}
@media (min-width: 1400px) {
  .home-7_hero-section {
    padding-top: 190px;
  }
}
.home-7_hero-section .row--custom {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .home-7_hero-section .row--custom {
    --bs-gutter-x: 105px;
    flex-direction: row-reverse;
  }
}
.home-7_hero-section .hero-shape-1 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: -1;
}
.home-7_hero-section .hero-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-7_hero-image {
  position: relative;
  max-width: 450px;
}
.home-7_hero-image img {
  width: 100%;
}
.home-7_hero-image-shape {
  position: absolute;
  top: 1%;
  right: -4%;
  width: 33.4%;
}
.home-7_hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .home-7_hero-content {
    max-width: 660px;
    text-align: initial;
    align-items: initial;
    row-gap: 34px;
  }
}
.home-7_hero-content .hero-content__title {
  margin-bottom: 24px;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: -0.01em;
  color: #191919;
  font-family: "Cabin", sans-serif;
}
.home-7_hero-content p {
  color: rgba(25, 25, 25, 0.8);
}
@media (min-width: 1200px) {
  .home-7_hero-content p {
    max-width: 560px;
  }
}
.home-7_hero-content-stat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}
@media (min-width: 992px) {
  .home-7_hero-content-stat-wrapper {
    align-items: flex-start;
  }
}
.home-7_hero-content-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
@media (min-width: 576px) {
  .home-7_hero-content-stat {
    flex-direction: row;
  }
}
.home-7_hero-content-stat .divider {
  height: 39px;
  width: 2px;
  background-color: #0A102F;
  opacity: 0.1;
  border-radius: 100px;
  display: none;
}
@media (min-width: 576px) {
  .home-7_hero-content-stat .divider {
    display: block;
  }
}
.home-7_hero-content-stat p {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: -1px;
  margin-bottom: initial;
}
.home-7_hero-content-stat p span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
.home-7_hero-content__customer-count {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 480px) {
  .home-7_hero-content__customer-count {
    flex-direction: row;
  }
}
.home-7_hero-content__customer-count img {
  width: 146px;
}
.home-7_hero-content__customer-count p span {
  display: block;
}
.home-7_hero-content__rating-count {
  display: flex;
  align-items: center;
}
.home-7_hero-content__rating-count p span {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

/* ========== Home 7 : Fact Section ============= */
.home-7_fact-section {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .home-7_fact-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.home-7_fact-section .row--custom {
  --bs-gutter-y: 30px;
  --bs-gutter-x: 40px;
  justify-content: center;
}
@media (min-width: 768px) {
  .home-7_fact-section .row--custom {
    justify-content: initial;
  }
}
@media (min-width: 992px) {
  .home-7_fact-section .row--custom {
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1400px) {
  .home-7_fact-section .row--custom {
    --bs-gutter-x: 60px;
  }
}
.home-7_fact-section .row--custom-inner {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
}
.home-7_fact-navigation-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 160px;
}
@media (min-width: 768px) {
  .home-7_fact-navigation-image {
    right: -30%;
    top: -5%;
  }
}
@media (min-width: 992px) {
  .home-7_fact-navigation-image {
    right: 0;
    top: 0;
  }
}
.home-7_fact-navigation-image .fact-round-image {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ========== Home 7 : Content Section 1 Style ============= */
.home-7_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-7_content-section-1 .row--custom {
    --bs-gutter-x: 30px;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1400px) {
  .home-7_content-section-1 .row--custom {
    --bs-gutter-x: 55px;
  }
}
@media (max-width: 991px) {
  .home-7_content-section-1 .content {
    align-items: center;
    text-align: center;
  }
}
.home-7_content-section-1 .content-text-block .content-title {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}
.home-7_content-section-1 .content-text-block p {
  color: rgba(25, 25, 25, 0.8);
}
@media (min-width: 992px) {
  .home-7_content-section-1 .content-text-block p {
    max-width: 592px;
  }
}
@media (min-width: 992px) {
  .home-7_content-image-1 {
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .home-7_content-image-1 {
    margin-right: 45px;
  }
}
@media (min-width: 1400px) {
  .home-7_content-image-1 {
    margin-right: 70px;
  }
}
.home-7_content-image-1 img {
  width: 100%;
}

/* ========== Home 7 : Content Section 2 Style ============= */
.home-7_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-7_content-section-2 .row--custom {
    flex-direction: row-reverse;
    --bs-gutter-x: 30px;
    align-items: center;
    justify-content: space-between;
  }
}
.home-7_content-section-2 .content {
  max-width: 580px;
}
@media (max-width: 991px) {
  .home-7_content-section-2 .content {
    align-items: center;
    text-align: center;
  }
}
.home-7_content-section-2 .content-text-block p {
  color: rgba(25, 25, 25, 0.8);
}
@media (min-width: 992px) {
  .home-7_content-section-2 .content-text-block p {
    max-width: 530px;
  }
}
.home-7_content-section-2 .content-list img {
  display: none;
}
@media (min-width: 480px) {
  .home-7_content-section-2 .content-list img {
    display: block;
  }
}
.home-7_content-image-2 img {
  width: 100%;
}

/* ========== Home 7 : Testimonial Section Style ============= */
.home-7_testimonial-section {
  background-color: #FAF9F5;
}
.home-7_testimonial-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-7_testimonial-section .row--custom {
    --bs-gutter-x: 30px;
    align-items: center;
  }
}
.home-7_testimonial-section .testimonial-content {
  text-align: center;
}
@media (min-width: 992px) {
  .home-7_testimonial-section .testimonial-content {
    padding-left: 30px;
    text-align: initial;
  }
}
@media (min-width: 1200px) {
  .home-7_testimonial-section .testimonial-content {
    padding-left: initial;
  }
}
.home-7_testimonial-section .testimonial-content p {
  color: rgba(25, 25, 25, 0.8);
}

/* ========== Home 7 : Brand Section Style ============= */
.home-7_brands-wrapper-inner {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .home-7_brands-wrapper-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-7_brands-wrapper-inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.home-7_brand-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.4;
  color: rgba(10, 16, 47, 0.7);
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .home-7_brand-title {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .home-7_brand-title {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .home-7_brand-title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1400px) {
  .home-7_brand-title {
    margin-bottom: 80px;
  }
}
.home-7_brands-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-7_brands-wrapper {
    justify-content: space-between;
  }
}
.home-7_single-brand {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}
@media (min-width: 480px) {
  .home-7_single-brand {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .home-7_single-brand {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .home-7_single-brand {
    width: auto;
  }
}
.home-7_single-brand img {
  width: 100%;
  max-width: auto;
  filter: grayscale(100%);
}
@media (min-width: 1200px) {
  .home-7_single-brand img {
    width: auto;
    max-width: 100%;
  }
}

/*=========== Home 7 : Cta ==========*/
.cta-home-7 {
  background-color: #A259FF;
  border-radius: 30px 30px 0 0;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .cta-home-7 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.cta-home-7 .cta-home-7-wrapper {
  border-radius: 5px;
  padding-top: 50px;
  padding-bottom: 0;
  position: relative;
}
@media (min-width: 576px) {
  .cta-home-7 .cta-home-7-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.cta-home-7 .cta-content-block {
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
}
@media (min-width: 992px) {
  .cta-home-7 .cta-content-block {
    max-width: 540px;
  }
}
@media (min-width: 1200px) {
  .cta-home-7 .cta-content-block {
    max-width: 580px;
  }
}
@media (min-width: 992px) {
  .cta-home-7 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-7 .cta-form-wrapper {
  max-width: 450px;
  width: 100%;
  margin: auto;
}
.cta-home-7 .cta-form__input-block {
  position: relative;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 3px;
  padding-left: 30px;
  border-radius: 500px;
  width: 100%;
  row-gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-7 .cta-form__input-block {
    border: 1px solid #191919;
  }
}
@media (min-width: 576px) {
  .cta-home-7 .cta-form__input-block {
    flex-direction: row;
    background-color: #fff;
  }
}
.cta-home-7 .cta-form__input-block input {
  padding: 12px;
  border-radius: 500px;
  border: none;
  outline: none;
  width: 100%;
  padding-left: 24px;
}
@media (min-width: 576px) {
  .cta-home-7 .cta-form__input-block input {
    padding-left: initial;
    background: none;
    border-radius: 500px 0 0 500px;
  }
}
.cta-home-7 .cta-form__input-block .btn-masco {
  --btn-spacing-y: 13px;
  white-space: nowrap;
}
.cta-home-7 .cta-form__input-block .btn-masco:hover {
  --btn-bg: #A259FF;
}
.cta-home-7 .cta-button-group {
  display: flex;
  gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-7 .cta-button-group {
    gap: 20px;
  }
}
.cta-home-7 .cta-button-group a {
  display: flex;
  align-items: stretch;
}
.cta-home-7 .cta-button-group a img {
  width: 100%;
}

/* ========== Home 7 : Pricing Section Style ============= */
.home-7_pricing-section {
  background: #191919;
}
@media (min-width: 768px) {
  .home-7_pricing-section {
    border-radius: 30px;
  }
}
.home-7_pricing-section .row--custom {
  --bs-gutter-y: 30px;
}
.home-7_pricing-section .section-heading {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .home-7_pricing-section .section-heading {
    margin-bottom: 60px;
  }
}
.home-7_pricing-section .section-heading__title {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-7_pricing-section .section-heading__title {
    margin-bottom: 50px;
  }
}
.home-7_pricing-control-block {
  text-align: center;
}

/* ========== Home 8 : Hero Section  Style ============= */
.home-8_hero-section {
  padding-top: 180px;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  z-index: 1;
}
@media (min-width: 576px) {
  .home-8_hero-section {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-8_hero-section {
    padding-bottom: initial;
  }
}
.home-8_hero-section .particles {
  display: none;
}
@media (min-width: 768px) {
  .home-8_hero-section .particles {
    display: block;
  }
}
.home-8_hero-section .particles img {
  position: absolute;
  z-index: -1;
}
.home-8_hero-section .particle-1 {
  right: 21%;
  top: 28%;
}
.home-8_hero-section .particle-2 {
  right: 16%;
  top: 42%;
}
.home-8_hero-section .particle-3 {
  right: 21.5%;
  bottom: 46%;
}
.home-8_hero-section .section-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: none;
}
@media (min-width: 992px) {
  .home-8_hero-section .section-divider {
    display: block;
  }
}
.home-8_hero-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
  text-align: center;
}
@media (min-width: 568px) {
  .home-8_hero-section .row--custom {
    --bs-gutter-y: 60px;
  }
}
.home-8_hero-content-block {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .home-8_hero-content-block {
    row-gap: 50px;
  }
}
.home-8_hero-text-block {
  position: relative;
}
.home-8_hero-text-block p {
  color: rgba(var(--black-2), 0.8);
}
@media (min-width: 992px) {
  .home-8_hero-text-block p {
    max-width: 678px;
    margin: 0 auto;
  }
}
.home-8_hero-content-shape {
  position: absolute;
  width: 29.8%;
  left: -27%;
  top: -12%;
  display: none;
}
.home-8_hero-content-shape img {
  width: 100%;
}
@media (min-width: 768px) {
  .home-8_hero-content-shape {
    display: block;
  }
}
.home-8_hero-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 18px;
  gap: 20px;
}
@media (max-width: 479px) {
  .home-8_hero-button-group .btn {
    width: 100%;
    --bs-btn-padding-y: 10px;
  }
}
.home-8_hero-image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .home-8_hero-image-wrapper {
    position: relative;
    top: 16%;
    column-gap: 40px;
  }
}
@media (min-width: 1400px) {
  .home-8_hero-image-wrapper {
    column-gap: 52px;
  }
}
.home-8_hero-image-wrapper .single-image {
  width: 80%;
}
.home-8_hero-image-wrapper .single-image img {
  width: 100%;
}
@media (min-width: 480px) {
  .home-8_hero-image-wrapper .single-image {
    width: 46%;
  }
}
@media (min-width: 576px) {
  .home-8_hero-image-wrapper .single-image {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .home-8_hero-image-wrapper .single-image {
    width: 35%;
  }
}
@media (min-width: 992px) {
  .home-8_hero-image-wrapper .single-image {
    width: 21%;
  }
  .home-8_hero-image-wrapper .single-image:nth-child(2) {
    margin-top: 4%;
  }
  .home-8_hero-image-wrapper .single-image:nth-child(3) {
    margin-top: 4%;
  }
  .home-8_hero-image-wrapper .single-image:nth-child(odd) {
    transform: rotate(5deg);
  }
  .home-8_hero-image-wrapper .single-image:nth-child(even) {
    transform: rotate(-5deg);
  }
}
@media (min-width: 1200px) {
  .home-8_hero-image-wrapper .single-image {
    width: 22%;
  }
}
@media (min-width: 1400px) {
  .home-8_hero-image-wrapper .single-image {
    width: initial;
  }
}

/* ========== Home 8 : Service Section Style ============= */
.home-8_stats-section {
  position: relative;
  bottom: 1px;
  background: #121212;
}
.home-8_stats-section .row--custom {
  justify-content: center;
  --gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-8_stats-section .row--custom {
    align-items: center;
  }
}
.home-8_stats-section .stats-wrapper .stats-single-row {
  --bs-gutter-y: 30px;
  justify-content: center;
}
.home-8_stats-section .stats-wrapper .stats-single {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1200px) {
  .home-8_stats-section .stats-wrapper .stats-single {
    padding-right: 22px;
    padding-left: 22px;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .home-8_stats-section .stats-wrapper .stats-single {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.home-8_stats-section .stats-wrapper .stats-single::after {
  content: "";
  width: 2px;
  height: 39%;
  background-color: #A259FF;
  position: absolute;
  right: 0%;
  top: 55%;
  transform: translateY(-50%);
  display: none;
}
@media (min-width: 576px) {
  .home-8_stats-section .stats-wrapper .stats-single::after {
    display: block;
  }
}
.home-8_stats-section .stats-wrapper .stats-single:first-child {
  left: 0px;
}
.home-8_stats-section .stats-wrapper .stats-single:last-child {
  padding-right: 0px;
}
.home-8_stats-section .stats-wrapper .stats-single:last-child::after {
  display: none;
}
.home-8_stats-section .stats-wrapper .stats-single__count {
  color: #FDFBF9;
  font-size: 32px;
  line-height: 1.14;
  font-weight: 700;
  margin-bottom: 8px;
}
@media (min-width: 576px) {
  .home-8_stats-section .stats-wrapper .stats-single__count {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .home-8_stats-section .stats-wrapper .stats-single__count {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .home-8_stats-section .stats-wrapper .stats-single__count {
    font-size: 56px;
  }
}
.home-8_stats-section .stats-wrapper .stats-single p {
  color: rgba(253, 251, 249, 0.6);
  font-size: 14px;
}
@media (min-width: 576px) {
  .home-8_stats-section .stats-wrapper .stats-single p {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .home-8_stats-section .stats-wrapper .stats-single p {
    font-size: 18px;
  }
}
.home-8_stats-title {
  color: #FDFBF9;
  text-align: center;
}
@media (min-width: 992px) {
  .home-8_stats-title {
    text-align: initial;
  }
}

/* ========== Home 8 : Content Section 1 Style ============= */
.home-8_content-section-1 {
  position: relative;
}
.home-8_content-section-1 .particle-1 {
  position: absolute;
  right: 13%;
  bottom: 40%;
}
.home-8_content-section-1 .particle-2 {
  position: absolute;
  right: 11%;
  bottom: 14%;
}
.home-8_content-section-1 .particle-3 {
  position: absolute;
  right: 19.5%;
  bottom: 6%;
}
.home-8_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-8_content-section-1 .row--custom {
    --bs-gutter-x: 30px;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .home-8_content-section-1 .content {
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .home-8_content-section-1 .content-text-block p {
    max-width: 500px;
  }
}
.home-8_content-section-1 .content-text-block .content-divider {
  margin-top: 20px;
}
.home-8_content-image-1-block {
  position: relative;
}
@media (min-width: 992px) {
  .home-8_content-image-1-block {
    max-width: 561px;
  }
}
.home-8_content-image-1 img {
  width: 100%;
}
.home-8_content-image-1-card {
  position: absolute;
  width: 58%;
  right: -25%;
  bottom: 6%;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
}
.home-8_content-image-1-card img {
  width: 100%;
  border-radius: 5px;
}

/* ========== Home 8 : Content Section 2 Style ============= */
.home-8_content-section-2 {
  position: relative;
}
.home-8_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-8_content-section-2 .row--custom {
    --bs-gutter-x: 25px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .home-8_content-section-2 .content {
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .home-8_content-section-2 .content-text-block p {
    max-width: 500px;
  }
}
.home-8_content-section-2 .content .content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
@media (min-width: 992px) {
  .home-8_content-section-2 .content .content-list {
    justify-content: initial;
  }
}
.home-8_content-section-2 .content .content-list img {
  display: none;
}
@media (min-width: 576px) {
  .home-8_content-section-2 .content .content-list img {
    display: block;
  }
}
.home-8_content-image-2-block {
  position: relative;
}
@media (min-width: 992px) {
  .home-8_content-image-2-block {
    max-width: 561px;
  }
}
.home-8_content-image-2 img {
  width: 100%;
}
.home-8_content-image-2-card {
  position: absolute;
  width: 54%;
  left: -25%;
  bottom: 5%;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
}
.home-8_content-image-2-card img {
  width: 100%;
  border-radius: 5px;
}

/* ========== Home 8 : Team Section Style ============= */
.home-8_team-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-8_team-section .row--custom {
    --bs-gutter-x: 30px;
    justify-content: space-between;
    align-items: center;
  }
}
.home-8_team-section .content .content-list {
  margin-top: 20px;
  text-align: initial;
}
@media (min-width: 768px) {
  .home-8_team-section .content .content-list {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .home-8_team-section .content .content-list {
    align-items: initial;
    margin-top: 30px;
  }
}

/* ========== Home 8 : Service Section Style ============= */
.home-8_service-section {
  position: relative;
  background: var(--black-2);
  z-index: 1;
}
.home-8_service-section .service-shape {
  z-index: -1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.home-8_service-section .particles {
  display: none;
}
@media (min-width: 992px) {
  .home-8_service-section .particles {
    display: block;
  }
}
.home-8_service-section .particles img {
  position: absolute;
  z-index: -1;
}
.home-8_service-section .particle-1 {
  left: 26%;
  top: 26%;
}
.home-8_service-section .particle-2 {
  left: 16%;
  top: 19%;
}
.home-8_service-section .particle-3 {
  left: 13%;
  top: 41%;
}
.home-8_service-section .section-heading p {
  color: rgba(253, 251, 249, 0.8);
  line-height: 1.67;
}
@media (min-width: 992px) {
  .home-8_service-section .section-heading p {
    max-width: 595px;
    margin: 0 auto;
  }
}
.home-8_service-section .row--service-list {
  justify-content: center;
}
@media (min-width: 992px) {
  .home-8_service-section .row--service-list {
    --bs-gutter-x: 50px;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .home-8_service-section .row--service-list {
    --bs-gutter-x: 80px;
  }
}
@media (min-width: 1400px) {
  .home-8_service-section .row--service-list {
    --bs-gutter-x: 140px;
  }
}
.home-8_service-list {
  color: #FDFBF9;
  margin-bottom: 0;
}
.home-8_service-list li {
  border-bottom: 1px solid rgba(253, 251, 249, 0.1);
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (min-width: 992px) {
  .home-8_service-list li:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.home-8_service-list__item a {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  letter-spacing: 0em;
  font-family: "Public Sans", sans-serif;
  line-height: 1.23;
  font-size: 18px;
  color: #FDFBF9;
}
@media (min-width: 768px) {
  .home-8_service-list__item a {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .home-8_service-list__item a {
    font-size: 26px;
  }
}
.home-8_service-list__item a:hover {
  color: #C1FF00;
}
.home-8_service-list__item a i {
  transform: rotate(335deg);
}

/* ========== Home 8 : Content Section 3 Style ============= */
.home-8_content-section-3 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-8_content-section-3 .row--custom {
    --bs-gutter-x: 40px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}
@media (max-width: 991px) {
  .home-8_content-section-3 .content {
    align-items: center;
    text-align: center;
  }
}
.home-8_content-image-3 img {
  width: 100%;
}

/* ========== Home 8 : Video Section Style ============= */
.home-8_video-section {
  position: relative;
  background: #FDFBF9;
  z-index: 1;
}
.home-8_video-section .section-heading__title {
  font-size: 32px;
  line-height: 1.14;
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
}
@media (min-width: 576px) {
  .home-8_video-section .section-heading__title {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .home-8_video-section .section-heading__title {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .home-8_video-section .section-heading__title {
    font-size: 56px;
  }
}
.home-8_video-shape {
  position: absolute;
  left: 11%;
  top: -13%;
  width: 11%;
  z-index: -1;
  display: none;
}
@media (min-width: 768px) {
  .home-8_video-shape {
    display: block;
  }
}
.home-8_video-shape img {
  width: 100%;
}
.home-8_hero-video-block {
  position: relative;
}
.home-8_hero-video-block img {
  width: 100%;
}

/* ========== Home 8 : Blog Section Style ============= */
@media (min-width: 992px) {
  .home-8_blog-section p {
    max-width: 595px;
    margin: 0 auto;
  }
}

/* ========== Home 8 : Pricing Section Style ============= */
.home-8_pricing-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.home-8_pricing-section .particles {
  display: none;
}
@media (min-width: 768px) {
  .home-8_pricing-section .particles {
    display: block;
  }
}
.home-8_pricing-section .particles img {
  position: absolute;
  z-index: -1;
}
.home-8_pricing-section .particle-1 {
  left: 15%;
  bottom: 23%;
}
.home-8_pricing-section .particle-2 {
  left: 18%;
  bottom: 15%;
}
.home-8_pricing-section .particle-3 {
  left: 15%;
  bottom: 3%;
}
@media (min-width: 992px) {
  .home-8_pricing-section .section-heading p {
    max-width: 600px;
    margin: 0 auto;
  }
}
.home-8_pricing-shape {
  position: absolute;
  right: 12%;
  top: 6%;
  width: 11%;
  display: none;
}
.home-8_pricing-shape img {
  width: 100%;
}
@media (min-width: 768px) {
  .home-8_pricing-shape {
    display: block;
  }
}
.home-8_pricing-control-block {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-8_pricing-control-block {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/*=========== Home 8 : Cta ==========*/
.cta-home-8 {
  background-color: #A259FF;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .cta-home-8 {
    background-color: transparent;
    padding-bottom: 0;
  }
}
.cta-home-8 .cta-home-8-wrapper {
  background-color: #A259FF;
  border-radius: 5px;
  padding-top: 50px;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
}
@media (min-width: 768px) {
  .cta-home-8 .cta-home-8-wrapper {
    margin-bottom: -191px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .cta-home-8 .cta-home-8-wrapper {
    padding-top: 100px;
    padding-top: 100px;
  }
}
.cta-home-8-shape {
  position: absolute;
  left: 5%;
  top: 18%;
  z-index: 1;
  width: 16%;
  display: none;
}
@media (min-width: 992px) {
  .cta-home-8-shape {
    display: block;
  }
}
.cta-home-8-shape img {
  width: 100%;
}
.cta-home-8 .cta-content-block {
  max-width: 560px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  position: relative;
  z-index: 2;
  color: #fff;
}
@media (min-width: 992px) {
  .cta-home-8 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-8 .cta-content-block p {
  color: rgba(253, 251, 249, 0.8);
}
.cta-home-8 .cta-button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-8 .cta-button-group {
    gap: 20px;
    flex-direction: row;
  }
}
.cta-home-8 .cta-button-group .btn-outline-l08 {
  --btn-border-color: #fff;
  --btn-color: #fff;
}
.cta-home-8 .cta-button-group .btn-fill--slide {
  --btn-bg: transparent;
}
.cta-home-8 .cta-button-group .btn-fill--slide:hover {
  --btn-color: #fff;
  --btn-fill-border-color: #fff;
}
.cta-home-8 .cta-button-group .btn-fill--slide-reverse:hover {
  --btn-color: #0A102F;
}
.cta-home-8 .curved-bg {
  height: 250px;
  background: var(--black-2);
  position: relative;
  display: none;
}
.cta-home-8 .curved-bg img {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-home-8 .curved-bg {
    display: block;
  }
}

/* ========== Home 9 : Hero Section Style ============= */
.home-9_hero-section {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .home-9_hero-section {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .home-9_hero-section {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .home-9_hero-section {
    padding-top: 165px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .home-9_hero-section {
    padding-top: 185px;
  }
}
.home-9_hero-section .row--custom {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
  justify-content: center;
  align-items: center;
}
.home-9_hero-content-block {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .home-9_hero-content-block {
    row-gap: 30px;
  }
}
.home-9_hero-text {
  text-align: center;
}
@media (min-width: 992px) {
  .home-9_hero-text {
    text-align: initial;
  }
}
.home-9_hero-text p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 992px) {
  .home-9_hero-text p {
    max-width: 685px;
  }
}
.home-9_hero-title {
  font-family: "Clash Display", sans-serif;
  letter-spacing: -2px;
  color: #221F1A;
  margin-bottom: 24px;
}
.home-9_hero-button-group {
  display: flex;
}
@media (max-width: 991px) {
  .home-9_hero-button-group {
    justify-content: center;
  }
}
.home-9_hero-image-block {
  position: relative;
}
@media (min-width: 992px) {
  .home-9_hero-image {
    margin: 0px -450px 0px 15px;
  }
}
.home-9_hero-image img {
  height: auto;
  width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.home-9_hero-image-shape-1 {
  position: absolute;
  top: -10%;
  left: -12%;
  width: 20%;
  z-index: -1;
}
.home-9_hero-image-shape-2 {
  position: absolute;
  right: -59%;
  bottom: -20%;
  z-index: -1;
  width: 66%;
}
.home-9_hero-image-shape-2 img {
  width: 100%;
}

/*=========== Home 9 : feature section style ==========*/
.home-9_feature-section {
  position: relative;
  background: #EBDECE;
}
.home-9_feature-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 18%;
}
.home-9_feature-shape img {
  width: 100%;
}

/* ========== Home 9 : Stats Section Style ============= */
.home-9_stats-section {
  background: #F4B905;
  position: relative;
  z-index: 1;
}
.home-9_stats-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-9_stats-section .row--custom {
    align-items: center;
    justify-content: space-between;
  }
}
.home-9_stats-text-block {
  text-align: center;
}
@media (min-width: 992px) {
  .home-9_stats-text-block {
    text-align: initial;
  }
}
.home-9_stats-text-block p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 992px) {
  .home-9_stats-text-block p {
    max-width: 490px;
  }
}
.home-9_stats-title {
  color: #221F1A;
  text-align: center;
}
@media (min-width: 992px) {
  .home-9_stats-title {
    text-align: initial;
  }
}
.home-9_stats-wrapper {
  display: flex;
}
.home-9_stats-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 12px;
  position: relative;
}
.home-9_stats-single::before {
  content: "";
  width: 2px;
  height: 80%;
  background-color: rgba(34, 31, 26, 0.1);
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
}
.home-9_stats-single:first-child {
  padding-right: 30px;
}
@media (min-width: 1200px) {
  .home-9_stats-single:first-child {
    padding-right: 50px;
  }
}
.home-9_stats-single:last-child {
  padding-left: 30px;
}
@media (min-width: 1200px) {
  .home-9_stats-single:last-child {
    padding-left: 50px;
  }
}
.home-9_stats-single:last-child::before {
  display: none;
}
.home-9_stats-single span {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #221F1A;
}
@media (min-width: 576px) {
  .home-9_stats-single span {
    font-size: 20px;
  }
}
.home-9_stats-single__count {
  font-family: "Inter", sans-serif;
  color: #221F1A;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -1px;
  font-weight: 700;
  color: #221F1A;
  margin-bottom: 8px;
}
@media (min-width: 576px) {
  .home-9_stats-single__count {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .home-9_stats-single__count {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .home-9_stats-single__count {
    font-size: 45px;
  }
}

/* ========== Home 9 : Content Section 1 Style ============= */
.home-9_content-section-1 {
  background: #EBDECE;
}
.home-9_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-9_content-section-1 .row--custom {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.home-9_content-section-1 .content p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 992px) {
  .home-9_content-section-1 .content p {
    max-width: 600px;
  }
}
.home-9_content-section-1 .content-link {
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: #324438;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.home-9_content-section-1 .content-link i {
  color: #EBDECE;
  background-color: #324438;
  width: 20px;
  max-width: 20px;
  min-height: 20px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-9_content-section-1 .content-title {
    max-width: 660px;
  }
}
.home-9_content-image-1-block {
  position: relative;
  max-width: 285px;
  z-index: 1;
  display: inline-block;
}
.home-9_content-image-1 img {
  width: 100%;
}
.home-9_content-image-1-shape {
  width: 178%;
}
.home-9_content-image-1-shape.absolute-center {
  z-index: -1;
}
.home-9_content-image-1-shape img {
  width: 100%;
}
.home-9_content-image-1-shape-2 {
  position: absolute;
  top: -9%;
  right: -18%;
  width: 23%;
}

/* ========== Home 9 : Tab Section Style ============= */
.tab-section {
  position: relative;
  z-index: 1;
}
.tab-section .section-heading {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .tab-section .section-heading {
    margin-bottom: 50px;
  }
}
.tab-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #324438;
  z-index: -1;
}
@media (min-width: 576px) {
  .tab-section::before {
    left: 30px;
    right: 30px;
    border-radius: 50px;
  }
}
@media (min-width: 992px) {
  .tab-section::before {
    left: 50px;
    right: 50px;
    border-radius: 50px;
  }
}
.tab-section .tab-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 22%;
}
@media (min-width: 576px) {
  .tab-section .tab-shape-1 {
    right: 30px;
  }
}
@media (min-width: 992px) {
  .tab-section .tab-shape-1 {
    right: 50px;
    border-radius: 50px;
  }
}
.tab-section .tab-shape-1 img {
  width: 100%;
}
.tab-section .tab-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 22%;
}
@media (min-width: 576px) {
  .tab-section .tab-shape-2 {
    left: 30px;
  }
}
@media (min-width: 992px) {
  .tab-section .tab-shape-2 {
    left: 50px;
    border-radius: 50px;
  }
}
.tab-section .tab-shape-2 img {
  width: 100%;
}
.tab-section .section-image {
  position: relative;
  z-index: 1;
}
.tab-section .section-image img {
  width: 100%;
}

.tab__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 905px;
  margin: 0 auto;
  column-gap: 15px;
  row-gap: 15px;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .tab__nav {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .tab__nav {
    margin-bottom: 60px;
    column-gap: 25px;
  }
}
@media (min-width: 1400px) {
  .tab__nav {
    column-gap: 0px;
    justify-content: space-between;
  }
}
.tab__nav .nav-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  padding: 13px 24px;
  border-radius: 500px;
}
.tab__nav .nav-link:hover {
  color: #324438;
  background: #F4B905;
}
.tab__nav .nav-link.active {
  content: "";
  color: #324438;
  background: #F4B905;
}
.tab__nav .nav-link.active svg {
  fill: #FFF;
  stroke: #324438;
}
.tab__nav .nav-link.active svg path {
  fill: #324438;
}
.tab__nav .tab-icon {
  fill: #FFF;
}
.tab__nav .tab-icon:hover {
  fill: #324438;
  background: #F4B905;
}

.tab-content .tab-pane:not(.show) {
  transform: translateY(50px);
}
.tab-content .tab-pane.show {
  animation: fadeUp 1s;
}

@keyframes fadeUp {
  from {
    transform: translateY(250px);
  }
  to {
    transform: translateY(0px);
  }
}
/* ========== Home 9 : Content Section 2 Style ============= */
.home-9_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-9_content-section-2 .row--custom {
    align-items: center;
    justify-content: space-between;
  }
}
.home-9_content-section-2 .content {
  max-width: 670px;
}
@media (min-width: 1400px) {
  .home-9_content-section-2 .content {
    margin-left: 70px;
  }
}
.home-9_content-section-2 .content p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 992px) {
  .home-9_content-section-2 .content p {
    max-width: 620px;
  }
}
.home-9_content-section-2 .content-widget-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (min-width: 992px) {
  .home-9_content-section-2 .content-widget-wrapper {
    row-gap: 30px;
  }
}
.home-9_content-image-2-block {
  position: relative;
  max-width: 283.1px;
  z-index: 1;
  display: inline-block;
}
.home-9_content-image-2 img {
  width: 100%;
}
.home-9_content-image-2-shape {
  width: 179.5%;
}
.home-9_content-image-2-shape.absolute-center {
  z-index: -1;
}
.home-9_content-image-2-shape img {
  width: 100%;
}
.home-9_content-image-2-shape-2 {
  position: absolute;
  top: -12%;
  left: -23%;
  width: 23%;
  z-index: -1;
}
@media (min-width: 768px) {
  .home-9_content-image-2-shape-2 {
    top: -9%;
    left: -18%;
  }
}

/* ========== Home 9 : Pricing Section Style ============= */
.home-9_pricing-section {
  position: relative;
  z-index: 1;
}
.home-9_pricing-section .pricing-section-heading {
  max-width: 690px;
  margin: 0 auto;
  text-align: center;
  color: #221F1A;
}
.home-9_pricing-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
.home-9_pricing-shape {
  position: absolute;
  left: 0;
  top: -9%;
  z-index: -1;
  width: 22%;
}
.home-9_pricing-shape img {
  width: 100%;
}
.home-9_pricing-control-block {
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .home-9_pricing-control-block {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}
.home-9_pricing-control-shape {
  display: none;
  position: absolute;
  right: 17.8%;
  bottom: -24%;
  width: 15%;
}
@media (min-width: 992px) {
  .home-9_pricing-control-shape {
    display: block;
  }
}
.home-9_pricing-control-shape img {
  width: 100%;
}

/* ========== Home 9 : Testimonial Section Style ============= */
.home-9_testimonial-section {
  background: #EBDECE;
}
.home-9_testimonial-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-9_testimonial-section .row--custom {
    justify-content: space-between;
    align-items: center;
  }
}
.home-9_testimonial-section .content-title {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-9_testimonial-section .content-title {
    margin-bottom: 50px;
    text-align: initial;
  }
}
.home-9_integrate-brand-wrapper {
  max-width: 691.72px;
  border: 1px solid #221F1A;
  border-radius: 10px 10px 10px 10px;
}
@media (min-width: 992px) {
  .home-9_integrate-brand-wrapper {
    margin-right: 40px;
  }
}
.home-9_integrate-brand-wrapper .brand-image-row {
  --bs-gutter-x: 0rem;
}
.home-9_integrate-brand-wrapper .brand-image-row > * {
  border-bottom: 1px solid #221F1A;
  border-right: 1px solid #221F1A;
}
.home-9_integrate-brand-wrapper .brand-image-row > *:last-child {
  border-bottom: 0px;
  border-right: 0px;
}
.home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(2n) {
  border-right: 0px;
}
@media (min-width: 992px) {
  .home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(2n) {
    border-right: 1px solid #221F1A;
  }
}
@media (min-width: 992px) {
  .home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(4n) {
    border-right: 0px;
  }
}
@media (min-width: 992px) {
  .home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(9) {
    border-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(10) {
    border-bottom: 0px;
  }
}
.home-9_integrate-brand-wrapper .brand-image-row > *:nth-child(11) {
  border-bottom: 0px;
}
.home-9_integrate-brand-image {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Home 9 : FAQ Section Style ============= */
.home-9_faq-section {
  position: relative;
  z-index: 1;
  background: #EBDECE;
}
.home-9_faq-section .row--custom {
  justify-content: center;
}
.home-9_faq-shape {
  position: absolute;
  top: -20%;
  right: 0;
  z-index: -1;
}
.home-9_faq-shape img {
  width: 100%;
}

/*=========== Home 9 : Cta ==========*/
.cta-home-9 {
  background: var(--l9-secondary);
  position: relative;
}
.cta-home-9 .cta-home-9-wrapper {
  padding-bottom: 80px;
}
@media (min-width: 576px) {
  .cta-home-9 .cta-home-9-wrapper {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .cta-home-9 .cta-home-9-wrapper {
    padding-bottom: 100px;
  }
}
.cta-home-9-shape img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 16%;
}
.cta-home-9 .row--custom {
  justify-content: center;
  row-gap: 30px;
}
@media (min-width: 768px) {
  .cta-home-9 .row--custom {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .cta-home-9 .row--custom {
    justify-content: space-between;
  }
}
.cta-home-9 .cta-text-block {
  text-align: center;
}
@media (min-width: 768px) {
  .cta-home-9 .cta-text-block {
    text-align: initial;
  }
}
.cta-home-9 .cta-text-block p {
  color: rgba(34, 31, 26, 0.8);
  max-width: 576px;
}
.cta-home-9 .cta-home-9-button {
  display: flex;
  justify-content: center;
  position: relative;
  column-gap: 20px;
}
.cta-home-9 .cta-home-9-button a {
  display: flex;
  align-items: stretch;
}
.cta-home-9 .cta-home-9-button a img {
  width: 100%;
}
.cta-home-9 .cta-home-9-button__shape {
  display: none;
  position: absolute;
  right: -40%;
  bottom: 100%;
  width: 37.6%;
}
@media (min-width: 768px) {
  .cta-home-9 .cta-home-9-button__shape {
    display: block;
  }
}

.home-10_hero-section {
  background: #FFF4D9;
  padding-top: 80px;
}
@media (min-width: 576px) {
  .home-10_hero-section {
    padding-top: 100px;
  }
}
@media (min-width: 992px) {
  .home-10_hero-section {
    padding-top: 120px;
  }
}
@media (min-width: 1200px) {
  .home-10_hero-section {
    padding-top: 160px;
  }
}
@media (min-width: 1400px) {
  .home-10_hero-section {
    padding-top: 185px;
  }
}
.home-10_hero-section .row--custom {
  --bs-gutter-y: 30px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-10_hero-section .row--custom {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
.home-10_hero-section-wrapper {
  border-bottom: 1px solid rgba(34, 31, 26, 0.1);
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .home-10_hero-section-wrapper {
    padding-bottom: 80px;
  }
}
.home-10_hero-content-block {
  text-align: center;
}
@media (min-width: 992px) {
  .home-10_hero-content-block {
    text-align: initial;
  }
}
.home-10_hero-content-block p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 992px) {
  .home-10_hero-content-block p {
    max-width: 630px;
  }
}
.home-10_hero-content-block .hero-content__title {
  font-family: "Clash Display", sans-serif;
}
.home-10_hero-text-block {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-10_hero-text-block {
    margin-bottom: 50px;
  }
}
.home-10_hero-button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
}
@media (min-width: 992px) {
  .home-10_hero-button-group {
    justify-content: initial;
  }
}
@media (max-width: 479px) {
  .home-10_hero-button-group .btn {
    width: 100%;
  }
}
.home-10_hero-button-group .btn-outline-l10:hover {
  --btn-bg: #2D2B92;
}
.home-10_button-bottom-rating {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 24px;
}
@media (min-width: 992px) {
  .home-10_button-bottom-rating {
    justify-content: initial;
  }
}
.home-10_button-bottom-rating span {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(34, 31, 26, 0.7);
  column-gap: 10px;
  position: relative;
  top: 7.5px;
}
.home-10_hero-image img {
  width: 100%;
}

/* ========== Home 10 : Content Section 1 Style ============= */
.home-10_content-section {
  background: #FFF4D9;
  position: relative;
  z-index: 1;
}
.home-10_content-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2D2B92;
  z-index: -1;
}
@media (min-width: 768px) {
  .home-10_content-section::before {
    border-radius: 30px;
  }
}
.home-10_content-section .content p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  font-family: var(--font-family-base);
  color: rgba(255, 244, 217, 0.8);
}
@media (min-width: 576px) {
  .home-10_content-section .content p {
    font-size: 18px;
  }
}

.home-10_content-section-1 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-10_content-section-1 .row--custom {
    --bs-gutter-x: 30px;
    justify-content: space-between;
    align-items: center;
  }
}
.home-10_content-section-1 .content p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: rgba(255, 244, 217, 0.8);
}
@media (min-width: 576px) {
  .home-10_content-section-1 .content p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .home-10_content-section-1 .content p {
    max-width: 570px;
  }
}
.home-10_content-image-1 img {
  width: 100%;
}

/* ========== Home 10 : Content Section 2 Style ============= */
.home-10_content-section-2 .row--custom {
  justify-content: center;
  --bs-gutter-y: 30px;
}
@media (min-width: 992px) {
  .home-10_content-section-2 .row--custom {
    --bs-gutter-x: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.home-10_content-section-2 .content p {
  max-width: 570px;
}
.home-10_content-section-2 .content .content-list {
  max-width: 575px;
}
.home-10_content-section-2 .content .content-list li:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(253, 251, 249, 0.1);
}
@media (min-width: 992px) {
  .home-10_content-section-2 .content .content-list li:not(:last-child) {
    padding-bottom: 25px;
  }
}
.home-10_content-section-2 .content .content-list li:not(:first-child) {
  padding-top: 15px;
}
@media (min-width: 992px) {
  .home-10_content-section-2 .content .content-list li:not(:first-child) {
    padding-top: 25px;
  }
}
.home-10_content-section-2 .content .content-list-item a {
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.038461538em;
  font-family: "Public Sans", sans-serif;
  line-height: 1.23;
  font-size: 18px;
  font-family: "Clash Display", sans-serif;
  color: #FDFBF9;
}
@media (min-width: 768px) {
  .home-10_content-section-2 .content .content-list-item a {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .home-10_content-section-2 .content .content-list-item a {
    font-size: 26px;
  }
}
.home-10_content-section-2 .content .content-list-item a i {
  display: block;
  transform: rotate(335deg);
}
.home-10_content-section-2 .content .content-list-item a:hover {
  color: #FFD874;
}
.home-10_content-image-2 {
  position: relative;
}
.home-10_content-image-2 img {
  width: 100%;
}
.home-10_content-image-2-card {
  position: absolute;
  bottom: 17.2%;
  left: -8%;
  width: 99.7%;
}
.home-10_content-image-2-card img {
  box-shadow: 0px 4.49307px 89.8615px rgba(0, 0, 0, 0.15);
  border-radius: 11.2327px;
}

.home-10_process-section {
  background: #FFF4D9;
}
.home-10_section-heading {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .home-10_section-heading {
    margin-bottom: 80px;
  }
}
.home-10_section-heading__title {
  font-size: 32px;
  font-weight: 500;
  line-height: 0.94;
  font-family: "Clash Display", sans-serif;
  letter-spacing: -1px;
  color: #221F1A;
}
@media (min-width: 576px) {
  .home-10_section-heading__title {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .home-10_section-heading__title {
    font-size: 46px;
  }
}
@media (min-width: 1200px) {
  .home-10_section-heading__title {
    font-size: 60px;
  }
}

/* ========== Home 10 : Testimonial Section Style ============= */
.home-10_testimonial-section {
  background: #FFF4D9;
  position: relative;
  z-index: 1;
}
.home-10_testimonial-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFD874;
  z-index: -1;
}
@media (min-width: 768px) {
  .home-10_testimonial-section::before {
    border-radius: 30px;
  }
}

/* ========== Home 10 : Integration Section Style ============= */
.home-10_integration-section .row--custom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .home-10_integration-section .row--custom {
    --bs-gutter-x: 24px;
    align-items: center;
  }
}
.home-10_integration-section .content {
  text-align: center;
}
@media (min-width: 992px) {
  .home-10_integration-section .content {
    text-align: initial;
  }
}
@media (min-width: 992px) {
  .home-10_integration-image {
    max-width: 460px;
  }
}
.home-10_integration-image img {
  width: 100%;
}
@media (min-width: 1200px) {
  .home-10_integration-image {
    padding-left: 24px;
  }
}

/* ========== Home 10 : Faq Section  Style ============= */
.home-10_faq-section {
  background: #FFF4D9;
}
.home-10_faq-section .row--custom {
  justify-content: center;
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-10_faq-section .row--custom {
    justify-content: space-between;
  }
}
@media (min-width: 1400px) {
  .home-10_faq-section .content {
    row-gap: 45px;
  }
}
.home-10_faq-section .content p {
  color: rgba(34, 31, 26, 0.8);
}
@media (min-width: 1200px) {
  .home-10_faq-section .accordion-style-5 {
    margin-left: 20px;
  }
}
@media (min-width: 1400px) {
  .home-10_faq-section .accordion-style-5 {
    margin-left: 55px;
  }
}

/* ========== Home 10 : Service Section Style ============= */
.home-10_service-section {
  background: #FFF4D9;
}
.home-10_service-section .section-heading--row > .row {
  justify-content: center;
  text-align: center;
}
@media (min-width: 992px) {
  .home-10_service-section .section-heading--row > .row {
    text-align: initial;
    justify-content: space-between;
    align-items: center;
  }
}
.home-10_service-section .row--custom {
  --bs-gutter-y: 5rem;
  justify-content: center;
}

/* ========== Home 9 : Service Section Style ============= */
.home-9_stats-section {
  background: #F4B905;
}
.home-9_stats-section .row--custom {
  justify-content: center;
  --gutter-y: 40px;
}
@media (min-width: 992px) {
  .home-9_stats-section .row--custom {
    align-items: center;
  }
}
.home-9_stats-text-block p {
  color: rgba(34, 31, 26, 0.8);
}
.home-9_stats-title {
  color: #FDFBF9;
  text-align: center;
}
@media (min-width: 992px) {
  .home-9_stats-title {
    text-align: initial;
  }
}
.home-9 .stats-wrapper .row--custom {
  --gutter-y: 40px;
  justify-content: center;
}

.stats-single {
  position: relative;
  padding-right: 30px;
  padding-left: 30px;
}
@media (min-width: 1200px) {
  .stats-single {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.stats-single::after {
  content: "";
  width: 2px;
  height: 39%;
  background-color: #A259FF;
  position: absolute;
  right: 0%;
  top: 55%;
  transform: translateY(-50%);
}
.stats-single:first-child {
  left: 0px;
}
.stats-single:last-child {
  padding-right: 0px;
}
.stats-single:last-child::after {
  display: none;
}
.stats-single__count {
  color: #FDFBF9;
  font-size: 32px;
  line-height: 1.14;
  font-weight: 700;
  margin-bottom: 8px;
}
@media (min-width: 576px) {
  .stats-single__count {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .stats-single__count {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .stats-single__count {
    font-size: 56px;
  }
}
.stats-single p {
  color: rgba(253, 251, 249, 0.6);
}

/*=========== Home 10 : Cta ==========*/
.cta-home-10 {
  padding-top: 80px;
  background: #FFF4D9;
  position: relative;
  z-index: 1;
}
.cta-home-10::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--l10-primary);
  z-index: -1;
}
@media (min-width: 768px) {
  .cta-home-10::before {
    border-radius: 30px 30px 0 0;
  }
}
.cta-home-10 .cta-home-10-wrapper {
  border-radius: 5px;
  padding-top: 50px;
  padding-bottom: 0;
  position: relative;
}
@media (min-width: 576px) {
  .cta-home-10 .cta-home-10-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.cta-home-10 .cta-content-block {
  padding-bottom: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  position: relative;
  z-index: 2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 576px) {
  .cta-home-10 .cta-content-block {
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .cta-home-10 .cta-content-block {
    row-gap: 50px;
  }
}
.cta-home-10 .cta-content-block .cta-text-block {
  margin-right: auto;
  margin-left: auto;
  max-width: 425px;
}
@media (min-width: 576px) {
  .cta-home-10 .cta-content-block .cta-text-block {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cta-home-10 .cta-content-block .cta-text-block {
    max-width: 620px;
  }
}
@media (min-width: 1200px) {
  .cta-home-10 .cta-content-block .cta-text-block {
    max-width: 720px;
  }
}
.cta-home-10 .cta-form-wrapper {
  max-width: 450px;
  width: 100%;
  margin: auto;
}
.cta-home-10 .cta-form__input-block {
  position: relative;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 3px;
  border-radius: 500px;
  width: 100%;
  row-gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-10 .cta-form__input-block {
    flex-direction: row;
    background-color: #fff;
  }
}
.cta-home-10 .cta-form__input-block input {
  padding: 12px;
  border-radius: 500px;
  border: none;
  outline: none;
  width: 100%;
}
@media (min-width: 576px) {
  .cta-home-10 .cta-form__input-block input {
    background: none;
    border-radius: 500px 0 0 500px;
  }
}
.cta-home-10 .cta-form__input-block .btn {
  white-space: nowrap;
}
.cta-home-10 .cta-button-group {
  display: flex;
  gap: 10px;
}
@media (min-width: 576px) {
  .cta-home-10 .cta-button-group {
    gap: 20px;
  }
}
.cta-home-10 .cta-button-group a {
  display: flex;
  align-items: stretch;
}
.cta-home-10 .cta-button-group a img {
  width: 100%;
}

/* ========== about : Banner Section Style ============= */
.banner_image-row {
  justify-content: center;
  row-gap: 10px;
}
.banner_image-row img {
  width: 100%;
}
@media (min-width: 479px) {
  .banner_image-row .single-image-2 {
    margin-top: 10%;
  }
}

/* ========== about : Content Section Style ============= */
.about_content-section .row--custom {
  --bs-gutter-y: 30px;
  --bs-gutter-x: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .about_content-section .row--custom {
    justify-content: space-between;
  }
}
.about_content-section .content {
  row-gap: 30px;
}
@media (min-width: 1200px) {
  .about_content-section .content {
    row-gap: 50px;
  }
}
.about_content-section .content p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .about_content-section .content p {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .about_content-section .content p {
    margin-bottom: 50px;
    max-width: 590px;
  }
}
.about_content-section .content-testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
@media (min-width: 992px) {
  .about_content-section .content-testimonial {
    text-align: initial;
  }
}
.about_content-section .content-testimonial__text p {
  font-style: italic;
  margin-bottom: 24px;
  font-weight: 600;
  color: rgba(10, 16, 47, 0.8);
  font-family: "Inter", sans-serif;
}
.about_content-section .content-testimonial__user-metadata {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  column-gap: 15px;
}
@media (min-width: 992px) {
  .about_content-section .content-testimonial__user-metadata {
    flex-direction: row;
    align-items: initial;
  }
}
.about_content-section .content-testimonial__body span {
  font-size: 14px;
  line-height: 1.43;
  color: rgba(var(--body-color-rgb), 0.8);
}
.about_content-section .content-testimonial__user-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .about_content-section .content-testimonial__user-name {
    font-size: 18px;
  }
}
.about_content-image-block {
  position: relative;
}
@media (min-width: 1200px) {
  .about_content-image-block {
    margin-right: 20px;
  }
}
@media (min-width: 1400px) {
  .about_content-image-block {
    margin-right: 55px;
  }
}
.about_content-image-block img {
  width: 100%;
}

/* ========== about : Feature Section Style ============= */
.about_feature-section .feature-widget-7-row {
  justify-content: center;
  --bs-gutter-y: 30px;
  --bs-gutter-x: 24px;
}
@media (min-width: 1200px) {
  .about_feature-section .feature-widget-7-row {
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 1400px) {
  .about_feature-section .feature-widget-7-row {
    --bs-gutter-x: 76px;
    --bs-gutter-y: 60px;
  }
}

/* ========= Home 1  : Portfolio Section Style ============= */
.about_portfolio-section .row--custom {
  --bs-gutter-x:20px;
  --bs-gutter-y:30px;
  justify-content: center;
}
@media (min-width: 768px) {
  .about_portfolio-section .row--custom {
    --bs-gutter-x:30px;
  }
}
@media (min-width: 992px) {
  .about_portfolio-section .row--custom {
    --bs-gutter-x:45px;
  }
}
@media (min-width: 1200px) {
  .about_portfolio-section .row--custom {
    --bs-gutter-x:61px;
  }
}

/* ========== about : Content Section Style ============= */
.about_fact-section .row--custom {
  --bs-gutter-y: 40px;
  --bs-gutter-x: 40px;
  justify-content: center;
  text-align: center;
}
@media (min-width: 992px) {
  .about_fact-section .row--custom {
    justify-content: space-between;
  }
}
.about_fact-section .content {
  text-align: center;
  align-items: center;
}
@media (min-width: 992px) {
  .about_fact-section .content {
    max-width: 636px;
    text-align: initial;
    align-items: initial;
  }
}
.about_fact-section .content p {
  max-width: 605px;
}
.about_stats-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1400px) {
  .about_stats-wrapper {
    margin-left: 50px;
  }
}
.about_stats-wrapper > * {
  border-bottom: 1px solid rgba(34, 31, 26, 0.1);
  border-right: 1px solid rgba(34, 31, 26, 0.1);
}
.about_stats-wrapper > *:nth-child(1) > div {
  margin-right: 30px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .about_stats-wrapper > *:nth-child(1) > div {
    margin-bottom: 50px;
  }
}
.about_stats-wrapper > *:nth-last-child(-n+2) {
  border-bottom: 0;
}
.about_stats-wrapper > *:nth-last-child(-n+2) > div {
  margin-top: 30px;
  margin-right: 30px;
}
.about_stats-wrapper > *:nth-of-type(2n) {
  border-right: 0px;
}
.about_stats-wrapper > *:nth-of-type(2n) > div {
  margin-left: 30px;
}
@media (min-width: 992px) {
  .about_stats-wrapper > *:nth-child(1) > div {
    margin-bottom: 50px;
  }
  .about_stats-wrapper > *:nth-child(2) > div {
    margin-bottom: 50px;
  }
  .about_stats-wrapper > *:nth-last-child(-n+2) > div {
    margin-top: 50px;
  }
  .about_stats-wrapper > *:nth-of-type(2n) > div {
    margin-left: 30px;
  }
}
.about_stats-single span {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #3147FF;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .about_stats-single span {
    font-size: 20px;
  }
}
.about_stats-single__count {
  line-height: 1.2;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: -0.0142857em;
}
@media (min-width: 480px) {
  .about_stats-single__count {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .about_stats-single__count {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .about_stats-single__count {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .about_stats-single__count {
    font-size: 70px;
  }
}

/* ========== service : Pricing Section Style ============= */
.service_pricing-section .section-heading {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.service_pricing-section .row--custom {
  --bs-gutter-y: 30px;
}
.service_pricing-section .pricing-control {
  height: 50px;
}

/* ========== service Details Main Section Style ============= */
.service-details_main-image {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .service-details_main-image {
    margin-bottom: 40px;
  }
}
.service-details_main-single {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .service-details_main-single {
    margin-bottom: 30px;
  }
}
.service-details_main-single p {
  margin-bottom: 20px;
}
.service-details_main-single p:last-child {
  margin-bottom: 0px;
}
.service-details_main-single ul {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.service-details_main-single li {
  display: flex;
  column-gap: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
}
@media (min-width: 576px) {
  .service-details_main-single li {
    font-size: 18px;
  }
}
.service-details_main-single li img {
  max-width: 26px;
  max-height: 26px;
  position: relative;
  top: 5px;
}
.service-details_main-title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .service-details_main-title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .service-details_main-title {
    font-size: 24px;
  }
}
.service-details_main-image-row {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .service-details_main-image-row {
    margin-bottom: 30px;
  }
}

/*=========== CTA Base ==========*/
.cta-base {
  background: #0A102F;
  padding-top: 50px;
}
@media (min-width: 576px) {
  .cta-base {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .cta-base {
    padding-top: 100px;
  }
}
.cta-base__wrapper {
  padding-bottom: 40px;
}
@media (min-width: 576px) {
  .cta-base__wrapper {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .cta-base__wrapper {
    padding-bottom: 80px;
  }
}
.cta-base__text-block {
  color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
  .cta-base__text-block {
    text-align: initial;
  }
}
.cta-base__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.cta-base__button .btn {
  --bs-btn-padding-x: 42px;
}
.cta-base__button-shape {
  display: none;
  position: absolute;
  right: -40%;
  bottom: 100%;
  width: 37.6%;
}
@media (min-width: 768px) {
  .cta-base__button-shape {
    display: block;
  }
}

/* Footer Title Common */
.footer-title {
  font-family: var(--font-family-base);
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
}
@media (min-width: 576px) {
  .footer-title {
    font-size: 20px;
  }
}

.copyright-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: rgba(var(--body-color-rgb), 0.1);
}

.copyright-inner {
  padding-top: 18px;
  padding-bottom: 18px;
  margin-top: 40px;
}
@media (min-width: 576px) {
  .copyright-inner {
    margin-top: 60px;
  }
}
@media (min-width: 992px) {
  .copyright-inner {
    margin-top: 80px;
  }
}
.copyright-inner p {
  font-size: 16px;
  margin-bottom: 0;
}

.footer-list {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  font-weight: 400;
}
@media (min-width: 576px) {
  .footer-list {
    font-size: 18px;
  }
}
.footer-list a {
  color: var(--body-color);
}

.footer-store-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

/* Footer Dark and Light Global */
[class*=footer--dark] {
  color: rgba(255, 255, 255, 0.8);
}
[class*=footer--dark] p {
  color: inherit;
}
[class*=footer--dark] .footer-title {
  color: #fff;
}
[class*=footer--dark] a {
  color: rgba(255, 255, 255, 0.8);
}
[class*=footer--dark] .copyright-border {
  border-color: rgba(255, 255, 255, 0.1);
}

[class*=footer--light] {
  color: rgba(var(--body-color-rgb), 0.8);
}
[class*=footer--light] .footer-title {
  color: var(--body-color);
}
[class*=footer--light] a {
  color: rgba(var(--body-color-rgb), 0.8);
}
[class*=footer--light] a.footer-link {
  text-decoration: underline;
  margin-bottom: 24px;
}
[class*=footer--light] a:hover {
  color: var(--bs-primary);
}
[class*=footer--light] .copyright-border {
  border-color: rgba(var(--body-color-rgb), 0.1);
}

/* Footer Section Padding */
.footer-padding-default {
  padding-top: 60px;
}
@media (min-width: 576px) {
  .footer-padding-default {
    padding-top: 80px;
  }
}

/* Footer Variations By Color */
.footer--dark-v1 {
  background: #0A102F;
}
.footer--dark-v1 .list-social {
  --color: #fff;
  --backgroundColor: rgba(255, 255, 255, 0.1);
  --hovBackground: #3147FF;
  --hovColor: #fff;
}
.footer--dark-v1 .copyright-inner {
  border: 0;
}
.footer--dark-v1 .copyright-block {
  background: rgba(255, 255, 255, 0.05);
}

/* Footer Variations By Color */
.footer--dark-v2 {
  background: var(--black-2);
}
.footer--dark-v2 .list-social {
  --color: #fff;
  --backgroundColor: rgba(255, 255, 255, 0.1);
  --hovBackground: #3147FF;
  --hovColor: #fff;
}
.footer--dark-v2 .copyright-inner {
  border: 0;
}
.footer--dark-v2 .copyright-block {
  background: rgba(255, 255, 255, 0.05);
}

.footer-l01 a:hover {
  color: var(--bs-primary);
}

.footer-l02 {
  background-color: #F5F5F5;
}
.footer-l02 .footer-title {
  color: #0A102F;
}
.footer-l02 .footer-list a {
  color: rgba(10, 16, 47, 0.8);
}
.footer-l02 .list-social li a:hover {
  background-color: var(--black-2);
  color: #fff;
}
.footer-l02 a:hover {
  color: var(--l2-primary);
}

.footer-l03 .copyright-border {
  border-color: transparent;
}
.footer-l03 li {
  line-height: 1.67;
}
.footer-l03 .copyright-block {
  background-color: #FAF9F5;
}
.footer-l03 .list-social li a:hover {
  background-color: var(--l3-primary);
  color: #0A102F;
}
.footer-l03 a:hover {
  color: var(--l3-primary);
}

.footer-l04 {
  background-color: #F5EFEB;
  line-height: 1.67;
}
.footer-l04 .list-social li a:hover {
  background-color: var(--l4-primary);
  color: #fff;
}
.footer-l04 a:hover {
  color: var(--l4-primary);
}

.footer-l05 {
  color: rgba(var(--cream-rgb), 0.8);
}
.footer-l05 .footer-title {
  color: #FDFBF9;
}
.footer-l05 a {
  color: rgba(var(--cream-rgb), 0.8);
}
.footer-l05 a:hover {
  color: var(--l5-primary);
}

.footer-l08 {
  padding-top: 60px;
}
@media (min-width: 992px) {
  .footer-l08 {
    padding-top: 100px;
  }
}
.footer-l08 .list-social li a:hover {
  background-color: var(--l8-primary);
  color: var(--black-2);
}
.footer-l08 a:hover {
  color: var(--l8-primary);
}

.footer-l10 {
  background-color: var(--l10-primary);
}
.footer-l10 .list-social li a:hover {
  background-color: var(--l10-secondary);
  color: var(--l10-primary);
}
.footer-l10 a:hover {
  color: var(--l10-secondary);
}

/* ==========  Footer Style v1  ============= */
.footer {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
}
@media (min-width: 576px) {
  .footer {
    font-size: 18px;
  }
}
.footer__content-block {
  max-width: 416px;
}
.footer__content-block .footer-brand {
  margin-bottom: 33px;
}
.footer__content-text {
  margin-bottom: 24px;
}
.footer a.footer-link {
  text-decoration: underline;
  margin-bottom: 24px;
}
.footer .row--list-block {
  justify-content: space-between;
  --bs-gutter-y:40px ;
}
.footer .row--footer-main {
  --bs-gutter-y:40px ;
}

/* ==========  Footer Style v2  ============= */
.footer-v2 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
}
@media (min-width: 576px) {
  .footer-v2 {
    font-size: 18px;
  }
}
.footer-v2__content-block {
  max-width: 320px;
}
.footer-v2__content-block .footer-brand {
  margin-bottom: 33px;
}
.footer-v2__content-text {
  margin-bottom: 24px;
}
.footer-v2 a.footer-link {
  text-decoration: underline;
  margin-bottom: 24px;
}
@media (min-width: 1400px) {
  .footer-v2__list-block {
    width: 893px;
  }
}
.footer-v2 .row--list-block {
  justify-content: space-between;
  --bs-gutter-y:40px;
}
.footer-v2 .row--footer-main {
  --bs-gutter-y:40px;
  justify-content: space-between;
}

/* ==========  Footer Style v2  ============= */
.footer-v3 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 576px) {
  .footer-v3 {
    font-size: 18px;
  }
}
.footer-v3__content-block {
  max-width: 320px;
}
.footer-v3__content-block .footer-brand {
  margin-bottom: 33px;
}
.footer-v3__content-text {
  margin-bottom: 24px;
}
.footer-v3 a.footer-link {
  text-decoration: underline;
  margin-bottom: 24px;
}
@media (min-width: 1400px) {
  .footer-v3__list-block {
    width: 893px;
  }
}
.footer-v3 .row--list-block {
  justify-content: space-between;
  --bs-gutter-y:40px;
}
.footer-v3 .row--footer-main {
  --bs-gutter-y:40px;
  --bs-gutter-x:40px;
  justify-content: space-between;
}
.footer-v3__copyright-section {
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
  padding-top: 34px;
  padding-bottom: 34px;
  margin-top: 80px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
@media (min-width: 992px) {
  .footer-v3__copyright-section {
    text-align: initial;
    justify-content: space-between;
    flex-direction: row;
  }
}

.faq-grid-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 25px;
}
@media (min-width: 992px) {
  .faq-grid-layout {
    justify-content: space-between;
  }
}
.faq-grid-layout > * {
  border-bottom: 1px solid #0A102F;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.faq-grid-layout > *:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px;
}
@media (min-width: 992px) {
  .faq-grid-layout > * {
    max-width: 440px;
  }
  .faq-grid-layout > *:nth-last-child(-n+2) {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1200px) {
  .faq-grid-layout > * {
    max-width: 526px;
  }
}
@media (min-width: 1400px) {
  .faq-grid-layout > * {
    max-width: 578px;
  }
}

.faq-grid-single .faq-grid__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  margin-bottom: 15px;
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .faq-grid-single .faq-grid__title {
    font-size: 20px;
  }
}
.faq-grid-single p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
}
@media (min-width: 576px) {
  .faq-grid-single p {
    font-size: 18px;
  }
}

.faq-filter-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 18px;
  justify-content: center;
}
@media (min-width: 992px) {
  .faq-filter-list {
    justify-content: initial;
    flex-direction: column;
    row-gap: 24px;
  }
}
.faq-filter-list .faq-title {
  color: #0A102F;
}
.faq-filter-list .faq-title:hover {
  color: #3147FF;
}

/* ========== Brand Section Style ============= */
.brand-section .brands-wrapper-inner {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .brand-section .brands-wrapper-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .brand-section .brands-wrapper-inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.brand-section .brand-heading {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .brand-section .brand-heading {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .brand-section .brand-heading {
    margin-bottom: 60px;
  }
}
@media (min-width: 1400px) {
  .brand-section .brand-heading {
    margin-bottom: 80px;
  }
}
.brand-section .brand-heading__title {
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.4;
  color: rgba(10, 16, 47, 0.7);
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .brand-section .brand-heading__title {
    font-size: 20px;
  }
}
.brand-section .brand-heading-2 {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .brand-section .brand-heading-2 {
    margin-bottom: 40px;
  }
}
.brand-section .brands-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 35px;
  justify-content: center;
}
@media (min-width: 1200px) {
  .brand-section .brands-wrapper {
    justify-content: space-between;
  }
}
.brand-section .brands-wrapper.filter-grayscale img {
  width: 100%;
  max-width: auto;
  filter: grayscale(100%);
}
@media (min-width: 1200px) {
  .brand-section .brands-wrapper.filter-grayscale img {
    width: auto;
    max-width: 100%;
  }
}
.brand-section .single-brand {
  padding-left: 15px;
  padding-right: 15px;
  width: 51%;
}
@media (min-width: 480px) {
  .brand-section .single-brand {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .brand-section .single-brand {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .brand-section .single-brand {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .brand-section .single-brand {
    width: auto;
  }
}
.brand-section .single-brand img {
  max-width: 100%;
}
.brand-section .single-brand-2 {
  padding-left: 15px;
  padding-right: 15px;
  width: 34%;
}
@media (min-width: 768px) {
  .brand-section .single-brand-2 {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .brand-section .single-brand-2 {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .brand-section .single-brand-2 {
    width: auto;
  }
}
.brand-section .single-brand-2 img {
  max-width: 100%;
}

/* ========== Testimonial masonry layout ============= */
.testimonial-masonry {
  row-gap: 30px;
  justify-content: center;
  column-count: 1;
  column-gap: 24px;
}
@media (min-width: 768px) {
  .testimonial-masonry {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .testimonial-masonry {
    column-count: 3;
  }
}
.testimonial-masonry-item {
  break-inside: avoid;
  vertical-align: top;
  margin-bottom: 24px;
}

/* ========== Component : banner Style  ============= */
.inner_banner-section {
  padding-top: 100px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .inner_banner-section {
    padding-top: 84px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .inner_banner-section {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .inner_banner-section {
    padding-top: 190px;
  }
}
.inner_banner-background-image {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.inner_banner-content-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
}
@media (min-width: 576px) {
  .inner_banner-content-block {
    row-gap: 40px;
  }
}
.inner_banner-title {
  text-align: center;
  color: #0A102F;
  line-height: 1.2;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: -0.0142857em;
}
@media (min-width: 480px) {
  .inner_banner-title {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .inner_banner-title {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .inner_banner-title {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .inner_banner-title {
    font-size: 70px;
  }
}

.banner__page-navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  color: #0A102F;
}
@media (min-width: 576px) {
  .banner__page-navigator {
    font-size: 18px;
  }
}
.banner__page-navigator li {
  text-transform: capitalize;
}
.banner__page-navigator li:after {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 13px;
  margin: 0 10px;
  display: inline-block;
}
.banner__page-navigator li:last-child:after {
  display: none;
}
.banner__page-navigator .active {
  color: var(--bs-primary);
}
.banner__page-navigator a {
  color: inherit;
}

.portfolio-v1 {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 24px;
}
@media (min-width: 768px) {
  .portfolio-v1 {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 40px;
  }
}
@media (min-width: 992px) {
  .portfolio-v1 {
    --bs-gutter-x: 60px;
    --bs-gutter-y: 60px;
  }
}

.portfolio-v2 .portfolio-widget {
  cursor: pointer;
}

/* ========== Contact main Section Style ============= */
.contact_main-section .row--cuatom {
  --bs-gutter-y: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .contact_main-section .row--cuatom {
    align-items: center;
    --bs-gutter-x: 25px;
  }
}
.contact_main-section .content-divider {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .contact_main-section .content-divider {
    margin-top: 50px;
  }
}
.contact_main-content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (min-width: 992px) {
  .contact_main-content {
    row-gap: 40px;
  }
}
@media (min-width: 1200px) {
  .contact_main-content {
    row-gap: 60px;
  }
}
@media (min-width: 1400px) {
  .contact_main-content {
    row-gap: 100px;
  }
}

.contact-2_form-section .form-box-style {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.contact-2_form-section .form-box-style__form-wrapper {
  border: none;
}
.contact-2_form-section .form--row-custom {
  --bs-gutter-y: 12px;
}
@media (min-width: 768px) {
  .contact-2_form-section .form--row-custom {
    --bs-gutter-y: 18px;
  }
}
@media (min-width: 992px) {
  .contact-2_form-section .form--row-custom {
    --bs-gutter-y: 24px;
  }
}

/* ========== Contact location Style ============= */
.contact_location-image {
  height: 600px;
}
@media (max-width: 991px) {
  .contact_location-image {
    height: 300px;
  }
}
@media (max-width: 567px) {
  .contact_location-image {
    height: 260px;
  }
}
.contact_location-image {
  width: 100%;
  object-fit: cover;
}

/* ========== Contact Page 3 main Section Style ============= */
.contact-3_main-section .row--custom {
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .contact-3_main-section .row--custom {
    align-items: center;
  }
}
.contact-3_main-comment-box-wrapper {
  border: 2px solid rgba(10, 16, 47, 0.5);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
@media (min-width: 992px) {
  .contact-3_main-comment-box-wrapper {
    row-gap: 40px;
  }
}
.contact-3_main-comment-box-wrapper .form-control {
  border: 0px;
  border-bottom: 1px dashed rgba(10, 16, 47, 0.1);
  padding-bottom: 15px;
  width: 100%;
  color: rgba(10, 16, 47, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  box-shadow: none;
  background: #FAF9F5;
}
@media (min-width: 576px) {
  .contact-3_main-comment-box-wrapper .form-control {
    font-size: 18px;
  }
}
.contact-3_main-comment-box-wrapper .form-control.textarea {
  height: 135px;
}
.contact-3_main-comment-box-wrapper .contact-title {
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .contact-3_main-comment-box-wrapper .contact-title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .contact-3_main-comment-box-wrapper .contact-title {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .contact-3_main-comment-box-wrapper .contact-title {
    margin-bottom: 40px;
  }
}
.contact-3_main-comment-box__form-input {
  margin-bottom: 24px;
}
.contact-3_main-comment-box__form-input-group {
  display: flex;
  column-gap: 24px;
}

.portfolio-v3 {
  --bs-gutter-x: 34px;
  --bs-gutter-y: 40px;
}
.portfolio-v3 .portfolio-widget .portfolio-widget_image-block {
  transition: all 0.2s ease-in-out;
}
.portfolio-v3 .portfolio-widget:hover .portfolio-widget_image-block {
  box-shadow: 10px 10px rgba(var(--body-color-rgb));
  border-radius: 10px;
}

.portfolio-v4 {
  justify-content: space-between;
}
.portfolio-v4 .row--custom {
  --bs-gutter-x: 34px;
  --bs-gutter-y: 30px;
}
.portfolio-v4 .portfolio-widget {
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 30px;
}
.portfolio-v4 .portfolio-widget__text {
  max-width: 650px;
}

/* ========== Home 7 : Content Section 2 Style ============= */
.pricing_main_pricing-section .section-heading p {
  color: rgba(18, 18, 18, 0.8);
}
@media (min-width: 992px) {
  .pricing_main_pricing-section .section-heading p {
    margin: 0 auto;
    max-width: 600px;
  }
}

/* ========== Home 7 : Content Section 2 Style ============= */
.pricing-2_main_pricing-section .section-heading {
  max-width: 600px;
  margin: 0 auto;
}
.pricing-2_main-pricing-control-block {
  margin-top: 30px;
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .pricing-2_main-pricing-control-block {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

.portfolio-details_article {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .portfolio-details_article {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .portfolio-details_article {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .portfolio-details_article {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
.portfolio-details_article-block {
  counter-reset: list;
}
.portfolio-details_article-block img {
  width: 100%;
}
.portfolio-details_article-body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .portfolio-details_article-body {
    row-gap: 30px;
  }
}
.portfolio-details_article-body h2 {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -1px;
  font-weight: 700;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .portfolio-details_article-body h2 {
    font-size: 30px;
  }
}
.portfolio-details_article-body h3 {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.5px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .portfolio-details_article-body h3 {
    font-size: 24px;
  }
}
.portfolio-details_article-body p {
  font-size: 16px;
  line-height: 1.67;
  margin-bottom: 8px;
}
.portfolio-details_article-body p ~ p {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .portfolio-details_article-body p {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .portfolio-details_article-body p ~ p {
    margin-top: 16px;
  }
}
.portfolio-details_article-body .list li {
  font-size: 18px;
  line-height: 1.67;
}
.portfolio-details_article-body .list li::before {
  counter-increment: list;
  content: counter(list) ". ";
  font-weight: 600;
}
.portfolio-details_thumbnail-block {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .portfolio-details_thumbnail-block {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .portfolio-details_thumbnail-block {
    margin-bottom: 60px;
  }
}
.portfolio-details_thumbnail-image {
  width: 100%;
}
.portfolio-details_thumbnail-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .portfolio-details_thumbnail-list {
    gap: 30px;
    margin-top: 30px;
  }
}
.portfolio-details_thumbnail-list h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: #0A102F;
}
@media (min-width: 768px) {
  .portfolio-details_thumbnail-list h3 {
    font-size: 20px;
  }
}
.portfolio-details_thumbnail-list p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.67;
  color: rgba(10, 16, 47, 0.8);
}
@media (min-width: 768px) {
  .portfolio-details_thumbnail-list p {
    font-size: 18px;
  }
}

.portfolio-details_related-post {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .portfolio-details_related-post {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .portfolio-details_related-post {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .portfolio-details_related-post {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
.portfolio-details_related-post-title-block {
  margin-bottom: 30px;
}
.portfolio-details_related-post-title-block p {
  max-width: 856px;
}
.portfolio-details_related-post_title {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 15px;
}
.portfolio-details_related-post .row {
  --bs-gutter-y: 24px;
}
.portfolio-details_related-post .portfolio-widget {
  height: initial;
}
.portfolio-details_related-post .portfolio-widget__text {
  max-width: 350px;
}

/* ========== Blog Main Style ============= */
.sidebar {
  /*~ ******Sidebar Blog Card *********** ~*/
}
.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
@media (min-width: 992px) {
  .sidebar-wrapper {
    row-gap: 30px;
  }
}
@media (min-width: 1200px) {
  .sidebar-wrapper {
    max-width: 416px;
    margin-left: auto;
  }
}
.sidebar-single {
  background: #FAF9F5;
  border-radius: 10px;
  padding: 28px 22px 28px 22px;
}
.sidebar-title-block {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .sidebar-title-block {
    margin-bottom: 30px;
  }
}
.sidebar-title-block .sidebar-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .sidebar-title-block .sidebar-title {
    font-size: 18px;
  }
}
.sidebar-title-block::before {
  content: "";
  width: 105%;
  height: 2px;
  background-color: #13111A;
  position: absolute;
  bottom: -24%;
  left: 0;
  transform: translateX(1%);
}
.sidebar-search-input {
  background: #FAF9F5;
  padding: 20px;
  border-radius: 10px;
}
.sidebar-search-input .input-wrapper {
  width: 100%;
  position: relative;
  background: #FFFFFF;
  border-radius: 500px;
}
.sidebar-search-input .input-wrapper .input-icon {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: auto;
  font-size: 20px;
  color: #0A102F;
}
.sidebar-search-input input {
  border: 1px solid #E1E1E1;
  border-radius: 500px;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  font-family: var(--font-family-base);
  box-shadow: none;
}
@media (min-width: 576px) {
  .sidebar-search-input input {
    font-size: 18px;
  }
}
.sidebar-search-input input::placeholder {
  color: #0A102F;
  padding-left: 20px;
}
.sidebar-category {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
  font-family: var(--font-family-base);
  width: 100%;
  color: #0A102F;
}
@media (min-width: 576px) {
  .sidebar-category {
    font-size: 18px;
  }
}
.sidebar-blog-widget-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.sidebar-newsletter {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.sidebar-newsletter p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
}
@media (min-width: 576px) {
  .sidebar-newsletter p {
    font-size: 18px;
  }
}
.sidebar-newsletter input {
  border: 1px solid #E1E1E1;
  border-radius: 500px;
  height: 55px;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(10, 16, 47, 0.8);
  font-family: var(--font-family-base);
}
.sidebar-newsletter input::placeholder {
  color: rgba(10, 16, 47, 0.8);
  padding-left: 40px;
}

/* ========== Contact main Section Style ============= */
.account-section {
  min-height: 100vh;
}
.account-section .account-heading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 40px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .account-section .account-heading-block {
    margin-bottom: 60px;
    row-gap: 60px;
  }
}
@media (min-width: 1400px) {
  .account-section .account-heading-block {
    row-gap: 100px;
  }
}
.account-section .account-heading-block .heading-md {
  margin-bottom: 15px;
}
.account-section .account-heading-block p {
  color: rgba(10, 16, 47, 0.8);
}
.account-section .account_comment-box {
  background: #FFFFFF;
  border: 2px solid #0A102F;
  border-radius: 10px;
  padding: 40px;
}
.account-section .account_comment-box__form-inner {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.account-section .account_comment-box .form-control {
  border: 1px solid rgba(10, 16, 47, 0.5);
  border-radius: 500px;
  padding: 15px 30px;
  width: 100%;
  color: rgba(10, 16, 47, 0.5);
  box-shadow: none;
}
.account-section .account_comment-box .account-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: var(--font-family-base);
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .account-section .account_comment-box .account-title {
    font-size: 18px;
  }
}
.account-section .account_comment-box .account-condition-block {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: space-between;
}
.account-section .account_comment-box .account-condition-block a {
  color: rgba(10, 16, 47, 0.8);
}
.account-section .account_comment-box .account-condition {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(10, 16, 47, 0.8);
}
.account-section .account_comment-box .account-condition span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(49, 71, 255, 0.8);
}
.account-section .account_comment-box__form-input-button {
  text-align: center;
}
.account-section .account_comment-box__form-input-button .button-bottom-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(10, 16, 47, 0.8);
  margin-top: 15px;
}
.account-section .account_comment-box__form-input-button .button-bottom-text a {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(49, 71, 255, 0.8);
}

/* ========== Blog Main Style ============= */
.blog-details_main-section .comment-widget {
  padding-left: 10px;
}
.blog-details_main-section .comment-widget__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-left: 30px;
  padding-right: 30px;
}
.blog-details_main-section .inner_banner-title {
  letter-spacing: -0.02em;
  font-size: 32px;
}
@media (min-width: 576px) {
  .blog-details_main-section .inner_banner-title {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .blog-details_main-section .inner_banner-title {
    font-size: 44px;
  }
}
@media (min-width: 1200px) {
  .blog-details_main-section .inner_banner-title {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .blog-details_main-section .inner_banner-title {
    max-width: 1076px;
  }
}

.blog-content-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
.blog-content-wrapper p {
  color: rgba(10, 16, 47, 0.8);
  margin-bottom: 24px;
}
.blog-content-wrapper h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.67;
  font-family: "Inter", sans-serif;
}
@media (min-width: 576px) {
  .blog-content-wrapper h3 {
    font-size: 18px;
  }
}
.blog-content-wrapper span {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  line-height: 1.67;
  color: #0A102F;
  font-family: "Inter", sans-serif;
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .blog-content-wrapper span {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .blog-content-wrapper span {
    margin-bottom: 40px;
  }
}
.blog-content-meta {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 24px;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.blog-content-meta a {
  color: #0A102F;
}
.blog-content-meta span {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.blog-content__user {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.blog-content__user img {
  height: 45px;
}
.blog-content__time {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.blog-content__category {
  padding: 5.5px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: rgba(10, 16, 47, 0.05);
  color: rgba(10, 16, 47, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.blog-content__social-options {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
  margin-top: 20px;
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .blog-content__social-options {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
.blog-content__social-options span {
  display: flex;
  column-gap: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
.blog-content__social-options .social-options-left {
  display: flex;
  column-gap: 24px;
}
.blog-content__social-options .social-options-right {
  display: flex;
  column-gap: 24px;
}
.blog-content__reply-form {
  position: relative;
  display: flex;
  row-gap: 15px;
  column-gap: 20px;
}
@media (max-width: 576px) {
  .blog-content__reply-form {
    flex-wrap: wrap;
  }
}
.blog-content__reply-form .form-control {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  color: rgba(10, 16, 47, 0.5);
  box-shadow: none;
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
}
@media (min-width: 576px) {
  .blog-content__reply-form .form-control {
    font-size: 18px;
  }
}
.blog-content__reply-form .form-control.textarea {
  height: 150px;
  border: 1px solid #0A102F;
  border-radius: 10px;
}
.blog-content__reply-form-image {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 500px;
}
.blog-content__reply-form-image img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}
.blog-content__reply-form__button-group {
  position: absolute;
  column-gap: 24px;
  max-width: 200px;
  display: flex;
  align-items: center;
  right: 8%;
  bottom: 11%;
}
.blog-content__reply-form__button-group button {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(10, 16, 47, 0.8);
  padding: 5.5px 32.5px;
  background: rgba(10, 16, 47, 0.05);
  border-radius: 500px;
  border-width: 0px;
}
.blog-content__reply-button {
  padding: 5.5px 32.5px;
  border-radius: 500px;
  background: rgba(10, 16, 47, 0.05);
  font-size: 16px;
  line-height: 1.5;
  color: rgba(10, 16, 47, 0.6);
}

.coming-soon-section {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
@media (min-width: 768px) {
  .coming-soon-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .coming-soon-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .coming-soon-section {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
.coming-soon-section .row {
  justify-content: center;
}
.coming-soon_content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coming-soon_content-wrapper .logo {
  max-width: 110px;
  margin-bottom: 100px;
}
.coming-soon_newsletter {
  position: relative;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
@media (max-width: 991px) {
  .coming-soon_newsletter {
    gap: 30px;
  }
}
.coming-soon_newsletter .newsletter-form-1 .form-control {
  font-weight: 400;
  font-size: 18px;
  border-color: rgba(var(--body-color-rgb), 0.5);
}
.coming-soon_newsletter .newsletter-form-1 .form-control::placeholder {
  color: rgba(var(--body-color-rgb), 0.5);
  font-weight: 400;
}
.coming-soon_newsletter .newsletter-form-1 button {
  padding-left: 42px;
  padding-right: 42px;
}
.coming-soon_newsletter-bottom-text {
  font-size: 16px;
  opacity: 0.8;
}

#clock {
  color: var(--bs-primary);
  font-size: 16px;
  line-height: 1.13;
  font-weight: 700;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  #clock {
    font-size: 32px;
    gap: 20px;
  }
}
@media (min-width: 992px) {
  #clock {
    font-size: 48px;
    gap: 30px;
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  #clock {
    font-size: 62px;
    gap: 40px;
    margin-bottom: 60px;
  }
}
#clock .counter-item {
  display: flex;
  flex-direction: column;
}
#clock .counter-postfixer {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 10px;
}
@media (min-width: 768px) {
  #clock .counter-postfixer {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  #clock .counter-postfixer {
    font-size: 20px;
  }
}

.error-section {
  padding-top: 200px;
  padding-bottom: 120px;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 372px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-content__image {
  margin-bottom: 30px;
}
.error-content__image img {
  max-width: 100%;
}
.error-content__button {
  margin-top: 21px;
}

/* ========== Career Main Style ============= */
.career_main-image {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .career_main-image {
    margin-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .career_main-image {
    margin-bottom: 100px;
  }
}
.career_main-image img {
  width: 100%;
}
.career-main__section-heading .navigation-list {
  gap: 15px;
}
.career-main__section-heading .navigation-list .btn {
  background-color: rgba(10, 16, 47, 0.0392156863);
  color: rgba(10, 16, 47, 0.6);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.career-main__section-heading .navigation-list li.active .btn {
  color: white !important;
}

/* ========== Career Details Main Style ============= */
.career-details {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .career-details {
    margin-bottom: 60px;
  }
  .career-details:last-child {
    margin-bottom: 0;
  }
}
.career-details_main-section .row--custom {
  --bs-gutter-y: 40px;
}
@media (min-width: 992px) {
  .career-details_main-section .row--custom {
    --bs-gutter-y: 60px;
  }
}
.career-details_section__title {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  font-family: var(--font-family-base);
  color: #0A102F;
  margin-bottom: 18px;
}
@media (min-width: 576px) {
  .career-details_section__title {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .career-details_section__title {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .career-details_section__title {
    font-size: 48px;
  }
}
.career-details_title {
  line-height: 1.5;
  letter-spacing: 0em;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.0208333333em;
  font-size: 20px;
  font-family: var(--font-family-base);
  color: #0A102F;
  margin-bottom: 18px;
}
@media (min-width: 768px) {
  .career-details_title {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .career-details_title {
    font-size: 24px;
  }
}
.career-details_job-info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 15px;
}
.career-details_job-info span {
  display: flex;
  column-gap: 5px;
  font-size: 16px;
  line-height: 1.67;
  font-weight: 400;
  color: #0A102F;
}
@media (min-width: 576px) {
  .career-details_job-info span {
    font-size: 18px;
  }
}
.career-details p {
  max-width: 636px;
  margin-bottom: 30px;
}
.career-details ul {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}
.career-details ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.67;
}
@media (min-width: 576px) {
  .career-details ul li {
    font-size: 18px;
  }
}

/* Common: Background Colors */
.bg-primary-l2 {
  background-color: var(--l2-primary);
}

/* Common: Background Image */
.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.object-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

/* Common: Positioning */
.absolute-center {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.link-box {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 42px;
  padding-right: 42px;
  text-align: center;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
}
.link-box a {
  color: #fff;
  text-decoration: underline;
}

.section-divider {
  position: relative;
  z-index: -99;
}
.section-divider img {
  width: 100%;
  margin-top: -1px;
}

.border-bottom-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* ========== Section Button   ============= */
.section-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media (min-width: 576px) {
  .section-button {
    margin-top: 60px;
  }
}
@media (min-width: 992px) {
  .section-button {
    margin-top: 80px;
  }
}

/* ========== Common: Spacing Combos  ============= */
.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.section-padding.has-section-heading {
  padding-top: 50px;
}
@media (min-width: 768px) {
  .section-padding.has-section-heading {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .section-padding.has-section-heading {
    padding-top: 121px;
  }
}

.section-padding-bottom {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section-padding-bottom {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-bottom {
    padding-bottom: 134px;
  }
}

.section-padding-top:not(.has-section-heading) {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .section-padding-top:not(.has-section-heading) {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-top:not(.has-section-heading) {
    padding-top: 134px;
  }
}
.section-padding-top.has-section-heading {
  padding-top: 50px;
}
@media (min-width: 768px) {
  .section-padding-top.has-section-heading {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .section-padding-top.has-section-heading {
    padding-top: 121px;
  }
}

.section-padding-95 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section-padding-95 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
}

.section-padding-100 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section-padding-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section-padding-120 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 676px) {
  .section-padding-120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-120 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .section-padding-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.section-padding-top-120 {
  padding-top: 60px;
}
@media (min-width: 676px) {
  .section-padding-top-120 {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-top-120 {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .section-padding-top-120 {
    padding-top: 120px;
  }
}

.section-padding-bottom-95 {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section-padding-bottom-95 {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-padding-bottom-95 {
    padding-bottom: 95px;
  }
}

.padding-top-100 {
  padding-top: 40px;
}
@media (min-width: 576px) {
  .padding-top-100 {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .padding-top-100 {
    padding-top: 100px;
  }
}

.padding-bottom-100 {
  padding-bottom: 40px;
}
@media (min-width: 576px) {
  .padding-bottom-100 {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .padding-bottom-100 {
    padding-bottom: 100px;
  }
}

.padding-top-120 {
  padding-top: 60px;
}
@media (min-width: 576px) {
  .padding-top-120 {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .padding-top-120 {
    padding-top: 120px;
  }
}

.padding-bottom-120 {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .padding-bottom-120 {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .padding-bottom-120 {
    padding-bottom: 120px;
  }
}

.padding-bottom-150 {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .padding-bottom-150 {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .padding-bottom-150 {
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .padding-bottom-150 {
    padding-bottom: 150px;
  }
}

/* ----------------------------
Common: Spacing Utilities
-------------------------------*/
.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

/* ----------------------------
Common: Responsive Spacer
-------------------------------*/
.spacer-top-80 {
  margin-top: 40px;
}
@media (min-width: 576px) {
  .spacer-top-80 {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .spacer-top-80 {
    margin-top: 80px;
  }
}

.spacer-bottom-80 {
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .spacer-bottom-80 {
    margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .spacer-bottom-80 {
    margin-bottom: 80px;
  }
}

/* ----------------------------
Common: Spacing Gutters
-------------------------------*/
.gutter-y-20 {
  --bs-gutter-y: 20px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gutter-y-40 {
  --bs-gutter-y: 40px;
}

.gutter-y-50 {
  --bs-gutter-y: 50px;
}

.gutter-y-default {
  --bs-gutter-y: 24px;
}

/* Common: Section Heading */
.section-heading {
  margin-bottom: 40px;
}
@media (min-width: 575px) {
  .section-heading {
    margin-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .section-heading {
    margin-bottom: 80px;
  }
}
.section-heading--white .section-heading__title {
  color: #fff;
}
.section-heading--row {
  row-gap: 30px;
}
.section-heading--row > .row {
  justify-content: space-between;
  align-items: flex-end;
  --bs-gutter-y: 30px;
}
.section-heading--row .section-heading__title {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .section-heading--row .section-heading__button {
    text-align: right;
  }
}

.heading-xxl {
  line-height: 1.05;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: -1px;
  margin-bottom: 24px;
}
@media (min-width: 480px) {
  .heading-xxl {
    font-size: 46px;
  }
}
@media (min-width: 576px) {
  .heading-xxl {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .heading-xxl {
    font-size: 68px;
  }
}
@media (min-width: 1200px) {
  .heading-xxl {
    font-size: 80px;
  }
}
@media (min-width: 1400px) {
  .heading-xxl {
    font-size: 100px;
  }
}
.heading-xxl--syne {
  line-height: 0.98;
  letter-spacing: -0.02em;
  font-family: "Syne", sans-serif;
}

.heading-xl {
  line-height: 1.11;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: -0.0111111111em;
  margin-bottom: 24px;
}
@media (min-width: 480px) {
  .heading-xl {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .heading-xl {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .heading-xl {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .heading-xl {
    font-size: 76px;
  }
}
@media (min-width: 1400px) {
  .heading-xl {
    font-size: 90px;
  }
}
.heading-xl--general-sans {
  font-weight: 600;
  font-family: "General Sans", sans-serif;
  line-height: 1.04;
  letter-spacing: initial;
}
.heading-xl--dm-sans {
  font-family: "DM Sans", sans-serif;
}
.heading-xl--public-sans {
  font-family: "Public Sans", sans-serif;
}
.heading-xl--syne {
  line-height: 0.98;
  letter-spacing: -0.02em;
  font-family: "Syne", sans-serif;
}
.heading-xl--general-sans {
  line-height: 1.11;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.0111111111em;
  line-height: 1.04;
  letter-spacing: 0em;
  font-family: "General Sans", sans-serif;
}
@media (min-width: 480px) {
  .heading-xl--general-sans {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .heading-xl--general-sans {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .heading-xl--general-sans {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .heading-xl--general-sans {
    font-size: 76px;
  }
}
@media (min-width: 1400px) {
  .heading-xl--general-sans {
    font-size: 90px;
  }
}
.heading-xl--v2 {
  line-height: 1.11;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: -0.0111111111em;
  letter-spacing: initial;
  line-height: 1.07;
}
@media (min-width: 480px) {
  .heading-xl--v2 {
    font-size: 42px;
  }
}
@media (min-width: 576px) {
  .heading-xl--v2 {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .heading-xl--v2 {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .heading-xl--v2 {
    font-size: 76px;
  }
}
@media (min-width: 1400px) {
  .heading-xl--v2 {
    font-size: 90px;
  }
}

.heading-md {
  font-size: 32px;
  line-height: 1.26;
  font-weight: 800;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .heading-md {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .heading-md {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .heading-md {
    font-size: 46px;
  }
}
.heading-md--dm-sans {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -0.021739em;
}
.heading-md--v3 {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 800;
}
@media (min-width: 576px) {
  .heading-md--v3 {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .heading-md--v3 {
    font-size: 44px;
  }
}
@media (min-width: 1200px) {
  .heading-md--v3 {
    font-size: 50px;
  }
}
.heading-md--general-sans {
  font-weight: 600;
  font-family: "General Sans", sans-serif;
}

.heading-lg {
  font-size: 32px;
  line-height: 1.14;
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .heading-lg {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .heading-lg {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .heading-lg {
    font-size: 56px;
  }
}
.heading-lg--general-sans {
  font-weight: 600;
  font-family: "General Sans", sans-serif;
  line-height: 1.26;
}
.heading-lg--clash-display {
  font-size: 32px;
  font-weight: 500;
  line-height: 0.94;
  font-family: "Clash Display", sans-serif;
  line-height: 1.13;
  font-weight: 500;
}
@media (min-width: 576px) {
  .heading-lg--clash-display {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .heading-lg--clash-display {
    font-size: 46px;
  }
}
@media (min-width: 1200px) {
  .heading-lg--clash-display {
    font-size: 60px;
  }
}
.heading-lg--syne {
  font-family: "Syne", sans-serif;
  letter-spacing: -0.017857em;
}
.heading-lg--cabin {
  font-size: 32px;
  line-height: 1.12;
  font-weight: 700;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}
@media (min-width: 576px) {
  .heading-lg--cabin {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .heading-lg--cabin {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .heading-lg--cabin {
    font-size: 56px;
  }
}
.heading-lg--public-sans {
  font-size: 32px;
  line-height: 1.12;
  font-weight: 700;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}
@media (min-width: 576px) {
  .heading-lg--public-sans {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .heading-lg--public-sans {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .heading-lg--public-sans {
    font-size: 56px;
  }
}

.heading-sm {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.025em;
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .heading-sm {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  .heading-sm {
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  .heading-sm {
    font-size: 40px;
  }
}

.heading-xs {
  font-weight: 600;
  letter-spacing: -0.033333em;
  font-family: "General Sans", sans-serif;
  line-height: 1.4;
  font-size: 24px;
  font-family: var(--font-family-base);
}
@media (min-width: 768px) {
  .heading-xs {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .heading-xs {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .heading-xs {
    font-size: 30px;
  }
}

.heading-xs-2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  font-family: var(--font-family-base);
}
@media (min-width: 576px) {
  .heading-xs-2 {
    font-size: 20px;
  }
}

/******* Utility Classes ********/
.justify-center {
  justify-content: center;
}

.h-full {
  height: 100%;
}

.fw-400 {
  font-weight: normal;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

@media (min-width: 768px) {
  .text-md-initial {
    text-align: initial !important;
  }
}

@media (min-width: 992px) {
  .text-lg-initial {
    text-align: initial !important;
  }
}

/******* Utility Text Colors ********/
.text-primary-l02 {
  color: var(--l2-primary);
}

/******* Utility Background Colors ********/
.bg-white {
  background: #FFFFFF;
}

.bg-cream {
  background-color: #FDFBF9;
}

.bg-cream-dark {
  background-color: #F5EFEB;
}

.bg-black {
  background-color: var(--body-color);
}

.bg-black-2 {
  background-color: #121212;
}

.bg-black-3 {
  background-color: #1B1C1D;
}

.bg-orange {
  background: #E35523;
}

.bg-primary-l6 {
  background: #3147FF;
}

.bg-primary-l09 {
  background: #324438;
}

.bg-secondary-l09 {
  background: #F4B905;
}

.bg-offwhite-2 {
  background: #FFF4D9;
}

.bg-light-2 {
  background-color: #FAF9F5;
}

.bg-offwhite-3 {
  background: #F5F3EA;
}

.bg-offwhite-dark {
  background: #EBDECE;
}

.bg-primary-opacity-l2 {
  background-color: rgba(var(--l2-primary-rgb), 0.03);
}

.text-black {
  color: var(--body-color) !important;
}

.text-black-2 {
  color: #121212;
}

.text-black-3 {
  color: #221F1A;
}

.text-l7-primary {
  color: #191919;
}

.text-l5-secondary {
  color: #1B1C1D;
}

.text-cream {
  color: #FDFBF9;
}

.text-offwhite-2 {
  color: #FFF4D9;
}

.service-divider {
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(10, 16, 47, 0.1);
}
@media (min-width: 576px) {
  .service-divider {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .service-divider {
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .service-divider {
    padding-bottom: 120px;
  }
}
