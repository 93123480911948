@import "../include/variables";
.cart-container {
   margin-top: 150px;
   display: flex;
   flex-direction: column;
   align-items: center;
}
#link-breadcrumb {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;

   /* identical to box height, or 114% */

   /* Information/DEF */
   font-family: "SF Pro Display";
   color: #1a63f4;
}
#mainlink-breadcrumb {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   text-decoration: none;
   line-height: 35px;
   @include breakpoint(mobile) {
      font-size: 14px;
      line-height: 0px;
   }
   /* identical to box height, or 114% */

   /* Grey/700 */

   color: #374253;
   &:hover {
      opacity: 0.7;
   }
}
#redlink-breadcrumb {
   font-family: "SF Pro Display";
   font-style: normal;
   line-height: 35px;
   font-weight: 500;
   font-size: 18px;
   text-decoration: none;
   /* identical to box height, or 114% */

   /* Primary/DEF */

   color: #ed1c24;
   @include breakpoint(mobile) {
      font-size: 14px;
      line-height: 0px;
   }
}
#digital {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   margin-left: 3px;
   line-height: 24px;
   cursor: pointer;
   /* identical to box height, or 150% */

   .decrease-button {
      background: #f4f7fb !important;
      border-radius: 5px;
   }
   /* Grey/600 */

   color: #596579;
}
.payment_border {
   border: 1px solid #ed1c24;
   border-radius: 16px;
}
#shipping-charge-address .Dropdown-control {
   width: 402px;
   background: #f4f7fb !important;
   /* Grey/300 */

   border: 1px solid #d0d7e2 !important;
   border-radius: 8px !important;
   @include breakpoint(mobile) {
      width: 100%;
      max-width: 400px;
   }
}
#shipping-charge-address .Dropdown-menu {
   width: 402px;
}
#selectborder {
   border: 1px solid #e6eaf0;
   border-radius: 6px;
   padding: 14px 26px;
   border: 1px solid #e6eaf0;
   border-radius: 6px;
   max-width: 571px;
   width: 100%;
}

#address-shipping {
   margin-left: 5px;
   font-style: normal;
   cursor: pointer;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/600 */

   color: #596579;
}
.delete-ship {
   cursor: pointer;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;

   /* identical to box height, or 150% */

   /* Primary/DEF */

   color: #ed1c24;
}
#voucher-button {
   margin-left: 47px !important;
   /* padding-left: 19px; */
   width: 255px !important;
   margin-top: 11px !important;
}
.apply-button {
   background: #a7b2c3;
   border-radius: 0px 10px 10px 0px;
   color: #ffff;
   pointer-events: none !important;
   cursor: not-allowed !important;
}
.focus-dropdown {
   &:focus {
      outline: #c2dbfe solid 4px;
      width: 402px;
      border-radius: 8px;
   }
}
.apply-button-green {
   background: #0e9e49;
   border-radius: 0px 10px 10px 0px;
   color: #ffff;
   &:hover {
      opacity: 0.9;
      background: #0e9e49;
   }
}

// .decrease-cart {
//    background: #f4f7fb;
//    border-radius: 5px;
//    color: black;
//    border: none;
// }
// .increase-cart {
//    background: #f4f7fb;
//    border-radius: 5px;
//    color: black;
//    border: none;
// }
.shipping-address_content {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/600 */

   color: #596579;
}
.edit {
   cursor: pointer;
}
.cart-container_title {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 36px;
   padding-left: 12px;
   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #192638;
   .css-1wuw8dw-MuiBreadcrumbs-separator {
      margin-left: 12px !important;
      margin-right: 12px !important;
   }
   @media only screen and (max-width: 768px) {
      padding-left: 22%;
   }
   @media only screen and (max-width: 600px) {
      padding-left: 12px;
   }
}
.cart-container_details {
   display: flex;
   justify-content: space-between;
   margin-top: 10px;
   @include breakpoint(mobile) {
      flex-direction: column;
      align-items: stretch;
      gap: 20px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      align-items: flex-start;
      gap: 0px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      align-items: flex-start;
      gap: 0px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      gap: 0px;
      align-items: flex-start;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 0px;
      align-items: flex-start;
   }
   .cart-container_address {
      /* Grey/200 */
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #e6eaf0;
      box-sizing: border-box;
      border-radius: 10px;
      @include breakpoint(mobile) {
         padding-right: 12px;
         div {
            padding-left: 12px !important;
         }
      }
   }
   .payment_methods {
      display: flex;
      gap: 15px;
      margin-top: 10px;
      flex-wrap: wrap;
   }
   .cart-container_address_flexcontainer {
      display: flex;
      position: relative;
      margin-top: 15px;
      width: 100%;
      padding-bottom: 35px;
      @include breakpoint(mobile) {
         padding-right: 12px;
      }
   }
   // #cash-on-delivery {
   //    margin-top: 20px;
   //    width: 500px;
   //    padding: 5px;
   //    height: 50px;
   //    margin-right: 10px;

   //    /* Grey/200 */

   //    border: 1px solid #e6eaf0;
   //    box-sizing: border-box;
   //    border-radius: 6px;
   // }
   .cart-container_address li {
      list-style-type: none;
   }
   .cart-container_address_title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 144% */

      /* Grey/700 */

      color: #374253;
   }
   .cart-container_address_subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Grey/Default */

      color: #6e6681;
   }
}
.cart-container_yourorders {
   width: 350px;

   left: 1187px;
   top: 274px;

   /* Grey/50 */

   background: #ffffff;
   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 10px;
   flex-basis: initial;
   @include breakpoint(mobile) {
      align-self: center;
   }
   .cart-container_yourorders_title {
      margin-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #374253;
   }
   .cart-container_yourorders_box_img {
      display: flex;
      justify-content: center;
      width: 95px;
      height: 112px;
      border: 1px solid #e6eaf0;
      box-sizing: border-box;
      border-radius: 8px;
      img {
         object-fit: contain;
         width: 100%;
      }
   }
}
.cart-container_yourorders_description_name {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;

   /* identical to box height, or 144% */
   color: #000000;
}
.cart-container_yourorders_description_color {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;

   /* identical to box height, or 157% */

   /* Grey/600 */

   color: #596579;
}
.cart-container_yourorders_description_price {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;

   /* identical to box height, or 157% */

   /* Grey/800 */

   color: #192638;
   display: flex;
   align-items: center;
}
.cart-container_yourorders_box {
   display: flex;
   justify-content: flex-start;
   gap: 12px;
   align-items: center;
}
// .cart-container_yourorders_box_description_pricecontainer{
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }
.cart-container_yourorders_total {
   display: flex;
   justify-content: space-around;
   .cart-container_yourorders_total_text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Grey/600 */

      color: #596579;
   }
   .cart-container_yourorders_total {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Grey/700 */

      color: #374253;
   }
}
.cart-container_yourorders_total_sum {
   display: flex;
   justify-content: space-around;
   margin-top: 8px;
}
.cart-container_yourorders_total_sum_text {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 20px;
   /* identical to box height, or 100% */

   /* Grey/800 */

   color: #192638;
}

.totalsum_btn {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 250px;
   height: 44px;
   left: 1211px;
   top: 796px;
   margin-left: 50px;
   /* Success/DEF */

   background: #0e9e49 !important;
   border-radius: 8px;
   border: none;
   &:hover {
      background: #096f33 !important;
   }
}
.totalsum_btn_disabled {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 250px;
   height: 44px;
   left: 1211px;
   top: 796px;
   margin-left: 50px;
   /* Success/DEF */

   background: grey !important;
   border-radius: 8px;
   border: none;
}
.btn_privacy {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   /* or 157% */

   display: flex;
   align-items: center;

   /* Grey/DEF */

   color: #7b8698;
}
.text_privacy {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;

   /* or 157% */

   /* Grey/DEF */
   text-align: center;
   color: #7b8698;
}
.edit {
   display: flex;
   gap: 10px;
}
#shipping-address {
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
   padding: 14px 26px;
   align-items: center;
   /* Grey/200 */
   width: 100%;
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 6px;
   p {
      margin: 0px;
   }
   label {
      margin: 0;
      margin-left: 6px;
   }
   div {
      display: flex;
      align-items: center;
   }
}

// #modal-body {
//    text-align: center;
// }
#modal-header {
   border-bottom: none !important;
   border-top-left-radius: none !important;
   border-top-right-radius: none !important;
   padding-bottom: 0;
}
#modal-footer {
   justify-content: center !important;
   border-top: none !important;
}
#modal-footer-address {
   justify-content: flex-start !important;
   border-top: none !important;
   padding-top: 0;
}
#cancel {
   color: #596579;
   background: #f4f7fb;
   border: none !important;
   border-radius: 12px;
   width: 100px;
   &:hover {
      background-color: #e8ecf2 !important;
      color: black !important;
   }
}
#removecart {
   width: 100px;
   background: #ed1c24;
   border: none !important;
   border-radius: 8px;
   &:hover {
      background: #d31118 !important;
   }
}
.cartdelete-title {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;

   /* identical to box height, or 140% */

   /* Grey/700 */

   color: #374253;
}
.cartdelete-sub {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;

   /* identical to box height, or 150% */

   /* Grey/600 */

   color: #596579;
}
#deletecart-modal {
   max-width: 500px !important;
}

.increase-cart {
   border-radius: 5px;
   background: #f4f7fb;
   border: none;
   color: black;
   padding: 3px 11px;
   margin: 5px;

   border: none;
   &:hover {
      background: #d4d8de;
      color: #000000;
   }
}
.decrease-cart {
   border-radius: 5px;
   color: black;
   background: #f4f7fb;
   border: none;

   padding: 3px 11px;
   margin: 5px;

   border: none;
   &:hover {
      background: #d4d8de;
      color: #000000;
   }
}
.increase-cart-disable {
   cursor: not-allowed !important;

   padding: 3px 11px;
   /* height: 22px; */
   margin: 5px;
   border: none;
}
.cart-container_yourorders_total_sum_text_disable {
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 20px;
   /* identical to box height, or 100% */

   /* Grey/800 */
   text-decoration: line-through;
   color: #192638;
}

.cart-container-redeem {
   font-style: normal;
   font-weight: 400;

   /* identical to box height, or 157% */
   font-size: 18px;
   font-style: italic;
   color: deeppink;
   font-weight: bold;
   /* Grey/600 */
}
#cart-container-price {
   color: deeppink;
   font-size: 18px;
   font-style: italic;
   font-weight: bold;
}
#modal-title {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 28px;
   /* identical to box height, or 140% */

   /* Grey/700 */

   color: #374253;
}
#modal-body-location {
   background: #ffffff;
   /* Grey/300 */

   border: 1px solid #d0d7e2;
   border-radius: 12px;
   width: 450px;
   @include breakpoint(mobile) {
      width: 96%;
   }
}
#modal-label {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/700 */

   color: #374253;
}
#modal-form {
   background: #f4f7fb;
   /* Grey/300 */

   border: 1px solid #d0d7e2;
   border-radius: 8px;
}

#modal_btn {
   background: #ed1c24;
   border-radius: 12px;
   width: 195px;
   border: none;
   padding: 10px 0px;
   &:hover {
      background: #d31118;
   }
   @include breakpoint(mobile) {
      width: fit-content;
      padding-left: 35px;
      padding-right: 35px;
   }
}
#modal_btn2 {
   background: #f4f7fb;
   border-radius: 12px;
   color: #596579;
   width: 195px;
   border: none;
   padding: 10px 0px;
   &:hover {
      background: #e4e6e9;
   }
   @include breakpoint(mobile) {
      width: fit-content;
      padding-left: 35px;
      padding-right: 35px;
   }
}
.modal-content {
   background-color: #ffff !important;
   border-radius: 18px;
   padding: 8px 0px;
   border: none !important;
}
#esewa-border {
   border: 1px solid #e6eaf0;
   border-radius: 16px;
}
.payment_hover {
   cursor: pointer;
   &:hover {
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.1);
   }
}
#delete_cart {
   margin-left: 15px;
   cursor: pointer;
   &:hover {
      opacity: 0.7;
   }
}
