@import "../include/variables";

.features__container {
   background: #f8fafc;
   height: 210px;
   display: flex;
   justify-content: center;
   width: 100%;
   @include breakpoint(mobile) {
      height: fit-content;
   }
   @include breakpoint(phablet) {
      height: fit-content;
   }
   @include breakpoint(tablet) {
      height: fit-content;
   }
   @include breakpoint(laptop) {
      height: fit-content;
   }
   @include breakpoint(desktop) {
      height: 210px;
   }
   @include breakpoint(2k) {
      height: 210px;
   }
   .features {
      padding: 50px 0px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include breakpoint(mobile) {
         flex-direction: column;
         align-items: center;
         gap: 30px;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
         align-items: center;
         gap: 30px;
      }
      @include breakpoint(tablet) {
         flex-direction: row;
         align-items: flex-start;
      }
      @include breakpoint(laptop) {
         align-items: flex-start;
         flex-direction: row;
      }
      @include breakpoint(desktop) {
         align-items: flex-start;
         flex-direction: row;
      }
      @include breakpoint(2k) {
         align-items: flex-start;
         flex-direction: row;
      }
   }
}
.features_container {
   display: flex;
   width: fit-content;
   justify-content: flex-start;
   gap: 30px;
   width: 300px;

   .icon__container {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
      img {
         height: 30px;
         width: 30px;
      }
   }
}
.features img {
   height: 30px;
   width: 30px;
}
.features_title {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;

   /* identical to box height, or 140% */

   /* Grey/700 */
   margin-bottom: 6px;
   color: #374253;
}
.features_description {
   font-style: normal;
   font-weight: normal;
   font-size: 13px;
   line-height: 25px;
   /* or 150% */

   /* Grey/600 */
   margin-bottom: 6px;
   color: #596579;
}
.features_link {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 10px;
   /* identical to box height, or 157% */

   /* Information/DEF */
   text-decoration: none;
   color: #1a63f4;
   &:hover {
      opacity: 0.7;
   }
}
