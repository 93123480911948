@import "../../include/variables";

.pricing-table {
   max-width: 1150px;
   margin: 0 auto;

   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-gap: 2rem;

   border-bottom: 1px solid rgba(230, 234, 240, 0.8);
}

.plan-button {
   /* Primary/DEF */

   background: #ed1c24 !important;
   border: none;
   border-radius: 200px;
   &:hover {
      background: #d31118 !important;
   }
}
.price-container {
   margin-top: 30px;
}
.pricing-title {
   padding-top: 10px;
   padding-bottom: 10px;
}

//newly made
.proporties__container {
   display: flex;
   flex-direction: column;
   margin-bottom: 30px;
   .proporties-wrapper {
      max-width: 1150px;
      width: 100%;
      margin: 0 auto;
      padding: 15px 10px;
      display: grid;
      grid-template-columns: 80px 1fr 1fr 1fr;
      grid-gap: 8px;
      align-items: center;
      border-bottom: 1px solid rgba(230, 234, 240, 0.8);
      @media screen and (max-width: 860px) {
         grid-template-columns: 80px 1fr 1fr;
         border: 0px;
      }
      @include breakpoint(mobile) {
         grid-template-columns: 1fr 1fr;
      }
   }
}
.form-wrapper {
   display: flex;
   gap: 22px;
   flex-basis: 85%;
   @media screen and (max-width: 1360px) {
      flex-basis: 98%;
      width: 100%;
   }
   @media screen and (max-width: 530px) {
      gap: 20px;
   }
   @include breakpoint(mobile) {
      width: 100%;
   }
   .Dropdown-root {
      width: 100%;
   }
}
.dropdown-wrapper {
   height: 130px;
}
.button-wrapper {
   max-width: 1150px;
   margin: 0 auto;
   // padding: 0 60px;
   padding: 15px 10px;
   display: grid;
   grid-template-columns: 80px 1fr 1fr 1fr;
   grid-gap: 8px;
   align-items: flex-end;
   margin-top: 25px;
   justify-items: center;
   width: 100%;
   @media screen and (max-width: 860px) {
      grid-template-columns: 80px 1fr 1fr;
   }
   @include breakpoint(mobile) {
      grid-template-columns: 1fr 1fr;
   }
}

.properties-title {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   text-align: center;
   margin: 0px;
   /* identical to box height, or 140% */

   /* Grey/600 */

   color: #596579;
}

//new css
.properties-description {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   text-align: center;
   /* identical to box height, or 144% */

   /* Grey/700 */

   color: #374253;
}
