@import "../include/variables";
.dashboardOrderHistory__Wrapper {
   .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #192638;
      margin-bottom: 6px;
   }
   .subheading {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b8698;
   }
   .order_options {
      display: flex;
      gap: 25px;
      .order_options__item {
         font-weight: 500;
         font-size: 20px;
         line-height: 24px;
         color: #192638;
         margin-bottom: 6px;
         padding-bottom: 3px;
         cursor: pointer;
      }
      .active {
         color: #ed1c24;
         border-bottom: 2px solid #ed1c24;
      }
   }
   .orderDate {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #596579;
   }
}

.orderCardHeader {
   display: flex;
   p {
      flex-basis: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #374253;
   }
}
.orderCard {
   display: flex;
   p {
      // flex-basis: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   img {
      flex-basis: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      color: #596579;
   }
}
.myorder_payment {
   .payment_method-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #374253;
   }
   .payment_methods {
      max-width: 1150px;
      margin: 0 auto;
      padding: 10px 10px;
      display: grid;

      grid-template-columns: repeat(3, 1fr);
      @media only screen and (max-width: 444px) {
         grid-template-columns: repeat(2, 1fr);
      }

      grid-gap: 2rem;
      .payment_options {
         width: 95px;
         border-radius: 24px;
         // border: 1px solid #e6e6e6;
         img {
            width: 100%;
            object-fit: cover;
         }
      }
   }
   .pay_now {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: rgb(237, 28, 36);
      border-radius: 24px;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      cursor: pointer;
   }
}

.packageOrder__card {
   display: flex;
   flex-direction: column;
   background-color: rgb(244, 247, 251);
   border-radius: 16px;
   .orderId {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #374253;
      margin-bottom: 4px;
   }
   .orderDate {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #596579;
   }
   .packageOrder__card__body {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .details {
         .title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #374253;
            margin-bottom: 8px;
         }
         .packageType,
         .total {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #596579;
            margin-bottom: 4px;
         }
      }
      .status {
         .paymentStatus {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #374253;
            margin-bottom: 4px;
         }
         .paidAt {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #596579;
         }
         .payNow_btn {
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            padding-left: 16px;
            padding-right: 16px;
            height: 50px;
            background-color: rgb(237, 28, 36);
            border-radius: 24px;
            color: #fff;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            cursor: pointer;
         }
      }
   }
}

.order_card {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background: #f6f6f6;
   border-radius: 8px;
   margin-bottom: 12px;
   @include breakpoint(mobile) {
      padding: 10px 14px;
   }
   @include breakpoint(phablet) {
      padding: 10px 14px;
   }
   @include breakpoint(laptop) {
      padding: 15px 30px;
   }
   .order_card_details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
         margin: 0px;
      }
      .orderId {
         font-family: "SF Pro Display";
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 24px;
         color: #374253;
      }
      .date {
         font-style: normal;
         font-weight: 500;
         font-size: 13px;
         line-height: 13px;
         display: flex;
         align-items: flex-end;
         gap: 6px;
         /* Grey/600 */
         color: #596579;
      }
      .status {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 6px;
         font-style: normal;
         font-weight: 500;
         font-size: 13px;
         line-height: 13px;
         color: #596579;
         .status_indicator {
            margin-left: 4.5px;
            margin-right: 4.5px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: #596579;
            text-transform: capitalize;
         }
      }
      .pending {
         color: #ff6f00;
         .status_indicator {
            background: #ff6f00;
         }
      }
      .delivered {
         color: #0faf51;
         .status_indicator {
            background: #0faf51;
         }
      }
      .cancelled {
         color: #ed1c24;
         .status_indicator {
            background: #ed1c24;
         }
      }
      .confirmed {
         color: #3e7cf6;
         .status_indicator {
            background: #3e7cf6;
         }
      }
   }
   .order_card_images {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: flex;
      }
      @include breakpoint(laptop) {
         display: flex;
      }
      @include breakpoint(desktop) {
         display: flex;
      }
      @include breakpoint(2k) {
         display: flex;
      }
      flex-direction: row;
      gap: 2px;
      .imageCard {
         img {
            height: 85px;
            width: 81px;
            object-fit: contain;
         }
      }
      .numberCard {
         display: flex;
         height: 85px;
         width: 81px;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         p {
            margin: 0px;
         }
         .imageNumber {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 13px;
            color: #9ca3af;
         }
         .more {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #9ca3af;
            line-height: 13px;
         }
      }
   }
   .order_card_view_order {
      color: #1a63f4;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
   }
}

.orderdetails_modal {
   display: flex;
   flex-direction: column;
   padding: 45px 35px;

   @include breakpoint(mobile) {
      padding: 20px 15px;
   }
   .order_Id_details {
      display: flex;
      flex-direction: row;
      gap: 22px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #374253;
      flex-wrap: wrap;
      p {
         flex-wrap: wrap;
      }
      .id_red {
         color: #ed1c24;
         white-space: nowrap;
      }
      .status_text {
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 10px;
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 13px;
         padding: 14px 12px;
      }
      .pending {
         color: #ff6f00;
         background: #ffecb3;
      }
      .delivered {
         color: #0faf51;
         background: #a6f5c7;
      }
      .cancelled {
         color: #f03e45;
         background: #fee9e9;
      }
      .confirmed {
         color: #3e7cf6;
         background: #d1e0fd;
      }
   }
   .orderdetails_modal_body {
      display: flex;
      justify-content: space-between;
      gap: 26px;
      margin-top: 30px;
      @include breakpoint(mobile) {
         flex-direction: column;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
      }
      @include breakpoint(2k) {
         flex-direction: row;
      }

      .ordersummaryanddelivery {
         flex-basis: 48%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: flex-start;
         gap: 32px;
         .orderSummary {
            background-color: #fff;
            border: 1px solid #d0d7e2;
            border-radius: 18px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 25px 30px;
            @include breakpoint(mobile) {
               padding: 15px 10px;
            }
            .orderSummary_Heading {
               margin-bottom: 12px;
               color: #374253;
               font-style: normal;
               font-weight: 600;
               font-size: 20px;
               line-height: 13px;
            }
            .orderSummary_details_row {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
               p {
                  margin: 0;
               }
               .details_row_title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #596579;
               }
               .details_row_description {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #596579;
               }
               .paid {
                  color: #0faf51;
               }

               .unpaid {
                  color: #ed1c24;
               }
            }
         }
         .deliveryDetails {
            background-color: #fff;
            border: 1px solid #d0d7e2;
            border-radius: 18px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 25px 30px;
            @include breakpoint(mobile) {
               padding: 15px 10px;
            }
            .deliveryDetails_Heading {
               margin-bottom: 12px;
               color: #374253;
               font-style: normal;
               font-weight: 600;
               font-size: 20px;
               line-height: 13px;
            }
            .delevery_details_row {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
               p {
                  margin: 0;
               }
               .details_row_title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #596579;
               }
            }
         }
      }
      .orderdetails {
         flex-basis: 48%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: flex-start;
         .item_summary {
            background-color: #fff;
            border: 1px solid #d0d7e2;
            border-radius: 18px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 25px 30px;
            padding-right: 12px;
            @include breakpoint(mobile) {
               padding: 15px 10px;
            }
            .itemSummary_Heading {
               margin-bottom: 22px;
               color: #374253;
               font-style: normal;
               font-weight: 600;
               font-size: 20px;
               line-height: 13px;
            }
            .items {
               display: flex;
               flex-direction: column;
               max-height: 300px;
               overflow-y: auto;
               padding-right: 24px;
               @include breakpoint(mobile) {
                  padding-right: 4px;
               }
               .item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  padding-bottom: 20px;
                  padding-top: 12px;
                  border-bottom: 1px solid #e6eaf0;
                  gap: 6px;
                  &:last-child {
                     border-bottom: 0px;
                  }
                  .item_details {
                     display: flex;
                     align-items: center;
                     gap: 14px;
                     .img {
                        width: 64px;
                        height: 66px;
                        object-fit: contain;
                     }
                     .product_details {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        p {
                           margin: 0;
                        }
                        .title {
                           font-style: normal;
                           font-weight: 600;
                           font-size: 15px;
                           line-height: 16px;
                           color: #374253;
                        }
                        .price {
                           font-style: normal;
                           font-weight: 500;
                           font-size: 13px;
                           line-height: 13px;
                           color: #374253;
                        }
                        .deal_text {
                           font-style: normal;
                           font-weight: 500;
                           font-size: 14px;
                           line-height: 14px;
                           color: #9f7878;
                        }
                     }
                  }
                  .totalandreview {
                     display: flex;
                     flex-direction: column;
                     align-items: flex-end;
                     gap: 10px;
                     .total {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 13px;
                        color: #374253;
                        white-space: nowrap;
                     }
                     .review {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 13px;
                        cursor: pointer;
                        color: #ff6f00;
                        padding: 8px 12px;
                        border: 1px solid #ff6f00;
                        border-radius: 8px;
                     }
                  }
               }
            }
            .internet_items {
               display: flex;
               flex-direction: column;
               gap: 3px;
               p {
                  margin: 0;
               }
               .internet_item_tab {
                  display: flex;
                  justify-content: space-between;
                  padding: 4px 6px;
                  align-items: center;
                  p {
                     margin: 0;
                  }
                  .internet_item_tab_text {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 16px;
                     line-height: 13px;
                     color: #596579;
                  }
               }
            }
            .viewPackage {
               display: flex;
               padding: 4px 6px;
               align-items: center;
               justify-content: flex-end;
               margin-top: 8px;
               .viewPakcageLink {
                  text-decoration: none;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #3371c7;
                  cursor: pointer;
                  &:hover {
                     color: #375c91;
                  }
               }
            }
         }
         .priceSummary {
            background-color: #fff;
            border: 1px solid #d0d7e2;
            border-radius: 18px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 25px 30px;
            margin-top: 16px;
            @include breakpoint(mobile) {
               padding: 15px 10px;
            }
            .orderSummary_Heading {
               margin-bottom: 12px;
               color: #374253;
               font-style: normal;
               font-weight: 600;
               font-size: 20px;
               line-height: 13px;
            }
            .orderSummary_details_row {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
               p {
                  margin: 0;
               }
               .details_row_title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #596579;
               }
               .details_row_description {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 13px;
                  color: #596579;
               }
               .paid {
                  color: #0faf51;
               }

               .unpaid {
                  color: #ed1c24;
               }
            }
         }
         .totalandButtomGroup {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            align-items: center;
            width: 100%;
            gap: 4px;
            p {
               margin: 0;
            }
            @media only screen and (max-width: 1200px) {
               flex-direction: column;
               gap: 20px;
               align-items: flex-start;
            }
            .total {
               font-style: normal;
               font-weight: 600;
               font-size: 24px;
               line-height: 13px;
               margin-left: 4px;
               color: #374253;
               white-space: nowrap;
               span {
                  color: #0e9e49;
               }
            }
            .buttonGroup {
               display: flex;
               gap: 10px;
               justify-content: flex-end;
               flex-wrap: wrap;
               .button {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 13px;
                  background: #f8fafc;
                  color: #596579;
                  padding: 12px 18px;
                  cursor: pointer;
                  border-radius: 4px;
                  border: 1px solid #596579;
                  display: flex;
                  align-items: center;
                  &:hover {
                     background-color: #a7a7a7;
                     color: #fff;
                     border: 1px solid #fff;
                  }
               }
               .paynow {
                  background: #0e9e49;
                  color: #e4feef;
                  border: 1px solid #fff;
                  &:hover {
                     background: #147c41;
                  }
               }
               .cancel_btn {
                  color: #e4feef;
                  background: #f03e45;
                  border: 1px solid #fff;
                  &:hover {
                     background: #d31118;
                  }
               }
            }
         }
      }
   }
}

.order_options__dropdown {
   margin-bottom: 12px;
   .Dropdown-control {
      width: 240px;
   }
   // .Dropdown-arrow {
   //    border-color: #ed1c24 transparent;
   // }
}
.Dropdown-menu {
   max-width: 240px;
}
