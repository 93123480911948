@import "../include/variables";
.movieinfo_container {
   position: relative;
   background: #0f1825;
   display: flex;
   flex-direction: column;
   align-items: center;

   .container_hide {
      transform: translateX(100%);
   }
   .showtime_container {
      height: 100%;
      position: absolute;
      width: 590px;
      background: url(../../assets/ppvmovies/showtimebg.png);
      right: 0;
      top: 0;
      box-shadow: inset 42.3333px -42.3333px 42.3333px rgba(194, 194, 194, 0.031),
         inset -42.3333px 42.3333px 42.3333px rgba(255, 255, 255, 0.031);
      backdrop-filter: blur(194.733px);
      transition: transform 1s;
   }
   .showtime_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: flex;
      }
      @include breakpoint(laptop) {
         display: flex;
      }
      @include breakpoint(desktop) {
         display: flex;
      }
      @include breakpoint(2k) {
         display: flex;
      }
      .showtime_content {
         display: flex;
         flex-direction: column;
         gap: 10px;
         width: 100%;
         max-width: 446px;
         margin-top: 75px;
         .showtime_content_time {
            display: flex;
            width: 100%;
            align-items: center;
            margin-left: -12px;

            ._title,
            ._content {
               display: flex;
               align-items: center;
               height: 70px;
               flex-basis: 50%;
               color: #fff;
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
            }
            ._title {
               padding-left: 36px;
               background: #1b2737;
            }
            ._content {
               background: #132133;
               justify-content: center;
            }
         }
      }
      .movie_button {
         margin-top: 35px;
         background: #ed1c24;
         width: 100%;
         max-width: 446px;
         text-align: center;
         padding: 12px 20px;
         box-sizing: border-box;
         border-radius: 12px;
         color: #ffffff;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         cursor: pointer;
         span {
            color: #ffffff;
         }
      }

      .close {
         position: absolute;
         right: 30px;
         top: 20px;
         background: transparent;
         border: none;
         color: #fff;
         font-size: 20px;
      }
      .showtime_title {
         font-style: normal;
         font-weight: 500;
         font-size: 30px;
         line-height: 32px;
         /* identical to box height, or 107% */
         /* Grey/50 */
         color: #ffffff;
         margin-top: 136px;
         margin-bottom: 42px;
      }
      .showtime_box {
         box-shadow: inset 20px -20px 20px rgba(19, 29, 43, 0.095),
            inset -20px 20px 20px rgba(255, 255, 255, 0.095);
         backdrop-filter: blur(40px);
         /* Note: backdrop-filter has minimal browser support */
         background: 101825;
         background: linear-gradient(#101825, #101825) padding-box,
            linear-gradient(93.97deg, #ad00c9 2.18%, #00d1ff 50.68%) border-box;
         border-radius: 10px;
         border: 1px solid transparent;
         width: 450px;
         height: 100px;
         display: flex;
         align-items: center;
         justify-content: center;
         .showtime_date {
            word-wrap: break-word;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */
            text-align: center;
            /* Grey/50 */
            color: #ffffff;
            //height and width
            width: 100px;
            height: 100px;
            //backgroundcolor
            background-color: transparent;
            padding: 15px;
            border: none;
            border-radius: 100%;
         }
         .active {
            background: radial-gradient(
               50% 50% at 50% 50%,
               rgba(196, 196, 196, 0.2) 0%,
               rgba(196, 196, 196, 0) 100%
            );
         }
      }
      .channelname_date_container {
         display: flex;
         align-items: center;
         .channel_name {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */
            /* Grey/50 */
            color: #ffffff;
            margin: 0;
         }
         .time_button {
            background: transparent;
            border: 1px solid #596579;
            border-radius: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            /* or 144% */
            height: 80px;
            width: 80px;
            text-align: center;
            /* Grey/50 */
            color: #ffffff;
            //margin
            margin-right: 24px;
         }
      }
   }
}
.movie_container {
   margin-left: 30px;
   display: flex;
   gap: 3em;
   @include breakpoint(mobile) {
      flex-direction: column;
      margin-left: 0px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      justify-content: flex-start;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      justify-content: flex-start;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      justify-content: flex-start;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      justify-content: flex-start;
   }
   .movie_img {
      img {
         width: 335px;
         height: 460px;
      }
   }
   .movie_info {
      .showtime_box {
         border-radius: 10px;
         border: 1px solid transparent;
         width: 100%;
         max-width: 520px;
         height: 100px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         @include breakpoint(mobile) {
            padding-left: 10px;
            padding-right: 10px;
         }
         .showtime_date {
            word-wrap: break-word;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            color: #7b8698;
            background-color: transparent;
            padding: 15px;
            border: none;
            border-radius: 100%;
            @include breakpoint(mobile) {
               padding: 8px;
            }
            @include breakpoint(phablet) {
               padding: 8px;
            }
            @include breakpoint(tablet) {
               padding: 8px;
            }
            @include breakpoint(laptop) {
               padding: 15px;
            }
            @include breakpoint(desktop) {
               padding: 15px;
            }
            @include breakpoint(2k) {
               padding: 15px;
            }
         }
         .active {
            color: #fff;
         }
      }
      .time_container {
         display: flex;
         gap: 20px;
         margin-top: 10px;
         padding-left: 12px;
         flex-wrap: wrap;
         .showtime {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            text-align: center;
            height: 80px;
            width: 80px;
            box-sizing: border-box;
            border-radius: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            word-wrap: break-word;
            cursor: pointer;
         }
         .expired {
            color: #596579;
            border: 1px solid #596579;
            cursor: not-allowed;
         }
         .active {
            color: #e4feef;
            background: #0e9e49;
            border: 1px solid #0e9e49;
         }
         .inactive {
            color: #0e9e49;
            border: 1px solid #0e9e49;
            background-color: transparent;
            &:active {
               border: 1px solid #fff;
               color: #fff;
            }
         }
      }
   }
}

.title {
   font-weight: 500;
   font-size: 40px;
   line-height: 52px;
   /* identical to box height, or 130% */
   color: #ffffff;
   text-align: left;
   @include breakpoint(mobile) {
      padding-left: 4px;
      padding-right: 4px;
   }
}
.movierating_wrapper {
   display: flex;
   align-items: center;
   color: #ffffff;
   @include breakpoint(mobile) {
      padding-left: 4px;
      padding-right: 4px;
   }
   .moviedefination {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      background-color: #ed1c24;
      border-radius: 4px;
      padding: 4px 10px;
   }
   .movierating,
   .movieduration {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: center;
   }
}
.moviegerna_wrapper {
   @include breakpoint(mobile) {
      padding-left: 4px;
      padding-right: 4px;
   }
   .gernabutton {
      background: #16202e;
      border-radius: 4px;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      cursor: context-menu;
      letter-spacing: 0.5px;
      color: #a7b2c3;
      border: none;
      padding: 8px 12px;
      text-align: center;
   }
}
.movie_info_text {
   font-weight: 400;
   font-size: 17px;
   line-height: 26px;
   /* or 153% */
   /* Grey/300 */
   max-width: 520px;
   color: #d0d7e2;
   border-bottom: 1px solid #374253;
   white-space: pre-wrap;
   @include breakpoint(mobile) {
      padding-left: 4px;
      padding-right: 4px;
   }
}
.movie_info_date {
   display: flex;
   margin-top: 42px;
   .movie_booking_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      /* identical to box height, or 100% */
      display: flex;
      align-items: center;
      justify-content: center;
      /* Grey/50 */
      color: #ffffff;
      background-color: #1a63f4;
      padding: 6px 10px;
      border-radius: 4px;
      margin-right: 16px;
      height: auto;
   }
   .booking_date {
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      /* Grey/50 */
      color: #ffffff;
      margin-right: 50px;
      span {
         color: #d0d7e2;
      }
   }
}
.movie_price {
   .price {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height, or 150% */
      /* Success/DEF */
      color: #0e9e49;
      span {
         font-weight: 500;
         font-size: 18px;
      }
      line-height: 26px;
   }
}

.movie_info_button_wrapper {
   display: flex;
   .movie_button {
      background: rgba(0, 0, 0, 0.05);
      /* Grey/50 */
      padding: 12px 20px;
      border: 1.2px solid #ffffff;
      box-sizing: border-box;
      border-radius: 12px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      cursor: pointer;
   }
   span {
      color: #ed1c24;
   }
}
.dishhomecommand_text {
   margin-left: 30px;
   @include breakpoint(mobile) {
      padding-left: 14px;
      padding-right: 8px;
      margin-left: 0px;
   }
   .title {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height, or 120% */

      /* Grey/50 */
      color: #ffffff;
   }
   .text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      max-width: 600px;
      /* or 144% */
      /* Grey/600 */
      white-space: pre-wrap;

      color: #596579;
      span {
         color: lightblue;
      }
   }
}
.movie_button {
   margin-top: 35px;
   background: #ed1c24;
   width: 100%;
   max-width: 446px;
   text-align: center;
   padding: 12px 20px;
   box-sizing: border-box;
   border-radius: 12px;
   color: #ffffff;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 20px;
   cursor: pointer;
   span {
      color: #ffffff;
   }
}
.showtime_content_time {
   display: flex;
   width: 100%;
   align-items: center;
   margin-left: -12px;

   ._title,
   ._content {
      display: flex;
      align-items: center;
      height: 70px;
      flex-basis: 50%;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
   }
   ._title {
      padding-left: 12px;
      background: #1b2737;
   }
   ._content {
      background: #132133;
      justify-content: center;
   }
}
