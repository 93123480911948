.dashboardBilling_Wrapper {
   .billing-table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      div {
         flex-basis: 22%;
         p {
            margin: 0px;
         }
      }
   }
   .billing-features {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      div {
         flex-basis: 22%;
         p {
            margin: 0px;
         }
      }

      p {
         font-style: normal;
         font-weight: 400;
         font-size: 13px;
         line-height: 13px;
         /* identical to box height, or 100% */

         /* Grey/700 */

         color: #374253;
      }
      a {
         /* Success/DEF */
         text-decoration: none;
         color: #0e9e49;
         border: 1px solid #0e9e49;
         box-sizing: border-box;
         border-radius: 4px;
         padding-left: 10px;
         padding-right: 10px;
      }
   }
   #billings {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height, or 100% */

      /* Grey/800 */

      color: #192638;
   }
   #billings-details {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Grey/DEF */

      color: #7b8698;
   }
   #bill-header {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 100% */

      /* Grey/DEF */

      color: #7b8698;
   }
   .status {
      display: flex;
      justify-content: space-between;
   }
   .billing-features :hover {
      background-color: #f8fafe;
   }
   .dashboard_pagination {
      justify-content: center;
      margin-top: 40px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
      }
   }
}
#documment-title {
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 150%;
   color: #374253;
   /* font-size: 18px; */
   margin: 0px;
}
