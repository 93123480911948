@import "../../scss/include/variables";

.ForgotPassword_Wrapper {
   align-items: center;
   width: 100%;
   display: flex;
   justify-content: space-between;
   gap: 25px;
   @include breakpoint(mobile) {
      justify-content: center;
   }
   @include breakpoint(tablet) {
      justify-content: center;
   }
   @include breakpoint(phablet) {
      justify-content: center;
   }
   @include breakpoint(desktop) {
      justify-content: space-between;
   }
   @include breakpoint(laptop) {
      justify-content: space-between;
   }
}

.ForgotPassword_Form {
   .ForgotPassword_Header {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 10px;
      color: #192638;
   }
   .ForgotPassword_Text {
      max-width: 400px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #192638;
      a {
         text-decoration: none;
         color: #ed1c24;
      }
   }
   @include breakpoint(mobile) {
      align-items: center;
   }
   @include breakpoint(phablet) {
      align-items: center;
   }
   @include breakpoint(tablet) {
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: stretch;
   }
   @include breakpoint(desktop) {
      align-items: stretch;
   }
   @include breakpoint(2k) {
      align-items: stretch;
   }
}

.ForgotPassword_Container {
   display: flex;
   flex-direction: column;
   .error {
      border: 1px solid $primary !important;
      margin-bottom: 8px !important;
   }
   .error_message {
      margin-bottom: 12px;
      max-width: 380px;
   }
   .ForgotPassword_Button {
      background: #ed1c24;
      border-radius: 8px;
      border: none;
      height: 44px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      max-width: 400px;
      margin-bottom: 32px;
      &:hover {
         cursor: pointer;
         &:hover {
            background: #d31118 !important;
         }
      }
   }
   input {
      margin-bottom: 20px !important;
      width: 100% !important;
   }
}
.Forgot_Image {
   display: flex;
   flex-basis: 50%;
   width: 100%;
   max-height: 100vh;
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(laptop) {
      display: flex;
   }
   @include breakpoint(desktop) {
      display: flex;
   }
   img {
      object-fit: cover;
      width: 100%;
      border-radius: 12px;
   }
}
