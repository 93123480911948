@import "../include/variables";
#products_image_Text_design {
   color: #7b8698;
   font-size: 12px;
   margin: 0;
   font-weight: 600;
   // &::first-letter {
   //    font-size: 14px;
   //    font-weight: 600;
   // }
}

.products {
   font-style: normal;
   font-weight: normal;
   font-size: 15px;
   line-height: 18px;
   /* identical to box height */

   text-align: center;

   /* Grey/DEF */

   color: #7b8698;
}
.arrival_box {
   max-width: 1150px;
   display: grid;
   grid-gap: 6px;
   justify-items: center;
   @include breakpoint(mobile) {
      grid-template-columns: repeat(2, 1fr);
   }
   @include breakpoint(phablet) {
      grid-template-columns: repeat(2, 1fr);
   }
   @include breakpoint(tablet) {
      grid-template-columns: repeat(3, 1fr);
   }
   @include breakpoint(laptop) {
      grid-template-columns: repeat(4, 1fr);
   }
   @include breakpoint(desktop) {
      grid-template-columns: repeat(5, 1fr);
   }
   @include breakpoint(2k) {
      grid-template-columns: repeat(5, 1fr);
   }
}

.arrival_title {
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   white-space: nowrap;

   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
   text-transform: capitalize;
   margin-bottom: 0px !important;
   margin-top: 12px;
   /* Grey/700 */

   color: #374253;
}
.arrival_cost {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   margin-bottom: 0px !important;

   /* identical to box height */

   text-align: center;
   text-transform: capitalize;

   /* Grey/DEF */

   color: #7b8698;
}
.arrival_img {
   width: 100%;
   height: 180px;
   background-color: #f4f7fb;
}
.arrival_container {
   border-radius: 6px;
   border: 1px solid #e6eaf0;
   width: 100%;
   &:hover {
      /* Grey/200 */

      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.1);
   }
   @include breakpoint(mobile) {
      width: 150px !important;
   }
}
.col-sm {
   margin-top: 20px;
}
