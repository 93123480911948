.order_options {
   display: flex;
   gap: 25px;
   .order_options__item {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #192638;
      margin-bottom: 6px;
      padding-bottom: 3px;
      cursor: pointer;
   }
   .active {
      color: #ed1c24;
      border-bottom: 2px solid #ed1c24;
   }
}

.billing-table {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 20px;
   width: 100%;
   div {
      flex-basis: 22%;
      p {
         margin: 0px;
      }
   }
}
.billing-features {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   margin-top: 20px;
   div {
      flex-basis: 22%;
      p {
         margin: 0px;
      }
   }

   p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      /* identical to box height, or 100% */

      /* Grey/700 */

      color: #374253;
   }
   a {
      /* Success/DEF */
      text-decoration: none;
      color: #0e9e49;
      border: 1px solid #0e9e49;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
   }
}

.iptv-movie-card {
   display: flex;
   flex-direction: column;
   .iptv-movie-card__image {
      width: 260px;
      height: 364px;
      border-radius: 12px;
      img {
         width: 100%;
         height: 100%;
      }
   }
   .iptv-movie-card__title {
      color: #192638;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px;
   }
   .spam_expiery {
      background: #0e9e49;
      border-radius: 6px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding: 6px 10px;
      text-align: center;
      width: fit-content;
   }
}

.dashboard_pagination {
   justify-content: center;
   margin-top: 34px;
   margin-bottom: 24px;
}
.dashboard_page_item {
   .dashboard_page_link {
      color: #37577d;
   }
}
.dashboard_active {
   .dashboard_page_link {
      color: #fff;
      background-color: #ed1c24;
      border-color: #ed1c24;
   }
}
