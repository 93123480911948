@import "../../scss/include/variables";
.modal-body {
   .Login_Wrapper {
      align-items: center;
      justify-content: center;
      width: 100%;
      display: flex;
   }
}

.Logo {
   margin-top: 10px;
}
.Login_Modal {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   justify-self: center;
   .Login_Alert {
      display: none;
   }
   .Login_Alert_show {
      max-width: 400px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 12px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: $primary;
      display: flex;
      align-items: center;
   }
   .Login_Header {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #192638;
   }
   .Register_Header {
      margin-bottom: 40px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #192638;
      a {
         text-decoration: none;
         color: #1a63f4;
      }
   }
   @include breakpoint(mobile) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(tablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(phablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(desktop) {
      width: 90%;
   }
   @include breakpoint(laptop) {
      width: 90%;
   }
}
.Login_Container {
   display: flex;
   flex-direction: column;
   padding: 0px;
   .Signin_Button {
      background: #ed1c24;
      border-radius: 8px;
      border: none;
      height: 44px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      max-width: 400px;
   }
   .ForgetPassword_Wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 400px;
      margin-bottom: 35px;
      ._checkbox {
         display: flex;
         align-items: center;
         .CheckBox {
            margin: 0 !important;
            margin-right: 4px !important;
            height: 20px !important;
            width: 20px !important ;
         }
         label {
            margin: 0;
         }
      }
   }
   .ForgetPassword_Link {
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #1a63f4;
   }
   input {
      margin-bottom: 32px !important;
      width: 100% !important;
   }
}
