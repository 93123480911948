@import "../include/variables";

.tvchannelcard_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 208px;
   height: 161px;
   border-radius: 10px;
   border: 1px solid #e6eaf0;
   @include breakpoint(mobile) {
      width: 155px;
   }
   .tvchannelcard_img {
      width: 150px;
      flex-basis: 68%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      img {
         width: 146px;
         height: 88px;
         border-radius: 10px;
         object-fit: contain;
      }
   }
   .tvchannelcard_text {
      display: flex;
      flex-basis: 32%;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      /* identical to box height, or 144% */
      /* Grey/700 */
      color: #374253;
      background: rgba(244, 247, 251, 0.5);
      width: 100%;
      p {
         margin: 0;
      }
   }
}
