@import "@splidejs/splide/dist/css/splide.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../include/variables";

.slide_container {
   position: relative;
   // max-height: 700px;
   // @include breakpoint(mobile) {
   //    max-height: 400px;
   // }
   // @include breakpoint(tablet) {
   //    max-height: 400px;
   // }
   // @include breakpoint(laptop) {
   //    max-height: 470px;
   // }
   // @include breakpoint(desktop) {
   //    max-height: 680px;
   // }
   // @include breakpoint(2k) {
   //    max-height: 1200px;
   // }
   // .slick-slider {
   //    @include breakpoint(mobile) {
   //       max-height: 400px;
   //    }
   //    @media screen and (max-width: 498px) {
   //       max-height: 240px;
   //    }
   // }
}
.slick-prev {
   z-index: 5;
   left: 30px;
}
.slick-next {
   z-index: 5;
   right: 30px;
}
.slick-arrow {
   @media screen and (max-width: 498px) {
      top: 35%;
   }
}
.slick-dots {
   z-index: 5;
   bottom: -35px;
   @media screen and (max-width: 498px) {
      bottom: 0px;
   }

   .slick-active {
      button:before {
         color: #ed1c24 !important;
      }
   }
   li {
      button:before {
         font-size: 8px;
      }
   }
   @include breakpoint(2k) {
      bottom: 5px;
   }
}
.slick-slide {
   @include breakpoint(2k) {
      margin-top: -30px;
   }
}
.slick-active {
   margin-right: -105px;
   margin-left: -40px;
   // @include breakpoint(2k) {
   //    margin-right: -160px;
   //    margin-left: -160px;
   // }
}
.slick-cloned {
   margin-left: 25px;
}

.background_blur {
   display: flex;
   position: absolute;
   height: 200px;
   width: 100%;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.91) 100%);
   bottom: 0;

   @include breakpoint(mobile) {
      height: 100px;
   }
   @media screen and (max-width: 495px) {
      bottom: 10px;
      height: 150px;
   }

   .text_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      z-index: 1;
      bottom: -10px;

      @include breakpoint(mobile) {
         bottom: -15px;
      }
      .title {
         font-weight: 500;
         font-size: 20px;
         line-height: 28px;
         color: #0f1825;
      }
      .more {
         font-weight: 500;
         font-size: 20px;
         line-height: 28px;
         /* identical to box height, or 140% */

         /* Information/DEF */

         color: #1a63f4;
      }
      @include breakpoint(2k) {
         bottom: 25px;
      }
   }
}

.imgcontainer {
   width: 84vw !important;
   height: 100%;
   max-height: 75vh;
   // overflow: hidden;
   // object-fit: cover;
   .bannerimg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // @include breakpoint(tablet) {
      //    max-height: 350px;
      // }
      // @include breakpoint(laptop) {
      //    max-height: 400px;
      // }
      // @include breakpoint(desktop) {
      //    max-height: 605px;
      // }
      // @include breakpoint(2k) {
      //    max-height: 700px;
      // }
   }
}
.single-movie-banner {
   width: 100vw !important ;
   max-height: 86vh;
   margin-left: -10px;
}

.overlay-black-inactiveslide {
   .bannerimg {
      filter: hue-rotate(360deg) blur(2px) contrast(0.8) saturate(1.1) grayscale(0.6);
   }
}

.splide__pagination {
   bottom: -0.5em;
   height: 45px;
}

.splide__pagination__page {
   height: 8px;
   width: 8px;
   background-color: #a7b2c3;
}
.splide__pagination__page.is-active {
   // background: #ed1c24;
   transform: none;
}
