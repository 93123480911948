@import "../include/variables";

.support_container {
   display: flex;
   @include breakpoint(mobile) {
      justify-content: space-between;
      flex-direction: column;
      gap: 0px;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(phablet) {
      justify-content: space-between;
      flex-direction: column;
      gap: 0px;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(tablet) {
      justify-content: space-between;
      flex-direction: column;
      gap: 0px;
      padding-left: 12px;
      padding-right: 12px;
   }
   @include breakpoint(laptop) {
      justify-content: space-between;
      flex-direction: row;
      gap: 50px;
      padding: 0px 8px;
   }
   @include breakpoint(desktop) {
      justify-content: space-between;
      flex-direction: row;
      gap: 80px;
      padding: 0px 8px;
   }
   @include breakpoint(2k) {
      justify-content: space-between;
      flex-direction: row;
      gap: 120px;
      padding: 0px 8px;
   }
   #billings {
      margin-top: 60px;
   }

   .form__container {
      .heading {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         color: #192638;
         margin-top: 46px;
      }
      .subheading {
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 22px;
         color: #7b8698;
         margin-bottom: 40px;
      }
      display: flex;
      flex-direction: column;
      flex-basis: 45%;

      .support__form {
         input,
         select {
            width: 100%;
            max-width: 100%;
         }
         textarea {
            background: #f4f7fb;
            border: 1px solid #d0d7e2;
            border-radius: 8px;
            padding: 14px 16px;
         }
         display: flex;
         flex-direction: column;
         ._inputGroup {
            display: flex;
            gap: 20px;
         }
         ._input {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            width: 100%;
            max-width: 100%;
            .submit_Button {
               border: none;
               padding: 12px 0px;
               background: #ed1c24;
               border-radius: 8px;
               font-style: normal;
               font-weight: 500;
               font-size: 17px;
               line-height: 20px;
               text-align: center;
               text-transform: capitalize;
               color: #ffffff;
            }
         }
      }
   }
   .img__container {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
         margin-top: 0px;
         align-items: center;
      }
      @include breakpoint(phablet) {
         margin-top: 0px;
         align-items: center;
      }
      @include breakpoint(tablet) {
         margin-top: 0px;
         align-items: center;
      }
      @include breakpoint(laptop) {
         margin-top: 70px;
         align-items: flex-start;
      }
      @include breakpoint(desktop) {
         margin-top: 70px;
         align-items: flex-start;
      }
      @include breakpoint(2k) {
         margin-top: 70px;
         align-items: flex-start;
      }
      img {
         border-radius: 12px;
         margin-bottom: 30px;
         width: 420px;
         height: 300px;
         object-fit: contain;
      }
      .contact_heading {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #374253;
         margin-bottom: 4px;
      }
      .contact_subheading {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #7b8698;
         margin-bottom: 24px;
         a {
            text-decoration: none;
            color: #1a63f4;
         }
      }
   }
}
.contact_tab__container {
   position: absolute;
   right: 40px;
   top: 40%;
   display: flex;
   flex-direction: column;
   @media screen and (max-width: 998px) {
      top: 55%;
      right: 10px;
   }
   .contact_tab__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      justify-content: space-between;
      border-radius: 12px;
      width: 248px;
      height: 56px;
      margin-bottom: 14px;
      @media screen and (max-width: 998px) {
         width: fit-content;
      }
      a {
         margin: 0px;
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #ffffff;
         text-decoration: none;
         @media screen and (max-width: 998px) {
            display: none;
         }
      }

      img {
         height: 32px;
         width: 32px;
      }
   }
   .phone {
      background: #ed1c24;
   }
   .viber {
      background: #8a62a9;
   }
   .whatsapp {
      background: #44c54c;
   }
}
.phone_details {
   border-radius: 8px;

   padding: 10px;
   height: 230px;
   width: 230px;
}
.phone {
   background: #ed1c24;
}
.viber {
   background: #8a62a9;
}
.whatsapp {
   background: #44c54c;
}
