@import "../include/variables";

.package_wrapper {
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;

   .package_text {
      .package_table {
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #1a63f4;
      }

      .package_header {
         font-style: normal;
         font-weight: bold;
         // font-size: 54px;
         // line-height: 62px;
         color: $grey;
         @include HeaderFontResponsive(54, 62);
         // @include HeaderFontResponsive(44, 58);
      }
   }
   .package_card {
      position: relative;
   }
   #right-image {
      left: 74%;
      position: absolute;
      top: 6%;
   }
   .package_container {
      width: 80vw;
      background: #fef9f3;
      border-radius: 40px;
      @media screen and (max-width: "400px") {
         width: 94vw;
      }
      .package_toggle {
         display: flex;
         text-align: center;
         align-items: center;
         justify-content: center;
         p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $grey;
         }
         .togglebutton {
            div {
               height: 30px !important;
               div {
                  &:nth-child(1) {
                     height: 25px !important;
                  }
                  &:nth-child(2) {
                     height: 100% !important;
                     div {
                        height: 20px !important;
                     }
                  }
               }
            }
         }
         p {
            margin: 0;
         }
      }
      .package_card_wrapper {
         display: flex;
         flex-direction: row;
         justify-content: center;
         .splide {
            margin-left: 15px;
            height: 480px;
            .HD_package {
               height: 460px;
            }
            @include breakpoint(mobile) {
               width: 350px;
            }
            @include breakpoint(phablet) {
               width: 400px;
            }
            @include breakpoint(tablet) {
               width: 650px;
            }
            @include breakpoint(laptop) {
               width: 900px;
            }
            @include breakpoint(desktop) {
               width: 1020px;
            }
            @include breakpoint(2k) {
               width: 1020px;
            }
         }
         .splide__pagination {
            display: none;
            @media screen and (max-width: "400px") {
               display: flex;
               bottom: -2.7em;
               .splide__pagination__page.is-active {
                  background: #e82806;
               }
            }
         }
         .splide__arrow {
            top: 48% !important;
         }
         .splide__arrow--prev {
            @media screen and (max-width: "400px") {
               display: none;
            }
            @include breakpoint(mobile) {
               left: -1.7em !important;
            }
            @include breakpoint(phablet) {
               left: -5em !important;
            }
            @include breakpoint(tablet) {
               left: -5em !important;
            }
            @include breakpoint(laptop) {
               left: -5em !important;
            }
            @include breakpoint(desktop) {
               left: -5em !important;
            }
            @include breakpoint(2k) {
               left: -5em !important;
            }
         }
         .splide__arrow--next {
            @media screen and (max-width: "400px") {
               display: none;
            }
            @include breakpoint(mobile) {
               right: -1em !important;
            }
            @include breakpoint(phablet) {
               right: -3em !important;
            }
            @include breakpoint(tablet) {
               right: -3em !important;
            }
            @include breakpoint(laptop) {
               right: -3em !important;
            }
            @include breakpoint(desktop) {
               right: -3em !important;
            }
            @include breakpoint(2k) {
               right: -3em !important;
            }
         }

         .HD_package {
            margin: 20px;
            @include breakpoint(mobile) {
               display: none;
               &:first-child {
                  display: block !important;
               }
            }
         }
      }
   }
}
