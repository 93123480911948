@import "../include/variables";

.sme_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
   margin-bottom: 120px;
}

.sme_banner {
   display: flex;
   height: 500px;
   align-items: center;
   justify-content: space-between;
   padding-left: 28px;

   .sme_banner_text {
      display: flex;
      flex-direction: column;

      .sme_header {
         font-style: normal;
         font-weight: 700;
         font-size: 54px;
         line-height: 62px;
         color: #192638;
         max-width: 480px;
         span {
            color: #0e9e49;
         }
      }
      .sme_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 520px;
         margin-bottom: 25px;
      }
      .sme_button {
         font-style: normal;
         font-weight: 400;
         font-size: 20px;
         line-height: 30px;
         color: #ffffff;
         border: none;
         background-color: #ed1c24;
         border-radius: 60px;
         padding: 13px 36px;
         width: fit-content;
      }
   }
   .sme_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}

.sme_section_container {
   .formContainer {
      display: flex;
      gap: 60px;
      justify-content: space-around;
      .sme_wrapper {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         @include breakpoint(mobile) {
            flex-direction: column;
         }
         .sme_text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h1 {
               font-style: normal;
               font-weight: 600;
               // font-size: 54px;
               // line-height: 62px;
               max-width: 427px;
               @include HeaderFontResponsive(54, 62);
               span {
                  color: #ed1c24;
               }
            }
            p {
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
               color: #596579;
               max-width: 359px;
               a {
                  text-decoration: none;
               }
            }
         }
      }
      .formWrapper {
         display: flex;
         flex-direction: column;
         .error {
            border: 1px solid $primary !important;
            margin-bottom: 8px !important;
         }
         .error_message {
            margin-bottom: 12px;
         }
         .sme_text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h1 {
               font-style: normal;
               font-weight: 500;
               // line-height: 42px;
               max-width: 427px;
               @include HeaderFontResponsive(34, 42);
               span {
                  color: #ed1c24;
               }
            }
         }
         .inputContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            label {
               text-align: left;
               margin-bottom: 6px;
            }
            input {
               width: 100%;
               margin-bottom: 18px;
            }
         }
         .inputGroup {
            display: flex;
            gap: 18px;
            input {
               max-width: 190px;
            }
            width: 100%;
            .inputContainer {
               width: fit-content;
            }
         }
         .sme_button {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #ffffff;
            border: none;
            background-color: #ed1c24;
            border-radius: 12px;
            padding: 0px 20px;
            height: 44px;
            width: 166px;
            max-width: 400px;
            margin-top: 18px;
            &:hover {
               background: #d31118;
            }
         }
         .loading {
            background: $grey;
            cursor: no-drop;
         }
      }
   }
}
.button_wrapper_modal {
   display: flex;
   gap: 20px;
}
.cancel {
   background: #f4f7fb;
   color: #596579;
   /* border-color: grey; */
   width: 150px;
   border-radius: 12px !important;
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   border: none !important;
   padding: 0px 20px;
   height: 44px;
   width: 166px;
   max-width: 400px;
   margin-top: 18px;
   &:hover {
      background-color: #e8ecf2 !important;
      color: black !important;
   }
}
