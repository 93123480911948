@import "../../scss/include/variables";
:root {
   --select-focus: green;
}

input,
select,
textarea {
   background: #f4f7fb;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 10px 14px;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 16px;
   margin-bottom: 32px;
   max-width: 400px;
   &:focus {
      outline: none;
      border: 1px solid #1a63f4;
   }
}

.input-signup {
   background: #f4f7fb !important;
   border-radius: 8px !important;
   padding: 10px 14px !important;
   font-style: normal !important;
   font-weight: normal !important;
   font-size: 14px !important;
   line-height: 16px !important;
   margin-bottom: 32px !important;
   border: 1px solid #d0d7e2 !important;
}

option {
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 16px;
   color: #374253;
   max-width: 400px;
   background-color: #f4f7fb;
   padding: 5px 5px;
}

button {
   cursor: pointer;
}

label {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   margin-bottom: 6px;
}
.Password_Container {
   position: relative;
   display: flex;
   flex-direction: column;
   max-width: 400px;
   // margin-top: 18px;
}
.Pw_Icon {
   position: absolute;
   right: 18px;
   top: 35px;
   height: 16px;
   width: 16px;
   &:hover {
      cursor: pointer;
   }
   i {
      color: #374253;
      font-size: 16px;
   }
}
.form-check-input[type="radio"] {
   padding: 0px !important;
}
