@import "../../scss/include/variables";

.currentmovies_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   .currentmovies_text,
   .currentmovies_moviecontainer {
      width: 80%;
      display: flex;
   }
   .currentmovies_text {
      display: flex;
      justify-content: center;
      // justify-content: space-between;
      .date_button {
         padding: 0px;
         background-color: transparent;
         color: #0f1825;
         border: 2px solid #a7b2c3;
         color: #a7b2c3;
         margin: 0px 8px;
         width: 58px;
         height: 58px;
         border-radius: 100% !important;
         text-align: center;
         font-weight: 500;
         font-size: 14px;
         line-height: 22px;
      }
      .active {
         border: 2px solid #0e9e49;
         color: #0e9e49;
      }
      h2 {
         font-weight: 700;
         font-size: 40px;
         line-height: 52px;
         /* identical to box height, or 130% */
         /* Grey/900 */
         margin: 0px;
         background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
         background-clip: text;
         -webkit-text-fill-color: transparent;
      }
   }
   .currentmovies_moviecontainer {
      flex-wrap: wrap;
      justify-content: center;
   }

   .dh-itv {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      flex-wrap: wrap;
      .moviecard {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;

         .moviecard_img {
            height: auto;
            width: 300px;
            height: 410px;
            position: relative;
            &:hover img {
               opacity: 0.5;
            }
            &:hover .moviecard_info {
               opacity: 1;
            }
            img {
               width: 100%;
               height: 100%;
               transition: all 0.5s ease-in-out;
               border-radius: 6px;
               object-fit: cover;
               backface-visibility: hidden;
            }

            .moviecard_info {
               transition: 0.3 ease;
               opacity: 0;
               position: absolute;
               height: 100%;
               width: 100%;
               top: 50%;
               left: 50%;
               border-radius: 6px;
               transform: translate(-50%, -50%);
               -ms-transform: translate(-50%, -50%);
               text-align: center;
               background: rgba(0, 0, 0, 0.6);
               //button alignment
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               .moviecard_button {
                  min-width: 157px;
                  border: 1.2px solid #ffffff;
                  border-radius: 12px;
                  background: rgba($color: #000000, $alpha: 0.05);
                  padding: 8px 16px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px;
                  /* identical to box height, or 157% */
                  /* White */
                  color: #ffffff;
                  text-decoration: none;
                  &:hover {
                     background: #0e9e49;
                     color: #ffffff;
                  }
               }
            }
         }
         .moviecard_text {
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
               font-size: 20px;
               font-style: normal;
               font-weight: 600;
               line-height: 28px;
               margin-top: 16px;
               margin-bottom: 0;
            }
            .genre_container {
               display: flex;
               flex-direction: row;
               gap: 8px;
               margin-top: 4px;
               .genre {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  /* identical to box height, or 143% */
                  /* Grey/900 */
                  color: #eff1f5;
                  background: #7d7d7d;
                  border-radius: 4px;
                  padding: 2px 4px;
               }
            }
            .moviecard_btn {
               margin-top: 12px;
               a {
                  background: #0e9e49;
                  border-radius: 4px;
                  padding: 8px 16px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px;
                  /* identical to box height, or 157% */
                  /* White */
                  color: #ffffff;
                  text-decoration: none;
               }
            }
         }
      }
   }
}
