.latestoffer_wrapper {
   margin-top: 90px;
}
#offer_latest {
   .splide__arrow {
      top: 51% !important;
   }
   .splide__list {
      justify-content: center;
   }
}
.recommended-text-offer {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   -webkit-box-orient: vertical;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   margin-top: 30px;
   color: #374253;
   max-width: 250px;
   text-align: center;
}
