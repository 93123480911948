@import "../../include/variables";

.category-bar {
   height: 54px;
   width: 100%;
   // margin-top: 150px;
   // margin-left: 200px;
   /* Grey/100 */
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: #f4f7fb;
   border-radius: 5px;
   margin-bottom: 50px;
   gap: 4px;
}

#sale-tag {
   position: absolute;
   width: 76px;
   height: 32px;
   left: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* Warning/900 */
   background: rebeccapurple;
   border-radius: 6px 0px;
   z-index: 1;
   p {
      margin: 0px;
   }
}
#sale-tag-category {
   position: absolute;
   width: 76px;
   height: 32px;
   left: -15px;
   top: -15px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* Warning/900 */
   background: rebeccapurple;
   color: white;
   border-radius: 5px 0px;
   z-index: 1;
   p {
      margin: 0px;
   }
}
#filter-min-price {
   width: 110px;
}
#maxprice-filter {
   background: red;
   color: white;
   border: none;
   &:hover {
      background: #d31118 !important;
   }
}
.price-container-category {
   display: flex;
   gap: 10px;
   margin-top: 20px;
}
#color-filter {
   border-radius: 50%;
   height: 20px;
   width: 20px;
   cursor: pointer;
   background-color: black;
}
.color-container {
   margin-top: 10px;
   gap: 10px;
   display: flex;
   max-width: 190px;
   /* width: 100%; */
   margin-top: 10px;
   gap: 10px;
   flex-wrap: wrap;
   display: flex;
}
.producttag_wrapper {
   margin-top: 20px;
   max-width: 1150px;
   margin: 0 auto;

   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 15px;
}
#sale-price {
   font-size: 14px;
   text-decoration: line-through;
}

#sale-tag p {
   text-align: center;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Grey/50 */

   color: #ffffff;
}
.category-products {
   // margin-top: 150px;
   display: flex;
   gap: 40px;
   p {
      margin-bottom: 0px !important;
   }
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
   @include breakpoint(2k) {
      flex-direction: row;
   }
   .filter__section {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: none;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
   .canvasopen {
      margin-bottom: -16px;
      margin-left: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #ed1c24;
      padding: 4px 14px;
      width: fit-content;
      cursor: pointer;
      border: 1px solid #ed1c24;
      border-radius: 60px;
      @include breakpoint(mobile) {
         display: block;
      }
      @include breakpoint(phablet) {
         display: block;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: none;
      }
      @include breakpoint(desktop) {
         display: none;
      }
      @include breakpoint(2k) {
         display: none;
      }
   }
}

.product_view {
   position: relative;
   padding: 15px;
   flex-basis: 23%;
   height: 375px;
   overflow: hidden;
   border: 1px solid #e6eaf0;
   border-radius: 5px;
   min-width: 200px;
   @include breakpoint(mobile) {
      min-width: 170px;
      padding-left: 8px;
      padding-right: 8px;
   }
   .category_imagewrapper {
      width: 100%;
      height: 195px;
      border-radius: 5px;
      position: relative;
      img {
         // height: 100%;
         width: 100%;
         height: 175px;
         object-fit: contain;
         padding: 6px;
      }
   }
}

.list_view {
   width: 100%;
   padding: 15px;
   overflow: hidden;
   display: flex;
   border: 1px solid #ebebeb;
   position: relative;
   margin-top: 50px;
   /* Grey/200 */

   .category_imagewrapper {
      flex-basis: 40%;
   }

   .view_img {
      width: 100%;
      height: 100%;
      max-height: 220px;
      object-fit: contain;
   }
}
.css-187mznn-MuiSlider-root {
   width: 74% !important;
}
.icon-div {
   height: 100%;
   display: flex;
   justify-content: flex-start;
   border-width: 1px;
   border-style: solid;
   border-color: #ebebeb;
   border-radius: 5px;
   -moz-border-radius: 5px;
   -webkit-border-radius: 5px;
   align-items: flex-start;
}
.list_view:hover {
   border-color: red;
}
.product_view:hover {
   border-color: red;
}
.list_grid {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   margin-bottom: 0px !important;
   text-align: center;
   display: none;
   color: #7b8698;
}
.list_description {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   margin-bottom: 0px !important;

   display: block;
   color: #7b8698;
}

.view_title {
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
   text-transform: capitalize;
   margin-bottom: 0px !important;
   margin-top: 15px;
   color: #374253;
}
.listview-title {
   font-style: normal;
   font-weight: 600;
   font-size: 16px;

   text-transform: capitalize;
   margin-bottom: 0px !important;
   margin-top: 15px;
   color: #374253;
}
.categorylist_price {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   margin-bottom: 0px !important;
   /* text-align: center; */
   text-transform: capitalize;
   color: #7b8698;
}
.star-grid {
   display: flex;
   justify-content: center;
}
.brand-grid {
   display: flex;
   justify-content: center;
   margin-top: 20px;
}
.brand-list {
   margin-top: 20px;
}
.categorygrid_price {
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   font-size: 14px;
   margin-bottom: 0px !important;
   text-align: center;
   text-transform: capitalize;
   color: #7b8698;
}
.css-187mznn-MuiSlider-root {
   width: 74% !important;
}
.icon-div {
   display: flex;
   width: 65px;
   align-items: center;
}

#category-label {
   margin-left: 5px;
   font-size: 16px;
   color: #0f1825;
   font-weight: 400;

   line-height: 20px;
}
.slider-label {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 28px;
   border-bottom: 1px solid #ebebeb;

   /* identical to box height, or 140% */
   position: relative;
   margin-top: 20px;

   /* Grey/700 */

   padding-bottom: 19px;
   color: #374253;
}
#tv-box {
   display: flex;
}

#inside-tv {
   margin-top: 20px;
   padding: 10px;

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
   border-radius: 15px;
}
#inside-tv:hover {
   background-color: #ef1e1e;
   color: #fff;
   cursor: pointer;
}
.slider-label:after {
   content: "";
   width: 65px;
   height: 2px;
   background: #ef1e1e;
   position: absolute;

   left: 0;
   bottom: -1px;
}
#slider-price {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   margin-top: 10px;
   /* identical to box height */

   /* Grey/600 */

   color: #596579;
}
.slider-li {
   list-style-type: none;
}
#slider-container {
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
   justify-content: space-around;
   width: 60%;
}
#seo-word {
   font-weight: 500;
   font-size: 16px;

   /* identical to box height */

   /* Grey/600 */

   color: #596579;
}
#tv-box {
   text-align: center;
   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
   border-radius: 22px;
}
#scales {
   background: #ffffff;
   /* Grey/400 */
   height: 15px;
   width: 15px;
   border: 1px solid #a7b2c3;
   box-sizing: border-box;
   border-radius: 5px;
}
.category_box {
   width: 100%;
   display: flex;
   margin-bottom: 30px;
   flex-wrap: wrap;
   // justify-content: center;
   @include breakpoint(mobile) {
      gap: 5px;
      justify-content: center;
   }
   @include breakpoint(phablet) {
      gap: 8px;
   }
   @include breakpoint(tablet) {
      gap: 12px;
   }
   @include breakpoint(laptop) {
      gap: 18px;
   }
   @include breakpoint(desktop) {
      gap: 18px;
   }
   @include breakpoint(2k) {
      gap: 18px;
   }
}
.category_box_list {
   display: flex;
   flex-direction: column;
}
.details_info {
   padding-left: 30px;
   width: 65%;
}

.active-view {
   color: #f03e45;
   margin-left: 10px;
   cursor: pointer;
}
.grid {
   margin-left: 10px;
   color: #7b8698;
   cursor: pointer;
}
.list {
   margin-left: 5px;
   color: #7b8698;
   cursor: pointer;
}
.card-img-top {
   object-fit: contain;
   height: 130px;
}
.filterproducts {
   display: flex;
   justify-content: space-around;
   flex-direction: column;
}
.form-category {
   display: flex;
   gap: 10px;
   align-items: center;
}
.icon-div img {
   margin-left: 15px;
}
#form-div {
   margin-right: 15px;
   width: 210px;
   height: 34px;
   margin-bottom: 0px !important;

   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 30px !important;
   @include breakpoint(mobile) {
      width: 125px;
      font-size: 14px;
      option {
         font-size: 14px;
         line-height: 18px;
      }
   }
}
#form-div1 {
   width: 178px;
   height: 34px;
   margin-bottom: 0px !important;

   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 30px !important;
   @include breakpoint(mobile) {
      width: 125px;
      font-size: 14px;
      option {
         font-size: 14px;
         line-height: 18px;
      }
   }
}
#category_product {
   margin-top: 50px;
}
#seo-words {
   text-align: center;
}
// .view_img:hover{

//     -webkit-transform: scale(1.5);
// 	transform: scale(1.5);
// 	-webkit-transition: .3s ease-in-out;
// 	transition: .3s ease-in-out;
//         }
.activeColor_category {
   border: 3px solid red;
}
.activeTag_category {
   border: 1px solid #ed1c24;
   filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
   border-radius: 22px;
}
#sale-tag2 {
   color: white;
   position: absolute;
   width: 76px;
   top: 0;
   left: 0;
   height: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: rebeccapurple;
   border-radius: 5px 0px;
   z-index: 1;
}
#producttag_title {
   border: 1px solid #e6eaf0;
   cursor: pointer;
   padding: 10px;
   border-radius: 22px;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   text-align: center;
   /* identical to box height */

   /* Grey/600 */

   color: #596579;
   margin-bottom: 0px !important;
   &:hover {
      opacity: 0.8;
   }
}
