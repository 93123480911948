.kyc-container {
   margin-top: 80px;
}
.kyc-text {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 36px;
   /* identical to box height, or 150% */

   /* Success/DEF */

   color: #0e9e49;
}
#kyc-zone {
   width: 250px;
}
#kyc-text-wrapper {
   margin-top: 20px;
}
.apply-cas-button {
   background: #ed1c24;
   border-radius: 0px 8px 8px 0px;
   color: white;
   border: none;
}
.apply-cas-button-otp {
   background: #ed1c24;
   border-radius: 0px 8px 8px 0px;
   color: white;
   border: none;
   font-size: 13.5px;
}
.input_container {
   max-width: 516px;
   display: flex;
   flex-direction: row;
   gap: 15px;
   margin-top: 15px;
}
#ward_no {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   /* identical to box height, or 100% */

   /* Grey/700 */

   color: #3d3358;
}
.input-wrapper-billing {
   display: flex;
   justify-content: flex-start;
   align-items: baseline;
   gap: 34px;
}
#input-flex {
   // background: #f4f7fb;
   border: 1px solid #d0d7e2;
   box-sizing: border-box;
   border-radius: 8px;
   width: 183px;
}
#billing-title {
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 26px;
   margin-top: 30px;
   color: #374253;
}

#kycform-btn {
   margin-top: 20px;
   background: #ed1c24;
   border-radius: 12px;
   border: none;
   width: 200px;
   height: 45px;
   &:hover {
      background: #d31118 !important;
   }
}
.apply-otp-button {
   background: #ed1c24;
   color: white;
   border: none;
}
