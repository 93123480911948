.ottmovieSlide__widthContainer {
   background-color: #6d3bff;
   color: #ffffff;
   padding-top: 60px;
   padding-bottom: 80px;
}
.ottmovieSlide__container {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   .ottmovieSlide__heading {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: #ffffff;
      margin-bottom: 12px;
   }
   .ottmovieSlide__text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      max-width: 420px;
      text-align: center;
      margin-bottom: 50px;
   }
   .ottmovieSlide__slider {
      display: flex;
      gap: 20px;
      .nextmoviecard_wrapper {
         max-width: 315px;
         .nextmoviecard_img {
            img {
               max-height: 500px;
               max-width: 320px;
               margin: 0px;
            }
         }
      }
   }
}
