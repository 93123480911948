@import "../../scss/include/variables";

.ottFeature__container {
   margin-top: 110px;
   margin-bottom: 140px;
   display: flex;

   padding-left: 12px;
   padding-right: 12px;
   @include breakpoint(mobile) {
      flex-direction: column;
      gap: 35px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      gap: 35px;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
      gap: 25px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      gap: 30px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      gap: 30px;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 90px;
   }
   .ottFeatureText {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
      .feature_text {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #0e9e49;
      }
      .featuretextt_heading {
         font-style: normal;
         color: #192638;
         max-width: 420px;
         margin-bottom: 20px;
         @include breakpoint(mobile) {
            font-weight: 600;
            font-size: 38px;
            line-height: 46px;
         }
         @include breakpoint(phablet) {
            font-weight: 600;
            font-size: 44px;
            line-height: 54px;
         }
         @include breakpoint(tablet) {
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
         }
         @include breakpoint(laptop) {
            font-weight: 700;
            font-size: 54px;
            line-height: 64px;
         }
         @include breakpoint(desktop) {
            font-weight: 700;
            font-size: 54px;
            line-height: 64px;
         }
         @include breakpoint(2k) {
            font-weight: 800;
            font-size: 58px;
            line-height: 69px;
         }
      }
      .featuretext_para {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #596579;
         max-width: 400px;
      }
   }
   .ottFeatureCards {
      flex-basis: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
      .ottFeatureCard {
         max-width: 280px;
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         img {
            width: 64px;
            height: 64px;
            margin-bottom: 14px;
         }
         .feature_card_heading {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 10px;
         }
         .feature_card_text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
         }
      }
   }
}
