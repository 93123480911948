@import "../include/variables";
.hot-deals {
   margin-top: 100px;
   .hot_deals_shopnow {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 69px;
      margin-bottom: 5px;
      color: #ed1c24;
   }
}

.hot_deals {
   display: flex;
   gap: 30px;
   align-items: center;
   justify-content: center;
}
.hot_deals_title {
   font-style: normal;
   font-weight: 600;
   font-size: 40px;
   line-height: 52px;
   text-align: center;

   color: #192638;
}
.hot_deals_description {
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 26px;
   /* identical to box height, or 144% */
   margin-bottom: 50px;
   text-align: center;

   /* Grey/600 */

   color: #596579;
}
#hot_deals_wrapper {
   display: flex;
   gap: 15px;
   flex-wrap: wrap;
   justify-content: center;
}
.hot_deals_container {
   flex-basis: 33%;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: left;
   height: 565px;
   max-width: 312px;
   /* Grey/50 */
   background: #ffffff;
   /* Grey/100 */
   border: 1px solid #f4f7fb;
   box-sizing: border-box;
   box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.06);
   border-radius: 13px;
   position: relative;
   cursor: pointer;
   &:hover {
      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.1);
   }
}
.hot_deals_container_brand {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   /* identical to box height, or 150% */
   /* Grey/600 */
   margin-top: 30px;

   color: #596579;
}
.hot_deals_container_name {
   font-style: normal;
   font-weight: 600;
   font-size: 24px;

   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #192638;
}
.hot_deals_container_price {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;

   /* identical to box height, or 144% */

   /* Grey/600 */

   color: #596579;
}
.hot_deals_img {
   background-image: url("../../assets//store/hotdeals.png");
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
   height: 300px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   .apple {
      width: 300px;
      height: 300px;
      object-fit: contain;
   }
}

.hot_deals_text_wrapper {
   width: 100%;
   text-align: left;
   padding-left: 33px;
   height: 195px;
   @include breakpoint(mobile) {
      padding-left: 18px;
      padding-right: 18px;
   }
}

.shop-now {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 26px;
   /* identical to box height, or 144% */
   position: absolute;
   bottom: 22px;
   margin: 0px;
   height: fit-content;

   /* Grey/900 */

   color: #0f1825;
}
