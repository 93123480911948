@import "../../scss/include/variables";

.Signup_Wrapper {
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.Signup_Form {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   @include breakpoint(mobile) {
      align-items: center;
   }
   @include breakpoint(phablet) {
      align-items: center;
   }
   @include breakpoint(tablet) {
      align-items: center;
   }
   @include breakpoint(laptop) {
      align-items: stretch;
   }
   @include breakpoint(desktop) {
      align-items: stretch;
   }
   @include breakpoint(2k) {
      align-items: stretch;
   }
   .Signup_Alert {
      display: none;
   }
   .Signup_Alert_show {
      max-width: 400px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: $primary;
      display: flex;
      align-items: center;
   }
   .Login_Header {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #3d3358;
   }
   .Register_Header {
      max-width: 350px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 40px;
      color: #192638;
      a {
         text-decoration: none;
         color: #1a63f4;
      }
   }
   @include breakpoint(mobile) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(tablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(phablet) {
      padding-right: 10px;
      width: 100%;
   }
   @include breakpoint(desktop) {
      width: 50%;
   }
   @include breakpoint(laptop) {
      width: 50%;
   }
}

.Signup_Container {
   display: flex;
   flex-direction: column;
   margin-top: 12px;
   .Name {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      width: 100%;
   }
   .Name_Container {
      display: flex;
      padding: 0;
      max-width: 400px;
      justify-content: space-between;
      @include breakpoint(mobile) {
         flex-direction: column;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
      }
   }
   .Password_Container {
      .error {
         border: 1px solid $primary !important;
         margin-bottom: 8px !important;
      }
      input {
         padding-right: 35px;
      }
   }
   .error {
      border: 1px solid $primary !important;
      margin-bottom: 8px !important;
   }
   .error_message {
      margin-top: 0px;
      margin-bottom: 12px;
   }

   .Signin_Button {
      background: #ed1c24;
      border-radius: 8px;
      border: none;
      height: 44px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 26px;
      color: #fff;
      max-width: 400px;
      &:hover {
         background: #d31118 !important;
      }
   }
   input {
      margin-bottom: 32px !important;
      width: 100% !important;
   }
}
.Signup_Image {
   flex-basis: 50%;
   width: 100%;
   max-height: 700px;
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(laptop) {
      display: flex;
   }
   @include breakpoint(desktop) {
      display: flex;
   }
   img {
      object-fit: cover;
      width: 100%;
      border-radius: 12px;
   }
}
#verification-code-btn {
   color: #ffff;
   border: 1px solid grey;
   border-radius: 8px;
   max-width: 405px;
   padding: 10px;
   text-align: center;
   background-color: #0e9e49;
   &:hover {
      background: #096f33 !important;
   }
}
