@import "../../scss/include/variables";

#notfound {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   height: 80vh;
   .notfound {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .notfound-404 {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         .notFoundText {
            margin-top: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            color: #192638;
         }
      }
      a {
         text-decoration: none;
         font-style: normal;
         font-weight: 500;
         font-size: 23px;
         line-height: 20px;
         color: #1a63f4;
         margin-top: 30px;
      }
   }
}
