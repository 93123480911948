@import "../../include/variables";

.indvProduct_container {
   @include breakpoint(mobile) {
      flex-direction: column;
      gap: 0;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      gap: 0;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      gap: 50px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      gap: 50px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      gap: 50px;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 50px;
   }
}
.product_title {
   font-style: normal;
   font-weight: 600;
   font-size: 26px;
   line-height: 26px;
   margin-top: 30px;
   margin-bottom: 20px;
   /* identical to box height, or 100% */

   /* Grey/800 */

   color: #192638;
   display: inline-flex;
   align-items: center;
}
#modal-addcart-header {
   border-top: none !important;
}
#wishlist-main {
   cursor: pointer;
}
#addtocart-cart {
   background: #ed1c24 !important;
   border-radius: 12px !important;
   border: none;
   &:hover {
      background: #d31118 !important;
   }
}
#continue-shopping {
   background: #f4f7fb;
   border-radius: 12px;
   color: #596579;
   border: none;
}
#addtocart-modal {
   width: 400px;
   height: 400px;
}
.add-to-cart-header {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   margin-top: 15px;
   /* identical to box height, or 150% */

   text-align: center;

   /* Grey/600 */

   color: #596579;
}
#modal-addcart-footer {
   border-bottom: none !important;
   display: flex;
   margin: auto;
   gap: 15px;
   margin-top: 15px;
}
.product_option {
   gap: 12px;
   display: flex;
   align-items: baseline;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 23px;
   /* identical to box height, or 100% */

   /* Grey/700 */

   color: #374253;
   margin-bottom: 8px;
   @include breakpoint(mobile) {
      margin-bottom: 3px;
   }
}
#color-filter {
   margin-bottom: 10px;
}
#availaibility-brand {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 16px;

   /* identical to box height, or 100% */

   /* Success/DEF */

   color: #0e9e49;
}
#not-availaibility-brand {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 16px;
   margin-left: 31px;
   /* identical to box height, or 100% */

   /* Success/DEF */

   color: #ed1c24;
}
#brand-span {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 16px;
   /* identical to box height, or 100% */

   /* Grey/DEF */

   color: #7b8698;
}
#color-margin {
   line-height: 30px;
   @include breakpoint(mobile) {
      line-height: 26px;
   }
}
#dot {
   height: 20px;
   width: 20px;
   background-color: #bbb;
   border-radius: 50%;
}
.fluid {
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   font-family: Arial;
   line-height: 1.3;
   font-size: 16px;
}

.original-image {
   border: 1px solid #e6eaf0;
   position: relative;
   // overflow: hidden;
   // height: 400px;
   // width: 330px;
   text-align: center;
   border-radius: 6px;
   margin: 8px 10px 10px 8px;
   // img {
   //    display: inline-block;
   //    max-height: 100%;
   //    max-width: 100%;
   //    vertical-align: middle;
   // }
}

#thumbnail-image {
   display: flex;
   gap: 15px;
   margin-top: 20px;
   margin-left: 1em;
   max-width: 500px;
   .splide {
      width: 390px;
   }
   .splide__arrow--next {
      right: -40px !important;
   }
   .splide__arrow {
      top: 48% !important;
   }
}
#thumbnail-image img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}
.thumbnail-container {
   background: #f4f7fb;
   /* Primary/DEF */
   background: #f4f7fb;
   border: none;
   width: 80px;

   height: 100px;
   border-radius: 4px;
   margin: 0 8px;
}
.thumbnail-container-active {
   background: #f4f7fb;
   /* Primary/DEF */
   background: #f4f7fb;
   border: 1px solid #ed1c24;
   width: 80px;
   height: 100px;
   border-radius: 4px;
   margin: 0 8px;
}
.xzoom {
   height: 350px;
   width: 350px;
}
.xzoom-thumbs img {
   display: inline-block;
   height: 80px;
   width: 80px;
   border: 1 px solid #ccc;
}
.productimage_cost {
   font-style: normal;
   font-weight: 600;
   font-size: 30px;
   line-height: 30px;
   margin-top: 20px;
   /* identical to box height, or 100% */

   /* Grey/800 */

   color: #192638;
}
.productimage_description {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 24px;
   margin-bottom: 30px;
   white-space: break-spaces !important;
   /* or 150% */

   /* Grey/700 */

   color: #374253;
}

.number {
   width: 173px;
   height: 44px;

   /* Primary/DEF */
   border: 1px solid grey !important;

   /* Primary/DEF */
   border: 1px solid grey !important;

   border-radius: 60px !important;
   background: white !important;
   color: black !important;
}
.add-cart {
   width: 173px;
   height: 44px;
   margin-left: 10px;
   /* Primary/DEF */
   border: none !important;
   background: #ed1c24 !important;
   border-radius: 60px !important;
   &:hover {
      background: #d31118 !important;
   }
}

.more_description {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 24px;
   /* or 150% */

   /* Grey/600 */

   color: #596579;
}
.description_title {
   font-style: normal;
   font-weight: 600;
   font-size: 24px;
   line-height: 36px;
   /* identical to box height, or 150% */

   /* Grey/800 */

   color: #192638;
}
.description_desc {
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 26px;
   /* or 144% */

   /* Grey/600 */

   color: #596579;
}
.image_container {
   margin-top: 30px;
   width: 450px;
   height: 400px;
   padding: 30px;

   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 10px;
}
img {
   max-width: unset !important;
}
@media only screen and (max-width: 600px) {
   #thumbnail-image {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      margin-left: 1em;
      max-width: 500px;
      .splide {
         width: 300px;
         margin-left: 30px;
      }
      .thumbnail-container {
         background: #f4f7fb;
         /* Primary/DEF */
         background: #f4f7fb;
         border: none;
         width: 70px;

         height: 100px;
         border-radius: 4px;
         margin: 0 8px;
      }
      .thumbnail-container-active {
         background: #f4f7fb;
         /* Primary/DEF */
         background: #f4f7fb;
         border: 1px solid #ed1c24;
         width: 70px;
         height: 100px;
         border-radius: 4px;
         margin: 0 8px;
      }
   }
}
