@import "../include/variables";
.carousel-title {
   font-style: normal;
   font-weight: bold;
   font-size: 50px;
   line-height: 115%;
   /* or 75px */
   letter-spacing: 0.02em;
}
.slideBanner_img {
   @include breakpoint(mobile) {
      max-height: calc(60vh - 110px);
      height: auto;
      min-height: 300px;
   }
   @include breakpoint(phablet) {
      max-height: calc(60vh - 110px);
      height: auto;
      min-height: 300px;
   }
   @include breakpoint(tablet) {
      max-height: calc(60vh - 110px);
      min-height: 400px;
   }
   @include breakpoint(laptop) {
      max-height: calc(100vh - 110px);
      min-height: 450px;
   }
   @include breakpoint(desktop) {
      max-height: calc(100vh - 110px);
      min-height: 650px;
   }
   @include breakpoint(2k) {
      max-height: calc(100vh - 110px);
      min-height: 750px;
   }
}
.carousel-caption {
   top: 15%;
   bottom: auto;
   text-align: left;
   margin-left: 5px;
}
#dishome {
   background: linear-gradient(93.97deg, #ad00c9 2.18%, #00d1ff 50.68%);
   background-clip: text;
   -webkit-text-fill-color: transparent;
}
.carousel-para {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 36px;
}
.banner-button {
   width: 206px;
   height: 60px;
   // background: #e53935 !important;
   border: none !important;
   border-radius: 60px !important;
   transition: filter 0.3s;
}
.banner-button:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
   filter: brightness(0.7) !important;
}
.carousel-control-prev,
.carousel-control-next {
   // visibility: hidden;
   z-index: 6;
}

.slideBanner__player-wrapper {
   .react-player {
      // &::before {
      //    content: "";
      //    position: absolute;
      //    top: 0;
      //    left: 0;
      //    width: 100%;
      //    height: 14%;
      //    background: rgba(0, 0, 0);
      //    z-index: 99;
      // }
      // &::after {
      //    content: "";
      //    position: absolute;
      //    bottom: 0;
      //    left: 0;
      //    width: 100%;
      //    height: 12%;
      //    background: rgba(0, 0, 0);
      //    z-index: 99;
      // }
      video {
         object-fit: cover;
      }
   }
}

@media only screen and (max-width: 600px) {
   .banner-button {
      width: 100px;
      height: 30px;
      font-size: 10px;
   }
   .carousel-para {
      font-size: 14px;
      line-height: 25px;
   }

   .carousel-title {
      line-height: 100%;
      font-size: 18px;
   }
   .carousel-caption {
      top: 5%;
      bottom: auto;
      left: 0;
   }
}
@media only screen and (min-width: 600px) {
   .banner-button {
      width: 100px;
      height: 30px;
      font-size: 10px;
   }
   .carousel-para {
      font-size: 14px;
      line-height: 25px;
   }

   .carousel-title {
      line-height: 100%;
      font-size: 24px;
   }
   .carousel-caption {
      top: 5%;
      bottom: auto;
      left: 0;
      right: 50%;
   }
}
@media only screen and (min-width: 768px) {
   .banner-button {
      width: 150px;
      height: 50px;
      font-size: 10px;
   }
   .carousel-para {
      font-size: 14px;
      line-height: 25px;
   }

   .carousel-title {
      line-height: 100%;
      font-size: 30px;
   }
   .carousel-caption {
      top: 10%;
      bottom: auto;
      left: 0;
      right: 50%;
   }
}
@media only screen and (min-width: 992px) {
   .carousel-title {
      font-size: 35px;
      line-height: 115%;

      /* or 75px */
   }

   .carousel-para {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 36px;
   }
   .banner-button {
      width: 206px;
      height: 60px;
      font-size: 18px;
   }
}

@media only screen and (min-width: 1200px) {
   .carousel-title {
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 115%;

      /* or 75px */

      letter-spacing: 0.02em;
   }
   .carousel-caption {
      top: 15%;
      bottom: auto;
      left: 0;
      right: 50%;
   }
   .carousel-para {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
   }
   .banner-button {
      width: 206px;
      height: 60px;

      // background: #e53935 !important;
      border: none !important;
      border-radius: 60px !important;
   }
   .carousel-control-prev,
   .carousel-control-next {
      // visibility: hidden;
      z-index: 6;
   }
}
