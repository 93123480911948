@import "../../scss/include/variables";

.downloads_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
}
.downloads_banner {
   display: flex;
   height: 370px;
   align-items: center;
   justify-content: space-between;
   padding-left: 12px;
   .downloads_banner_text {
      display: flex;
      flex-direction: column;
      .downloads {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #7b8698;
      }
      .downloads_header {
         font-style: normal;
         font-weight: 700;
         font-size: 54px;
         line-height: 62px;
         color: #192638;
         max-width: 450px;
         span {
            color: #0e9e49;
         }
      }
      .downloads_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 450px;
      }
   }
   .downloads_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}
.downloads_container {
   display: flex;
   flex-direction: column;
   padding-left: 12px;
   margin-top: 60px;
   .downloads_container_title_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .titleContainer {
         display: flex;
         flex-direction: column;
         .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #ed1c24;
         }
         .sub_caption {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
         }
      }
   }
   .downloads_item_container {
      display: flex;
      flex-wrap: wrap;
      @include breakpoint(mobile) {
         justify-content: center;
         gap: 18px;
      }
      @include breakpoint(phablet) {
         justify-content: center;
         gap: 18px;
      }
      @include breakpoint(tablet) {
         justify-content: flex-start;
         gap: 35px;
      }
      @include breakpoint(laptop) {
         justify-content: space-between;
         gap: 18px;
      }
      @include breakpoint(desktop) {
         justify-content: space-between;
         gap: 18px;
      }
      @include breakpoint(2k) {
         justify-content: space-between;
         gap: 18px;
      }
      .downloads_item {
         display: flex;
         flex-direction: column;
         background: #fafafa;
         border: 1px solid #f4f7fb;
         box-sizing: border-box;
         border-radius: 8px;
         max-width: 305px;
         .download_item_thumbnail {
            width: 100%;
            height: 200px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
         }
         .download_item_heading {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            margin: 6px 15px;
            color: #192638;
         }
         .download_item_sub_heading {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #596579;
            margin: 0px 15px;
         }
         .download_item_button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: #ed1c24;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            padding: 10px 0px;
            margin: 15px 15px;
            color: #ffffff;
            text-decoration: none;
            a {
               color: #ffffff;
               text-decoration: none;
            }
         }
      }
   }
   .dashboard_pagination {
      justify-content: center;
      margin-top: 34px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
         border-color: #ed1c24;
      }
   }
}
