@import "../../scss/include/variables";
.package_title {
   font-style: normal;
   font-weight: bold;
   // font-size: 45px;
   // line-height: 62px;
   /* identical to box height, or 124% */
   margin-top: 6%;
   text-align: center;
   @include HeaderFontResponsive(45, 62);
   /* Grey/900 */

   color: #0f1825;
}

.package_description {
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 36px;
   /* identical to box height, or 150% */

   text-align: center;

   /* Grey/700 */

   color: #374253;
}
// #right-img{
//     float: right;
//     position: relative;
//     top: -102px;
//     right: 12%;
// }

#right-img {
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: block;
   }
   @include breakpoint(laptop) {
      display: block;
   }
   @include breakpoint(desktop) {
      display: block;
   }
   @include breakpoint(2k) {
      display: block;
   }
}
.toggle_button {
   margin-top: 15px;
   text-align: center;
   border-radius: 6px 0px 0px 6px;
   .btn-inactive {
      background: #ffff;
      color: #596579;
      border: 1px solid #d0d7e2;
      border-radius: 6px;
   }
   .btn-active {
      background: #ed1c24 !important;
      color: white !important;
      box-shadow: none !important;
   }
}
.HD_package {
   /* Grey/50 */
   background: #ffffff;
   box-shadow: 0px 8px 32px rgba(142, 142, 142, 0.25);
   border-radius: 20px;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 30px;
   max-height: 550px;
   width: 300px;
   // width: 100%;
   min-width: 240px;
   @include breakpoint(mobile) {
      width: 300px;
   }
   @include breakpoint(phablet) {
      max-width: 250px;
   }
   @include breakpoint(tablet) {
      max-width: 260px;
   }
   @include breakpoint(laptop) {
      max-width: 300px;
   }
   @include breakpoint(desktop) {
      max-width: 300px;
   }
   @include breakpoint(2k) {
      max-width: 300px;
   }
}

.package {
   margin-top: 5%;
   justify-content: center;
   display: flex;
   // justify-content: space-around;
   flex-wrap: wrap;
}
.rectangle_image {
   display: flex;
   justify-content: center;
}
.package_name {
   font-style: normal;
   font-weight: 700;
   font-size: 20px;
   margin-top: 15px;
   line-height: 26px;
   text-align: center;
   color: #192638;
}
.package_des {
   font-style: normal;
   font-weight: normal;
   font-size: 13px;
   line-height: 150%;
   /* or 24px */
   text-align: center;
   /* Grey/800 */
   color: #192638;
}

.package_cost {
   text-align: center;
}

.channels_list {
   list-style-type: none;
   text-align: left;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 150%;
   /* identical to box height, or 27px */

   /* Grey/700 */

   color: #374253;
}
.channels_list li {
   margin-top: 10px;
   img {
      display: inline-block;
      margin-right: 6px;
      width: 16.67px;
      height: 16.67px;
   }
}
.package_link {
   text-align: center;
   margin-top: 30px;
   .link,
   .link_active {
      font-style: normal;
      padding: 8px 30px;
      text-decoration: none;
      color: #1a63f4;
      border-radius: 40px;

      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      /* identical to box height, or 118% */

      text-align: center;
      text-transform: capitalize;
      &:hover {
         opacity: 0.7 !important;
      }

      /* Primary/DEF */
   }
   .link_active {
      background: #ffffff;
      color: #ed1c24;
   }
   ._link,
   ._link_active {
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 20px;
      padding: 8px 30px;
      border-radius: 12px;
      text-decoration: none;
      color: #374253;
      border: 1.9px solid #ed1c24;
      transition: all 0.3s ease-in-out;
      &:hover {
         opacity: 0.7 !important;
      }
   }
   ._link_active {
      background: #ed1c24;
      border: 1.9px solid #ed1c24;
      color: #ffffff;
   }
}

@media only screen and (max-width: 600px) {
   .HD_package {
      margin-bottom: 30px;
   }
}
// @media only screen and (min-width: 600px) {
//     .HD_package{

//         margin-bottom: 30px;
//      margin-left: 70px;
//         }
// }

.package_card_wrapper {
   display: flex;
   flex-direction: row;
   justify-content: center;

   .package_card_wrapper__wrapper {
      align-items: center;
      @include breakpoint(mobile) {
         flex-direction: column;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
      }
      @include breakpoint(tablet) {
         flex-direction: row;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
      }
      @include breakpoint(2k) {
         flex-direction: row;
      }
   }
   // .HD_package {
   //    @include breakpoint(mobile) {
   //       display: none;
   //       &:first-child {
   //          display: block !important;
   //       }
   //    }
   //    .cost {
   //       font-style: normal;
   //       font-weight: 600;
   //       font-size: 40px;
   //       line-height: 40px;
   //       /* identical to box height, or 115% */

   //       /* Grey/700 */

   //       color: #374253;
   //    }
   // }
   .active {
      transform: scale(1.03);
      z-index: 2;
      background: linear-gradient(160.18deg, #dc0d00 0.59%, #9412c1 54.09%, #e60e9c 100.15%);
      p,
      li,
      .cost {
         color: #fff;
      }
   }
}
sup {
   top: -1.5em !important;
}
.middle_button {
   font-style: normal;
   font-weight: 500;
   font-size: 23px;
   line-height: 20px;
   padding: 8px 30px;
   border-radius: 12px;
   text-decoration: none;
   color: #ffffff;
   border: 1.9px solid #ed1c24;
   background: #ed1c24;
   transition: all 0.3s ease-in-out;
   &:hover {
      background-color: #d31118 !important;
      color: #ffffff !important;
   }
}
