@import "../include/variables";

.feature_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   .feature_header {
      h1 {
         color: $grey;
         font-style: normal;
         font-weight: 600;
         @include HeaderFontResponsive(44, 52);
         text-align: center;
         // @include breakpoint(mobile) {
         //    font-weight: 500;
         //    font-size: 28px;
         //    line-height: 42px;
         //    text-align: center;
         // }
         // @include breakpoint(phablet) {
         //    font-weight: 500;
         //    font-size: 32px;
         //    line-height: 48px;
         //    text-align: center;
         // }
         // @include breakpoint(tablet) {
         //    font-weight: 500;
         //    font-size: 36px;
         //    line-height: 54px;
         //    text-align: center;
         // }
         // @include breakpoint(laptop) {
         //    font-weight: 600;
         //    font-size: 40px;
         //    line-height: 62px;
         // }
         // @include breakpoint(desktop) {
         //    font-weight: 600;
         //    font-size: 40px;
         //    line-height: 62px;
         // }
         // @include breakpoint(2k) {
         //    font-weight: 600;
         //    font-size: 40px;
         //    line-height: 62px;
         // }
      }
   }
   .feature_container {
      display: flex;
      flex-direction: row;
      width: 80%;
      height: min-content;
      gap: 15px;
      @include breakpoint(mobile) {
         flex-direction: column;
         width: 100%;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
         width: 100%;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
         justify-content: space-between;

         width: 80%;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
         width: 80%;
         justify-content: space-between;
      }

      .feature_image {
         flex-basis: 60%;
         display: flex;
         align-items: center;
         justify-content: center;
         @include breakpoint(mobile) {
            display: flex;
         }
         @include breakpoint(tablet) {
            display: flex;
         }
         @include breakpoint(phablet) {
            display: flex;
         }
         @include breakpoint(laptop) {
            flex-basis: 55%;
         }
         @include breakpoint(desktop) {
            flex-basis: 55%;
         }
         @include breakpoint(2k) {
            flex-basis: 50%;
         }
         img {
            max-height: 700px;
            width: 100%;
            border-radius: 16px;
         }
      }
      .feature_text {
         display: flex;
         flex-direction: column;
         flex-basis: 40%;
         gap: 30px;
         @include breakpoint(mobile) {
            flex-basis: auto;
            .text_section {
               &:last-child {
                  display: none;
               }
            }
         }
         @include breakpoint(tablet) {
            flex-direction: column;
            gap: 20px;
            .text_section {
               &:last-child {
                  display: none;
               }
            }
         }
         @include breakpoint(laptop) {
            flex-direction: column;
            gap: 20px;
            .text_section {
               &:last-child {
                  display: block;
               }
            }
         }
         .text_section {
            h1 {
               font-style: normal;
               font-weight: bold;
               color: $grey;
               @include Paragraph(32, 42);
            }
            p {
               font-style: normal;
               font-weight: normal;
               font-size: 16px;
               line-height: 24px;
               color: #374253;
            }
         }
      }
   }
}

.text_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   h1 {
      font-style: normal;
      font-weight: bold;
      // font-size: 54px;
      // line-height: 62px;
      @include BigHeaderFontResponsive(54, 62);
   }
   span {
      color: #0e9e49;
   }
}
