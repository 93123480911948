@import "variables";

// .navbar > .container-fluid {
//   margin-left: 120px;
//   margin-right: 120px;
//   height: 40px;
// }
.nav_container {
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 999;
   .mobileSearch {
      transition: all 1s ease-in-out;
      position: absolute;
      display: flex;
      top: 105px;
      width: 100%;
      background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
      justify-content: center;
      padding: 12px;
      gap: 16px;
      input {
         height: 35px;
         width: 100%;
      }
      .Search_submit {
         background-color: #fff;
         border: none;
         border-radius: 16px;
         color: #192638;
         padding-left: 16px;
         padding-right: 16px;
      }
   }
}
.package_card {
   position: relative;
   @include breakpoint(mobile) {
      padding-left: 8px;
      padding-right: 8px;
   }
}
.notification-cart {
   display: flex;
   align-items: center;
   position: relative;
   justify-content: center;
   width: 30px;
   .notification_panel {
      position: absolute;
      @include breakpoint(mobile) {
         left: -195px;
      }
      @include breakpoint(phablet) {
         right: -40px;
      }
      @include breakpoint(tablet) {
         right: -35px;
      }
      @include breakpoint(laptop) {
         right: -60px;
      }
      @include breakpoint(desktop) {
         right: -120px;
      }
      @include breakpoint(2k) {
         right: -120px;
      }
      // right: -40px;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 16px rgba(36, 44, 55, 0.1);
      border-radius: 6px;
      z-index: 1;
      top: 42px;
      padding: 0px 0px;
      width: 350px;
      transition: all 0.3s ease-in-out;
      .notificationHeader {
         .mark_all_Read {
            font-size: 14px;
            font-weight: 500;
            color: #1b2b83;
            text-align: end;
            width: fit-content;
         }
      }
      .notificationHeader,
      .notificationFooter {
         padding: 1rem 0.8rem;
         border-bottom: 1px solid #e6eaf0;
         border-top: 1px solid #e6eaf0;
         text-align: center;
         p {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #374253;
         }
         a {
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #374253;
         }
      }
      .notificationCard {
         display: flex;
         flex-direction: column;
         width: 100%;
         border-bottom: 0.0625rem solid #eaedf2;
         padding: 0.7rem 1rem;
         p {
            margin: 0px;
         }
         .heading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.25rem;
            p {
               margin: 0px;
               font-weight: 600;
            }
            .time {
               font-size: 14px;
               font-weight: 400;
            }
         }
         .Content {
            font-size: 14px;
            font-weight: 400;
            margin-top: 0.25rem;
         }
      }
   }
}
.wishlist-notification {
   position: absolute;
   background: red;
   width: 7px;
   height: 8px;
   border-radius: 50%;
   top: 2%;
   color: white;
   right: 2%;
}
.notification {
   right: -20%;
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   background: red;
   min-width: 20px;
   max-height: 20px;
   width: fit-content;
   height: 100%;
   border-radius: 50%;
   color: white;
   top: -30%;
   padding: 4px 2px;
   p {
      text-align: center;
      margin: 0;
   }
}
#right-img {
   position: absolute;
   left: 73%;
   top: 5%;
}
.first_nav {
   background-color: #ffffff;
}
input[type="text"]::placeholder {
   padding-left: 25px;
}
.second_nav {
   margin-top: 55px;
}
.navbar {
   position: fixed !important;
   width: 100%;
}

.nav-link {
   font-family: SF Pro Display;
   padding: 0px !important;
   line-height: 14px;
   font-size: 12px;
   text-align: center;
   letter-spacing: -0.015em;
   /* Grey/800 */
   // padding-right: 29px !important;
   color: #192638 !important;
}
.navbar-expand .navbar-collapse {
   display: flex !important;
   flex-basis: auto;
}
.navbar > .container-fluid {
   width: 80%;
   padding-left: 0px;
   .navbar-brand {
      margin-right: 0px;
      height: 45px;
   }
}
.bg-light {
   --bs-bg-opacity: 1;
   background-color: none !important;
}
.second_nav > .container-fluid {
   width: 79%;
}
.splide__arrow {
   top: 12%;
}
.splide__arrow--prev {
   left: -2em;
}
.splide__arrow--next {
   right: -2em;
}

.bg-light {
   background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
   box-shadow: 0px 1px 0px rgba(209, 209, 209, 0.12);
   height: 50px;
   z-index: -1;
}
.secondnav-link {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 14px;

   align-items: center;
   letter-spacing: -0.01em;

   /* Grey/50 */

   color: #ffffff !important;
}
#secondnav-text {
   height: 14px;
   font-size: 14px;
   padding: 0px 10px !important;
   margin-bottom: 0;
   text-align: center;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #ffffff !important;
   text-decoration: none;
   &:hover {
      transition: transform all 1s ease;
      &::after {
         transition: transform all 1s;
         opacity: 1;
         display: flex;
         content: "";
         height: 3px;
         margin-top: 4px;
         border-radius: 2px;
         background-color: #ffffff;
      }
   }
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: block;
   }
   @include breakpoint(laptop) {
      display: block;
   }
   @include breakpoint(desktop) {
      display: block;
   }
   @include breakpoint(2k) {
      display: block;
   }
}

.secondnav_active {
   &::after {
      transition: all 1s;
      opacity: 1;
      display: flex;
      content: "";
      height: 3px;
      margin-top: 4px;
      border-radius: 2px;
      background-color: #ffffff;
   }
}
#top-navlink {
   height: 14px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: -0.015em;
   text-decoration: none;
   color: #192638;
   &:hover {
      &::after {
         opacity: 1;
         display: flex;
         content: "";
         height: 3px;
         margin-top: 4px;
         border-radius: 2px;
         background-color: #ed1c24;
      }
   }
   @include breakpoint(mobile) {
      display: none;
   }
   @include breakpoint(phablet) {
      display: none;
   }
   @include breakpoint(tablet) {
      display: none;
   }
   @include breakpoint(laptop) {
      display: block;
   }
   @include breakpoint(desktop) {
      display: block;
   }
   @include breakpoint(2k) {
      display: block;
   }
}
.topnav_active {
   &::after {
      opacity: 1;
      display: flex;
      content: "";
      height: 3px;
      margin-top: 4px;
      border-radius: 2px;
      background-color: #ed1c24;
   }
}

.navbar-expand .navbar-nav {
   gap: 24px !important;
   flex-direction: row;
}
.hamburger-icon {
   @include breakpoint(mobile) {
      display: flex;
   }
   @include breakpoint(phablet) {
      display: flex;
   }
   @include breakpoint(tablet) {
      display: flex;
   }
   @include breakpoint(laptop) {
      display: none;
   }
   @include breakpoint(desktop) {
      display: none;
   }
   @include breakpoint(2k) {
      display: none;
   }
}
.secondnav-contact {
   width: fit-content;
   height: 14px;
   font-size: 14px;
   text-align: center;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #ffffff !important;
   margin-bottom: 0;
   @include breakpoint(mobile) {
      text-align: center;

      width: 100%;
   }
   @include breakpoint(phablet) {
      text-align: center;
      width: 100%;
   }
   @include breakpoint(tablet) {
      text-align: center;

      width: fit-content;
   }
   @include breakpoint(laptop) {
      width: fit-content;
   }
   @include breakpoint(desktop) {
      width: fit-content;
   }
   @include breakpoint(2k) {
      width: fit-content;
   }
}

.form-control {
   border: 1px solid #a7b2c3 !important;
}
.icon-nav {
   margin-left: 40px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 10px;
   @include breakpoint(mobile) {
      margin-left: 0px;
   }
   input {
      margin-bottom: 0px !important;
      // width: 250px !important;
      // height: 35px !important;
      border: 1px solid #a7b2c3 !important;
   }
   #search-locate-icon {
      @include breakpoint(mobile) {
         padding: 0.175rem 0rem;
      }
      @include breakpoint(phablet) {
         padding: 0.375rem 0.75rem;
      }
      @include breakpoint(tablet) {
         padding: 0.375rem 0.75rem;
      }
      @include breakpoint(laptop) {
         padding: 0.375rem 0.45rem;
      }
      @include breakpoint(desktop) {
         padding: 0.375rem 0.75rem;
      }
      @include breakpoint(2k) {
         padding: 0.375rem 0.75rem;
      }
   }
   #search-locate {
      display: flex;
      box-shadow: none;

      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: flex;
         max-width: max-content;
      }
      @include breakpoint(tablet) {
         display: flex;
         max-width: max-content;
      }
      @include breakpoint(laptop) {
         display: flex;
         max-width: max-content;
      }
      @include breakpoint(desktop) {
         display: flex;
         max-width: max-content;
      }
      @include breakpoint(2k) {
         display: flex;
         max-width: max-content;
      }
   }
   #search-locate-icon {
      @include breakpoint(mobile) {
         border: none;
      }
      @include breakpoint(phablet) {
         border-right: none !important;
         border: 1px solid #a7b2c3;
      }
      @include breakpoint(tablet) {
         border-right: none !important;
         border: 1px solid #a7b2c3;
      }
      @include breakpoint(laptop) {
         border-right: none !important;
         border: 1px solid #a7b2c3;
      }
      @include breakpoint(desktop) {
         border-right: none !important;
         border: 1px solid #a7b2c3;
      }
      @include breakpoint(2k) {
         border-right: none !important;
         border: 1px solid #a7b2c3;
      }
   }
   .ProfileSubMenu {
      position: absolute;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 16px rgba(36, 44, 55, 0.1);
      border-radius: 6px;
      z-index: 1;
      right: -15px;
      top: 49px;
      padding: 10px 0px;
      @include breakpoint(desktop) {
         right: -73px;
      }
      @include breakpoint(2k) {
         right: -73px;
      }
      .profileSubMenuItem {
         display: flex;
         align-items: center;
         width: 180px;
         height: 40px;
         padding-left: 22px;
         .svg-fill {
            fill: #596579;
            margin-right: 10px;
         }
         .svg-stroke {
            stroke: #596579;
            margin-right: 10px;
         }
         .profileSubMenuItem__text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #596579;
            margin: 0px;
         }
         &:hover {
            background: #f5f7fa;
            cursor: pointer;
            .profileSubMenuItem__text {
               color: #ed1c24;
            }
            .svg-fill {
               fill: #ed1c24;
            }
            .svg-stroke {
               stroke: #ed1c24;
            }
         }
      }
   }
   .menu_hide {
      display: none;
   }
}

.search-responsive {
   @include breakpoint(mobile) {
      display: flex;
   }
   @include breakpoint(phablet) {
      display: flex;
   }
   @include breakpoint(tablet) {
      display: flex;
   }
   @include breakpoint(laptop) {
      display: none;
   }
   @include breakpoint(desktop) {
      display: none;
   }
   @include breakpoint(2k) {
      display: none;
   }
}

.sideNav {
   height: 100%;
   position: fixed;
   top: 60px;
   left: 0%;
   background-color: #ffff;
   overflow-x: hidden;
   transition: display 0.3s ease-in-out;
   display: none;
   a {
      padding: 8px 8px 8px 14px;
      transition: 0.3s;
      margin-bottom: 5px;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #374253;
   }
   .nav-item {
      padding: 8px 8px 8px 40px;
      transition: 0.3s;
      margin-bottom: 5px;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #374253;
      .dropdown-toggle {
         &::after {
            position: absolute;
            right: 25px;
            margin-left: 0;
         }
      }
   }
   .NavDropDown-container {
      display: flex;
      flex-direction: column;
      .NavDropDown-title-container {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 8px 8px 8px 14px;
         p {
            transition: 0.3s;
            text-decoration: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            margin: 0px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #374253;
         }
         a {
            padding: 0px 10px 0 0;
         }
         .NavDropDown-arrow {
            transition: transform 0.3s ease-in-out;
            cursor: pointer;
            margin-right: 10px;
         }
         .rotate-icon {
            transform: rotate(-180deg);
         }
      }
      .NavDropDown-child {
         padding: 8px 8px 8px 28px;
      }
   }
   .sidenav_link_active,
   .navdropdown_title_active,
   .navchild_active {
      background-color: #f4f7fb;
   }
}
.bread-container {
   width: 100%;
   height: 140px;
   display: flex;
   justify-content: center;
   /* Information/50 */
   align-items: center;
   background: #f3f7fe;
   @include breakpoint(mobile) {
      height: 65px;
   }
   @include breakpoint(phablet) {
      height: 100px;
   }
   @include breakpoint(tablet) {
      height: 100px;
   }
}
