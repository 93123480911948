@import "../../scss/include/variables";
.ottbanner_btn:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
   background-color: #d31118 !important;
}
.ottbanner__wrapper {
   display: flex;
   max-height: 75vh;
   position: relative;
   min-height: 60vh;
   justify-content: center;
   .react-player {
      video {
         object-fit: cover;
      }
   }
   .ott__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      @include breakpoint(mobile) {
         padding-left: 8px;
         padding-right: 8px;
         flex-basis: 100%;
      }
      .ottbanner_heading {
         font-style: normal;
         letter-spacing: -0.015em;
         margin-bottom: 20px;
         z-index: 1;
         font-weight: 600;
         @include BigHeaderFontResponsive(52, 62);
         max-width: 650px !important;
      }
      .ottbanner_text {
         font-style: normal;
         font-weight: 500;
         // font-size: 24px;
         // line-height: 36px;
         color: #d0d7e2;
         max-width: 433px;
         margin-bottom: 40px;
         z-index: 1;
         @include Paragraph(24, 36);
         max-width: 650px !important;
      }
      .ottbanner_btn {
         width: fit-content;
         font-weight: 400;
         font-size: 20px;
         line-height: 30px;
         border: none;
         color: #ffffff;
         background: #e53935;
         border-radius: 60px;
         padding: 13px 36px;
         z-index: 1;
         @include breakpoint(mobile) {
            font-size: 16px;
            line-height: 24px;
            padding: 10px 24px;
         }
      }
      .ottbanner_disabled_btn {
         cursor: not-allowed;
         background-color: grey;
         width: fit-content;
         font-weight: 400;
         font-size: 20px;
         line-height: 30px;
         border: none;
         color: #ffffff;

         border-radius: 60px;
         padding: 13px 36px;
         z-index: 1;
         @include breakpoint(mobile) {
            font-size: 16px;
            line-height: 24px;
            padding: 10px 24px;
         }
      }
   }
   .ott__img {
      flex-basis: 50%;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
}
#Signin_Button {
   background: #ed1c24;
   border: none;
   border-radius: 8px;
   color: #fff;
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   height: 44px;
   line-height: 16px;
   max-width: 400px;
   margin-top: 16px;
}
#Signin_Button_cancel {
   background: #f4f7fb;
   border: none;
   border-radius: 8px;
   color: #596579;
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   height: 44px;
   line-height: 16px;
   max-width: 400px;
   margin-top: 16px;
}
#Signin_Button_disabled {
   background: grey;
   border: none;
   border-radius: 8px;
   color: #fff;
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   height: 44px;
   line-height: 16px;
   max-width: 400px;
   margin-top: 16px;
   cursor: not-allowed;
}
