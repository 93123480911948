.featured {
   margin-top: 130px;
   width: 80%;
}
.product {
   width: 100%;
   height: 230px;
   display: flex;
   /* Grey/100 */
   background: #f4f7fb;
   border-radius: 6px;
   align-items: flex-start;
   gap: 20px;
   justify-content: center;
   &:hover {
      border: 1px solid #e6eaf0;
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.1);
   }
}
.gaming-products {
   display: flex;
}

.category {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */
   margin-top: 20px;

   /* Grey/600 */

   color: #596579;
}

.featured_title {
   font-size: 18px;
   line-height: 22px;
   font-weight: 500;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   margin-bottom: 4px;
   color: #374253;
}
// .featured_title {
//    font-style: normal;
//    font-weight: 500;
//    font-size: 18px;
//    line-height: 26px;
//    /* identical to box height, or 144% */

//    /* Grey/700 */

//    color: #374253;
// }
.product-cost {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 16px;
   /* identical to box height, or 157% */

   /* Grey/700 */

   color: #374253;
}
.product-list {
   display: flex;
   gap: 11px;
   flex-wrap: wrap;
   justify-content: center;
}
#flex-product {
   height: 230px;
   min-width: 330px;
   flex-basis: 32%;
   cursor: pointer;
}
