@import "../../scss/include/variables";

.wrapper-internet {
   background: #f4f7fb;
   position: relative;
   height: calc(100vh - 110px);
   height: auto;
   max-height: 750px;
   .container {
      display: flex;
      height: 100%;
      .row {
         width: 100%;
      }
   }
   @include breakpoint(mobile) {
      // height: calc(60vh - 110px);
      height: auto;
      min-height: 300px;

      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }
   @include breakpoint(phablet) {
      // height: calc(60vh - 110px);
      min-height: 300px;

      height: auto;
      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }
   @include breakpoint(tablet) {
      // height: calc(60vh - 110px);
      min-height: 400px;

      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }
   @include breakpoint(laptop) {
      // height: calc(100vh - 110px);
      min-height: 450px;

      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }
   @include breakpoint(desktop) {
      // height: calc(100vh - 110px);
      min-height: 650px;

      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }
   @include breakpoint(2k) {
      // height: calc(100vh - 110px);
      min-height: 750px;

      .container {
         padding-top: 0px !important;
         div {
            // padding-top: 0px !important;
            margin-top: 0px !important;
         }
      }
   }

   overflow: hidden;
   .py-5 {
      padding-bottom: 0 !important;
      padding-top: 2rem !important;
   }
   #internetbanner-heading {
      font-style: normal;
      font-weight: 700;
      // font-size: 54px;
      // line-height: 64px;
      letter-spacing: -0.015em;
      @include HeaderFontResponsive(54, 64);
      /* Grey/800 */

      color: #fff;
   }
   .internet-lead {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;

      /* or 150% */

      /* Grey/700 */

      color: #fff;
   }
   .row {
      --bs-gutter-x: 0;
   }
   #banner-btn {
      background: #ed1c24;
      border-radius: 60px;
      border: none;
      transition: filter 0.3s;
      &:hover {
         filter: brightness(0.7);
      }
   }
   .react-player {
      video {
         object-fit: cover;
      }
   }
   // .heroImage {
   //    @include breakpoint(mobile) {
   //       display: none !important;
   //    }
   //    @include breakpoint(phablet) {
   //       display: none !important;
   //    }
   //    @include breakpoint(tablet) {
   //       display: none !important;
   //    }
   //    @include breakpoint(laptop) {
   //       display: block !important;
   //    }
   //    @include breakpoint(desktop) {
   //       display: block !important;
   //    }
   //    @include breakpoint(2k) {
   //       display: block !important;
   //    }
   // }
}
#compare-btn {
   background: #ffff;
   border-radius: 60px;
   border: 1px solid #ed1c24;

   color: #ed1c24;
}
.green {
   color: #48b576;
}
