.splide__arrow {
   top: 45.5% !important;
   border-radius: 0;
}
.splide__arrow--prev {
   left: -40px !important;
}

//media query for mobile
@media screen and (max-width: 600px) {
   .splide__arrow--prev {
      left: -10px !important;
   }
   .splide__arrow--next {
      right: -10px !important;
   }
}
