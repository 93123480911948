@import "../include/variables";

.referBanner__widthContainer {
   flex-direction: column;
   .referBanner {
      display: flex;
      max-height: 400px;
      justify-content: space-between;
      .referText {
         display: flex;
         flex-direction: column;
         justify-content: center;
         text-align: left;
         @include breakpoint(mobile) {
            margin-top: 24px;
            flex-basis: 100%;
            padding-left: 30px;
         }
         @include breakpoint(phablet) {
            margin-top: 24px;
            padding-left: 30px;
            flex-basis: 100%;
         }
         @include breakpoint(tablet) {
            margin-top: 24px;
            padding-left: 30px;
            flex-basis: 100%;
         }
         @include breakpoint(laptop) {
            flex-basis: 50%;
            margin: 0;
            padding: 0;
         }
         @include breakpoint(desktop) {
            margin: 0;
            flex-basis: 50%;
            padding: 0;
         }
         @include breakpoint(2k) {
            margin: 0;
            flex-basis: 50%;
            padding: 0;
         }
         .referText__heading {
            font-style: normal;
            font-weight: 700;
            font-size: 54px;
            line-height: 64px;
            letter-spacing: -0.015em;
            color: #192638;
            span {
               color: #ed1c24;
            }
         }
         .referText__subheading {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #192638;
         }
      }
      .referImage {
         flex-basis: 50%;
         @include breakpoint(mobile) {
            display: none;
         }
         @include breakpoint(phablet) {
            display: none;
         }
         @include breakpoint(tablet) {
            display: none;
         }
         @include breakpoint(laptop) {
            display: block;
         }
         @include breakpoint(desktop) {
            display: block;
         }
         @include breakpoint(2k) {
            display: block;
         }
         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
   .referCardContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      flex-wrap: wrap;
      justify-content: center;
      .referCard {
         display: flex;
         flex-direction: column;
         max-width: 300px;
         align-items: center;
         img {
            height: 170px;
            width: 170px;
            object-fit: contain;
            margin-bottom: 24px;
         }
         .referCard__heading {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            // color: #ffa914;
            margin-bottom: 16px;
            color: #3c444d;
         }
         .referCard__text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            /* or 157% */

            text-align: center;

            /* Grey/600 */

            color: #596579;
         }
      }
   }
   .referContent {
      display: flex;
      align-items: flex-start;
      @include breakpoint(mobile) {
         flex-direction: column-reverse;
         gap: 40px;
      }
      @include breakpoint(phablet) {
         flex-direction: column-reverse;
         gap: 40px;
      }
      @include breakpoint(tablet) {
         flex-direction: column-reverse;
         gap: 40px;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
         gap: 140px;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
         gap: 140px;
      }
      @include breakpoint(2k) {
         flex-direction: row;
         gap: 140px;
      }
      .referForm {
         flex-basis: 60%;
         display: flex;
         flex-direction: column;
         padding-left: 12px;
         padding-right: 12px;
         .referForm__heading {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #374253;
         }
         .referForm__form {
            display: flex;
            flex-direction: column;
            label {
               margin-bottom: 6px;
               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 14px;
               color: #374253;
            }
            input {
               margin-bottom: 30px !important;
               width: 100%;
               max-width: 100%;
            }
            .referButton {
               border: none;
               margin-top: 15px;
               padding: 12px 0px;
               background-color: #ed1c24;
               font-style: normal;
               font-weight: 500;
               font-size: 17px;
               line-height: 20px;
               color: #ffffff;
               border-radius: 10px;
               &:hover {
                  background: #d31118 !important;
               }
            }

            .searchContainer{
               input{
                  margin-bottom: 0px !important;
               }
            }
         }
      }
      .referTermAndCondition {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         text-align: left;
         padding-left: 12px;
         padding-right: 10px;
         .referTermAndCondition__heading {
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #192638;
            margin-bottom: 24px;
         }
         .referTermAndCondition__text {
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            color: #596579;
            margin: 0px;
            &::before {
               content: "•";
               margin-right: 10px;
               font-size: 22px;
            }
         }
      }
   }
}
