#modal-header {
   border-bottom: none !important;
}
#modal-footer {
   border-top: none !important;
}
// .modal-body {
//    height: 500px;
// }
#welcome-body {
   position: relative;
}
