@import "../../scss/include/variables";

.packagesCollection{
   width: 100%;
   display: grid;
   grid-template-columns: repeat(10, 1fr);
   gap: 20px;
   border: 2px solid #dc3545;
   border-radius: 10px;
   padding: 2rem;

   @media screen and (max-width: 4505px) {
      grid-template-columns: repeat(9,1fr);
   }

   @media screen and (max-width: 4160px) {
      grid-template-columns: repeat(8,1fr);
   }

   @media screen and (max-width: 3710px) {
      grid-template-columns: repeat(7,1fr);
   }

   @media screen and (max-width: 3260px) {
      grid-template-columns: repeat(6,1fr);
   }

   @media screen and (max-width: 2822px) {
      grid-template-columns: repeat(5,1fr);
   }

   @media screen and (max-width: 2340px) {
      grid-template-columns: repeat(4,1fr);
   }

   @media screen and (max-width: 1468px) {
      grid-template-columns: repeat(3,1fr);
   }

   @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2,1fr);
   }

   
   @media screen and (max-width: 570px) {
      padding: 3rem 0.3rem;
   }
}

.packagesCollection.upgrade{
   padding: 0;
   border: none;

}

.packagesCollection.actual_package{
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   gap: 20px;
   flex-wrap: wrap;

   .currentCardWrapper{
      width: 100%;
   }

   @media screen and (max-width: 767px) {
      align-items: center;
   }
}

.dishhomegoPackage_card {
   .dishhomego_text_heading {
      -webkit-text-fill-color: transparent;
      background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
      background-clip: text;
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
      margin: 0;
   }

   .goPackageCard {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      //box shadow and border
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      filter: drop-shadow(4px 9px 14px rgba(155, 156, 142, 0.19));
      border-radius: 16px;
      background: transparent;
      width: 100%;
      height: 465px;
      // border: 1px solid #e5e5e5;
      // width: 100%;
      //padding
      padding: 20px 25px;

      @media screen and (max-width: 570px) {
         padding: 0 0 24px;
         height: max-content;
      }
      .cover {
         width: 100%;
         height: 330px;
         object-fit: cover;
         border-radius: 16px 16px 0px 0px;
         box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.5);
         align-self: center;
         margin-bottom: 10px;

         @media screen and (max-width: 450px) {
            height: 220px;
         }
      }
      .__altCover {
         position: relative;
         border-radius: 16px;
         box-shadow: none;
         align-self: center;
         margin-bottom: 5px;
         width: 100%;
         height: 225px;
         object-fit: fill;
         border: 1px solid #ed1c24;
      }
      .details {
         width: 100%;
         @media screen and (max-width: 570px) {
            padding: 0 8px;
         }
      }
      .card-title {
         color: #000;
         font-size: 24px;
         font-style: normal;
         font-weight: 500;
         line-height: normal;
         margin-top: 8px;

         @media screen and (max-width: 570px) {
            font-size: 16px;
         }
      }
      .custom-btn {
         display: flex;
         gap: 12px;
         align-items: center;
         width: 100%;
         justify-content: center;
         img {
            width: 18px;
            height: 18px;
         }
      }
   }
   .__altCard {
      box-shadow: none;
      filter: none;
      border: none;
      background: transparent;
      width: 158px;
      height: 295px;
      padding: 0;
      .details {
         display: flex;
         flex-direction: column;
         align-items: center;
         .card-title {
            font-size: 16px;
         }
         .custom-btn {
            font-size: 14px;
            width: 97%;
         }
      }
   }

   .dashboard_pagination {
      justify-content: center;
      margin-top: 40px;
   }
   .dashboard_page_item {
      .dashboard_page_link {
         color: #37577d;
      }
   }
   .dashboard_active {
      .dashboard_page_link {
         color: #fff;
         background-color: #ed1c24;
      }
   }
   .dropdownContainer {
      .Dropdown-control {
         width: 240px;
      }
      .Dropdown-arrow {
         top: 18px;
      }
   }
   .btn-container-ott {
      display: flex;
      transition: all ease 0.3s;
      justify-content: space-between;
      align-items: center;
      // // margin-bottom: 12px;
      .includeinpackage_header {
         position: relative;
         font-size: 22px;
         font-weight: bold;
         // border-left: 7px solid #ed1c24;
         padding-left: 18px;
         padding-bottom: 6px;
         &:before {
            content: "";
            display: block;
            width: 7px;
            height: 28px;
            background: linear-gradient(to right, #e21940, #c40d5b);
            left: -12px;
            top: -2px;
            position: absolute;
         }
      }
      .btns {
         display: flex;
         gap: 25px;
         .btn-ottpackagetype {
            width: fit-content;
            border-radius: 0;
            border-radius: 12px 12px 0 0;
            border: none;
            &:hover {
               border: 5px solid;
               border-image-slice: 1;
               border-width: 4px;
               border-left: 0;
               border-right: 0;
               border-top: 0;
               border-image-source: linear-gradient(
                  90deg,
                  #e51b27 0.01%,
                  #ce0cee 50%,
                  #c10c58 99.99%
               );
            }
         }
         .btn-ottpackagetype-alt {
            width: fit-content;
            border-radius: 0;
            border-radius: 12px 12px 0 0;
            border: none;
            transition: all 0.1s ease;
            &:hover {
               border-radius: 10px 10px 0 0;
               background: linear-gradient(to right, #e21940, #c40d5b);
               color: #fff;
            }
         }
         .active {
            border: 5px solid;
            border-image-slice: 1;
            border-width: 4px;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-image-source: linear-gradient(
               to left,
               #e51b27 0.01%,
               #ce0cee 50%,
               #c10c58 99.99%
            );
            font-weight: bold;
            color: red;
         }
         .inactive {
            background-color: transparent;
         }
         .btn--active {
            border-radius: 10px 10px 0 0;
            background: linear-gradient(to right, #e21940, #c40d5b);
            color: #fff;
         }
         .btn--inactive {
            color: rgba(#e21940, 0.9);
         }
      }
   }
}

// .priceSection {
//    background: linear-gradient(37.01deg, #e61b27 5.01%, #ce0cee 10%, #c10c58 79.99%);
//    box-shadow: 0px 1px 0px rgba(209, 209, 209, 0.12);
//    height: 100%;
//    color: #fff;
//    width: fit-content;
//    margin-bottom: 8px;
//    display: flex;
//    flex-direction: column;
//    border-radius: 10px;
// }

.dishhomego_text_heading {
   -webkit-text-fill-color: transparent;
   background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
   background-clip: text;
   font-size: 40px;
   font-weight: 700;
   line-height: 52px;
   margin: 0;
}

.PackageCardTop {
   background: #fcf5f5;
   .title_text {
      color: rgba(0, 0, 0, 0.75);
      font-size: 48px;
      font-weight: bold;
      line-height: 56px;
      text-align: left;
      margin-bottom: 12px;
   }
   .description_text {
      color: rgb(0, 0, 0);
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      white-space: pre-wrap;
   }
}

.ottSubPackageTitleGradient {
   -webkit-text-fill-color: transparent;
   background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
   background-clip: text;
   font-size: 40px;
   font-weight: 700;
   line-height: 52px;
   margin: 0;
}

.subPackageContainer {
   width: 100%;
   display: grid;
   gap: 20px;
   @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
   }
   @include breakpoint(phablet) {
      grid-template-columns: repeat(1, 1fr);
   }
   @include breakpoint(tablet) {
      grid-template-columns: repeat(1, 1fr);
   }
   @include breakpoint(laptop) {
      grid-template-columns: repeat(2, 1fr);
   }
   @include breakpoint(desktop) {
      grid-template-columns: repeat(2, 1fr);
   }
   @include breakpoint(2k) {
      grid-template-columns: repeat(3, 1fr);
   }
}
.subPackageCardWrapper {
   display: flex;
   flex-direction: column;
   max-width: 350px;
   background: #ffffff;
   // box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.25);
   border: 1px solid rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   // border-radius: 15px;
   align-items: center;
   .subPackageCardTitle {
      margin-top: 18px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #192638;
   }
   .priceButtonWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 11px;
      max-width: 370px;
      .subPackageCardPriceButton {
         background: #ccffa9;
         border-radius: 6px;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 18px;
         text-align: center;
         color: #192638;
         padding: 4px 10px;
         &:hover {
            cursor: pointer;
            background: #0e9e49;
            color: #fff;
         }
      }
      .subPackageCardPriceButton_active {
         background: #0e9e49;
         border-radius: 6px;
         color: #fff;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 18px;
         text-align: center;
         padding: 4px 10px;
         &:hover {
            cursor: pointer;
         }
      }
   }

   // .subPackageCardLearnMoreButton {
   //    background: #ffffff;
   //    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
   //    border-radius: 35px;
   //    font-family: "SF Pro Display";
   //    font-style: normal;
   //    font-weight: 500;
   //    font-size: 20px;
   //    line-height: 52px;
   //    /* identical to box height, or 260% */
   //    display: flex;
   //    align-items: center;
   //    text-align: center;
   //    height: fit-content;
   //    /* Gradient */
   //    background: linear-gradient(90.01deg, #6d292d 0.01%, #635b64 50%, #d6739c 99.99%);
   //    -webkit-background-clip: text;
   //    -webkit-text-fill-color: transparent;
   //    background-clip: text;
   //    padding: 6px 44px;
   //    margin-top: 24px;
   //    cursor: pointer;
   //    &:hover {
   //       background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);

   //       -webkit-background-clip: text;
   //       -webkit-text-fill-color: transparent;
   //       background-clip: text;
   //    }
   // }
   .subPackageCardLearnMoreButton {
      margin-top: 18px;
      margin-bottom: 18px;
      color: #3f9ce3;
      &:hover {
         cursor: pointer;
         color: #1547ec;
      }
   }
}

.SubPackageModalpriceButtonWrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 11px;
   max-width: 370px;
   .subPackageCardPriceButton {
      background: #ccffa9;
      border-radius: 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #192638;
      padding: 6px 12px;
      &:hover {
         cursor: pointer;
         background: #0e9e49;
         color: #fff;
      }
   }
   .subPackageCardPriceButton_active {
      background: #0e9e49;
      border-radius: 6px;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      padding: 6px 12px;
      &:hover {
         cursor: pointer;
      }
   }
}

.continueButton {
   margin-top: 18px;
   width: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #ff0000;
   color: #fff;
   box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
   border-radius: 30px;
   padding: 12px 32px;
   align-self: flex-end;
   margin-right: 6px;
   &:hover {
      cursor: pointer;
   }
}

.border-right {
   border-right: 1px solid rgba(0, 0, 0, 0.13);
   padding-right: 24px;
   margin-bottom: 28px;
}

.border-bottom {
   border-bottom: 1px solid rgba(0, 0, 0, 0.13);
   padding-bottom: 14px;
   margin-bottom: 28px;
}

.subPackageCheckBox:checked {
   background-color: #df3910;
   border-color: #f32408 !important;
}

.subPackageCheckBox:focus {
   box-shadow: 0 0 0 0.25rem rgba(225, 54, 16, 0.25);
}

.dishhomeGoFeatureComparisonContainer {
   position: relative;
   display: flex;
   height: 425px;
   .dishHomegoInfoCardContainer {
      position: absolute;
      right: 0;
      display: flex;
      gap: 26px;
      height: 385px;
      .dishHomegoInfoCard {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;
         gap: 12px;
         padding: 25px 45px;
         border-radius: 26px;
         height: 100%;
         //lignt pink
         background: #fae0ee;
         mix-blend-mode: darken;
         .icon {
            //make black icon white
            filter: invert(1);
         }
         .dishHomegoInfoCardHeader {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #d4067a;
         }
         .button {
            background: #d1006f;
            border-radius: 6px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.7px;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            padding: 6px 12px;
            cursor: pointer;
            &:hover {
               cursor: pointer;
            }
         }
      }
   }
   .infoTableContainer {
      display: flex;
      width: 100%;
      align-items: flex-end;
      .table-striped > tbody > tr:nth-child(odd) > td,
      .table-striped > tbody > tr:nth-child(odd) > th {
         background-color: #f6c6e1;
         mix-blend-mode: darken;
         //remove other color
      }
      .table {
         border: none;
         margin-bottom: 55px;
         border-spacing: 26px;
         --bs-table-striped-bg: transparent;
         tr,
         td,
         th {
            border: none;
         }
         td {
            text-align: center;
            color: #d60f7d;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            padding: 15px 10px;
         }
      }
   }
}

//Extra

.bottomContainerSubPackage {
   width: 100%;
   max-width: 580px;
   min-width: 340px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
   margin-bottom: 22px;
   @include breakpoint(mobile) {
      min-width: 270px;
   }
   .plan_heading_container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-radius: 15px 15px 0px 0px;
      background: linear-gradient(180deg, #e21940 0%, #c40d5b 100%);
      box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.3);
      .plan_text-heading {
         color: #fff;
         font-size: 22px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 1.25px;
         text-transform: uppercase;
      }
   }
   .buttonGroup {
      gap: 9px;
      width: fit-content;
      padding: 22px 22px;
      @include breakpoint(mobile) {
         width: 100%;
         flex-wrap: wrap;
         padding: 12px 14px;
      }
   }
   .subPackageCardPriceButton {
      width: fit-content;
      background: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #192638;
      padding: 10px 22px;
      border-radius: 10px;
      border: 1px solid var(--plan-gradient, #e21940);
      &:hover {
         cursor: pointer;
         background: var(--plan-gradient, linear-gradient(180deg, #e21940 0%, #c40d5b 100%));
         color: #fff;
      }
   }
   .active {
      background: var(--plan-gradient, linear-gradient(180deg, #e21940 0%, #c40d5b 100%));
      color: #fff;
   }

   .learnMoreButton {
      width: fit-content;
      background: rgba(0, 0, 0, 0.05);
      border: 1.2px solid #fff;
      border-radius: 12px;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 8px 12px;
      text-transform: capitalize;
   }
   .ActivateNowContainer {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      .text {
         font-size: 14px;
         font-weight: 400;
         line-height: 18px;
         color: #fff;
      }
      .activateButton {
         width: fit-content;
         background: #e61b27;
         border-radius: 6px;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 18px;
         text-align: center;
         color: #fff;
         padding: 12px 18px;
         &:hover {
            cursor: pointer;
            background: #e4878a;
            color: #fff;
         }
      }
   }
}
.activateButton {
   width: fit-content;
   background: #e61b27;
   border-radius: 6px;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 22px;
   text-align: center;
   color: #fff;
   padding: 4px 10px;
   margin-top: 22px;
   &:hover {
      cursor: pointer;
      background: #e4878a;
      color: #fff;
   }
}
.customppv-card {
   p {
      margin: 0;
   }
}
.dishhomego-packagedetails-modal {
   .modal-content {
      background-color: #fcf5f5 !important;
   }
   .modal-container {
      align-items: center;
      .modal-custom-header {
         color: rgba(0, 0, 0, 0.75);
         font-size: 55px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 40px;
      }
      @media screen and (max-width: 800px) {
         .modal-content-container {
            padding: 0 10px 20px;
         }
         .modal-custom-header {
            font-size: 42px;
         }
      }
      .modal-content-container {
         display: flex;
         gap: 55px;
         padding: 0 40px 20px;
         width: 100%;
         .modal-image {
            width: 380px;
            height: 475px;
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.5);
            @include breakpoint(mobile) {
               width: 100%;
               height: auto;
            }
         }
         .modal-details {
            margin-top: 8px;
            display: flex;
            flex-direction: column;

            .overview,
            p {
               margin-bottom: 12px;
               font-size: 20px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
            }
            .btn-container {
               display: flex;
               gap: 18px;
               margin-top: 18px;
               margin-bottom: 18px;
               flex-wrap: wrap;
               .custom-btn {
                  border-radius: 10px;
                  background: #51a80e;
                  color: #fff;
                  cursor: pointer;
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 7px 11px;
                  &:hover {
                     background: #317100;
                  }
               }
               .active {
                  background: #317100;
               }
            }
            .custom-btn {
               width: fit-content;
            }
            @include breakpoint(mobile) {
               width: 100%;
            }
         }
      }
   }
   p {
      margin: 0;
   }
}

.packageCartDishhomeGo__container {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   position: fixed;
   bottom: 90px;
   right: 44px;
   z-index: 999;
   transition: all 0.5s ease;
   @include breakpoint(mobile) {
      right: 40px;
   }
   .packageCartDishhomeGo {
      height: fit-content;
      border-radius: 10px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(230, 234, 240);
      width: 400px;
      margin-bottom: 12px;
      @include breakpoint(mobile) {
         width: 340px;
      }
      .packageCartContainer {
         display: flex;
         flex-direction: column;
         h4 {
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;
            width: 100%;
            border-bottom: 1px solid #0000001f;
            margin-bottom: 8px;
         }
         .package-data {
            gap: 18px;
            margin-bottom: 8px;
            justify-content: space-between;
            transition: all 0.5s ease;
            img {
               height: 60px;
               width: 52px;
               margin-right: 6px;
            }
            h6 {
               font-size: 16px;
               font-weight: bold;
            }
            p {
               font-size: 14px;
               line-height: 16px;
            }
            h7 {
               color: #0e9e49;
            }
            .deleteIcon {
               display: none;
            }
            &:hover {
               .delectContainer {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #ed1c24;
                  margin-left: 4px;
                  border-radius: 12px 0px 0px 12px;
                  .deleteIcon {
                     color: #fff;
                     cursor: pointer;
                     display: block;
                     font-size: 28px;
                  }
               }
            }
         }
      }
   }
   .display-none {
      display: none;
   }
   .DishhomegoPackageCart__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ed1c24;
      height: 52px;
      width: 52px;
      position: relative;
      cursor: pointer;
      .cartIcon {
         width: 26px;
         filter: invert(1);
      }
      .closeIcon {
         width: 22px;
         filter: invert(1);
      }
      .cart-number {
         position: absolute;
         display: flex;
         align-items: center;
         justify-content: center;
         right: 3px;
         top: 2px;
         font-size: 12px;
         font-weight: 600;
         color: white;
         background: #ed1c24;
         margin: 0;
         border-radius: 50%;
         height: 14px;
         width: 14px;
         padding-left: 1.5px;
      }
   }
}

.packageCartContainer {
   display: flex;
   flex-direction: column;
   h4 {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      width: 100%;
      border-bottom: 1px solid #0000001f;
      margin-bottom: 8px;
   }
   .package-data {
      gap: 18px;
      margin-bottom: 8px;
      justify-content: space-between;
      transition: all 0.5s ease;
      img {
         height: 60px;
         width: 52px;
         margin-right: 6px;
      }
      h6 {
         font-size: 16px;
         font-weight: bold;
      }
      p {
         font-size: 14px;
         line-height: 16px;
      }
      h7 {
         color: #0e9e49;
      }
      .deleteIcon {
         display: none;
      }
      &:hover {
         .delectContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ed1c24;
            margin-left: 4px;
            border-radius: 12px 0px 0px 12px;
            .deleteIcon {
               color: #fff;
               cursor: pointer;
               display: block;
               font-size: 28px;
            }
         }
      }
   }
}

.responsiveDiv {
   @include breakpoint(mobile) {
      .DHGOContent {
         margin: 0px !important;
      }
   }
}
.reactLoading {
   display: flex;
   align-items: center;
   justify-content: center;
   svg {
      height: inherit;
   }
}
.plan__wrapper {
   display: flex;
   gap: 20px;
   @include breakpoint(mobile) {
      flex-direction: column;
      align-items: center;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      align-items: center;
      padding: 0;
      border: none;
   }
   @include breakpoint(tablet) {
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      border: none;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      border: none;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      border: none;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      border: none;
   }
   .plan__item__details {
      padding: 24px;
      border: 1px solid #e6eaf0;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
      background: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      width: 100%;
      // max-height: 370px;
      @include breakpoint(mobile) {
         max-width: calc(100vw - 22px);
      }
      @include breakpoint(phablet) {
         max-width: 480px;
      }
      @include breakpoint(tablet) {
         max-width: 480px;
      }
      @include breakpoint(laptop) {
         max-width: 580px;
      }
      @include breakpoint(desktop) {
         max-width: 580px;
      }
      @include breakpoint(2k) {
         max-width: 580px;
      }
      .current_active_plan {
         font-weight: 500;
         font-size: 20px;
         color: #374253;
         margin-bottom: 13px;
      }
      .Plan__item__heading {
         display: flex;
         justify-content: space-between;
         align-items: center;
         border: 1px solid #f00;
         border-radius: 15px;
         padding: 12px 22px;
         margin-bottom: 16px;
         color: #f00;
         gap: 8px;
         .custombtn {
            border: 0.5px solid #f00;
            background: #fff;
            border-radius: 35px;
            font-weight: 400;
            font-size: 17px;
            color: #f00;
            @include breakpoint(mobile) {
               font-size: 15px;
               padding: 4px 8px;
            }
            @include breakpoint(phablet) {
               font-size: 15px;
               padding: 4px 8px;
               line-height: 18px;
            }
            @include breakpoint(laptop) {
               font-size: 17px;
               line-height: 19px;
               padding: 8px 12px;
            }
            &:hover {
               background: #f00;
               color: #fff;
            }
         }

         // margin-bottom: 12px;
         // border-bottom: 1px solid #e6eaf0;
         // @include breakpoint(mobile) {
         //    flex-direction: column;
         // }
         // @include breakpoint(phablet) {
         //    flex-direction: column;
         // }
         // @include breakpoint(tablet) {
         //    flex-direction: row;
         // }
         // @include breakpoint(laptop) {
         //    flex-direction: row;
         // }
         // @include breakpoint(desktop) {
         //    flex-direction: row;
         // }
         // @include breakpoint(2k) {
         //    flex-direction: row;
         // }
         p,
         h3 {
            margin: 0;
         }
         h3 {
            font-weight: 500;
            font-size: 20px;

            color: #374253;
         }
         p {
            font-weight: 500;
            font-size: 17px;
            display: flex;
            align-items: center;
            gap: 12px;
            color: #3c3d3f;
            @include breakpoint(mobile) {
               font-size: 15px;
               img {
                  height: 16px;
                  width: 18px;
               }
            }
            @include breakpoint(phablet) {
               font-size: 15px;
               img {
                  height: 16px;
                  width: 18px;
               }
            }
            @include breakpoint(laptop) {
               font-size: 17px;
               img {
                  height: 20px;
                  width: 24px;
               }
            }
         }
      }
      .Plan__item__features {
         display: flex;
         flex-direction: column;
         padding-bottom: 12px;
         padding-top: 8px;
         border-bottom: 1px solid #e6eaf0;
         border-top: 1px solid #e6eaf0;
         .item__feature {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 0px;
            align-items: center;
            h2 {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 24px;
               margin: 0;
               color: #596579;
               display: flex;
               align-items: center;
            }
            p {
               font-style: normal;
               font-weight: 500;
               font-size: 16px;
               line-height: 24px;
               color: #374253;
               margin: 0px;
            }
         }
      }
      .Plan__item__price {
         display: flex;
         width: 100%;
         padding: 12px 0px;
         justify-content: flex-end;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         color: #192638;
         p {
            margin: 0;
         }
         sub {
            color: #596579;
            font-size: 18px;
            line-height: 26px;
         }
      }
   }
}
.upgradeCardWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 12px !important;
   padding-top: 12px;
   // border-top: 0.3px solid rgba(#000000, 0.1);
   .upgradeCardContainer {
      display: flex;
      gap: 8px;
      align-self: baseline;
      flex-wrap: wrap;
   }
   h3 {
      margin-bottom: 24px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      span{
         display: block;
         width: 4px;
         height: 25px;
         background: linear-gradient(to left, #e51b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
      }
   }
   .upgradeCard {
      height: auto;
   }
}
.upgradeCard {
   width: 270px !important;
   display: flex;
   flex-direction: column;
   // justify-content: center;
   align-items: center;
   //box shadow and border
   border: 1px solid #e6eaf0;
   box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
   border-radius: 16px;
   background: transparent;
   height: 425px;
   // border: 1px solid #e5e5e5;
   // width: 100%;
   //padding
   padding: 20px 24px !important;
   .cover {
      width: 100%;
      height: 255px;
      object-fit: cover;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.5);
      align-self: center;
      margin-bottom: 10px;
   }

   .details {
      width: 100%;
   }
   h3 {
      margin-bottom: 12px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
   }
   .card-title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 8px;
   }
   .custom-btn {
      display: flex;
      gap: 12px;
      align-items: center;
      width: 95%;
      justify-content: center;
      font-size: 16px;
      img {
         width: 16px;
         height: 16px;
      }
   }
}

.gradient_border_ott {
   position: relative;
   &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 10px;
      border: 2px solid transparent; /*2*/
      background: linear-gradient(to right, #e21940, #c40d5b) border-box; /*3*/
      mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude; /*5*/
   }
}
