@import "../include/variables";

.dashboard_container {
   display: flex;
   gap: 50px;
   margin-top: 65px;
   .table_of_content_btn {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      background: #ed1c24;
      border-radius: 8px;
      gap: 10px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      max-width: 178px;
      margin-bottom: 40px;
   }
}
.main_page {
   margin-top: 105px;
}
.kyc_notice {
   background: #1a63f4;
   padding-top: 10px;
   /* width: 1920px; */
   height: fit-content;
   p {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */
      text-align: center;
      /* Warning/50 */
      color: #fdf8f2;
   }
   a {
      color: #fdf8f2;
      text-decoration: none;
   }
}
