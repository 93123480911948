@import "../include/variables";

.categorycard_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 108px;
   width: 107px;
   padding: 12px 24px;
   box-shadow: 0px 12px 32px rgba(146, 155, 173, 0.12);
   // background-color: #3e7cf6;
   border-radius: 14px;
   margin: 1rem;
   @include breakpoint(mobile) {
      margin: 0;
   }
   .categorycard_img {
      img {
         height: 36px;
         width: 36px;
         // filter: grayscale(1) brightness(0) invert(1);
         // filter: grayscale(1);
      }
   }
   .categorycard_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #000;
      text-transform: capitalize;
      p {
         margin: 0;
      }
   }
}

// .active {
//     background: #3e7cf6;
// }

.inactive {
   box-shadow: none;
   background-color: #fff;
   &:hover {
      cursor: pointer;
   }
   .categorycard_text {
      color: #374253;
   }

   .categorycard_img {
      img {
         filter: grayscale(1);
      }
   }
}
