@import "../include/variables";
.enterprise_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
   margin-bottom: 120px;
}
.enterprise_banner {
   display: flex;
   height: 500px;
   align-items: center;
   justify-content: space-between;
   padding-left: 28px;

   .enterprise_banner_text {
      display: flex;
      flex-direction: column;

      .enterprise_header {
         font-style: normal;
         font-weight: 700;
         // font-size: 54px;
         // line-height: 62px;
         @include HeaderFontResponsive(54, 62);
         color: #192638;
         max-width: 480px;
         span {
            color: #0e9e49;
         }
      }
      .enterprise_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 520px;
         margin-bottom: 25px;
      }
      .enterprise_button {
         font-style: normal;
         font-weight: 400;
         font-size: 20px;
         line-height: 30px;
         color: #ffffff;
         border: none;
         background-color: #ed1c24;
         border-radius: 60px;
         padding: 13px 36px;
         width: fit-content;
      }
   }
   .enterprise_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}
.enterprise_section_container {
   .enterprise_section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
      margin-bottom: 120px;
      @include breakpoint(mobile) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
         gap: 40px;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
         padding: 0px;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
         padding: 0px;
      }
      @include breakpoint(2k) {
         flex-direction: row;
         padding: 0px;
      }
      .enterprise_img {
         flex-basis: 45%;
         display: flex;
         align-items: center;
         justify-content: center;
         img {
            width: 100%;
            object-fit: contain;
            @include breakpoint(mobile) {
               height: 180px;
            }
         }
      }
      .enterprise_text_section {
         display: flex;
         flex-direction: column;
         flex-basis: 50%;
         .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 52px;
            color: #192638;
            margin-bottom: 40px;
            @include HeaderFontResponsive(40, 52);
            @include breakpoint(mobile) {
               margin-bottom: 22px;
            }
         }
         .bullet_point {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #192638;
            margin-bottom: 32px;
            @include breakpoint(mobile) {
               margin-bottom: 20px;
               font-size: 18px;
               line-height: 24px;
            }
            span {
               font-weight: 600;
               position: relative;
               &::before {
                  @include breakpoint(mobile) {
                     display: none;
                  }
                  @include breakpoint(phablet) {
                     display: none;
                  }
                  @include breakpoint(tablet) {
                     display: none;
                  }
                  @include breakpoint(laptop) {
                     display: block;
                  }
                  @include breakpoint(desktop) {
                     display: block;
                  }
                  @include breakpoint(2k) {
                     display: block;
                  }
                  content: "";
                  background: url("../../assets/internet_tick.png");
                  width: 20px;
                  height: 20px;
                  float: left;
                  left: -32px;
                  top: 4px;
                  position: absolute;
               }
            }
         }
         .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #374253;
            @include breakpoint(mobile) {
               font-size: 18px;
               line-height: 22px;
            }
         }
         .buttonGroup {
            display: flex;
            margin-top: 40px;
            gap: 22px;
            @include breakpoint(mobile) {
               gap: 14px;
            }
            .enterprise_button,
            .enterprise_button__learnMore {
               font-style: normal;
               font-weight: 400;
               font-size: 20px;
               line-height: 30px;
               color: #ffffff;
               border: none;
               background-color: #ed1c24;
               border-radius: 12px;
               padding: 13px 36px;
               width: fit-content;
               &:hover {
                  background: #d31118 !important;
               }
               @include breakpoint(mobile) {
                  padding: 10px 20px;
               }
            }
            .enterprise_button__learnMore {
               background-color: transparent;
               color: #1a63f4;
               &:hover {
                  background: none !important;
                  color: #13419c;
               }
            }
         }
      }
   }
   .row-reverse {
      @include breakpoint(mobile) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
      }
      @include breakpoint(laptop) {
         flex-direction: row-reverse;
         padding: 0px;
      }
      @include breakpoint(desktop) {
         flex-direction: row-reverse;
         padding: 0px;
      }
      @include breakpoint(2k) {
         flex-direction: row-reverse;
         padding: 0px;
      }
      .enterprise_text_section {
         flex-basis: 65%;
         @include breakpoint(mobile) {
            padding-left: 10px;
            padding-right: 10px;
         }
         @include breakpoint(phablet) {
            padding-left: 10px;
            padding-right: 10px;
         }
         @include breakpoint(tablet) {
            padding-left: 10px;
            padding-right: 10px;
         }
         @include breakpoint(laptop) {
            padding-left: 80px;
            padding-right: 0px;
         }
         @include breakpoint(desktop) {
            padding-left: 80px;
            padding-right: 0px;
         }
         @include breakpoint(2k) {
            padding-left: 80px;
            padding-right: 0px;
         }
      }
      .enterprise_img {
         flex-basis: 35%;
         display: flex;
         align-items: center;
         justify-content: center;
         img {
            object-fit: contain;
            width: 100%;
            @include breakpoint(mobile) {
               height: 180px;
            }
         }
      }
   }
   .padding-top-bottom {
      padding-top: 80px;
      padding-bottom: 80px;
      margin-bottom: 0px;
      position: relative;
      @include breakpoint(mobile) {
         padding-top: 40px;
         padding-bottom: 40px;
      }
   }
   .formContainer {
      display: flex;
      gap: 60px;
      justify-content: space-around;
      @include breakpoint(mobile) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
         align-items: center;
      }
      @include breakpoint(phablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
         align-items: center;
      }
      @include breakpoint(tablet) {
         flex-direction: column;
         padding-left: 10px;
         padding-right: 10px;
         align-items: center;
      }
      @include breakpoint(laptop) {
         flex-direction: row;
         padding: 0px;
         align-items: flex-start;
      }
      @include breakpoint(desktop) {
         flex-direction: row;
         padding: 0px;
      }
      align-items: flex-start;

      @include breakpoint(2k) {
         flex-direction: row;
         padding: 0px;
         align-items: flex-start;
      }
      // .enterprise_wrapper {
      //    display: flex;
      //    flex-direction: row;
      //    justify-content: space-between;
      //    @include breakpoint(mobile) {
      //       flex-direction: column;
      //    }
      //
      //       p {
      //          font-weight: 500;
      //          font-size: 18px;
      //          line-height: 26px;
      //          color: #596579;
      //          max-width: 359px;
      //          a {
      //             text-decoration: none;
      //          }
      //       }
      //    }
      // }
      .formWrapper {
         display: flex;
         flex-direction: column;
         .error {
            border: 1px solid $primary !important;
            margin-bottom: 8px !important;
         }
         .error_message {
            margin-bottom: 12px;
         }
         .enterprise_text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h1 {
               font-style: normal;
               font-weight: 500;

               // line-height: 42px;
               max-width: 427px;
               @include HeaderFontResponsive(34, 42);
               span {
                  color: #ed1c24;
               }
            }
         }
         .inputContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            label {
               text-align: left;
               margin-bottom: 6px;
            }
            input {
               width: 100%;
               margin-bottom: 18px;
            }
         }
         .inputGroup {
            display: flex;
            gap: 18px;
            input {
               max-width: 190px;
            }
            width: 100%;
            .inputContainer {
               width: fit-content;
            }
         }
         .enterprise_button {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #ffffff;
            border: none;
            background-color: #ed1c24;
            border-radius: 60px;
            padding: 13px 36px;
            width: 100%;
            max-width: 400px;
            margin-top: 18px;
            &:hover {
               background: #d31118 !important;
            }
         }
         .loading {
            background: $grey;
            cursor: no-drop;
         }
      }
   }
}
.enterpriseHeading {
   font-style: normal;
   font-weight: 700;
   font-size: 54px;
   line-height: 62px;
   text-align: center;
   color: #192638;
   margin-bottom: 80px;
   @include HeaderFontResponsive(54, 62);
   @include breakpoint(mobile) {
      margin-bottom: 40px;
   }
   span {
      color: #0e9e49;
   }
}
.bg_green {
   background: linear-gradient(180deg, #e1eee7 0%, #edf0e9 100%);
}
.bg_blue {
   background-color: #3e7cf6;
   .enterprise_section {
      .enterprise_img {
         img {
            object-fit: contain;
            bottom: 0;
            @include breakpoint(mobile) {
               position: relative;
               height: 180px;
               width: 100%;
            }
            @include breakpoint(phablet) {
               position: relative;
               height: 100%;
               width: 100%;
            }
            @include breakpoint(tablet) {
               position: relative;
               height: 100%;
               width: 100%;
            }
            @include breakpoint(laptop) {
               position: absolute;
               width: 740px;
               height: 470px;
            }
            @include breakpoint(desktop) {
               position: absolute;
               width: 897px;
               height: 598px;
            }
            @include breakpoint(2k) {
               position: absolute;
               width: 897px;
               height: 598px;
            }
         }
      }
      .enterprise_text_section {
         .heading,
         .description {
            color: #f4f7fb;
         }
         .buttonGroup {
            .enterprise_button__learnMore {
               color: #fff;
            }
         }
      }
   }
}

.img_bottom {
   .enterprise_section {
      .enterprise_img {
         img {
            object-fit: contain;
            bottom: 0;
            right: -40px;
            @include breakpoint(mobile) {
               position: relative;
               width: 420px;
            }
            @include breakpoint(phablet) {
               position: relative;
               width: 420px;
            }
            @include breakpoint(tablet) {
               position: relative;
               width: 420px;
            }
            @include breakpoint(laptop) {
               position: absolute;
               width: 340px;
            }
            @include breakpoint(desktop) {
               position: absolute;
               width: 420px;
            }
            @include breakpoint(2k) {
               position: absolute;
               width: 420px;
            }
         }
      }
   }
}

.bg_pink {
   background: linear-gradient(180deg, #f5e8f2 0%, #f0e8f5 100%);
}
.margin_top_bottom {
   margin-top: 80px;
   margin-bottom: 80px;
}

.enterprise_button_learnmore {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #ffffff;
   border: none;
   background-color: #ed1c24;
   border-radius: 12px;
   padding: 9px 29px;
   margin-top: 31px;
   width: -moz-fit-content;
   width: fit-content;
   &:hover {
      background: #d31118 !important;
   }
}
.enterprise_button_learnmore_cancel {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #596579;
   border: none;
   background: #f4f7fb;
   border-radius: 12px;
   padding: 9px 29px;
   margin-top: 31px;
   width: -moz-fit-content;
   width: fit-content;

   &:hover {
      background-color: #e8ecf2 !important;
      color: black !important;
   }
}
.description_learnmore {
   font-style: normal;
   white-space: break-spaces !important;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #374253;
   margin: 0;
}
.title_learnmore {
   font-style: normal;
   font-weight: 600;
   font-size: 40px;
   line-height: 52px;
   color: #192638;
   margin-bottom: 20px;
}
.cancel_enterprise {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: grey;

   border: none !important;
   background: #f4f7fb;
   color: #596579;
   border-radius: 60px;
   padding: 13px 36px;
   width: 100%;
   max-width: 400px;
   margin-top: 18px;

   &:hover {
      background-color: #e8ecf2 !important;
      color: black !important;
   }
}
