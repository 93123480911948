.dashboarditem__container {
   max-width: 320px;
   min-width: 200px;
   display: flex;
   flex-direction: column;
   .dashboarditem__conrainer__header {
      display: flex;
      flex-direction: column;
      padding: 24px 14px;
      width: 100%;
      justify-content: flex-start;
      background: #ed1c24;
      color: #ffffff;
      .casid {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
      }
      .balance {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 22px;
      }
   }
   .dashboarditem__container__body {
      display: flex;
      flex-direction: column;
      padding: 0px 14px 0px 4px;
      width: 100%;
      justify-content: flex-start;
      color: #596579;
      @media screen and (max-width: 1100px) {
         color: #000;
      }

      .dashboarditem__container__item {
         margin: 16px 0 16px 0;
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 26px;
         display: flex;
         width: fit-content;
         align-items: center;
         cursor: pointer;
         .svg-fill {
            fill: #374253;
            margin-right: 8px;
            @media screen and (max-width: 1100px) {
               color: #000;
            }
         }
         .svg-stroke {
            stroke: #374253;
            margin-right: 8px;
            @media screen and (max-width: 1100px) {
               color: #000;
            }
         }
      }
      .active {
         color: #ed1c24;
         .svg-fill {
            fill: #ed1c24;
         }
         .svg-stroke {
            stroke: #ed1c24;
         }
      }
   }
}
.userCard {
   background: #ed1c24;
   border-radius: 16px;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(20px);
   border: 1px solid rgba(237, 28, 36, 0.3);
   width: fit-content;
   padding: 12px;
   min-width: 300px;
   width: 100%;

   padding-left: 16px;
   border-radius: 16px;
   .userName {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 8px;
      img {
         height: 45px;
         width: 45px;
         border-radius: 50%;
         color: #fff;
      }
      .details {
         color: rgba(255, 255, 255, 0.913);
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         margin: 0px;
      }
   }
   .details {
      color: rgba(255, 255, 255, 0.913);
      margin-left: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
   }
   .details-name {
      color: rgba(255, 255, 255, 0.913);
      margin-left: 6px;
      font-style: normal;
      font-weight: 800;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
   }
}
