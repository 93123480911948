@import "../include/variables";

.appbanner {
   // margin-top: 100px;
   position: relative;
   width: 100%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   background: #ed1c24;
   .appbanner-text {
      @include breakpoint(mobile) {
         display: flex;
         flex-direction: column;
         align-items: center;
         p {
            text-align: center;
         }
      }
   }
}
.ott {
   margin-top: 0px;
   background: linear-gradient(90.01deg, #e61b27 0.01%, #ce0cee 50%, #c10c58 99.99%);
}

.TV {
   font-style: normal;
   font-weight: 700;
   font-size: 38px;
   line-height: 45px;
   text-transform: capitalize;
   max-width: 360px;
   color: #ffffff;
}
.TV_des {
   font-style: normal;
   font-weight: 300;
   font-size: 18px;
   line-height: 28px;
   /* or 140% */

   display: flex;
   align-items: center;

   color: #ffffff;
}

.download_box {
   margin-top: 30px;
}
.google-subtext {
   font-size: 8px;
}
.download_button {
   cursor: pointer !important;
   &:hover {
      opacity: 0.7;
   }
}

.ott_top {
   background-color: "blue";
   text-align: center;
   color: #fff;
}
#bottom1 {
   width: 225px;
   position: absolute;
   left: 0;
   top: 0;
   z-index: -1;
}
#bottom2 {
   width: 240px;
   height: absolute;
   position: absolute;
   right: 0;
   bottom: 0;
   z-index: -1;
}
#bottom3 {
   width: 222px;
   position: absolute;
   z-index: -1;
   bottom: 0;
}
#bottom4 {
   position: absolute;
   top: 0;
   z-index: -1;
   right: 123px;
   width: 240px;
   height: 120px;
}
