@import "../include/variables";
.refer-wrapper {
   display: flex;
   justify-content: space-around;
   /* gap: 30px; */
   margin-top: 15px;
   margin-bottom: 14px;
   gap: 15px;
}
.refer-wrapper div {
   flex-basis: 31%;
   &:first-child {
      flex-basis: 28%;
   }
   &:last-child {
      flex-basis: 15%;
      display: flex;
      justify-content: center;
   }
   &:nth-last-child(2) {
      flex-basis: 20%;
   }
}
.refer-title {
   display: flex;
   justify-content: space-around;
   margin-top: 40px;
   gap: 15px;
}
.refer-title div {
   flex-basis: 31%;
   &:first-child {
      flex-basis: 28%;
   }
   &:last-child {
      flex-basis: 15%;
      display: flex;
      justify-content: center;
   }
   &:nth-last-child(2) {
      flex-basis: 20%;
   }
}
.refer-para {
   color: #262b40;
   font-weight: bold;
}
#refer-status {
   color: green;
   //    margin-left: 4px;
}
// #refer-number {
//    margin-left: 3px;
// }

.refer-mobile-wrapper {
   display: flex;
   flex-direction: column;
   /* gap: 30px; */
   margin-top: 8px;
   margin-bottom: 8px;
   gap: 5px;
   p {
      margin: 0;
   }
   width: 250px;
   border: 1px solid #7e7e7e84;
   border-radius: 16px;
   padding: 12px;
}
.refer__container {
   display: flex;
   flex-direction: column;
   @include breakpoint(mobile) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
   }
}
