@import "../include/variables";
.midbanner {
   margin-top: 100px;
   background-image: url("../../assets/midbanner.png");
   background-position: center; /* Center the image */
   background-repeat: no-repeat; /* Do not repeat the image */
   background-size: cover;
   opacity: 0.8;
   height: 500px;
}
.btn-midbanner {
   border: none !important;
   background: none;
   color: black;
   :hover {
      background: none !important;
   }
}

.tv_banner__wraper {
   width: 100%;
   margin-top: 100px;
   background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 45.2%, rgba(67, 6, 8, 0) 100%),
      url("../../assets/kmg_banner.webp");
   background-position: center; /* Center the image */
   background-repeat: no-repeat; /* Do not repeat the image */
   background-size: cover;
   height: 600px;
   display: flex;
   align-items: center;
   justify-content: flex-start;

   position: relative;
   .tv_banner__wrapper {
      z-index: 2;
      .tv_banner__container {
         padding-left: 15px;
         max-width: fit-content;
         color: #fff;
         background-color: rgba(0, 0, 0, 0.65);
         padding: 24px;
         border-radius: 10px;
         .tv_banner__button_group {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 30px;
            gap: 40px;
            @include breakpoint(mobile) {
               gap: 22px;
               flex-wrap: wrap;
            }
            .tv_banner__button {
               border: none;
               background: none;
               color: #fff;
               font-style: normal;
               font-weight: 700;
               font-size: 18px;
               line-height: 24px;
               padding: 0px;
               padding-bottom: 12px;
               opacity: 0.6;
            }
            .active {
               border-bottom: 3px solid #fff;
               opacity: 1;
            }
         }
         .tv_banner__text {
            max-width: 390px;
            .tv_banner__heading {
               font-style: normal;
               font-weight: 700;
               font-size: 50px;
               line-height: 72px;
               @include Paragraph(50, 72);
            }
            .tv_banner__para {
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
               letter-spacing: 0.75px;
               margin-bottom: 34px;
            }
            img {
               display: inline;
            }
         }
      }
   }
   .midBanner__player-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      filter: drop-shadow(10px 15px 24px rgba(37, 36, 36, 0.25));
      background-color: #000;
      &::before {
         content: "";
         position: absolute;
         top: 0;
         left: -14px;
         width: 16px;
         height: 100%;
         background-color: rgba(0, 0, 0);
      }
      &::after {
         content: "";
         position: absolute;
         top: 0;
         right: -14px;
         width: 16px;
         height: 100%;
         background-color: rgba(0, 0, 0);
      }
      .react-player {
         video {
            object-fit: cover;
         }
      }
   }
}
