@import "../include/variables";

.internet {
   width: 300px;
   height: 410px;
   /* Grey/50 */

   background: #ffffff;
   /* Grey/200 */

   border: 1px solid #e6eaf0;
   box-sizing: border-box;
   border-radius: 10px;
}
.category-wrapper {
   max-width: 1150px;
   margin: 0 auto;
   padding: 0 10px;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-gap: 2rem;
}
.internetpackage-wrapper .wifi {
   display: flex;
   justify-content: center;
   margin-top: 50px;
}
.internetpackage-wrapper {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 8px;
   justify-content: center;
   margin-top: 80px;
}
.internetpackage-wrapper-sme {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 8px;
   padding-left: 50px;

   margin-top: 80px;
}
#triangle-img {
   position: absolute;
   bottom: -20px;
   left: 0;
   right: 0;
   margin: auto;
   display: block;
}
.wifi img {
   align-items: center;
   height: 50px;
}

.wifi_sme {
   display: flex;
   justify-content: center;
   margin-top: 50px;
   img {
      height: 50px;
   }
}

.internet p {
   text-align: center;
}
.mbps {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;

   line-height: 28px;
   /* identical to box height, or 140% */
   display: -webkit-box;
   margin-top: 15px;

   /* Grey/800 */
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   color: #192638;
}
.plans {
   padding: 0px 3px;
}
.sme_plans {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* or 150% */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   text-align: center;

   /* Grey/700 */
   margin-left: 10px;
   margin-right: 10px;
   color: #374253;
}
.month {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
   /* or 144% */
   margin-top: 30px;
   text-align: center;
}
/* Grey/700 */

.unique {
   font-style: normal;
   font-weight: 800;
   font-size: 28px;
   line-height: 26px;
   /* or 144% */

   text-align: center;

   /* Grey/700 */
}
.choose {
   margin: auto;
   justify-content: center;
   background: #0e9e49 !important;
   border-radius: 12px !important;
   border: none !important;
   width: 280px;
   height: 44px;
   &:hover {
      background: #0e7538 !important;
   }
}
.choose_middle {
   margin: auto;
   justify-content: center;
   background: #ffff !important;
   border-radius: 12px !important;
   border: none !important;
   width: 280px;
   height: 44px;
   &:hover {
      background: #ffffffc7 !important;
   }
}
#btn-text {
   background: -webkit-linear-gradient(160.18deg, #dc0d00 0.59%, #9412c1 54.09%, #e60e9c 100.15%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
}
.wrapper-button {
   margin-top: 50px;
   display: flex;
   justify-content: center;
}
#middle-div {
   color: white;
   position: relative;
   background: linear-gradient(121.55deg, #e61b27 1.11%, #ce0cee 50.56%, #c10c58 100%);
}
#middle-mbps {
   color: white;
}
.internet-para {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 24px;
   /* or 150% */
   text-align: center;
   /* Grey/700 */
   margin-top: 44px;
   color: #374253;
}
.view_more_plan {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   text-align: center;
   color: #5494da;
   margin-top: 18px;
   text-align: center;
   cursor: pointer;
   &:hover {
      color: #1f43d0;
   }
}
.internet-banner-bg {
   background: #0f1825;
   margin-top: 50px;
}
.internet-banner-wrapper {
   width: 100%;
   padding: 30px 0px;
   align-items: flex-start;
   display: flex;
   margin-top: 100px;

   justify-content: space-between;
   /* Grey/900 */
   .img {
      align-items: center;
      justify-content: center;

      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: none;
      }
      @include breakpoint(laptop) {
         display: flex;
      }
      @include breakpoint(desktop) {
         display: flex;
      }
      @include breakpoint(2k) {
         display: flex;
      }
      img {
         width: 100%;
      }
   }
}
.internet-banner-wrapper > div {
   @include breakpoint(mobile) {
      flex-basis: 100%;
      padding: 15px;
   }
   @include breakpoint(phablet) {
      flex-basis: 100%;
      padding: 15px;
   }
   @include breakpoint(tablet) {
      flex-basis: 100%;
      padding: 15px;
   }
   @include breakpoint(laptop) {
      flex-basis: 45%;
      padding: 0px;
   }
   @include breakpoint(desktop) {
      flex-basis: 45%;
      padding: 0px;
   }
   @include breakpoint(2k) {
      flex-basis: 45%;
      padding: 0px;
   }
}
#fastest {
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   /* identical to box height, or 150% */

   /* Primary/DEF */

   color: #ed1c24;
}
#why-dishhome {
   font-style: normal;
   font-weight: 600;
   font-size: 40px;
   line-height: 52px;
   /* identical to box height, or 130% */

   /* Grey/50 */

   color: #ffffff;
}
#internet-para {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   /* or 150% */

   /* Grey/300 */

   color: #d0d7e2;
}

.internet-feature-banner {
   margin-top: 20px;
   // display: flex;
   justify-content: space-between;
   gap: 25px;
   div {
      flex-basis: 48%;
   }
   #internet-feature-banner-title {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;

      /* identical to box height, or 100% */

      /* Grey/300 */
      margin-top: 20px;
      color: #d0d7e2;
   }
   #internet-feature-banner-subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      /* Grey/400 */

      color: #a7b2c3;
   }
}
.yellow {
   &:hover {
      background-color: #db6409 !important;
   }
}
.blue {
   &:hover {
      background-color: #0050f0 !important;
   }
}
