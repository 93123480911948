@import "../../scss/include/variables";

.contact_us_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
}
.contact_us_banner {
   display: flex;
   height: 370px;
   align-items: center;
   justify-content: space-between;
   padding-left: 12px;
   .contact_us_banner_text {
      display: flex;
      flex-direction: column;
      .contact_us {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #7b8698;
      }
      .contact_us_header {
         font-style: normal;
         font-weight: 700;
         font-size: 54px;
         line-height: 62px;
         color: #192638;
         max-width: 450px;
         span {
            color: #0e9e49;
         }
      }
      .contact_us_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 450px;
      }
   }
   .contact_us_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: none;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}
.contact_us_container {
   display: flex;
   justify-content: center;
   margin-top: 80px;
   align-items: center;
   @include breakpoint(mobile) {
      flex-direction: column;
      gap: 40px;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
      gap: 40px;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
      gap: 40px;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
      gap: 150px;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
      gap: 150px;
   }
   @include breakpoint(2k) {
      flex-direction: row;
      gap: 150px;
   }
   .contact_us_form_container {
      max-width: 620px;
      background: #ffffff;
      padding: 54px 50px;
      border: 1px solid #f4f7fb;
      box-sizing: border-box;
      box-shadow: 0px 4px 32px -4px rgba(56, 71, 95, 0.12);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
         padding: 24px 20px;
      }
      .caption {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         color: #374253;
      }
      .sub_caption {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 26px;
         color: #7b8698;
         margin-bottom: 40px;
      }
      .contact_us_form {
         display: flex;
         flex-direction: column;
         .error {
            border: 1px solid $primary !important;
            margin-bottom: 8px !important;
         }
         .error_message {
            margin-bottom: 12px;
         }
         .Signup_Alert {
            display: none;
         }
         .Signup_Alert_show {
            max-width: 400px;
            width: fit-content;
            display: flex;
            align-items: center;
            padding: 12px;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            color: $primary;
            display: flex;
            align-items: center;
         }
         label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #374253;
         }
         select,
         input,
         textarea {
            margin-top: 6px;
            margin-bottom: 30px !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #374253;
         }
         #package {
            display: flex;
            max-width: 100%;
         }
         .input_container {
            display: flex;
            width: 100%;
            gap: 15px;
            @include breakpoint(mobile) {
               flex-direction: column;
            }
            @include breakpoint(phablet) {
               flex-direction: column;
            }
            @include breakpoint(tablet) {
               flex-direction: row;
            }
            @include breakpoint(laptop) {
               flex-direction: row;
            }
            @include breakpoint(desktop) {
               flex-direction: row;
            }
            @include breakpoint(2k) {
               flex-direction: row;
            }
            .name_container,
            .email_container {
               display: flex;
               flex-direction: column;
               width: 100%;
            }
            .name_input,
            .email_input {
               max-width: 100%;
            }
         }
         .ph_number_input {
            max-width: 100%;
         }
         .subject_input {
            resize: none;
            height: 116px;
            background: #f4f7fb;
            border: 1px solid #d0d7e2;
            border-radius: 8px;
            line-height: 22px;
            padding: 14px 16px;
            width: 100%;
            max-width: 100%;
            &:focus-visible {
               border: 1px solid #d0d7e2;
            }
         }
         .contact_us_btn {
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            color: #ffffff;
            background: #ed1c24;
            border-radius: 8px;
            padding: 12px 20px;
            &:hover {
               background: #d31118 !important;
            }
         }
         .disabled {
            background: $grey !important;
            cursor: no-drop;
         }
      }
   }
   .contact_us_details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;
      @include breakpoint(mobile) {
         padding-left: 12px;
         padding-right: 8px;
      }
      @include breakpoint(phablet) {
         padding-left: 12px;
         padding-right: 8px;
      }
      @include breakpoint(tablet) {
         padding-left: 12px;
         padding-right: 8px;
      }
      @include breakpoint(laptop) {
         padding: 0;
      }
      @include breakpoint(desktop) {
         padding: 0;
      }
      @include breakpoint(2k) {
         padding: 0;
      }
      .contact_us_details_card {
         display: flex;
         flex-direction: column;
         width: 100%;
         .caption {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #374253;
            margin-bottom: 6px;
         }
         .sub_caption {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #596579;
            margin-bottom: 20px;
            max-width: 425px;
         }
         .details {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
               height: 18px;
               width: 18px;
               margin-right: 15px;
            }
            .details_text {
               margin: 0;
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 24px;
               color: #596579;
               a {
                  color: #1a63f4;
                  text-decoration: none;
               }
            }
         }
      }
   }
}
