@import "../../scss/include/variables";

.offer {
   margin-top: 20px;
}
.app-card {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
}
.btn-outline-danger {
   background: #ed1c24 !important;
}
.btn-outline-success {
   color: #596579;
   border-color: #d0d7e2;
}
.app-card-div {
   cursor: pointer;
}
.offer_card {
   width: 250px;
   margin-top: 50px;
   height: 110px;
   // margin-left: 120px;
   margin-bottom: 20px;
   background: #ffffff;
   display: flex;

   /* Grey/100 */

   border: 1px solid #f4f7fb;

   box-shadow: 2px 3px 30px -8px rgba(35, 30, 36, 0.14);
   border-radius: 16px;
   &:hover {
      background: #f4f7fb;
   }
}

.appcard_image {
   margin-left: 5%;
   width: auto;
   height: 50px;
   margin-top: 10%;
}
.offer_text {
   display: flex;
}
.appcard_text {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 22px;
   /* identical to box height, or 110% */

   /* Grey/900 */
   margin-top: 20px;

   color: #0f1825;
}
.appcard_subtext {
   font-style: normal;
   font-weight: normal;
   font-size: 15px;
   line-height: 20px;
   /* or 133% */

   /* Grey/600 */

   /* Grey/600 */

   color: #596579;
}
#appcard_child {
   margin-left: 16px;
   margin-top: 18px;
}
.offer_img {
   margin-left: 10px;
}
.appcard_des {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 10px;
   /* or 133% */

   /* Grey/600 */

   color: #596579;
}
