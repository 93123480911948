@import "../include/variables";

.exclusive_channel_wrapper {
   background: rgba(244, 247, 251, 0.5);
   .wrapper_heading {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 24px;
         color: #596579;
      }
   }
   .exclusive_channel_container {
      flex-direction: row;
      justify-content: space-evenly;
      overflow: hidden;
      gap: 30px;
      .splide__pagination {
         li {
            margin-top: 18px;
         }
      }
      .splide__pagination__page.is-active {
         background: #ed1c24;
      }
      .ottChannel_slide {
         width: 100%;
      }
   }
}
