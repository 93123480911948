@import "../../scss/include/variables";
.faq_width_container {
   background: rgba(243, 247, 254, 0.5);
   flex-direction: column;
}
.faq_banner {
   display: flex;
   height: 370px;
   align-items: center;
   justify-content: space-between;
   padding-left: 12px;
   .faq_banner_text {
      display: flex;
      flex-direction: column;
      .faq {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;

         color: #7b8698;
      }
      .faq_header {
         font-style: normal;
         font-weight: 700;
         // font-size: 54px;
         // line-height: 62px;
         @include HeaderFontResponsive(54, 62);
         color: #192638;
         max-width: 400px;
         span {
            color: #ed1c24;
         }
      }
      .faq_sub_header {
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #564d6d;
         max-width: 450px;
      }
   }
   .faq_banner_img {
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: block;
      }
      @include breakpoint(laptop) {
         display: block;
      }
      @include breakpoint(desktop) {
         display: block;
      }
      @include breakpoint(2k) {
         display: block;
      }
   }
}

.faq_container {
   margin-top: 50px;
   display: flex;
   margin-bottom: 40px;
   @include breakpoint(mobile) {
      justify-content: center;
   }
   @include breakpoint(phablet) {
      justify-content: center;
   }
   @include breakpoint(tablet) {
      justify-content: center;
   }
   @include breakpoint(laptop) {
      justify-content: space-between;
   }
   @include breakpoint(desktop) {
      justify-content: space-between;
   }
   @include breakpoint(2k) {
      justify-content: space-between;
   }
   .faq_container_category {
      display: flex;
      flex-direction: column;
      flex-basis: 28%;
      max-width: 220px;
      @include breakpoint(mobile) {
         display: none;
      }
      @include breakpoint(phablet) {
         display: none;
      }
      @include breakpoint(tablet) {
         display: none;
      }
      @include breakpoint(laptop) {
         display: flex;
      }
      @include breakpoint(desktop) {
         display: flex;
      }
      @include breakpoint(2k) {
         display: flex;
      }
      .category_button {
         display: flex;
         border: none;
         align-items: center;
         justify-content: flex-start;
         color: #7b8698;
         background: #fff;
         border-radius: 8px;
         height: 50px;
         padding: 14px 28px 14px 24px;
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
      }
      .active {
         color: #ed1c24;
         background: #fef3f3;
      }
   }
   .faq_container_content {
      @include breakpoint(mobile) {
         width: 95%;
      }
      @include breakpoint(phablet) {
         width: 85%;
      }
      @include breakpoint(tablet) {
         width: 85%;
      }
      @include breakpoint(laptop) {
         width: 65%;
      }
      @include breakpoint(desktop) {
         width: 65%;
      }
      @include breakpoint(2k) {
         width: 65%;
      }
      .table_of_content_btn {
         font-style: normal;
         font-weight: 600;
         font-size: 15px;
         line-height: 18px;
         color: #ffffff;
         background: #ed1c24;
         border-radius: 8px;
         gap: 10px;
         padding: 16px;
         justify-content: center;
         align-items: center;
         max-width: 178px;
         margin-bottom: 40px;
         @include breakpoint(mobile) {
            display: flex;
         }
         @include breakpoint(phablet) {
            display: flex;
         }
         @include breakpoint(tablet) {
            display: flex;
         }
         @include breakpoint(laptop) {
            display: none;
         }
         @include breakpoint(desktop) {
            display: none;
         }
         @include breakpoint(2k) {
            display: none;
         }
      }

      .active_title {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 36px;
         @include breakpoint(mobile) {
            font-size: 20px;
            line-height: 24px;
         }
         color: #192638;
         padding-bottom: 20px;
         width: 100%;
         border-bottom: 1px solid #e7e6eb;
      }
      .accordion {
         width: 100%;
         .accordion-item {
            border: none;
            border-bottom: 1px solid #e7e6eb;
            padding-top: 20px;
            padding-bottom: 20px;
            :focus {
               box-shadow: none !important;
            }
            @include breakpoint(mobile) {
               padding-top: 8px;
               padding-bottom: 8px;
            }

            .accordion-button {
               font-style: normal;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
               color: #374253;
               line-height: 26px;
               padding-left: 0px;
               padding-right: 0px;
               box-shadow: none !important;
               @include breakpoint(mobile) {
                  font-size: 16px;
                  line-height: 20px;
               }
            }
            .accordion-button:not(.collapsed) {
               background-color: #fff;
               color: #ed1c24;
               box-shadow: none;
               font-style: normal;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
               padding-left: 0px;
               padding-right: 0px;
               box-shadow: none !important;
               @include breakpoint(mobile) {
                  font-size: 16px;
                  line-height: 20px;
               }
            }
            .accordion-button::after {
               background-image: url(../../assets/package/expand.png);
            }
            .accordion-button:not(.collapsed)::after {
               background-image: url(../../assets/package/collapse.png);
            }
         }
         .accordion-body {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #374253;
            padding-left: 0px;
            padding-right: 0px;
            @include breakpoint(mobile) {
               font-size: 14px;
               line-height: 18px;
            }
         }
      }
   }
}
.faq_wrapper .faq_question .accordion .accordion-item .accordion-button {
   box-shadow: none !important;
}
