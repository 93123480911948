.dishhomeGo_history {
   .package_Container {
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      .dashboard-ppv__movieCard {
         display: flex;
         flex-direction: column;
         img {
            height: 364px;
            width: 250px;
            object-fit: cover;
            border-radius: 6px;
            margin-bottom: 16px;
         }
         .dashboard-ppv__movieCard-info-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #192638;
            margin-bottom: 8px;
         }
         .dashboard-ppv__movieCard-info-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #192638;
            margin-bottom: 12px;
         }
         .showtime {
            width: fit-content;
            background: #0e9e49;
            border-radius: 6px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            padding: 6px 10px;
            color: #ffffff;
         }
      }
   }
}
