@import "../include/variables";
.individualpackageinfo_bg {
   background-color: #fffaf4;
}

.individualpackageinfo_wrapper {
   display: flex;
   gap: 100px;
   // justify-content: space-evenly;
   @include breakpoint(mobile) {
      flex-direction: column;
   }
   @include breakpoint(tablet) {
      flex-direction: column;
   }
   @include breakpoint(phablet) {
      flex-direction: column;
   }
   @include breakpoint(laptop) {
      flex-direction: row;
   }
   @include breakpoint(desktop) {
      flex-direction: row;
   }
}
.individualpackageinfo_packagecard {
   display: flex;
   align-items: center;
   justify-content: center;
}
.individualpackageinfo_moreinfo_wrapper {
   display: flex;
   flex-direction: column;
   text-align: center;
   .small_text {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      /* identical to box height, or 144% */
      color: #1a63f4;
   }
   .individualpackageinfo_title {
      font-weight: 600;
      // font-size: 54px;
      // line-height: 62px;
      /* identical to box height, or 115% */
      /* Grey/800 */
      color: #192638;
      @include HeaderFontResponsive(54, 62);
   }
   .channel_info {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @include breakpoint(mobile) {
         flex-wrap: wrap;
      }
      @include breakpoint(tablet) {
         flex-wrap: wrap;
      }
      @include breakpoint(phablet) {
         flex-wrap: wrap;
      }
      @include breakpoint(laptop) {
         flex-wrap: nowrap;
      }
      @include breakpoint(desktop) {
         flex-wrap: nowrap;
      }
      .text {
         span {
            color: #0faf51;
         }
         @include breakpoint(mobile) {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 8px;
         }
         @include breakpoint(tablet) {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 8px;
         }
         @include breakpoint(phablet) {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 8px;
         }
         @include breakpoint(laptop) {
            flex-basis: 45%;
            text-align: left;
            width: 300px;
            max-width: 400px;
         }
         @include breakpoint(desktop) {
            flex-basis: 45%;
            text-align: left;
            width: 300px;
            max-width: 400px;
         }
      }
      .hd_channel,
      .sd_channel {
         flex-basis: 20%;
         display: flex;
         flex-direction: row;
         align-items: center;
         img {
            height: 64px;
            width: 64px;
         }
         .channel_text {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            h1 {
               margin: 0;
               font-weight: 600;
               font-size: 32px;
               line-height: 32px;
               /* identical to box height, or 100% */
               /* Grey/800 */
               color: #192638;
            }
            p {
               margin: 0;
               font-weight: 500;
               font-size: 18px;
               line-height: 26px;
               /* identical to box height, or 144% */
               /* Grey/700 */
               color: #374253;
            }
         }
      }
   }
}
