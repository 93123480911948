@import "../include/variables";
.brand {
   margin-top: 20px;
   cursor: pointer;
}

.brand_container {
   max-width: 1150px;
   margin: 0 auto;
   padding: 0 10px;
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   grid-gap: 2rem;
}
.brandwrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   .splide__arrow {
      top: 60% !important;
   }
   .splide__arrow--prev {
      left: -3em !important;
   }
   .splide__arrow--next {
      right: -3em !important;
   }
   .splide__pagination {
      bottom: -5.5em;
      height: 45px;
   }
   .splide__list {
      // justify-content: center;
   }
   .splide__pagination__page.is-active {
      background-color: #ed1c24;
   }
   .splide {
      @include breakpoint(mobile) {
         width: 400px;
      }
      @media only screen and (max-width: 400px) {
         width: 180px;
      }
      @include breakpoint(phablet) {
         width: 380px;
      }
      @include breakpoint(tablet) {
         width: 400px;
      }
      @include breakpoint(laptop) {
         width: 680px;
      }
      @media screen and (min-width: 1100px) {
         width: 600px;
         .splide__list {
            justify-content: center;
         }
      }
      @include breakpoint(desktop) {
         width: 800px;
         .splide__list {
            justify-content: center;
         }
      }
      @include breakpoint(2k) {
         width: 800px;
         .splide__list {
            justify-content: center;
         }
      }
   }
}
@media only screen and (max-width: 600px) {
   .brand_container {
      margin-left: 80px;
   }
}
