@import "../../scss/include/variables";

.infobanner_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;

   .text {
      .title {
         font-style: normal;
         color: #0f1825;
         text-align: center;
         span {
            color: #ed1c24;
         }
         @include breakpoint(mobile) {
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            text-align: center;
         }
         @include breakpoint(phablet) {
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
         }
         @include breakpoint(tablet) {
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
         }
         @include breakpoint(laptop) {
            font-weight: 600;
            font-size: 40px;
            text-align: center;
            line-height: 62px;
         }
         @include breakpoint(desktop) {
            font-weight: 600;
            font-size: 40px;
            text-align: center;
            line-height: 62px;
         }
         @include breakpoint(2k) {
            font-weight: 600;
            font-size: 40px;
            line-height: 62px;
         }
      }
   }
   .info_card_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      .red {
         background-color: #ed1c24;
      }
      .green {
         background-color: #0e9e49;
      }
      .yellow {
         background-color: #ff6f00;
      }
      .blue {
         background-color: #1a63f4;
      }
   }
}
