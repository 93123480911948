@import "variables";
.footer-wrapper {
   .icon-footer {
      display: none;
      @include breakpoint(mobile) {
         display: flex;
         font-size: 30px;
         justify-content: center;
         margin-bottom: 24px;
         gap: 15px;
      }
   }
}

.footer {
   background: #333333;
   padding: 8px;
   color: white;
   display: flex;
   justify-content: center;
   .fixed-width75 {
      @include breakpoint(mobile) {
         width: 100% !important;
         max-width: 100% !important;
         padding: 0px 16px;
      }
      .icon-footer {
         display: flex;
         flex-basis: 14%;
         justify-content: center;
         @include breakpoint(mobile) {
            display: none;
         }
      }
      .footericon-list {
         margin-left: 15px;
         position: relative;
         width: 30px;
         height: 30px;
         background: #3c3d3f;
         border-radius: 50%;
         &:hover {
            background: #5c5d5e;
         }
      }
   }
}

.footer-text {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   /* identical to box height, or 157% */
   cursor: pointer;
   /* Grey/50 */

   color: #ffffff;
   @include breakpoint(mobile) {
      &:last-child {
         display: none;
      }
   }
}
.first-footer {
   display: flex;
   justify-content: center;
   flex-basis: 80%;
   align-items: center;
   @include breakpoint(mobile) {
      width: 100%;
      flex: 1;
      justify-content: space-between;
      gap: 14px;
   }
}
.first-footer li {
   list-style-type: none;
   margin-left: 17px;
   margin-right: 17px;
   &:hover {
      color: #a7b2c3;
   }
   @include breakpoint(mobile) {
      margin: 5px;
   }
}
#footer_social_icon {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
