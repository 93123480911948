#products_image_Text_design {
   color: #7b8698;
   font-size: 12px;
   margin: 0;
   font-weight: 600;
   // &::first-letter {
   //    font-size: 14px;
   //    font-weight: 600;
   // }
}

.ProductDescriptionOptions {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-top: 20px;
   gap: 24px;
   .ProductDescriptionOptions_title,
   .ProductDescriptionOptions_title_active {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #192638;
      padding-bottom: 4px;
      border: none;
   }
   .ProductDescriptionOptions_title_active {
      border-bottom: 3px solid #ed1c24;
      color: #ed1c24;
   }
}
.rating_title {
   margin: 0px;
   font-size: 14px;
   line-height: 14px;
   color: #374253;
   margin-left: 10px;
}

.ReviewStars {
   margin-left: 8px;
}
.userReview {
   margin-bottom: 18px;
   border-bottom: 1px solid #b4b6ba;
   padding-bottom: 12px;
   margin-left: 6px !important;
   margin-right: 6px !important;
   p {
      margin: 0px;
      padding: 0px;
   }
   div {
      margin: 0px;
      padding: 0px;
   }
   .user_name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #374253;
   }
   .review_data {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #596579;
   }
}
